import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/modal.actions';
import OkCancelModal from './OkCancelModal';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickOk: () => {
    dispatch(hideModal());
  },
  onClickCancel: () => {
    dispatch(hideModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OkCancelModal);
