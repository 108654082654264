import { useState } from 'react';
import useEventListener from '@use-it/event-listener';
import { isTextFieldFocused as getIsTextFieldFocused } from '../../util/domUtil';

export default function useIsTextFieldFocused() {
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(
    getIsTextFieldFocused(),
  );

  useEventListener('resize', () => {
    setIsTextFieldFocused(getIsTextFieldFocused());
  });

  useEventListener('focusin', () => {
    setIsTextFieldFocused(getIsTextFieldFocused());
  });

  useEventListener('focusout', () => {
    setIsTextFieldFocused(getIsTextFieldFocused());
  });

  return isTextFieldFocused;
}
