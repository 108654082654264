import styled from 'styled-components';

export const Template = styled.div``;

export const Pages = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
`;

export const PagesTouchable = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const PagesButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  /*bottom: 35px;*/
  z-index: 5;
`;

export const PagesForwardBack = styled.div`
  position: relative;
  bottom: 12px;
`;

export const Slide = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;

export const Slides = styled.div`
  display: flex;
  overflow-x: hidden;

  transition: ${props => (props.isTransitioning ? 'transform 0.25s' : '')};

  /* * {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
  } */
`;
