import React from 'react';
import PropTypes from 'prop-types';
import './BottomInfo.css';

export default function BottomInfo({ children }) {
  return (
    <div className="BottomInfo">
      <div className="BottomInfo__Content">{children}</div>
    </div>
  );
}

BottomInfo.propTypes = {
  children: PropTypes.node,
};
