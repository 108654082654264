import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import * as S from './GroupsListItem.styled';
import avatarFallback from '../../Theme/Images/android-red-icon-192x192.png';
import Text from '../../Typography/Text/Text';
import GroupMenuConnected from '../../Studio/GroupMenu/GroupMenuConnected';

function GroupsListItem({
  onClick,
  groupCuid,
  groupName,
  imageUrl,
  isEditable = true,
}) {
  return (
    <ListItem
      button
      onClick={() => {
        onClick(groupCuid);
      }}>
      <ListItemAvatar>
        <S.GroupImage
          style={{ opacity: imageUrl ? 1 : 0.5 }}
          src={imageUrl || avatarFallback}
          alt="Song Icon"
        />
      </ListItemAvatar>
      <span>
        <span>
          <Text size="xLarge">{groupName}</Text>
        </span>
      </span>

      <ListItemSecondaryAction>
        <GroupMenuConnected groupCuid={groupCuid} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

GroupsListItem.propTypes = {
  onClick: PropTypes.func,
  groupCuid: PropTypes.string,
  groupName: PropTypes.string,
  imageUrl: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default GroupsListItem;
