import { connect } from 'react-redux';
import ResubscribeModal from './ResubscribeModal';
import { resubscribeThunk } from '../../../store/billing/billing.thunks';
import { hideModal } from '../../../store/modal/modal.actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onClickOk: () => {
    dispatch(resubscribeThunk());
  },
  onClickCancel: () => {
    dispatch(hideModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResubscribeModal);
