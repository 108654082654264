import { connect } from 'react-redux';
import PdfCard from './PdfCard';

import {
  getFileRecord,
  getFileUrl,
} from '../../../store/myMusic/myMusic.selectors';
import { deleteSheetMusicThunk } from '../../../store/myMusic/myMusic.thunks';
import { downloadSheetMusic } from '../../../util/pdfUtil';

const mapStateToProps = (state, ownProps) => {
  const { songCuid, sheetMusicCuid, fileCuid, filesetCuid } = ownProps;

  const fileRecord = getFileRecord(filesetCuid, fileCuid, state) || {};
  const { filename, size_in_bytes } = fileRecord;
  const pdfUrl = getFileUrl(fileCuid, filesetCuid, state);
  return {
    songCuid,
    sheetMusicCuid,
    filename: filename,
    sizeInBytes: size_in_bytes,
    pdfUrl,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: (pdfUrl, filename) => {
      downloadSheetMusic(pdfUrl, filename);
    },
    onClickDelete: (songCuid, sheetMusicCuid) => {
      dispatch(deleteSheetMusicThunk(songCuid, sheetMusicCuid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PdfCard);
