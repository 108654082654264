import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import Logo from '../../Theme/Logo/Logo';
import PopupView from '../../Layout/PopupView/PopupView';
import PageError from '../../Form/PageError/PageError';

import * as S from './ConfirmSignup.styled';

const ConfirmSignup = ({
  onConfirmSignup,
  errorMsg,
  location = { search: '?' },
}) => {
  const urlParams = querystring.parse(location.search.substring(1));
  const confirmationCode = urlParams.confirmation_code;
  const username = urlParams.user_name;

  const handleConfirmationSubmit = event => {
    // event.preventDefault();
    onConfirmSignup(username, confirmationCode);
  };

  return (
    <S.ConfirmSignup>
      {errorMsg && <PageError>{errorMsg}</PageError>}

      <>
        <Logo size="small" />
      </>

      <PopupView
        title="Welcome to Choirpal"
        imageName="trio"
        body={username}
        buttonText="Confirm"
        onDismiss={handleConfirmationSubmit}
      />
    </S.ConfirmSignup>
  );
};

ConfirmSignup.propTypes = {
  errorMsg: PropTypes.string,
  onConfirmSignup: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default ConfirmSignup;
