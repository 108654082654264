import React from 'react';
import PropTypes from 'prop-types';
import imageUrl from './Conductor.png';

const sizes = {
  xsmall: 242,
  medium: 484,
  large: 726, // width of png file
};

export default function Conductor({ classes, size = 'small' }) {
  const width = sizes[size];
  return (
    <div className="Conductor">
      <img
        style={{ width: width }}
        className={classes}
        src={imageUrl}
        alt="Choir Conductor"
      />
    </div>
  );
}

Conductor.propTypes = {
  classes: PropTypes.array,
  size: PropTypes.string,
};
