import apiInit, { getIdentityId } from '../api';

const { get, post, del } = apiInit('groups');

export const postGroupDB = async group =>
  post(`/groups/${group.groupCuid}`, group);

export const updateGroupDB = async (groupCuid, fileCuid) =>
  post(`/groups/${groupCuid}/update`, { fileCuid });

export const deleteGroupDB = async groupCuid => del(`/groups/${groupCuid}`);

export const leaveGroupDB = async groupCuid => del(`/groups/${groupCuid}/member`);

export const fetchGroupsDB = async () => get(`/groups`);

// Return songs (with filesets) belonging to groupCuid
export const fetchGroupSongs = async groupCuid =>
  get(`/groups/${groupCuid}/songs`);

export const inviteMembersDB = async (groupCuid, invites, groupName) =>
  post(`/groups/${groupCuid}/invites`, {
    invites,
    groupName,
  });

// REMOVE USER FROM GROUP
export const deleteMemberDB = async (groupCuid, userCuid) =>
  del(`/groups/${groupCuid}/member/${userCuid}`);

export const fetchMembersDB = async groupCuid =>
  get(`/groups/${groupCuid}/members`);

export const fetchGroupInvitesDB = async groupCuid =>
  get(`/groups/${groupCuid}/invites`);

export const fetchUserInvitesDB = async () => get(`/users/invites`);

export const deleteGroupInviteDB = async groupInviteCuid =>
  del(`/invites/${groupInviteCuid}`);

export const acceptInviteDB = async groupInviteCuid =>
  post(`/invites/${groupInviteCuid}/accept`);

export const declineInviteDB = async groupInviteCuid =>
  post(`/invites/${groupInviteCuid}/decline`);

export const postFileRecord = async fileRecord => {
  const identityId = await getIdentityId();
  post(`/file`, { fileRecord, identityId });
};
