import styled from 'styled-components';

import { SMALL_BREAKPOINT } from '../../hooks/useBreakpoint';

export const OkCancelContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  min-width: ${SMALL_BREAKPOINT}px;
`;

export const OkCancelView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const OkCancelBody = styled.div`
  margintop: 20;
  width: 80%;
  text-align: center;
  font-size: 20;
`;
