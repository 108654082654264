import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Repeat from '@material-ui/icons/Repeat';
import RepeatOne from '@material-ui/icons/RepeatOne';

import { withStyles } from '@material-ui/core/styles';

const icons = {
  DEFAULT: Repeat,
  LOOP_PLAYLIST: Repeat,
  LOOP_SONG: RepeatOne,
};

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    fontSize: 'large',
  },
});

function Loop({ loopingMode, isEnabled, onClick, classes }) {
  const CurrentIcon = icons[loopingMode] || Repeat;

  return (
    <div>
      <IconButton
        aria-label="Repeat"
        className={classes.button}
        onClick={onClick}>
        <CurrentIcon
          style={{
            backgroundColor: loopingMode === 'DEFAULT' ? '' : 'lightgray',
          }}
        />
      </IconButton>
    </div>
  );
}

Loop.propTypes = {
  loopingMode: PropTypes.string,
  isEnabled: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(Loop);
