import React from 'react';
import IconSVG from './IconSVG';
import { color } from '../../design-tokens';

// TODO: fix lint errors
/* eslint-disable */
export default function createSvgIcon(
  path,
  viewBoxWidth,
  viewBoxHeight,
  defaultColor = color.red80,
  defaultFillColor = color.white,
  defaultStrokeWidth,
) {
  return ({
    width,
    height,
    color = '',
    fillColor = '',
    strokeWidth = '',
    ...props
  }) => {
    const iconColor = color || defaultColor;
    const iconStrokeWidth = strokeWidth || defaultStrokeWidth;
    const iconFillColor = fillColor || defaultFillColor || iconColor;

    return (
      <IconSVG width={width} height={height} color={iconColor} {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
          stroke={iconColor}
          strokeWidth={iconStrokeWidth}
          fill={iconFillColor}>
          {path}
        </svg>
      </IconSVG>
    );
  };
}
