import * as events from './modal.events';

const initialState = { queue: [], hintsViewed: {} };

export const modalReducer = (state = initialState, action = {}) => {
  const { queue = [] } = state;
  switch (action.type) {
    case events.SHOW_MODAL:
      return {
        ...state,
        queue: [
          ...queue,
          {
            modalType: action.modalType,
            modalProps: action.modalProps,
          },
        ],
        hintsViewed: {
          ...state.hintsViewed,
          [action.modalType]: true,
        },
      };
    case events.HIDE_MODAL:
      return {
        ...state,
        queue: queue.slice(1),
      };

    default:
      return state;
  }
};
