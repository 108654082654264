import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import * as S from './SongMenu.styled.js';

function SongMenu({
  songCuid,
  songTitle,
  onClickSongDetails,
  onClickShare,
  onClickDelete,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleButtonClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleSongDetailsClick(e) {
    e.stopPropagation();
    onClickSongDetails(songCuid);
    handleClose();
  }
  function handleShareClick(e) {
    e.stopPropagation();
    onClickShare(songCuid);
    handleClose();
  }
  function handleDeleteClick(e) {
    e.stopPropagation();
    onClickDelete(songCuid, songTitle);
    handleClose();
  }
  return (
    <div style={{ color: 'inherit' }}>
      <IconButton
        aria-owns={anchorEl ? 'SongMenu__Menu' : undefined}
        aria-haspopup="true"
        color="inherit"
        aria-label="Song Context Menu"
        onClick={handleButtonClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="SongMenu__Menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleSongDetailsClick}>Song Details</MenuItem>
        <MenuItem onClick={handleShareClick}>Share</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

SongMenu.propTypes = {
  songCuid: PropTypes.string.isRequired,
  songTitle: PropTypes.string.isRequired,
  onClickSongDetails: PropTypes.func.isRequired,
  onClickShare: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default SongMenu;
