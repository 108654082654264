import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './CreateGroup.styled';

import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import H2 from '../../Typography/Heading/H2';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

function CreateGroup({
  onCancel,
  onCreate,
  onHideError,
  error,
  isPostingGroup,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [groupName, setGroupName] = useState('');

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleExit = () => {
    onCancel();
  };

  const handleGroupNameChange = event => {
    setGroupName(event.target.value);
    onHideError();
  };

  const handleSubmit = event => {
    event.preventDefault();
    onCreate(groupName);
  };

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={handleCancel}
      onEscapeKeyDown={handleCancel}
      onExited={handleExit}>
      <S.EditGroupDetails>
        <S.EditGroupContainer>
          <S.EditGroupForm onSubmit={handleSubmit}>
            <H2>New Group</H2>
            <S.GroupName>
              <TextField
                style={{ marginTop: 10, width: '100%' }}
                placeholder='"My Choir"'
                required
                variant="standard"
                margin="normal"
                value={groupName || ''}
                onChange={handleGroupNameChange}
                autoFocus={true}
              />
            </S.GroupName>
            <S.Save>
              {error ? (
                <Alert severity="warning">{error}</Alert>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth={true}
                  type="submit"
                  name="submit">
                  {isPostingGroup ? (
                    <CircularProgress
                      size={26}
                      color="secondary"
                      style={{ color: 'white' }}
                    />
                  ) : (
                    <>Create</>
                  )}
                </Button>
              )}
            </S.Save>
          </S.EditGroupForm>
        </S.EditGroupContainer>
      </S.EditGroupDetails>
    </Dialog>
  );
}

CreateGroup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onHideError: PropTypes.func.isRequired,
  error: PropTypes.string,
  isPostingGroup: PropTypes.bool.isRequired,
};

export default CreateGroup;
