import React from 'react';
import PropTypes from 'prop-types';
import * as S from './GroupHeader.styled';
import H1 from '../../Typography/Heading/H1';
import GroupAvatarConnected from '../GroupAvatar/GroupAvatarConnected';

import GroupMenuConnected from '../../Studio/GroupMenu/GroupMenuConnected';
import Link from '../../Form/Link/Link';

function GroupHeader({
  groupName,
  adminName,
  groupCuid,
  isAdmin,
  memberList = [],
}) {
  // All groups have at least one member, the creator, except the hardcoded groups.
  const membersText =
    memberList.length > 0 ? `${memberList.length} members` : '';
  return (
    <S.GroupHeader>
      <S.Left>
        <GroupAvatarConnected groupCuid={groupCuid} />

        <S.GroupDetails>
          <S.GroupName>
            <H1>{groupName}</H1>
          </S.GroupName>
          {adminName && <S.AdminName>admin: {adminName}</S.AdminName>}

          {isAdmin ? (
            <Link to={`/group/${groupCuid}/edit`}>{membersText}</Link>
          ) : (
            <React.Fragment>{membersText}</React.Fragment>
          )}
        </S.GroupDetails>
      </S.Left>
      <GroupMenuConnected groupCuid={groupCuid} />
    </S.GroupHeader>
  );
}

GroupHeader.propTypes = {
  groupCuid: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  groupName: PropTypes.string.isRequired,
  adminName: PropTypes.string,
  memberList: PropTypes.array,
};

export default GroupHeader;
