import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Link.styled';

const linkStyles = {};

export default function Link({ to, children, style }) {
  const combinedStyles = { ...linkStyles, ...style };
  return (
    <S.Link to={to} style={combinedStyles}>
      {children}
    </S.Link>
  );
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.string,
};
