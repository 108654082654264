import styled from 'styled-components';
import { color } from '../../design-tokens';

export const PrivacyTitle = styled.div`
  font-family: 'Platform Web';
  font-weight: 400;
  color: ${color.pink100}
  font-size: large;
  overflow: hidden;
`;
export const PrivacyPolicy = styled.div``;

export const Logo = styled.div`
  padding: 3em;
`;
export const PrivacyPolicyContainer = styled.div``;
