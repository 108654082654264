import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import querystring from 'querystring';
import PageError from '../../Form/PageError/PageError';
import H2 from '../../Typography/Heading/H2';
import * as SS from '../Auth.styled';

const RequestReset = ({
  onRequestReset,
  errorMsg,
  location = { search: '?' },
}) => {
  const urlParams = querystring.parse(location.search.substring(1));
  const [username, setUsername] = useState(urlParams.username || '');

  const onSubmit = event => {
    event.preventDefault();
    onRequestReset(username);
  };

  return (
    <SS.AuthPage>
      {errorMsg && <PageError>{errorMsg}</PageError>}

      <SS.AuthLogo />

      <H2>Reset Password</H2>

      <SS.AuthInputs>
        <TextField
          label="Email Address"
          style={{ width: '100%' }}
          variant="outlined"
          required
          value={username}
          onChange={({ target }) => setUsername(target.value.trim())}
        />
      </SS.AuthInputs>

      <div className="RequestReset__RequestResetButton">
        <Button
          style={{ width: '80%' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={onSubmit}>
          Reset Password
        </Button>
      </div>
    </SS.AuthPage>
  );
};

RequestReset.propTypes = {
  errorMsg: PropTypes.string,
  onRequestReset: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default RequestReset;
