import { connect } from 'react-redux';
import CreateGroup from './CreateGroup';

// import { editGroupRoute } from '../../routes';
import { addGroup, hideError } from '../../../store/groups/groups.actions';
import { newGroup } from '../../../store/groups/groups.model';
import { postGroupDBThunk } from '../../../store/groups/groups.thunks';
import {
  getGroupError,
  isPostingGroup,
} from '../../../store/groups/groups.selectors';

const mapStateToProps = state => {
  return {
    error: getGroupError(state) || null,
    isPostingGroup: isPostingGroup(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => {
    ownProps.history.goBack();
    dispatch(hideError());
  },
  onCreate: groupName => {
    const group = newGroup(groupName);
    dispatch(addGroup(group));
    // dispatch(editGroupRoute(group.groupCuid));
    dispatch(postGroupDBThunk(group));
  },
  onHideError: () => {
    dispatch(hideError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateGroup);
