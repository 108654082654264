import { APP_INIT, APP_UNLOAD, API_VERSION_SUCCESS } from '../app/app.events';
import {
  LOGIN_SUCCESS,
  FETCH_USER_SUCCESS,
  TOKEN_REFRESHED,
} from '../user/user.events';
import { fetchUserThunk } from '../user/user.thunks';
import {
  FETCH_USER_INVITES_SUCCESS,
  ACCEPT_INVITE_SUCCESS,
} from '../groups/groups.events';
import {
  fetchMySongsThunk,
  storeHardcodedSongsThunk,
} from '../myMusic/myMusic.thunks';
import { fetchSubscriptionThunk } from '../billing/billing.thunks';
import {
  fetchGroupsDBThunk,
  fetchUserInvitesThunk,
  acceptInviteThunk,
  fetchGroupSongsThunk,
} from '../groups/groups.thunks';
import {
  getUserInviteList,
  canViewHardcodedGroups,
} from '../groups/groups.selectors';
import { apiMismatch } from '../app/app.actions';
import { showModal } from '../modal/modal.actions';
import { fetchApiVersionThunk } from '../app/app.thunks';
import { getApiVersion } from '../../store/app/app.selectors';
import { isLoggedIn } from '../../store/user/user.selectors';
import { version } from '../../../package.json';
import { setPersistedState } from '../persistedStateUtil';
import semver from 'semver';

export const PreloaderMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  const { dispatch } = store;
  switch (action.type) {
    case APP_INIT: {
      dispatch(fetchApiVersionThunk());

      break;
    }
    case APP_UNLOAD: {
      isLoggedIn(state) && setPersistedState(state);

      break;
    }
    case API_VERSION_SUCCESS: {
      const apiVersion = getApiVersion(state);
      const apiMajor = semver.major(apiVersion);
      const uiMajor = semver.major(version);
      if (apiMajor > uiMajor) {
        dispatch(apiMismatch());
        dispatch(
          showModal('POPUP', {
            show: true,
            title: 'App Update Available',
            imageName: 'trio',
            body: 'Please close and reopen the app to update.',
            buttonText: null,
          }),
        );
      }

      break;
    }
    case TOKEN_REFRESHED: {
      // user session had expired - get S3 urls again
      dispatch(fetchMySongsThunk());
      dispatch(fetchGroupSongsThunk());

      break;
    }
    case FETCH_USER_SUCCESS: {
      if (
        process.env.REACT_APP_PRELOAD_HARDCODED_SONGS === 'true' &&
        canViewHardcodedGroups(state)
      ) {
        dispatch(storeHardcodedSongsThunk());
      }
      dispatch(storeHardcodedSongsThunk(true)); // choirpalGroup
      break;
    }

    case LOGIN_SUCCESS: {
      // const { songCuid } = action.data;
      dispatch(fetchGroupsDBThunk());
      dispatch(fetchUserInvitesThunk());
      dispatch(fetchMySongsThunk());
      dispatch(fetchUserThunk());
      dispatch(fetchSubscriptionThunk());

      break;
    }
    case FETCH_USER_INVITES_SUCCESS: {
      const invites = getUserInviteList(state);
      invites.forEach(invite => {
        dispatch(acceptInviteThunk(invite.groupInviteCuid));
      });
      break;
    }
    case ACCEPT_INVITE_SUCCESS: {
      dispatch(fetchGroupsDBThunk());

      break;
    }

    // TODO: Should we be depending on this event?
    // TODO: Where should this code live
    case '@@router/LOCATION_CHANGE': {
      // Used to hide the Main AppBar when typing
      // Trigger the event after the url change has had time to be rendered
      // This was needed for the show/hide MainToolbar feature
      // setTimeout(() => dispatch(windowResize()), 0);
      break;
    }

    default: {
      break;
    }
  }
};
