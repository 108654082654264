import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M50.853,125.404
    c0,10.82-9.148,19.596-20.427,19.596C19.142,145,10,136.225,10,125.404c0-10.812,9.143-19.596,20.427-19.596"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="50.853"
      y1="125.404"
      x2="50.853"
      y2="27.821"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M140,111.475c0,10.82-9.147,19.597-20.429,19.597
    c-11.282,0-20.425-8.776-20.425-19.597c0-10.813,9.143-19.596,20.425-19.596"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="140"
      y1="111.475"
      x2="140"
      y2="13.892"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M50.853,27.821c0-5.459,3.531-6.034,7.066-6.83"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M132.877,5.189C138.271,4.009,140,8.585,140,11.969
    "
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="132.877"
      y1="5.189"
      x2="57.919"
      y2="20.991"
    />
    <polyline
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      points="140,11.969 140,36.981 50.132,56.087  "
    />
  </g>,
  150,
  150,
);
