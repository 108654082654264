import styled from 'styled-components';
import { fontSize } from '../../design-tokens';

export const PlayPause = styled.div``;
export const Filename = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 50%;
  width: 100%;
  overflow: hidden;
  /*font-weight: bold;*/
  font-size: ${fontSize.xSmall};
  margin-bottom: 6px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: flex-end;
  height: 20px;
  margin-right: 16px;
  margin-bottom: 6px;
`;
