import { connect } from 'react-redux';
import RecentlyPlayedSongs from './RecentlyPlayedSongs';
import {
  getRecentlyPlayedSongs,
  getCurrentSongCuid,
  getPlaylistType,
} from 'src/store/player/player.selectors';
import { playSongThunk } from '../../../store/player/player.thunks';
import withAppContext from '../../../withAppContext';

const mergeProps = (state, { dispatch, ownProps }) => {
  const recentlyPlayedSongs = getRecentlyPlayedSongs(state);

  const { history } = ownProps;
  const onClickSong = songCuid => {
    playSongThunk(
      songCuid,
      'RECENTLY_PLAYED_PLAYLIST',
      undefined,
      state,
      dispatch,
      history,
    );
  };

  return {
    currentSongCuid: getCurrentSongCuid(state),
    isPlaylistActive: getPlaylistType(state) === 'RECENTLY_PLAYED_PLAYLIST',
    recentlyPlayedSongs,
    onClickSong,
  };
};

export default withAppContext(
  connect(
    state => state,
    (dispatch, ownProps) => ({ dispatch, ownProps }),
    mergeProps,
  )(RecentlyPlayedSongs),
);
