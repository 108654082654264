import React from 'react';
import PropTypes from 'prop-types';
// import * as S from './DeleteSongModal.styled';
import OkCancelModal from '../OkCancelModal/OkCancelModal';

const DeleteSongModal = ({
  songCuid,
  songTitle,
  playingSongCuid,
  onClickOk,
  onClickCancel,
}) => {
  const handleOkClick = () => {
    onClickOk(songCuid, playingSongCuid);
  };
  const handleCancelClick = () => {
    onClickCancel && onClickCancel();
  };

  return OkCancelModal({
    title: 'Delete Song?',
    imageName: 'trio',
    body: (
      <div>
        <div>Are you sure you want to delete the song:</div>
        <strong>{songTitle}</strong>
      </div>
    ),
    okButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    onClickOk: handleOkClick,
    onClickCancel: handleCancelClick,
  });
};

DeleteSongModal.propTypes = {
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  songCuid: PropTypes.string.isRequired,
  songTitle: PropTypes.string.isRequired,
  playingSongCuid: PropTypes.string.isRequired,
};

export default DeleteSongModal;
