import React from 'react';
import PropTypes from 'prop-types';
import marketplacePng from './Screenshot--marketplace.png';
import playerPng from './Screenshot--player.png';
import playlistPng from './Screenshot--playlist.png';
import songUploadPng from './Screenshot--songUpload.png';
import sheetMusic from './Screenshot--sheetMusic.png';

import * as S from './Screenshot.styled';

const images = {
  marketplace: marketplacePng,
  player: playerPng,
  playlist: playlistPng,
  songUpload: songUploadPng,
  sheetMusic,
};

export default function Screenshot({ variant, size = 'medium' }) {
  const img = images[variant];
  return (
    <S.Screenshot>
      <S.ScreenshotImage src={img} alt="Screenshot" size={size} />
    </S.Screenshot>
  );
}

Screenshot.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
};
