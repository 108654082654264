import { connect } from 'react-redux';
import App from './App';
import { isLoggedIn } from './store/user/user.selectors';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(App);
