import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Link from '../../Form/Link/Link';

import './RegisterInterest.css';

export default function RegisterInterest({ classes }) {
  const [email, setEmail] = useState('');

  return (
    <div className="RegisterInterest">
      <form
        className="RegisterInterest__Form"
        action="https://choirpal.us20.list-manage.com/subscribe/post"
        method="POST">
        <input type="hidden" name="u" value="2e3b5ae17396695eae30643e3" />
        <input type="hidden" name="id" value="8690ea0bd6" />

        <div className="RegisterInterest__Email">
          <TextField
            style={{ minWidth: '310px' }}
            label="Sign up to be notified when we launch!"
            placeholder="Your email"
            required
            variant="outlined"
            margin="dense"
            name="MERGE0"
            id="MERGE0"
            value={email}
            onChange={({ target }) =>
              setEmail(target.value.trim().toLowerCase())
            }
          />
        </div>
        <div className="RegisterInterest__RegisterButton">
          <Button
            className="RegisterInterest__RegisterButton"
            style={{ marginTop: 10 }}
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            name="submit">
            Subscribe
          </Button>
        </div>
      </form>

      <div
        className="RegisterInterest__PrivacyPolicy"
        style={{ display: 'none' }}>
        <span>
          By signing up I consent to receiving updates from Choirpal. See{' '}
        </span>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

RegisterInterest.propTypes = {
  classes: PropTypes.array,
};
