import styled from 'styled-components';
import { choirpal_grey_light } from '../../Theme/colors';

export const AddMedia = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  /*justify-content: space-around;*/
  align-items: center;
  text-align: center;
  padding-bottom: 20px;

  button {
    /*margin-bottom: 20px;*/
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const FileInputContainer = styled.div`
  display: none;
`;

export const SongDetails = styled.div`
  /*border: 1px solid var(--choirpal-grey-light);*/
  /*border-radius: 10px;*/
  width: 90%;
  position: relative;
  min-height: 100px;
`;

export const SongTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  margin-left: 16px;
`;

export const EditSongButton = styled.div`
  position: absolute;
  right: -10px;
  top: 5px;
`;

export const TracksContainer = styled.div`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const PlayButton = styled.div`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
  padding-right: 8px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${choirpal_grey_light};
  width: 100%;
  margin-bottom: 8px;
`;

export const GuidesContainer = styled.div`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;
