import { path } from 'ramda';
import { connect } from 'react-redux';
import MemberList from './MemberList';
import {
  deleteGroupInviteThunk,
  deleteMemberThunk,
  fetchMembersThunk,
  inviteMembersThunk,
} from '../../../store/groups/groups.thunks';
import {
  getMemberList,
  getPendingInviteList,
  getGroup,
} from '../../../store/groups/groups.selectors';

const mapStateToProps = (state, ownProps) => {
  const groupCuid = path(['groupCuid'], ownProps);

  return {
    groupCuid,
    group: getGroup(groupCuid, state),
    members: getMemberList(groupCuid, state),
    invites: getPendingInviteList(groupCuid, state),
  };
};

const mapDispatchToProps = dispatch => ({
  onInviteMembers: (groupCuid, emails, groupName) => {
    dispatch(inviteMembersThunk(groupCuid, emails, groupName));
  },
  onDeleteMember: (groupCuid, userCuid) => {
    dispatch(deleteMemberThunk(groupCuid, userCuid));
  },
  onDeleteInvite: (groupCuid, groupInviteCuid) => {
    dispatch(deleteGroupInviteThunk(groupCuid, groupInviteCuid));
  },
  onListMembers: groupCuid => {
    dispatch(fetchMembersThunk(groupCuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberList);
