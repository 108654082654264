import {
  POST_SUBSCRIPTION_STARTED,
  POST_SUBSCRIPTION_FAIL,
  POST_SUBSCRIPTION_SUCCESS,
  FETCH_COUPON_STARTED,
  FETCH_COUPON_FAIL,
  FETCH_COUPON_SUCCESS,
  FETCH_SUBSCRIPTION_STARTED,
  FETCH_SUBSCRIPTION_FAIL,
  FETCH_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_STARTED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  RESUBSCRIBE_STARTED,
  RESUBSCRIBE_SUCCESS,
  RESUBSCRIBE_FAIL,
  CLEAR_MODAL_MSGS,
  CLEAR_STRIPE_ERRORS,
} from './billing.events';
const a = event => () => ({ type: event });

// Subscribe to Choirpal
export const postSubscriptionStarted = a(POST_SUBSCRIPTION_STARTED);
export const postSubscriptionSuccess = (subscription, update) => ({
  type: POST_SUBSCRIPTION_SUCCESS,
  data: {
    subscription,
    update,
  },
});
export const postSubscriptionFail = response => ({
  type: POST_SUBSCRIPTION_FAIL,
  data: {
    error: response,
  },
});

export const postStripeFail = response => ({});

// read subscription to Choirpal
export const fetchSubscriptionStarted = a(FETCH_SUBSCRIPTION_STARTED);
export const fetchSubscriptionSuccess = response => ({
  type: FETCH_SUBSCRIPTION_SUCCESS,
  data: {
    subscription: response,
  },
});
export const fetchSubscriptionFail = a(FETCH_SUBSCRIPTION_FAIL);

// read subscription to Choirpal
export const fetchCouponStarted = a(FETCH_COUPON_STARTED);
export const fetchCouponSuccess = response => ({
  type: FETCH_COUPON_SUCCESS,
  data: {
    coupon: response,
  },
});
export const fetchCouponFail = response => ({
  type: FETCH_COUPON_FAIL,
  data: {
    error: response,
  },
});

// cancel subscription
export const cancelSubscriptionStarted = a(CANCEL_SUBSCRIPTION_STARTED);
export const cancelSubscriptionSuccess = response => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  data: {
    subscription: response,
  },
});
export const cancelSubscriptionFail = response => ({
  type: CANCEL_SUBSCRIPTION_FAIL,
  data: {
    error: response,
  },
});

// cancel subscription
export const resubscribeStarted = a(RESUBSCRIBE_STARTED);
export const resubscribeSuccess = response => ({
  type: RESUBSCRIBE_SUCCESS,
  data: {
    subscription: response,
  },
});
export const resubscribeFail = response => ({
  type: RESUBSCRIBE_FAIL,
  data: {
    error: response,
  },
});

export const clearModalMsgs = () => ({
  type: CLEAR_MODAL_MSGS,
  data: null,
});

export const clearStripeErrors = () => ({
  type: CLEAR_STRIPE_ERRORS,
  data: null,
});
