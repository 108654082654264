import { path } from 'ramda';

export const getBillingDetails = state => {
  const billing = state.billing || state;
  return billing.sub;
};

export const isPro = state => {
  const billing = state.billing || state;
  if (billing.sub) {
    return billing.sub.status === 'active';
  } else {
    return false;
  }
};

export const getSubscriptionStatus = state => {
  const billing = state.billing || state;
  return billing.sub.status;
};
export const getCoupon = state => {
  const billing = state.billing || state;
  return billing.coupon;
};

export const getCouponError = state => {
  const billing = state.billing || state;
  return billing.couponError;
};

export const getErrorMsg = state => {
  return path(['billing', 'errorMsg'], state);
};

export const getSuccessMsg = state => {
  return path(['billing', 'successMsg'], state);
};

// selector for when user is creating/updating subscription
export const isFetchingSubscription = state => {
  const billing = state.billing || state;
  // check if any stripe action is currently happening.
  return billing.isFetchingSubscription;
};

export const isPostingSubscription = state => {
  const billing = state.billing || state;
  return billing.isPostingSubscription;
};

export const isResubscribing = state => {
  const billing = state.billing || state;
  return billing.isResubscribing;
};

export const isCancellingSubscription = state => {
  const billing = state.billing || state;
  return billing.isCancellingSubscription;
};

export const isFetchingCoupon = state => {
  const billing = state.billing || state;
  // check if any stripe action is currently happening.
  return billing.isFetchingCoupon;
};
