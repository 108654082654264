import { connect } from 'react-redux';
import { appInit, appUnload } from '../../../store/app/app.actions';
import AppInit from './AppInit';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(appInit());
  },
  onUnload: () => {
    dispatch(appUnload());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppInit);
