import React, { useRef } from 'react';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import { audioTimestamp } from '../../../util/audioTimestamp';
import './ProgressBar.css';

function ProgressBar(props) {
  const progressBarRef = useRef(null);

  const getProgressBarWidth = () => {
    const barWidth = path(['current', 'offsetWidth'], progressBarRef);
    return isNumber(barWidth) ? barWidth : 0;
  };

  function getPositionIndicatorX() {
    const barWidth = getProgressBarWidth();
    const { currentTimeSeconds, durationSeconds } = props;

    return (currentTimeSeconds / durationSeconds) * barWidth;
  }

  const getPositionIndicatorStyles = () => {
    const x = getPositionIndicatorX();
    return { width: x + 'px' };
  };

  function renderBufferChunks() {
    const chunks = props.bufferChunks || [];
    return chunks.map(renderBufferChunk);
  }

  const renderBufferChunk = chunk => {
    const barWidth = getProgressBarWidth();
    const { durationSeconds } = props;

    const { start, end } = chunk;
    const left = (start / durationSeconds) * barWidth;
    const width = ((end - start) / durationSeconds) * barWidth;

    return (
      <div
        key={start}
        className="ProgressBar__BufferChunk"
        style={{ width: width + 'px', left: left + 'px' }}
      />
    );
  };

  return (
    <div className="ProgressBar" ref={progressBarRef}>
      <div
        className="ProgressBar__PositionIndicator"
        style={getPositionIndicatorStyles()}
      />
      {props.showTimes && (
        <div className="ProgressBar__TimestampContainer">
          <span>{audioTimestamp(props.currentTimeSeconds)}</span>
          <span>{audioTimestamp(props.durationSeconds)}</span>
        </div>
      )}
      <div className="ProgressBar__BufferContainer">{renderBufferChunks()}</div>
    </div>
  );
}

ProgressBar.propTypes = {
  currentTimeSeconds: PropTypes.number.isRequired,
  durationSeconds: PropTypes.number.isRequired,
  bufferChunks: PropTypes.array.isRequired,
  showTimes: PropTypes.bool.isRequired,
};

export default ProgressBar;
