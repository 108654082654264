import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { version } from '../package.json';

import cuid from 'cuid';
import * as serviceWorker from './serviceWorker';
import App from './AppConnected';
import amplifyInit from './amplify.init.js';
import configureStore from './store/configureStore';
import { choirpal_red_100 } from './ui/Theme/colors';
import AppContext from './AppContext';

const color = `color: ${choirpal_red_100}; font-weight: bold;`;
console.log('%c', color, process?.env);
console.log({ version });
console.log(`%c -------- ${cuid()} --------`, color);
console.log('');
console.log('');

const history = createBrowserHistory();
const store = configureStore(history);
amplifyInit(window.location.hostname);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppContext.Provider value={{ history }}>
        <App history={history} />
      </AppContext.Provider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
