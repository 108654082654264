import { path } from 'ramda';

export const getEmailAddress = state => path(['user', 'email'], state);
export const getTokenExpiry = state => path(['user', 'tokenExpiry'], state);
export const getCreatedDate = state => path(['user', 'createdDate'], state);

export const getCognitoId = state => path(['user', 'cognitoId'], state);

export const isEmailVerified = state =>
  path(['user', 'emailVerified'], state) === true;

export const getTempUser = state => {
  return path(['user', 'newUser'], state);
};

export const getErrorMsg = state => {
  return path(['user', 'errorMsg'], state);
};

export const isLoggedIn = state => {
  const expired = getTokenExpiry(state);
  const currentTime = Math.floor(new Date().getTime() / 1000);
  if (currentTime > expired) {
    return false;
  } else {
    return !!getEmailAddress(state);
  }
};

export const hasFailedLogin = state => {
  return path(['user', 'error'], state) === true;
};

export const isPostingCredentials = state => {
  return path(['user', 'isPostingCredentials'], state);
};

export const isPostingRegistrationDetails = state => {
  return path(['user', 'isPostingRegistrationDetails'], state);
};
