import { Auth } from 'aws-amplify';
import apiInit from '../api';

const { get } = apiInit('groups');

export async function postLoginDetails(username, password) {
  const response = await Auth.signIn(username, password);
  return response;
}

export async function postConfirmUser(email, confirmationCode) {
  const confuser = await Auth.confirmSignUp(email, confirmationCode);
  return confuser;
}

export async function getCurrentSession() {
  const session = await Auth.currentSession();
  return session;
}
export const fetchUser = async () => get(`/users`);
