import { useState } from 'react';
import useEventListener from '@use-it/event-listener';

export default function useViewportDimensions() {
  const [dimensions, setDimensions] = useState({ height: window.innerHeight });

  useEventListener('resize', () =>
    setDimensions({ height: window.innerHeight }),
  );

  useEventListener('orientationchange', () =>
    setDimensions({ height: window.innerHeight }),
  );

  return dimensions;
}
