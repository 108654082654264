import { path, clone, dissoc, without } from 'ramda';
import {
  ADD_SONG,
  UPDATE_SONG,
  ADD_TRACK,
  ADD_SHEET_MUSIC,
  DELETE_SHEET_MUSIC,
  ADD_GUIDE,
  ADD_SECTION,
  DELETE_SECTION,
  NUDGE_SECTION,
  ADD_PAGE,
  DELETE_PAGE,
  // ADD_FILESET,
  DELETE_GUIDE,
  UPDATE_TRACK,
  UPDATE_SONG_META,
  DELETE_TRACK,
  FETCH_SONGS_STARTED,
  FETCH_SONGS_SUCCESS,
  FETCH_SONGS_FAIL,
  POST_SONG_STARTED,
  POST_SONG_SUCCESS,
  POST_SONG_FAIL,
  // POST_FILESET_SUCCESS,
  STORE_FILESET,
  STORE_FILESET_URLS,
  DELETE_SONG_STARTED,
  DELETE_SONG_SUCCESS,
  DELETE_SONG_FAILED,
  SET_UPLOAD_STATUS,
  ADD_TRACKS_TO_GUIDE,
  STORE_SONG,
  FETCH_SONG_GROUPS_STARTED,
  // FETCH_SONG_GROUPS_FAIL,
  FETCH_SONG_GROUPS_SUCCESS,
  SHARE_SONG_STARTED,
  SHARE_SONG_SUCCESS,
  SHARE_SONG_FAIL,
} from './myMusic.events';
import { getMySong, getSection } from './myMusic.selectors';
import { setPath, asList, byId } from '../storeUtil';

const initialState = {
  isFetchingSongList: false,
  isPostingSong: false,
  isFetchingSongGroups: false,
  isPostingShareSong: false,
  lastFetchDate: null,
  songs: {},
  mySongCuids: [],
  filesets: {},
  filesetUrls: {},
  songGroups: {},
  uploadStatus: null,
};

export const myMusicReducer = (state = initialState, action) => {
  const songs = { ...state.songs };
  switch (action.type) {
    case ADD_SONG: {
      const { song } = action;
      const { songCuid } = song;
      songs[songCuid] = song;
      const mySongCuids = [...state.mySongCuids, songCuid];
      return { ...state, songs, mySongCuids };
    }

    case UPDATE_SONG: {
      const { song } = action;
      const { songCuid } = song;
      songs[songCuid] = song;
      return { ...state, songs };
    }

    case UPDATE_SONG_META: {
      const { songCuid, meta } = path(['data'], action);
      const songDocument = getMySong(songCuid, state);
      const songDocumentCopy = { ...songDocument, meta };
      return { ...state, [songDocument.songCuid]: songDocumentCopy };
    }

    case ADD_TRACK: {
      const { songCuid, track, filesetCuid } = path(['data'], action);
      const song = getMySong(songCuid, state);

      const songCopy = clone(song);
      const { tracks, trackOrder } = songCopy;
      const { trackCuid } = track;
      if (filesetCuid) songCopy.filesetCuid = filesetCuid;
      tracks[trackCuid] = track;
      !trackOrder.includes(trackCuid) && trackOrder.push(trackCuid);
      songs[songCuid] = songCopy;
      return { ...state, songs };
    }

    case ADD_SHEET_MUSIC: {
      const { songCuid, sheetMusic: sheetMusicRecord, filesetCuid } = path(
        ['data'],
        action,
      );
      const song = getMySong(songCuid, state);

      const songCopy = clone(song);
      const { sheetMusic, sheetMusicOrder } = songCopy;
      const { sheetMusicCuid } = sheetMusicRecord;
      if (filesetCuid) songCopy.filesetCuid = filesetCuid;
      sheetMusic[sheetMusicCuid] = sheetMusicRecord;
      !sheetMusicOrder.includes(sheetMusicCuid) &&
        sheetMusicOrder.push(sheetMusicCuid);
      songs[songCuid] = songCopy;
      return { ...state, songs };
    }

    case STORE_FILESET: {
      const { fileset } = action;
      const { filesetCuid } = fileset;
      const filesets = { ...state.filesets, [filesetCuid]: fileset };
      return { ...state, filesets };
    }

    case STORE_FILESET_URLS: {
      const { filesetCuid, urls } = action;
      const filesetUrls = { ...state.filesetUrls, [filesetCuid]: urls };
      return { ...state, filesetUrls };
    }

    case UPDATE_TRACK: {
      const { songCuid, track } = action;
      const song = getMySong(songCuid, state);

      const songCopy = { ...song };
      const { tracks } = song;
      const tracksCopy = { ...tracks };
      tracksCopy[track.trackCuid] = track;
      songCopy.tracks = tracksCopy;
      songs[songCuid] = songCopy;
      return { ...state, songs };
    }

    case DELETE_TRACK: {
      const { songCuid, trackCuid } = path(['data'], action);
      const song = getMySong(songCuid, state);

      const songCopy = clone(song);

      delete songCopy.tracks[trackCuid];
      songCopy.trackOrder = songCopy.trackOrder.filter(
        cuid => cuid !== trackCuid,
      );

      songs[songCuid] = songCopy;
      return { ...state, songs };
    }

    case DELETE_SHEET_MUSIC: {
      const { songCuid, sheetMusicCuid } = path(['data'], action);
      const song = getMySong(songCuid, state);

      const songCopy = clone(song);

      delete songCopy.sheetMusic[sheetMusicCuid];
      songCopy.sheetMusicOrder = songCopy.sheetMusicOrder.filter(
        cuid => cuid !== sheetMusicCuid,
      );

      songs[songCuid] = songCopy;
      return { ...state, songs };
    }

    case DELETE_GUIDE: {
      const { songCuid, guideCuid } = path(['data'], action);
      const song = getMySong(songCuid, state);

      const songCopy = { ...song };
      delete songCopy.guides[guideCuid];
      songs[songCuid] = songCopy;
      return { ...state, songs };
    }

    case ADD_GUIDE: {
      const { songCuid, guide } = path(['data'], action);
      const song = getMySong(songCuid, state);

      const songCopy = { ...song };
      songCopy.guides[guide.guideCuid] = guide;
      songs[songCuid] = songCopy;

      return { ...state, songs };
    }

    case ADD_SECTION: {
      const { songCuid, guideCuid, section } = action;
      const { position } = section;
      const songDocument = setPath(
        ['guides', guideCuid, 'sections', position],
        section,
        getMySong(songCuid, state),
      );

      return setPath(['songs', songCuid], songDocument, state);
    }

    case DELETE_SECTION: {
      const { songCuid, guideCuid, currentTimeMillis } = action;
      const songDocument = getMySong(songCuid, state);
      const sections = path(['guides', guideCuid, 'sections'], songDocument);
      const position = Object.keys(sections)
        .reverse()
        .find(pos => pos <= currentTimeMillis);
      const updatedSections = dissoc(position, sections);
      const updatedSong = setPath(
        ['guides', guideCuid, 'sections'],
        updatedSections,
        songDocument,
      );

      return setPath(['songs', songCuid], updatedSong, state);
    }

    case NUDGE_SECTION: {
      const { songCuid, guideCuid, position, offset } = action;
      const songDocument = getMySong(songCuid, state);
      const sections = path(['guides', guideCuid, 'sections'], songDocument);
      const section = sections[position];
      const updatedSection = {
        ...section,
        position: section.position + offset,
      };
      const sectionList = asList(sections);
      const updatedSectionList = without([section], sectionList);
      updatedSectionList.push(updatedSection);
      const updatedSections = byId('position', updatedSectionList);
      const updatedSong = setPath(
        ['guides', guideCuid, 'sections'],
        updatedSections,
        songDocument,
      );

      return setPath(['songs', songCuid], updatedSong, state);
    }

    case ADD_PAGE: {
      const { songCuid, guideCuid, position, pageTitle } = action.payload;

      const section = getSection(songCuid, guideCuid, position, state);
      const songDocument = setPath(
        ['guides', guideCuid, 'sections', position],
        { ...section, isPageBreak: true, pageTitle },
        getMySong(songCuid, state),
      );

      return setPath(['songs', songCuid], songDocument, state);
    }

    case DELETE_PAGE: {
      const { songCuid, guideCuid, position } = action.payload;

      const section = getSection(songCuid, guideCuid, position, state);
      const songDocument = setPath(
        ['guides', guideCuid, 'sections', position],
        { ...section, isPageBreak: false },
        getMySong(songCuid, state),
      );

      return setPath(['songs', songCuid], songDocument, state);
    }

    case FETCH_SONGS_STARTED: {
      return { ...state, isFetchingSongList: true };
    }

    case FETCH_SONGS_SUCCESS: {
      const { lastFetchDate, songList = [] } = path(['data'], action);
      // TODO
      const mySongCuids = songList.map(song => song.songDocument.songCuid);
      return {
        ...state,
        lastFetchDate,
        mySongCuids,
        isFetchingSongList: false,
      };
    }

    case STORE_SONG: {
      const songDocument = path(['songDocument'], action);
      const existingSongs = state.songs;
      // const existingFilesets = state.filesets;
      return {
        ...state,
        songs: { ...existingSongs, [songDocument.songCuid]: songDocument },
        // filesets: { ...existingFilesets, [fileset.filesetCuid]: fileset },
      };
    }

    case FETCH_SONGS_FAIL: {
      return { ...state, isFetchingSongList: false };
    }

    case POST_SONG_STARTED: {
      return { ...state, isPostingSong: true };
    }

    case POST_SONG_SUCCESS: {
      return { ...state, isPostingSong: false };
    }

    case POST_SONG_FAIL: {
      return { ...state, isPostingSong: false };
    }

    case DELETE_SONG_STARTED: {
      return { ...state };
    }

    case DELETE_SONG_FAILED: {
      return { ...state };
    }

    case DELETE_SONG_SUCCESS: {
      const { songCuid } = action;
      const updatedSongs = dissoc(songCuid, songs);
      const { mySongCuids = [] } = state;
      const updatedSongCuids = mySongCuids.filter(cuid => cuid !== songCuid);
      return { ...state, songs: updatedSongs, mySongCuids: updatedSongCuids };
    }

    case SET_UPLOAD_STATUS: {
      const { filesRemaining, filename } = action;
      return { ...state, uploadStatus: { filesRemaining, filename } };
    }

    case ADD_TRACKS_TO_GUIDE: {
      const { songCuid, guideCuid, trackList = [] } = action;
      const song = songs[songCuid];
      const { tracks } = song;
      Object.keys(trackList).forEach(trackCuid => {
        const isSelected = trackList[trackCuid];
        const track = tracks[trackCuid] || {};
        if (isSelected) {
          track.guideCuid = guideCuid;
        } else if (track.guideCuid === guideCuid) {
          // Only unmap a track if it is currently mapped to the current guide
          track.guideCuid = '';
        }
      });
      return { ...state, songs };
    }

    case FETCH_SONG_GROUPS_STARTED: {
      return { ...state, isFetchingSongGroups: true };
    }

    case FETCH_SONG_GROUPS_SUCCESS: {
      const { songCuid, groupList = [] } = path(['data'], action);

      return setPath(
        ['songGroups', songCuid],
        groupList.map(({ groupCuid }) => groupCuid),
        state,
      );
    }

    case SHARE_SONG_STARTED: {
      return { ...state, isPostingShareSong: true };
    }

    case SHARE_SONG_SUCCESS: {
      return { ...state, isPostingShareSong: false };
    }

    case SHARE_SONG_FAIL: {
      return { ...state, isPostingShareSong: false };
    }

    default:
      return state;
  }
};
