import React, { Fragment } from 'react';
import { Element } from 'react-scroll';
import PropTypes from 'prop-types';
import * as S from './Plans.styled';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Button, Divider } from '@material-ui/core';
// import Trio from '../../Theme/Trio/Trio';
// import Conductor from '../../Theme/Conductor/Conductor';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import H1 from '../../Typography/Heading/H1';
import H3 from '../../Typography/Heading/H3';
import { Check } from '@material-ui/icons';

function Plans({ doProClick, doRegisterClick, isLoggedIn, has_trialed }) {
  const handleProClick = () => {
    doProClick();
  };
  const handleRegisterClick = () => {
    doRegisterClick();
  };

  const plans = [
    {
      name: 'Free Plan',
      benefits: [
        'Create up to 10 songs with multiple parts',
        'Define and edit sections and lyrics',
        'Listen to and learn your lines with ease',
      ],
      price: 0,
      interval: 'per month',
      buttonLoggedIn: (
        <Button variant="outlined" disabled color="primary" size="large">
          Current Plan
        </Button>
      ),
      buttonHome: (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => handleRegisterClick()}>
          Sign me up!
        </Button>
      ),
      // image: <Conductor size="xsmall" />,
    },
    {
      name: 'Pro Plan',
      benefits: [
        'All benefits of the free plan with unlimited songs',
        'Create groups and invite your choir members',
        'Share your songs amongst your choirs',
      ],
      price: 2.99,
      finePrint: '*Subscription will be billed every 3 months at €8.97*',

      interval: 'per month',
      buttonLoggedIn: (
        <Fragment>
          <Button
            variant="contained"
            onClick={() => handleProClick()}
            color="primary"
            size="large">
            {!has_trialed ? <>14-day Free Trial</> : <>Get Choirpal Pro</>}
          </Button>
        </Fragment>
      ),
      buttonHome: (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleRegisterClick()}>
          Try For Free!
        </Button>
      ),
      // image: <Trio size="xsmall" />,
    },
  ];

  return (
    <Element name="pricing">
      <S.Plans>
        {plans.map(planItem => (
          <S.Card key={planItem.name}>
            <Card>
              <S.Price>
                <H3>{planItem.name}</H3>
                <H1>€{planItem.price}</H1>
                {planItem.interval && <H3 size="small">{planItem.interval}</H3>}
                {planItem.finePrint && (
                  <p style={{ fontSize: '10px' }}>{planItem.finePrint}</p>
                )}
              </S.Price>

              <Divider variant="middle" />
              <CardContent style={{ minHeight: '250px' }}>
                {/* <S.Image>{planItem.image}</S.Image>  */}
                <S.Benefits>
                  <List style={{ textAlign: 'center' }}>
                    {planItem.benefits.map(benefitItem => {
                      return (
                        <ListItem key={`key-${benefitItem}`}>
                          <ListItemIcon>
                            <Check />
                          </ListItemIcon>
                          <ListItemText primary={benefitItem} />
                        </ListItem>
                      );
                    })}
                  </List>
                </S.Benefits>
              </CardContent>
              <Divider variant="middle" />
              {isLoggedIn ? (
                <S.Button>{planItem.buttonLoggedIn}</S.Button>
              ) : (
                <S.Button>{planItem.buttonHome}</S.Button>
              )}
            </Card>
          </S.Card>
        ))}
      </S.Plans>
    </Element>
  );
}

Plans.propTypes = {
  doProClick: PropTypes.func.isRequired,
  doRegisterClick: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  has_trialed: PropTypes.bool,
};

export default Plans;
