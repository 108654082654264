import styled from 'styled-components';
import { fontFamily } from '../../design-tokens';
import { SMALL_BREAKPOINT } from '../../hooks/useBreakpoint';

export const StripeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* align-items: center; */
  width: 100%;
  min-width: ${SMALL_BREAKPOINT}px;
  /* max-width: 400px; */
  font-family: ${fontFamily.regular};
  min-height: ${SMALL_BREAKPOINT}px;
`;

export const StripeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
`;

export const StripeBody = styled.div`
  width: 80%;
  align-items: center;
  text-align: center;
  font-size: 20;
`;

export const Title = styled.div`
  display: flex;
  margin-left: 1em;
`;
