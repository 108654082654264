import styled from 'styled-components';
import { fontFamily } from '../../design-tokens';

import { SMALL_BREAKPOINT } from '../../hooks/useBreakpoint';

export const PopupView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* align-items: center; */
  width: 100%;
  min-width: ${SMALL_BREAKPOINT}px;
  /* max-width: 400px; */
  font-family: ${fontFamily.regular};
`;
