import throttle from 'lodash/throttle';
import { setPersistedState } from '../persistedStateUtil';

const localStoragePersistInterval = 10000;

const throttledPersister = throttle(state => {
  setPersistedState(state);
}, localStoragePersistInterval);

export const LocalStorageMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  switch (action.type) {
    default: {
      throttledPersister(state);
      break;
    }
  }
};
