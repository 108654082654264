import React from 'react';
import PropTypes from 'prop-types';
// import * as S from './DeleteGroupModal.styled';
import OkCancelModal from '../OkCancelModal/OkCancelModal';

const ResubscribeModal = ({ renewal_date, onClickOk, onClickCancel }) => {
  const handleOkClick = () => {
    onClickOk && onClickOk();
  };
  const handleCancelClick = () => {
    onClickCancel && onClickCancel();
  };

  return OkCancelModal({
    title: 'Continue with Choirpal Pro?',
    imageName: 'trio',
    body: (
      <div>
        Your subscription will be renewed on <strong> {renewal_date} </strong>,
        is this ok?
      </div>
    ),
    okButtonText: 'Yes, resubscribe!',
    cancelButtonText: 'Cancel',
    onClickOk: handleOkClick,
    onClickCancel: handleCancelClick,
  });
};

ResubscribeModal.propTypes = {
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  renewal_date: PropTypes.string.isRequired,
};

export default ResubscribeModal;
