import { path } from 'ramda';

const allMigrations = {
  // '1.0.0': require('./migrations/myMusic.migration.1.0.0.js').default,
  '2.1.0': require('./migrations/myMusic.migration.2.1.0.js').default,
  // '9.0.0': require('./migrations/persistentState.migration.9.0.0.js').default,
};

function migrate(state = {}) {
  const schemaVersion = path(['__schemaVersion'], state) || '0.0.0';

  let newState = state;
  // eslint-disable-next-line
  for (const migrationVersion in allMigrations) {
    const currentMigration = allMigrations[migrationVersion];
    if (schemaVersion < migrationVersion) {
      newState = currentMigration(newState);
    }
  }
  return newState;
}

export default migrate;
