const isValidEmail = (text = '') =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    text,
  );

const separators = [';', ',', '<', '>', /\r/gi, /\n/gi];

const filterEmail = (text = '') => {
  let filteredText = text;
  separators.forEach(separator => {
    filteredText = filteredText.replaceAll(separator, ' ');
  });
  filteredText = filteredText
    .split(' ')
    .filter(t => t !== '')
    .filter(isValidEmail);
  return filteredText;
};

export const extractValidEmails = freeText => {
  return filterEmail(freeText);
};
