import { connect } from 'react-redux';
import FileUploadProgressBar from './FileUploadProgressBar';
import FileUploadSubscriber from './FileUploadSubscriber';
import {
  getUploadFileName,
  getNumFilesRemaining,
} from '../../../store/myMusic/myMusic.selectors';

const mapStateToProps = (state, ownProps) => {
  const { bytesUploaded, fileSizeBytes } = ownProps;
  return {
    filename: getUploadFileName(state),
    numFilesRemaining: getNumFilesRemaining(state),
    bytesUploaded,
    fileSizeBytes,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => {},
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileUploadSubscriber(FileUploadProgressBar));
