import React from 'react';
import PropTypes from 'prop-types';
import * as S from './AppViewport.styled';
import useViewportDimensions from '../../hooks/useViewportDimensions';

// const NAV_BAR_HEIGHT = 56;
const BOTTOM_NAV_VIEWPORT_OFFSET = 102;

function AppViewport({ path, children }) {
  const { height: viewportHeight } = useViewportDimensions();

  return (
    <S.AppViewport
      // iOS Cordova scroll position was being preserved.
      // Force re-creation of element when url changes.
      key={'AppViewport/' + path}
      style={{
        height: viewportHeight - BOTTOM_NAV_VIEWPORT_OFFSET,
      }}>
      {children}
    </S.AppViewport>
  );
}

AppViewport.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default AppViewport;
