import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, elemId }) => {
  const elem = document.getElementById(elemId);
  const [, setCounter] = useState(0);

  // Hack: hit a race condition so trigger a re-render
  useEffect(() => {
    setTimeout(() => {
      setCounter(Math.random());
    }, 50);
  }, [setCounter]);

  return elem ? ReactDOM.createPortal(<div>{children}</div>, elem) : null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  elemId: PropTypes.string.isRequired,
};

export default Portal;
