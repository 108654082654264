import React from 'react';
// import PropTypes from 'prop-types';
import * as S from './Loading.styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { choirpal_red_100 } from '../../Theme/colors';

function Loading() {
  return (
    <S.Loading>
      <CircularProgress
        size={26}
        color="secondary"
        style={{ color: choirpal_red_100 }}
      />
    </S.Loading>
  );
}

Loading.propTypes = {};

export default Loading;
