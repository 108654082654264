export const ADD_GROUP = 'groups/addGroup';
export const UPDATE_GROUP = 'groups/updateGroup';
export const DELETE_GROUP = 'groups/deleteGroup';

export const POST_GROUP_AVATAR_STARTED = 'groups/postGroupAvatarStarted';
export const POST_GROUP_AVATAR_SUCCESS = 'groups/postGroupAvatarSuccess';
export const POST_GROUP_AVATAR_FAIL = 'groups/postGroupAvatarFail';

export const INVITE_MEMBERS_STARTED = 'groups/inviteMembersStarted';
export const INVITE_MEMBERS_SUCCESS = 'groups/inviteMembersSuccess';
export const INVITE_MEMBERS_FAIL = 'groups/inviteMembersFail';

export const DELETE_INVITE_STARTED = 'groups/deleteInviteStarted';
export const DELETE_INVITE_SUCCESS = 'groups/deleteInviteSuccess';
export const DELETE_INVITE_FAIL = 'groups/deleteInviteFail';

export const DELETE_GROUP_STARTED = 'groups/deleteGroupStarted';
export const DELETE_GROUP_SUCCESS = 'groups/deleteGroupSuccess';
export const DELETE_GROUP_FAILED = 'groups/deleteGroupFailed';

export const FETCH_GROUPS_STARTED = 'groups/fetchGroupsStarted';
export const FETCH_GROUPS_SUCCESS = 'groups/fetchGroupsSuccess';
export const FETCH_GROUPS_FAIL = 'groups/fetchGroupsFail';

export const POST_GROUP_STARTED = 'groups/postGroupStarted';
export const POST_GROUP_SUCCESS = 'groups/postGroupSuccess';
export const POST_GROUP_FAIL = 'groups/postGroupFail';

export const DELETE_MEMBER_STARTED = 'groups/deleteUserStarted';
export const DELETE_MEMBER_SUCCESS = 'groups/deleteUserSuccess';
export const DELETE_MEMBER_FAIL = 'groups/deleteUserFail';

export const LEAVE_GROUP_STARTED = 'groups/leaveGrouStarted';
export const LEAVE_GROUP_SUCCESS = 'groups/leaveGroupSuccess';
export const LEAVE_GROUP_FAIL = 'groups/leaveGroupFail';

export const FETCH_MEMBERS_STARTED = 'groups/fetchMembersStarted';
export const FETCH_MEMBERS_SUCCESS = 'groups/fetchMembersSuccess';
export const FETCH_MEMBERS_FAIL = 'groups/fetchMembersFail';

export const FETCH_INVITES_STARTED = 'groups/fetchInvitesStarted';
export const FETCH_INVITES_SUCCESS = 'groups/fetchInvitesSuccess';
export const FETCH_INVITES_FAIL = 'groups/fetchInvitesFail';

export const FETCH_USER_INVITES_STARTED = 'groups/fetchUserInvitesStarted';
export const FETCH_USER_INVITES_SUCCESS = 'groups/fetchUserInvitesSuccess';
export const FETCH_USER_INVITES_FAIL = 'groups/fetchUserInvitesFail';

export const ACCEPT_INVITE_STARTED = 'groups/acceptInviteStarted';
export const ACCEPT_INVITE_SUCCESS = 'groups/acceptInviteSuccess';
export const ACCEPT_INVITE_FAIL = 'groups/acceptInviteFail';

export const FETCH_GROUP_SONGS_STARTED = 'groups/fetchGroupSongsStarted';
export const FETCH_GROUP_SONGS_SUCCESS = 'groups/fetchGroupSongsSuccess';
export const FETCH_GROUP_SONGS_FAIL = 'groups/fetchGroupSongsFail';

export const HIDE_ERROR = 'groups/hideError';
