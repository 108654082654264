import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as S from './Modal.styled';

const Modal = ({ isVisible, children }) =>
  ReactDOM.createPortal(
    <S.ModalContainer>
      <S.Modal isVisible={isVisible}>{children}</S.Modal>
    </S.ModalContainer>,
    document.body,
  );

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
