import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './EditGroup.styled';

import H1 from '../../Typography/Heading/H1';
import MemberListConnected from '../MemberList/MemberListConnected';

function EditGroup({
  isSaved,
  doFetchMembersDB = () => {},
  doFetchInvitesDB = () => {},
  group,
  isPostingGroup,
  ...props
}) {
  const { groupCuid } = group;

  /* eslint-disable */
  useEffect(() => {
    if (groupCuid && !isPostingGroup) {
      doFetchMembersDB(groupCuid);
      doFetchInvitesDB(groupCuid);
    }
  }, [groupCuid, isPostingGroup]);

  useEffect(() => {}, [group.groupCuid]);
  /* eslint-enable */

  if (!group.groupCuid) {
    return null;
  }

  return (
    <S.EditGroup>
      <H1>{group.groupName}</H1>

      {group.groupName && (
        <S.MemberListContainer isVisible={groupCuid}>
          <MemberListConnected groupCuid={groupCuid} />
        </S.MemberListContainer>
      )}
    </S.EditGroup>
  );
}

EditGroup.propTypes = {
  doFetchMembersDB: PropTypes.func,
  doFetchInvitesDB: PropTypes.func,
  group: PropTypes.object,
  isSaved: PropTypes.bool,
  isPostingGroup: PropTypes.bool,
};

export default EditGroup;
