import React from 'react';
import PropTypes from 'prop-types';

import pinkSvg from './Arch--pink.svg';
import redSvg from './Arch--red.svg';
import yellowSvg from './Arch--yellow.svg';

import * as S from './Arch.styled';

const images = {
  pink: pinkSvg,
  red: redSvg,
  yellow: yellowSvg,
};

export default function Arch({ color }) {
  const img = images[color];
  return (
    <S.Arch>
      <S.ArchImage src={img} alt="Arch" />
    </S.Arch>
  );
}

Arch.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.array,
};
