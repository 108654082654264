export const audioTimestamp = (durationSeconds = 0.0) => {
  const totalSeconds = durationSeconds | 0;
  const remainingSeconds = totalSeconds % 60;
  const minutes = (totalSeconds - remainingSeconds) / 60;
  const remainingSecondsStr =
    remainingSeconds > 10
      ? `${remainingSeconds}`.padEnd(2, '0')
      : `${remainingSeconds}`.padStart(2, '0');
  return `${minutes}:${remainingSecondsStr}`;
};

export const mmSSmmm = (timeMillis = 0) => {
  const millis = timeMillis % 1000;
  const remainderSeconds = (timeMillis - millis) / 1000;
  const seconds = remainderSeconds % 60;
  const minutes = (remainderSeconds - seconds) / 60;

  return (
    String(minutes) +
    ':' +
    String(seconds).padStart(2, '0') +
    '.' +
    String(millis).padStart(3, '0')
  );
};

export const mmSSm = (timeMillis = 0) => mmSSmmm(timeMillis).substring(0, 6);
export const mmSS = (timeMillis = 0) => mmSSmmm(timeMillis).substring(0, 4);
