import Dexie from 'dexie';

var db = new Dexie('AudioDB');
db.version(1).stores({ files: 'cuid' });

export const saveFile = (cuid, file) => {
  db.transaction('rw', db.files, async () => {
    // Make sure we have something in DB:
    const existingFiles = await db.files
      .where('cuid')
      .equals(cuid)
      .count();
    if (existingFiles === 0) {
      const record = {
        cuid,
        file,
      };
      await db.files.add(record);
    }
  }).catch(e => {
    console.error(e.stack || e);
  });
};

export const readFile = (cuid, callback, fail) => {
  db.transaction('rw', db.files, async () => {
    const records = await db.files
      .where('cuid')
      .equals(cuid)
      .toArray();
    if (records[0]) {
      callback(records[0].file);
    } else {
      fail && fail();
    }
  }).catch(e => {
    console.error(e.stack || e);
  });
};
