import React from 'react';
import ChoirpalIcon from '../../Theme/ChoirpalIcon/ChoirpalIcon';
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks';
import AppVersionConnected from '../AppVersion/AppVersionConnected';
import Link from '../../Form/Link/Link';
import * as S from './Footer.styled';

export default function Footer() {
  return (
    <S.Footer>
      <S.Footer_Container>
        <S.Footer_ContentContainer>
          <S.Footer_Content>
            <div>
              <S.Footer_Title>Practice, made perfect</S.Footer_Title>
            </div>
            <S.Footer_Body>
              <div>Made with love in Dublin, Ireland</div>
              <div>Major Fifth Software Ltd</div>
            </S.Footer_Body>
            <ChoirpalIcon />
          </S.Footer_Content>
        </S.Footer_ContentContainer>

        <S.Footer_Divider />

        <S.Footer_Footer>
          <SocialMediaLinks style={{ margin: '10px auto' }} invert={true} />
          <S.Footer_CopyrightNotice>
            <Link to="/privacy">Privacy Policy</Link>
          </S.Footer_CopyrightNotice>
        </S.Footer_Footer>
      </S.Footer_Container>
      <AppVersionConnected />
    </S.Footer>
  );
}
