import React from 'react';
import styled from 'styled-components';
import useBreakpoint from '../hooks/useBreakpoint';
import Logo from '../Theme/Logo/Logo';

export const AuthPage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;
  padding-bottom: 125px;
`;

export const AuthInputs = styled.div`
  text-align: center;
  width: 80%;
  margin: 30px auto;
  min-height: 80px;
`;

export const AuthButtons = styled.div`
  margin-top: 20px;
`;

export const StyledLogo = styled.div``;

export const AuthLogo = () => {
  const { isMediumOrAbove } = useBreakpoint();

  return (
    <StyledLogo>
      <Logo size={isMediumOrAbove ? 'large' : 'small'} />
    </StyledLogo>
  );
};
