/* eslint-disable */

// ISSUE: webpack complains about undeclared variables
//
// postcss-simplevars gives out about undeclared variables
// unless it's passed a js object with them - leading to
// some duplication of variable declaration
//
// https://github.com/postcss/postcss-simple-vars#variables
//

module.exports = {
  choirpal_grey_dark: '#262626',
  choirpal_grey_mid: '#7F7F7F',
  choirpal_grey_light: '#E4E6E8',

  choirpal_red_100: '#ff6b6f',
  choirpal_red_80: '#ff898b',
  choirpal_red_60: '#ffa8a9',
  choirpal_red_40: '#ffc5c6',
  choirpal_red_20: '#ffe1e1',

  choirpal_pink_100: '#ffe5d9',
  choirpal_pink_80: '#ffeae1',
  choirpal_pink_60: '#fff0e8',
  choirpal_pink_40: '#fff5ef',
  choirpal_pink_20: '#fffaf8',

  choirpal_blue_100: '#1d428a',
  choirpal_blue_80: '#4b69a1',
  choirpal_blue_60: '#798eba',
  choirpal_blue_40: '#a5b4d1',
  choirpal_blue_20: '#d3d9e8',

  choirpal_yellow_100: '#ffc56e',
  choirpal_yellow_80: '#ffd08c',
  choirpal_yellow_60: '#ffdcaa',
  choirpal_yellow_40: '#ffe8c6',
  choirpal_yellow_20: '#fff4e3',

  cp1_red: '#FF6B6F',
  cp1_pink: '#FFE5D9',
  cp1_blue: '#1d428a',
  cp1_yellow: '#FFC56E',

  cp2_red: '#FFA8A9',
  cp2_red_soft: '#FFC5C6',
  cp2_pink: '#FFF2EC',
  cp2_blue: '#798EBA',
  cp2_green: '#708690',

  choirpal_white: '#FFFFFF',

  // 'clr-primary': '#5f6368',
  // 'clr-secondary': '#D8AA96',
  // 'clr-tertiary': '#fafafa',
  // 'clr-black': '#807182',
  // 'clr-error': '#F7B1AB',
};
