import { push } from 'connected-react-router';
import { Auth } from 'aws-amplify';
import { isNative } from '../../util/envUtil';
import {
  registerStarted,
  registerFailed,
  loginFailed,
  tokenRefreshed,
  logoutStarted,
  logoutSuccess,
  logoutFailed,
  storeUser,
  storeNewUser,
  loginStarted,
  fetchUserStarted,
  fetchUserSuccess,
  fetchUserFail,
} from './user.actions';
import {
  postLoginDetails,
  postConfirmUser,
  fetchUser,
  getCurrentSession,
} from './user.api';

import { authInit } from '../../amplify.init';

export const loginThunk = (username, password, route) => dispatch => {
  dispatch(loginStarted());
  return postLoginDetails(username, password).then(
    response => {
      dispatch(storeUser(response));
      // dispatch(isAuthenticated(true))
      if (route) {
        dispatch(push(route));
      } else {
        dispatch(push('/groups'));
      }
    },
    error => {
      let msg;
      if (error.message !== undefined) {
        msg = error.message;
      } else {
        msg = error;
      }
      console.error(msg);
      dispatch(loginFailed(msg));
    },
  );
};

const forcePageReload = () => !isNative() && window.location.reload(true);

export function logoutThunk() {
  return function(dispatch) {
    dispatch(logoutStarted());
    return logoutUser().then(
      response => {
        dispatch(logoutSuccess());
        dispatch(push('/'));
        localStorage.clear();
        forcePageReload();
      },
      error => {
        const msg = returnError(error);
        dispatch(logoutFailed());
        dispatch(loginFailed('Error:', msg));
        alert('There was a problem logging out. Please try again');
      },
    );
  };
}

export const registerConfirmThunk = (
  username,
  confirmationCode,
) => dispatch => {
  return postConfirmUser(username, confirmationCode).then(
    response => {
      dispatch(push(`/login?username=${username}`));
    },
    error => {
      console.error(error);
      const msg = returnError(error);
      console.error(msg);
      dispatch(loginFailed('Error:', msg));
    },
  );
};

export const getCurrentCredentialsThunk = () => dispatch => {
  return getCurrentSession()
    .then(response => {
      dispatch(tokenRefreshed(response));
    })
    .catch(err => {
      dispatch(loginFailed());
      // dispatch(push('/'));
      console.error(err);
    });
};

export const registerThunk = (username, password) => dispatch => {
  dispatch(registerStarted());
  return Auth.signUp({
    username,
    password,
  }).then(
    response => {
      dispatch(storeNewUser(response));
      console.log('ALERT USER TO CHECK EMAIL');
      dispatch(push(`/login?verify_email=todo`));
    },
    error => {
      const msg = returnError(error);
      console.error(msg);
      dispatch(registerFailed(msg));
    },
  );
};

export function resetThunk(username, password) {
  return function(dispatch) {
    return resetUser(username).then(
      response => {
        dispatch(storeUser(response));
        console.log('ALERT TO CHECK EMAIL');
        dispatch(push(`/login`));
      },
      error => {
        const msg = returnError(error);
        console.error(msg);
        dispatch(loginFailed('Error:', msg));
      },
    );
  };
}

export function resetConfirmThunk(username, code, password) {
  return function(dispatch) {
    return resetUserConfirm(username, code, password).then(
      response => {
        console.log('ALERT SUCCESSFUL PASSWORD CHANGE');
        dispatch(push(`/login?username=${username}`));
      },
      error => {
        const msg = returnError(error);
        console.error(msg);
        dispatch(loginFailed(msg));
      },
    );
  };
}

export const doPostLogin = () => dispatch => {
  return Auth.currentAuthenticatedUser()
    .then(response => {
      console.log('storing user', response);
      dispatch(storeUser(response));
      dispatch(push('/groups'));
    })
    .catch(err => console.log(err));
};

// sends the email
async function resetUser(username) {
  const response = await Auth.forgotPassword(username);
  return response;
}
// confirms the password reset
async function resetUserConfirm(username, code, password) {
  const response = await Auth.forgotPasswordSubmit(username, code, password);
  return response;
}

function doSocialLogin(identity_provider) {
  const oauth = authInit(window.location.hostname);
  const { domain, redirectSignIn, responseType, clientId } = oauth;
  const url =
    `https://${domain}/oauth2/authorize` +
    `?redirect_uri=${redirectSignIn}` +
    `&response_type=${responseType}` +
    `&client_id=${clientId}` +
    `&identity_provider=${identity_provider}` +
    `&scope=email+openid+profile+aws.cognito.signin.user.admin`;
  window.location = url;
}

export const doGoogleLogin = () => {
  doSocialLogin('Google');
};

export const doFacebookLogin = () => {
  doSocialLogin('Facebook');
};

export async function logoutUser() {
  const response = await Auth.signOut();
  return response;
}

export const returnError = error => {
  let msg;
  if (error.message !== undefined) {
    msg = error.message;
  } else {
    msg = error;
  }
  console.log(msg);
  return msg;
};

export const fetchUserThunk = () => dispatch => {
  dispatch(fetchUserStarted());
  return fetchUser()
    .then(response => {
      dispatch(fetchUserSuccess(response));
    })
    .catch(error => {
      console.error(error);
      dispatch(fetchUserFail());
    });
};
