import styled from 'styled-components';

export const Screenshot = styled.div`
  width: 100%;
  min-height: 400px;
`;
export const ScreenshotImage = styled.img`
  /* min-width: 320px; */
  max-width: ${props => (props.size === 'small' ? 200 : 320)}px;
  min-height: 400px;
  /* width: 100%; */
  /* transform: scale(); */
`;
