import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
// import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../Layout/Modal/Modal';
import PlayPause from '../../Player/PlayPause/PlayPause';
import ProgressBar from '../../Player/ProgressBar/ProgressBar';
import * as SS from '../../Theme/Shared.styled';
import EditTrack from '../EditTrack/EditTrack';
import * as S from './TrackPlayer.styled';
import useDebouncedEffect from 'use-debounced-effect';

function TrackPlayer({
  songCuid,
  track,
  fileRecord,
  onDelete,
  s3GetUrl,
  onAddTrack,
}) {
  const player = useRef(null);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [audioState, setAudioState] = useState({
    isPlaying: false,
    currentTimeSeconds: 0,
  });
  const [trackName, setTrackName] = useState(track?.voicePart);

  useDebouncedEffect(
    () => {
      onAddTrack(songCuid, {
        ...track,
        voicePart: trackName,
        subPart: '',
        audioMix: '',
      });
    },
    3000,
    [trackName],
  );

  /* eslint-disable */
  useEffect(() => {
    s3GetUrl &&
      fileRecord &&
      s3GetUrl(fileRecord.fileCuid, url => {
        player.current.src = url;
        player.current.addEventListener('timeupdate', e => tick());
      });
  }, [fileRecord]);
  /* eslint-enable */

  if (!fileRecord) return <></>;

  const handleDelete = evt => {
    evt.preventDefault();
    onDelete(songCuid, track.trackCuid);
  };

  const tick = () => {
    if (!player.current) {
      return;
    }
    const _audioState = {
      isPlaying: !player.current.paused,
      currentTimeSeconds: player.current.currentTime,
    };
    console.log('_audioState', _audioState);
    setAudioState(_audioState);
  };

  function handleTrackDetailsChange(voicePart, subPart, audioMix) {
    setIsEditEnabled(false);
    onAddTrack(songCuid, { ...track, voicePart, subPart, audioMix });
  }

  const handlePlayClick = () => player.current.play();
  const handlePauseClick = () => player.current.pause();

  const { voicePart, subPart, audioMix } = track;

  return (
    <SS.Card style={{ height: 64 }}>
      <Modal isVisible={isEditEnabled}>
        <EditTrack
          filename={fileRecord.filename}
          voicePart={voicePart}
          subPart={subPart}
          audioMix={audioMix}
          onSave={handleTrackDetailsChange}
        />
      </Modal>
      <PlayPause
        isPlaying={audioState.isPlaying}
        onPlay={handlePlayClick}
        onPause={handlePauseClick}
        color="primary"
      />
      <SS.RowContent>
        <div style={{ display: 'none' }}>
          {Math.round(audioState.currentTimeSeconds)}
        </div>

        <TextField
          style={{ marginTop: 10, width: '100%' }}
          // label="Track Name"
          placeholder="Track Name: e.g. 'Alto'"
          required
          // variant="outlined"
          margin="dense"
          // autoFocus={!songTitle}
          value={trackName}
          InputProps={{ disableUnderline: true }}
          onChange={({ target }) => {
            setTrackName(target.value);
          }}
        />

        <S.Filename>{`${fileRecord.filename}`}</S.Filename>
        {/* <S.Labels>
          <Chip
            label={voicePart || '+ add label'}
            color="primary"
            style={{ height: '20px', marginLeft: '2px' }}
            onClick={handleEditClick}
          />
          {subPart && (
            <Chip
              label={subPart}
              color="primary"
              style={{ height: '20px', marginLeft: '2px' }}
              onClick={handleEditClick}
            />
          )}
          {audioMix && (
            <Chip
              label={audioMix}
              color="primary"
              style={{ height: '20px', marginLeft: '2px' }}
              onClick={handleEditClick}
            />
          )}
        </S.Labels> */}

        <div style={{ display: 'none' }}>
          <ProgressBar
            currentTimeSeconds={43}
            durationSeconds={147}
            bufferChunks={[{ start: 0, end: 147 }]}
            showTimes={true}
          />
        </div>

        <div style={{ display: 'none' }}>
          <audio controls ref={player} />
        </div>
      </SS.RowContent>

      <S.ButtonContainer>
        <IconButton
          color="secondary"
          aria-label="Delete Pdf"
          onClick={handleDelete}>
          <Delete />
        </IconButton>
      </S.ButtonContainer>
    </SS.Card>
  );
}

TrackPlayer.propTypes = {
  songCuid: PropTypes.string.isRequired,
  track: PropTypes.object.isRequired,
  fileRecord: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  s3GetUrl: PropTypes.func,
  onAddTrack: PropTypes.func.isRequired,
};

export default TrackPlayer;
