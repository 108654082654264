import { TOGGLE_LOOPING } from '../settings/settings.events';
import AudioPlayer from '../../webaudio/AudioPlayer';

export const AudioMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  switch (action.type) {
    case TOGGLE_LOOPING: {
      const { loopingMode } = state.settings;
      AudioPlayer.loop(loopingMode === 'LOOP_SONG');
      break;
    }
    default: {
      break;
    }
  }
};
