import styled from 'styled-components';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

export const Position = styled.div`
  position: absolute;
  font-size: 0.6rem;

  left: 0.3rem;
  bottom: 0.3rem;
`;

export const TitleInput = styled.input`
  text-align: center;
  /*border: 0px;*/
  border: 1px solid orange;
  /*background: transparent;*/
`;

export const DeletePageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StartEditing = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 11;
`;

export const Spacer = styled.div`
  min-height: 40px;
  width: 100%;
`;
