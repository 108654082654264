import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/modal.actions';
import WelcomeHintModal from './WelcomeHintModal';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickClose: () => {
    dispatch(hideModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomeHintModal);
