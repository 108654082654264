import React from 'react';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';

const ProtectedRoute = props => {
  const { component: Component, isLoggedIn, isAuthInProgress, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthInProgress) {
          return <></>;
        }
        return isLoggedIn === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  isLoggedIn: PropTypes.bool.isRequired,
  isAuthInProgress: PropTypes.bool.isRequired,
  location: PropTypes.object,
};

export default ProtectedRoute;
