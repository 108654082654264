import { push } from 'connected-react-router';
import { path } from 'ramda';
import { connect } from 'react-redux';
import { addGuide, addTrack } from '../../../store/myMusic/myMusic.actions';
import { newGuide, newTrack } from '../../../store/myMusic/myMusic.model';
import {
  getMySong,
  getTrackUrlsByCuid,
  isUploadInProgress,
} from '../../../store/myMusic/myMusic.selectors';
import {
  deleteSongThunk,
  fetchMySongsThunk,
  fetchSongThunk,
  postSongThunk,
  s3DeleteThunk,
  s3UploadThunk,
} from '../../../store/myMusic/myMusic.thunks';
import { maximisePlayer, openSong } from '../../../store/player/player.actions';
import { getErrorMsg } from '../../../store/user/user.selectors';
import AudioPlayer from '../../../webaudio/AudioPlayer';
import { shareSongRoute, songDetailsRoute } from '../../routes';
import AddMedia from './AddMedia';

const mapStateToProps = (state, ownProps) => {
  const songCuid = path(['match', 'params', 'songCuid'], ownProps);
  const song = getMySong(songCuid, state) || {};
  return {
    songCuid,
    song,
    errorMsg: getErrorMsg(state),
    isUploadInProgress: isUploadInProgress(state),
    trackUrls: getTrackUrlsByCuid(songCuid, state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickSongDetails: songCuid => {
    dispatch(songDetailsRoute(songCuid));
  },
  onClickShareSong: songCuid => {
    dispatch(shareSongRoute(songCuid));
  },
  onClickAddTrack: songCuid => {
    const track = newTrack();
    dispatch(addTrack(songCuid, track));
  },
  onClickAddGuide: songCuid => {
    const guide = newGuide();
    dispatch(addGuide(songCuid, guide));
    dispatch(push(`/my-music/${songCuid}/guide/${guide.guideCuid}`));
  },
  fileUploadS3: (file, path) => {
    dispatch(s3UploadThunk(file, path));
  },
  // name includes path to file i.e. music/filename or pdfs/filename(in future)
  fileDeleteS3: name => {
    dispatch(s3DeleteThunk(name));
  },
  onClickpostSong: song => {
    dispatch(postSongThunk(song));
  },
  onClickfetchSong: song => {
    dispatch(fetchSongThunk(song));
  },
  onClickfetchAllSongs: song => {
    dispatch(fetchMySongsThunk(song));
  },
  onClickdeleteSong: songCuid => {
    dispatch(deleteSongThunk(songCuid));
  },
  onClickPlaySong: (songCuid, trackUrls) => {
    dispatch(openSong(songCuid));
    dispatch(maximisePlayer(songCuid));

    AudioPlayer.loadAudio(trackUrls);
    AudioPlayer.play();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddMedia);
