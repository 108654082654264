import styled, { createGlobalStyle } from 'styled-components';

export const App = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: env(safe-area-inset-top, 40px);
  padding-bottom: env(safe-area-inset-bottom, 40px);
`;

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    height: 100%;
    width: 100%;
  }
`;

export const Foo = styled.div`
  // Foo
`;
