export const isTextFieldFocused = () => {
  const { activeElement } = document;
  const { tagName = '', attributes } = activeElement;
  if (['input', 'textarea'].includes(tagName.toLowerCase())) {
    return true;
  }
  if (attributes.getNamedItem('contenteditable')) {
    return true;
  }
  return false;
};
