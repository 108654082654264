import { connect } from 'react-redux';
import SongList from './SongList';
import { selectGroupSongDocumentList } from '../../../store/groups/groups.selectors';

const mapStateToProps = (state, ownProps) => {
  const { groupCuid } = ownProps;

  return {
    groupCuid,
    songDocumentList: selectGroupSongDocumentList(groupCuid, state) || [],
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(SongList);
