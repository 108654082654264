import { connect } from 'react-redux';
import GroupView from './GroupView';
import { path } from 'ramda';
import {
  selectGroupSongDocumentList,
  getGroup,
  getMemberList,
  isFetchingGroupSongs,
} from '../../../store/groups/groups.selectors';
import {
  fetchGroupSongsThunk,
  fetchMembersThunk,
} from '../../../store/groups/groups.thunks';

const mapStateToProps = (state, ownProps) => {
  const groupCuid = path(['match', 'params', 'groupCuid'], ownProps);
  const songDocumentList = selectGroupSongDocumentList(groupCuid, state);
  const group = getGroup(groupCuid, state) || {};
  const { groupName = 'loading...', adminName = '' } = group;
  const { isAdmin } = group;
  return {
    groupName,
    adminName,
    groupCuid,
    isAdmin,
    isFetchingGroupSongs: isFetchingGroupSongs(state),
    numSongs: songDocumentList.length,
    memberList: getMemberList(groupCuid, state),
  };
};

const mapDispatchToProps = dispatch => ({
  doFetchGroupSongs: groupCuid => {
    dispatch(fetchGroupSongsThunk(groupCuid));
  },
  doFetchMembers: groupCuid => {
    dispatch(fetchMembersThunk(groupCuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupView);
