import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import LoginButtons from './LoginButtons';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onClickRegister: () => {
    dispatch(push('/register'));
  },
  onClickLogin: () => {
    dispatch(push('/login'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginButtons);
