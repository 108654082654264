import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from '../../Layout/SectionHeader/SectionHeader';
import IconPlay from '../../Theme/Icons/IconPlay';
import H2 from '../../Typography/Heading/H2';
import EditSongDetailsConnected from '../EditSongDetails/EditSongDetailsConnected';
import GuideListItemConnected from '../GuideListItem/GuideListItemConnected';
import MediaPickerConnected from '../MediaPicker/MediaPickerConnected';
import PdfCardConnected from '../PdfCard/PdfCardConnected';
import SongMenuConnected from '../SongMenu/SongMenuConnected';
import TrackPlayerConnected from '../TrackPlayer/TrackPlayerConnected';
import * as S from './AddMedia.styled';

const ENABLE_GUIDES = false;
const acceptedAudioFileTypes = [
  'audio/*',
  '.mp3',
  '.m4a',
  '.aac',
  '.ogg',
  '.wav',
  '.flac',
  '.alac',
  '.wma',
].join(',');

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

const AddMedia = ({
  songCuid,
  classes,
  onClickAddTrack,
  onClickAddGuide,
  onClickpostSong,
  onClickfetchSong,
  onClickfetchAllSongs,
  onClickdeleteSong,
  onClickSongDetails,
  onClickPlaySong,
  fileUploadS3,
  fileDeleteS3,
  song,
  isUploadInProgress,
  trackUrls,
}) => {
  // Check if song has been downloaded yet
  if (!song.meta) {
    return null;
  }
  // const { songTitle, writtenBy, recordedBy, imageUrl } = song.meta;
  const numTracks = Object.keys(song.tracks).length;

  function handleClickPlaySong() {
    onClickPlaySong(song.songCuid, trackUrls);
  }

  function handleAddGuideClick() {
    onClickAddGuide(song.songCuid);
  }

  function renderTracks() {
    const { tracks } = song;
    return Object.values(tracks).map(renderTrack);
  }

  function renderSheetMusic() {
    const { sheetMusic } = song;
    const sheetMusicList = Object.values(sheetMusic);
    const sheet = sheetMusicList[0];
    // console.log({ song, sheetMusic, sheetMusicList, sheet });

    return sheet && sheet.fileCuid ? (
      <PdfCardConnected
        songCuid={songCuid}
        sheetMusicCuid={sheet.sheetMusicCuid}
        fileCuid={sheet.fileCuid}
        filesetCuid={song.filesetCuid}
      />
    ) : (
      <MediaPickerConnected
        songCuid={song.songCuid}
        filesetCuid={song.filesetCuid}
        type="SHEET_MUSIC"
        label="Add Sheet Music"
        acceptFileTypes=".pdf"
        allowMultiple={false}
      />
    );
  }

  function renderTrack(track) {
    return (
      <TrackPlayerConnected
        trackCuid={track.trackCuid}
        key={track.trackCuid}
        songCuid={song.songCuid}
      />
    );
  }

  function renderGuides() {
    const { guides } = song;
    return Object.values(guides).map(renderGuide);
  }

  function renderGuide(guide) {
    return (
      <GuideListItemConnected
        songCuid={song.songCuid}
        guideCuid={guide.guideCuid}
        key={guide.guideCuid}
      />
    );
  }

  function renderGuideContainer() {
    return (
      <S.GuidesContainer>
        <SectionHeader
          title="Guides"
          subTitle={Object.keys(song.guides).length + ' guides'}
        />
        {renderGuides()}

        <Button
          style={{ marginTop: 10, width: '100%' }}
          size="large"
          color="primary"
          fullWidth={true}
          onClick={handleAddGuideClick}>
          <AddIcon
            style={{ height: '40px', width: '40px', marginRight: '12px' }}
          />
          Add Guide
        </Button>
      </S.GuidesContainer>
    );
  }

  if (isUploadInProgress) {
    return <></>;
  }

  return (
    <S.AddMedia>
      <S.SongTitleContainer>
        <S.TitleContainer>
          <H2>Edit Song Details</H2>
        </S.TitleContainer>
        <SongMenuConnected songCuid={songCuid} />
      </S.SongTitleContainer>

      <EditSongDetailsConnected songCuid={songCuid} />

      <S.TracksContainer>
        <SectionHeader title="Tracks" subTitle={numTracks + ' tracks'} />
        {renderTracks()}
        <MediaPickerConnected
          songCuid={song.songCuid}
          filesetCuid={song.filesetCuid}
          type="AUDIO_TRACK"
          label="Add Track(s)"
          acceptFileTypes={acceptedAudioFileTypes}
          allowMultiple={true}
        />
      </S.TracksContainer>

      <S.TracksContainer>
        <SectionHeader
          title="Sheet Music"
          subTitle={Object.keys(song.sheetMusic).length + ' file(s)'}
        />
        {renderSheetMusic()}
      </S.TracksContainer>

      {ENABLE_GUIDES && renderGuideContainer()}

      <br />
      <br />
      {numTracks > 0 && (
        <S.PlayButton>
          <Button
            style={{ minWidth: '180px', width: '80%' }}
            variant="contained"
            color="primary"
            size="large"
            onClick={handleClickPlaySong}>
            <IconPlay size="large" color="white" />
            &nbsp; &nbsp; Start Editing
          </Button>
        </S.PlayButton>
      )}

      <div style={{ minHeight: 50, opacity: 0 }}>spacer</div>
    </S.AddMedia>
  );
};

AddMedia.propTypes = {
  songCuid: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  dofetchSong: PropTypes.func,
  onClickAddTrack: PropTypes.func.isRequired,
  onClickAddGuide: PropTypes.func.isRequired,
  onClickSongDetails: PropTypes.func.isRequired,
  onClickpostSong: PropTypes.func.isRequired,
  onClickfetchSong: PropTypes.func.isRequired,
  onClickfetchAllSongs: PropTypes.func.isRequired,
  onClickdeleteSong: PropTypes.func.isRequired,
  onClickPlaySong: PropTypes.func.isRequired,
  song: PropTypes.object,
  fileUploadS3: PropTypes.func.isRequired,
  fileDeleteS3: PropTypes.func.isRequired,
  isUploadInProgress: PropTypes.bool,
  trackUrls: PropTypes.array,
};

export default withStyles(styles)(AddMedia);
