import { connect } from 'react-redux';
import Controls from './Controls';
import {
  getMeta,
  getNextSectionPosition,
  getPreviousSectionPosition,
  getTrackList,
  hasSheetMusic,
} from '../../../store/myMusic/myMusic.selectors';
import {
  getCurrentSongCuid,
  getSelectedTrackIndex,
} from '../../../store/player/player.selectors';
import { changeTrack } from '../../../store/player/player.actions';
import AudioPlayer from '../../../webaudio/AudioPlayer';
import AudioPlayerSubscriber from '../../Audio/AudioPlayerSubscriber/AudioPlayerSubscriber';

import { getIsMuted } from '../../../store/settings/settings.selectors';
import { toggleIsMuted } from '../../../store/settings/settings.actions';

const mapStateToProps = state => {
  const songCuid = getCurrentSongCuid(state);
  const trackList = getTrackList(songCuid, state);

  return {
    songCuid,
    hasSheetMusic: hasSheetMusic(songCuid, state),
    meta: getMeta(state),
    trackList,
    selectedTrackIndex: getSelectedTrackIndex(state),
    state,
    isMuted: getIsMuted(state),
  };
};

const mapDispatchToProps = dispatch => ({
  changeTrack: trackIndex => {
    AudioPlayer.changeTrack(trackIndex);
    dispatch(changeTrack(trackIndex));
  },
  play: () => AudioPlayer.play(),
  pause: () => AudioPlayer.pause(),
  onClickMute: isMuted => {
    AudioPlayer.mute(!isMuted);
    dispatch(toggleIsMuted());
  },
  onLoad: isMuted => {
    AudioPlayer.mute(isMuted);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    gotoNextSection: () => {
      const nextSectionPosition = getNextSectionPosition(
        AudioPlayer.position(),
        stateProps.state,
      );
      AudioPlayer.seek(nextSectionPosition);
    },
    gotoPreviousSection: () => {
      const nextSectionPosition = getPreviousSectionPosition(
        AudioPlayer.position(),
        stateProps.state,
      );
      AudioPlayer.seek(nextSectionPosition);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AudioPlayerSubscriber(Controls));
