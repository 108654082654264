import styled from 'styled-components';

export const TopSticky = styled.div`
  position: fixed;
  top: 0; /* iOS Cordova hack - Component was disappearing */
  padding-top: env(safe-area-inset-top, 40px);
  width: 100%;
  background-color: white;
  z-index: 1;
  will-change: opacity;
  opacity: ${props => (props.isIn ? 1 : 1)};
  transition: opacity 225ms ease-in-out;
`;
