import styled from 'styled-components';

export const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  width: 100%;
`;

export const ListContainer = styled.div`
  display: block;
`;

export const List = styled.ul`
  display: block;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.div`
  display: flex;
`;

export const ChipAndEmail = styled.div`
  display: flex;
  flex-direction: row;
`;
