import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as S from './EditSongDetails.styled';
import useDebouncedEffect from 'use-debounced-effect';

function EditSongDetails({ onSave, song }) {
  const [songTitle, setSongTitle] = useState('');
  const [writtenBy, setWrittenBy] = useState('');
  const [recordedBy, setRecordedBy] = useState('');

  /* eslint-disable */
  useEffect(() => {
    if (!song?.songCuid) {
      return;
    }
    const { meta } = song;
    setSongTitle(meta.songTitle);
    setWrittenBy(meta.writtenBy);
    setRecordedBy(meta.recordedBy);
  }, [song.songCuid]);

  /* eslint-enable */

  // Auto-save
  useDebouncedEffect(
    () => {
      onSave({
        ...song,
        meta: { songTitle, writtenBy, recordedBy },
      });
    },
    3000,
    [songTitle, writtenBy, recordedBy],
  );

  if (!song?.songCuid) {
    return null;
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSave({ ...song, meta: { songTitle, writtenBy, recordedBy } });
  }

  return (
    <S.EditSongDetails>
      <S.EditSongDetails__Container>
        <S.EditSongDetailsForm onSubmit={handleSubmit}>
          <S.SongTitle>
            <TextField
              style={{ marginTop: 10, width: '100%' }}
              label="Song Title"
              placeholder="e.g. 'Happy Song'"
              required
              variant="outlined"
              margin="dense"
              autoFocus={!songTitle}
              value={songTitle}
              onChange={({ target }) => {
                setSongTitle(target.value);
              }}
            />
          </S.SongTitle>

          <S.WrittenBy>
            <TextField
              label="Written / Arranged By"
              placeholder="e.g. 'Eric Whitacre"
              fullWidth={true}
              variant="standard"
              margin="dense"
              value={writtenBy}
              onChange={({ target }) => {
                setWrittenBy(target.value);
              }}
            />
          </S.WrittenBy>

          <S.RecordedBy>
            <TextField
              label="Recorded By"
              placeholder="e.g. 'Fingal Academy of Music'"
              fullWidth={true}
              variant="standard"
              margin="dense"
              value={recordedBy}
              onChange={({ target }) => {
                setRecordedBy(target.value);
              }}
            />
          </S.RecordedBy>

          {/* <S.Save>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
              type="submit"
              name="submit"
              onClick={handleSubmit}>
              Save
            </Button>
          </S.Save> */}
        </S.EditSongDetailsForm>
      </S.EditSongDetails__Container>
    </S.EditSongDetails>
  );
}

EditSongDetails.propTypes = {
  song: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditSongDetails;
