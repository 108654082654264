import generateCuid from 'cuid';

export function newGroup(groupName = '') {
  return {
    groupCuid: generateCuid(),
    createdTimestamp: null,
    updatedTimestamp: null,
    groupName,
    createdBy: '',
    isAdmin: true,
    imageUrl: null,
  };
}

export function newFileRecord(file = {}, identityId) {
  return {
    fileCuid: generateCuid(),
    filename: file.name,
    sizeInBytes: file.size,
    mimeType: file.type,
    lastModified: new Date(file.lastModified || 0).toISOString(),
    identityId,
  };
}
