import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PropTypes from 'prop-types';
import React from 'react';
// import * as S from './SongListItem.styled';
// import './SongListItem.css';
// import avatarFallback from '../../Theme/Images/android-red-icon-192x192.png';
import SheetMusicIconConnected from '../../Studio/SheetMusicIcon/SheetMusicIconConnected';
import IconMusicNotes from '../../Theme/Icons/IconMusicNotes';
import Text from '../../Typography/Text/Text';
import SongMenuConnected from '../SongMenu/SongMenuConnected';

function SongListItem({
  onClickSong,
  onClickEdit,
  onClickIcon,
  songCuid,
  songTitle,
  writtenBy,
  recordedBy,
  sheetMusicUrl,
  pdfFileName,
  imageUrl,
  isEditable,
}) {
  function handleSongClick(songCuid) {
    onClickSong(songCuid);
  }

  function handleIconClick(e) {
    e.stopPropagation();
    onClickIcon(songCuid);
  }

  return (
    <ListItem
      button
      className="SongListItem"
      onClick={() => {
        handleSongClick(songCuid);
      }}>
      <ListItemAvatar>
        <IconMusicNotes onClick={handleIconClick} />
      </ListItemAvatar>
      <span>
        <div>
          <Text size="xLarge">{songTitle || 'Untitled'}</Text>
          <Text size="small"> - {writtenBy || 'Written By'}</Text>
        </div>
        <Text size="small">{recordedBy || 'Recorded By'}</Text>
      </span>

      <ListItemSecondaryAction>
        {sheetMusicUrl && <SheetMusicIconConnected songCuid={songCuid} />}
        {isEditable && <SongMenuConnected songCuid={songCuid} />}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

SongListItem.propTypes = {
  onClickSong: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickIcon: PropTypes.func,
  songCuid: PropTypes.string.isRequired,
  songTitle: PropTypes.string,
  writtenBy: PropTypes.string,
  recordedBy: PropTypes.string,
  imageUrl: PropTypes.string,
  sheetMusicUrl: PropTypes.string,
  pdfFileName: PropTypes.string,
  isEditable: PropTypes.bool,
  filesetUrls: PropTypes.object,
};

export default SongListItem;
