import {
  postGroupDB,
  updateGroupDB,
  deleteGroupDB,
  leaveGroupDB,
  fetchGroupsDB,
  inviteMembersDB,
  deleteMemberDB,
  fetchMembersDB,
  fetchGroupInvitesDB,
  fetchUserInvitesDB,
  deleteGroupInviteDB,
  acceptInviteDB,
  fetchGroupSongs,
  postFileRecord,
} from './groups.api';
import {
  postGroupStarted,
  postGroupFail,
  postGroupSuccess,
  acceptInviteStarted,
  acceptInviteSuccess,
  acceptInviteFail,
  deleteGroupStarted,
  deleteGroupFail,
  deleteGroupSuccess,
  leaveGroupStarted,
  leaveGroupSuccess,
  leaveGroupFail,
  fetchGroupsStarted,
  fetchGroupsSuccess,
  inviteMembersStarted,
  inviteMembersSuccess,
  inviteMembersFail,
  deleteMemberStarted,
  deleteMemberSuccess,
  deleteMemberFail,
  fetchMembersStarted,
  fetchMembersSuccess,
  fetchMembersFail,
  fetchInvitesStarted,
  fetchInvitesSuccess,
  fetchInvitesFail,
  fetchUserInvitesStarted,
  fetchUserInvitesSuccess,
  fetchUserInvitesFail,
  deleteGroupInviteStarted,
  deleteGroupInviteSuccess,
  deleteGroupInviteFail,
  fetchGroupSongsStarted,
  fetchGroupSongsSuccess,
  fetchGroupSongsFail,
  postGroupAvatarSuccess,
  postGroupAvatarStarted,
  postGroupAvatarFail,
  fetchGroupsFail,
} from './groups.actions';
import { newFileRecord } from './groups.model';
import { storeSong, storeFileset } from '../myMusic/myMusic.actions';
import { s3FileUpload, fetchS3Url } from '../s3/s3-utils';
import { getIdentityId } from '../api';
import { editGroupRoute } from '../../ui/routes';

export const postGroupDBThunk = group => dispatch => {
  dispatch(postGroupStarted());
  return postGroupDB(group).then(
    response => {
      dispatch(postGroupSuccess(response));
      dispatch(editGroupRoute(group.groupCuid));
    },
    error => {
      const { response } = error;
      const { detail = '' } = response?.data;
      const msg = detail.includes('Key')
        ? 'This group name is already taken, please try another.'
        : 'There was an issue creating this group, please try again.';
      dispatch(postGroupFail(msg));
    },
  );
};

export const deleteGroupDBThunk = groupCuid => dispatch => {
  dispatch(deleteGroupStarted());
  return deleteGroupDB(groupCuid)
    .then(response => {
      dispatch(deleteGroupSuccess(groupCuid));
    })
    .catch(error => {
      console.error(error);
      dispatch(deleteGroupFail());
    });
};

export const leaveGroupThunk = groupCuid => dispatch => {
  dispatch(leaveGroupStarted());
  return leaveGroupDB(groupCuid)
    .then(response => {
      dispatch(leaveGroupSuccess(groupCuid));
    })
    .catch(error => {
      console.error(error);
      dispatch(leaveGroupFail());
    });
};

export const fetchGroupsDBThunk = () => async dispatch => {
  dispatch(fetchGroupsStarted());
  const groupList = await fetchGroupsDB();
  const promises = groupList.map(async group => {
    if (group.fileCuid) {
      const fileRecord = {
        fileCuid: group.fileCuid,
        identityId: group.identityId,
      };
      const avatarUrl = (await fetchS3Url(fileRecord)) || null;
      return (group.imageUrl = avatarUrl);
    }
  });
  Promise.all(promises)
    .then(result => {
      dispatch(fetchGroupsSuccess(groupList));
    })
    .catch(err => {
      console.log(err);
      dispatch(fetchGroupsFail());
    });
};

export const inviteMembersThunk = (
  groupCuid,
  emails = [],
  groupName,
) => dispatch => {
  dispatch(inviteMembersStarted());
  return inviteMembersDB(groupCuid, emails, groupName)
    .then(response => {
      dispatch(inviteMembersSuccess(response, groupCuid));
    })
    .catch(error => {
      console.error(error);
      dispatch(inviteMembersFail());
    });
};

export const fetchGroupInvitesThunk = groupCuid => dispatch => {
  dispatch(fetchInvitesStarted());
  return fetchGroupInvitesDB(groupCuid)
    .then(response => {
      dispatch(fetchInvitesSuccess(groupCuid, response));
    })

    .catch(error => {
      console.error(error);
      dispatch(fetchInvitesFail());
    });
};

export const fetchUserInvitesThunk = () => dispatch => {
  dispatch(fetchUserInvitesStarted());
  return fetchUserInvitesDB()
    .then(response => {
      dispatch(fetchUserInvitesSuccess(response));
    })

    .catch(error => {
      console.error(error);
      dispatch(fetchUserInvitesFail());
    });
};

export const deleteGroupInviteThunk = (
  groupCuid,
  groupInviteCuid,
) => dispatch => {
  dispatch(deleteGroupInviteStarted());
  return deleteGroupInviteDB(groupInviteCuid)
    .then(dispatch(deleteGroupInviteSuccess(groupCuid, groupInviteCuid)))
    .catch(error => {
      console.error(error);
      dispatch(deleteGroupInviteFail());
    });
};

export const deleteMemberThunk = (groupCuid, userCuid) => dispatch => {
  dispatch(deleteMemberStarted());
  return deleteMemberDB(groupCuid, userCuid)
    .then(response => {
      dispatch(deleteMemberSuccess(groupCuid, userCuid));
    })
    .catch(error => {
      console.error(error);
      dispatch(deleteMemberFail());
    });
};

export const fetchMembersThunk = groupCuid => dispatch => {
  dispatch(fetchMembersStarted());
  return fetchMembersDB(groupCuid)
    .then(response => {
      dispatch(fetchMembersSuccess(groupCuid, response));
    })
    .catch(error => {
      console.error(error);
      dispatch(fetchMembersFail(error));
    });
};

export const acceptInviteThunk = groupInviteCuid => dispatch => {
  dispatch(acceptInviteStarted());
  return acceptInviteDB(groupInviteCuid)
    .then(response => {
      dispatch(acceptInviteSuccess());
    })
    .catch(error => {
      console.error(error);
      dispatch(acceptInviteFail());
    });
};

export const fetchGroupSongsThunk = groupCuid => dispatch => {
  dispatch(fetchGroupSongsStarted());
  return fetchGroupSongs(groupCuid)
    .then((response = []) => {
      response.forEach(song => {
        song.songDocument && dispatch(storeSong(song.songDocument));
        song.fileset && dispatch(storeFileset(song.fileset));
      });
      dispatch(fetchGroupSongsSuccess(groupCuid, response));
    })

    .catch(error => {
      console.error(error);
      dispatch(fetchGroupSongsFail());
    });
};

// group avatar upload
export const postGroupAvatarThunk = (file, groupCuid) => async dispatch => {
  dispatch(postGroupAvatarStarted());
  // Create file record
  const identityId = await getIdentityId();
  const fileRecord = newFileRecord(file, identityId);
  await postFileRecord(fileRecord);

  // Upload media to S3
  await s3FileUpload(file, fileRecord.fileCuid);

  // update group with fileCuid
  await updateGroupDB(groupCuid, fileRecord.fileCuid);

  fetchS3Url(fileRecord)
    .then(avatarUrl => {
      const data = { avatarUrl, groupCuid };
      dispatch(postGroupAvatarSuccess(data));
    })
    .catch(err => {
      console.log(err);
      dispatch(postGroupAvatarFail());
    });
};
