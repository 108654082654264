import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  TOKEN_REFRESHED,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  REGISTER_STARTED,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  HIDE_ERROR,
  FETCH_USER_STARTED,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
} from './user.events';

const a = event => () => ({ type: event });

export const registerStarted = () => ({ type: REGISTER_STARTED });

export const registerFailed = errorMsg => ({
  type: REGISTER_FAILED,
  data: { errorMsg },
});

export const storeNewUser = newUser => ({
  type: REGISTER_SUCCESS,
  data: { newUser },
});

export const loginStarted = () => ({ type: LOGIN_STARTED });

export const storeUser = cognitoUser => ({
  type: LOGIN_SUCCESS,
  data: cognitoUser,
});

export const tokenRefreshed = session => ({
  type: TOKEN_REFRESHED,
  data: session,
});
export const loginFailed = errorMsg => {
  return {
    type: LOGIN_FAILED,
    data: { errorMsg },
  };
};

export const logoutStarted = () => ({ type: LOGOUT_STARTED });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFailed = () => ({ type: LOGOUT_FAILED });

export const hideError = () => {
  return {
    type: HIDE_ERROR,
    data: null,
  };
};

export const fetchUserStarted = a(FETCH_USER_STARTED);
export const fetchUserFail = a(FETCH_USER_FAIL);
export const fetchUserSuccess = response => ({
  type: FETCH_USER_SUCCESS,
  data: {
    ...response,
    lastFetchDate: new Date(),
  },
});
