import React, { useState, useEffect } from 'react';
import AudioPlayer from '../../../webaudio/AudioPlayer';

// This function takes a component...
export default (WrappedComponent, selectData) => {
  // ...and returns another component...
  return function AudioPlayerSubscriber(props) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(-1);
    const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);

    useEffect(() => {
      const subscriberId = AudioPlayer.subscribe(audioDetails => {
        handleChange(audioDetails);
      });

      return () => {
        subscriberId && AudioPlayer.unsubscribe(subscriberId);
      };
    });

    function handleChange(audioDetails) {
      isPlaying !== audioDetails.isPlaying &&
        setIsPlaying(audioDetails.isPlaying);
      duration !== audioDetails.duration && setDuration(audioDetails.duration);
      selectedTrackIndex !== audioDetails.selectedTrackIndex &&
        setSelectedTrackIndex(audioDetails.selectedTrackIndex);
    }

    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return (
      <WrappedComponent
        isPlaying={isPlaying}
        durationSeconds={duration}
        selectedTrackIndex={selectedTrackIndex || 0}
        {...props}
      />
    );
  };
};
