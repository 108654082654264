import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { extractValidEmails } from '../../../util/emailUtil';
import { color } from '../../design-tokens';
import H1 from '../../Typography/Heading/H1';
import Text from '../../Typography/Text/Text';
import * as S from './AddMember.styled';

function AddMember({ isVisible, onClickSend, onClickCancel }) {
  const [textAreaValue, setTextAreaValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeTextArea = ({ target }) => {
    setTextAreaValue(target.value);
  };
  const handleClickSend = () => {
    onClickSend(extractValidEmails(textAreaValue));
  };
  const count = () => extractValidEmails(textAreaValue).length;

  useEffect(() => {
    if (isVisible) {
      setTextAreaValue('');
    }
  }, [isVisible]);

  return (
    <Dialog
      open={isVisible}
      onBackdropClick={onClickCancel}
      onEscapeKeyDown={onClickCancel}
      fullScreen={fullScreen}>
      <S.AddMemberView>
        <S.AddMemberContainer>
          <H1>Invite Members</H1>
          <S.TextAreaContainer>
            <textarea
              style={{ minWidth: '250px', minHeight: '160px' }}
              rows="1"
              label=""
              placeholder="user01@example.org user02@example.org"
              required
              variant="outlined"
              margin="dense"
              value={textAreaValue}
              autoFocus
              onChange={handleChangeTextArea}
            />
            <Text style={{ color: color.red100 }}>{count()} valid emails</Text>
          </S.TextAreaContainer>

          <S.SendInvitesButton>
            <Button
              style={{ width: '80%', maxWidth: '400px' }}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleClickSend}>
              Send Invite(s)
            </Button>
          </S.SendInvitesButton>

          <S.SendInvitesButton>
            <Button
              onClick={onClickCancel}
              style={{ width: '80%', maxWidth: '400px' }}
              variant="outlined"
              color="primary"
              size="large">
              Cancel
            </Button>
          </S.SendInvitesButton>
        </S.AddMemberContainer>
      </S.AddMemberView>
    </Dialog>
  );
}

AddMember.propTypes = {
  onClickSend: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default AddMember;
