import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import H1 from '../../Typography/Heading/H1';
import Checkout from '../../Account/Billing/Checkout/Checkout';
import Dialog from '@material-ui/core/Dialog';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import * as S from './StripeModal.styled';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';

const StripeModal = ({ source, onClickCancel, successMsg }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  function handleCancelClick() {
    onClickCancel && onClickCancel();
    setIsVisible(false);
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={'md'}
      open={isVisible}
      onBackdropClick={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}>
      <S.StripeContainer>
        <S.Title>
          {!source ? (
            <H1 style={{ textAign: 'center' }} size="small">
              {!successMsg ? <>Checkout</> : <>Success</>}
            </H1>
          ) : (
            <H1 style={{ textAign: 'center' }} size="small">
              Update Card
            </H1>
          )}
          <hr />
        </S.Title>
        <S.StripeView>
          <Checkout />
        </S.StripeView>
      </S.StripeContainer>
    </Dialog>
  );
};

StripeModal.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  source: PropTypes.object,
  successMsg: PropTypes.string,
};

export default StripeModal;
