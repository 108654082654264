import React from 'react';
import PropTypes from 'prop-types';
import * as S from './ContentFrame.styled';
import Footer from '../../Site/Footer/Footer';
import TopNavConnected from '../../Site/TopNav/TopNavConnected';

function ContentFrame({ children }) {
  return (
    <S.ContentFrame>
      <S.ContentFrame_PageContainer>
        <TopNavConnected />
        {children}
      </S.ContentFrame_PageContainer>
      <Footer />
    </S.ContentFrame>
  );
}

ContentFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentFrame;
