import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Controls.css';
import ProgressBarConnected from '../ProgressBar/ProgressBarConnected';
import PartSelectorTabs from '../PartSelectorTabs/PartSelectorTabs';
import LoopConnected from '../Loop/LoopConnected';
// import ReplayIcon from '@material-ui/icons/Replay';
// import PauseIcon from '@material-ui/icons/Pause';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import useIsTextFieldFocused from '../../hooks/useIsTextFieldFocused';
import SheetMusicIconConnected from '../../Studio/SheetMusicIcon/SheetMusicIconConnected';

import Fab from '@material-ui/core/Fab';

import IconPlay from '../../Theme/Icons/IconPlay';
import IconPause from '../../Theme/Icons/IconPause';
import IconNext from '../../Theme/Icons/IconNext';
import IconPrevious from '../../Theme/Icons/IconPrevious';
import IconSoundOn from '../../Theme/Icons/IconSoundOn';
import IconSoundOff from '../../Theme/Icons/IconSoundOff';

function Controls(props) {
  const {
    isPlaying,
    trackList,
    pause,
    play,
    changeTrack,
    gotoNextSection,
    gotoPreviousSection,
    selectedTrackIndex,
    songCuid,
    hasSheetMusic,
    isMuted,
    onClickMute,
    onLoad,
  } = props;
  const isTextFieldFocused = useIsTextFieldFocused();
  const isMuteButtonEnabled = process.env.REACT_APP_SHOW_MUTE_BUTTON === 'true';

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  });

  useEffect(() => {
    onLoad(isMuted);
  }, [onLoad, isMuted]);

  function onKeyDown(e) {
    if (e.keyCode !== 32) {
      return;
    }
    if (isTextFieldFocused) {
      return;
    }
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }

  const dropdownChange = value => {
    return changeTrack(value);
    // return changeTrack(e.target.value);
  };

  const handleClickMute = () => {
    onClickMute(isMuted);
  };

  function renderPartsDropdown() {
    return (
      <div className="foo" style={{ width: '100%' }}>
        <PartSelectorTabs
          onChange={dropdownChange}
          labels={trackList.map(({ voicePart, subPart, title }, index) => {
            return `${voicePart} ${subPart}`.trim();
          })}
          selectedTrackIndex={selectedTrackIndex}
        />
      </div>
    );
  }

  function renderMuteButton() {
    return (
      <>
        {isMuted ? (
          <IconSoundOff size="large" onClick={handleClickMute} />
        ) : (
          <IconSoundOn size="large" onClick={handleClickMute} />
        )}
      </>
    );
  }

  return (
    <div className="Controls">
      <div id="controls-floating-action-button" />
      <div id="controls-dots-container" />

      <ProgressBarConnected showTimes={true} />

      <div className="Controls__Toolbar">
        {renderPartsDropdown()}
        <div> </div>
      </div>

      <div className="Controls__Container">
        <div style={{ width: 72 }}>
          {hasSheetMusic && <SheetMusicIconConnected songCuid={songCuid} />}
          {isMuteButtonEnabled && renderMuteButton()}
        </div>

        <div className="Controls__Back">
          <IconPrevious fontSize="default" onClick={gotoPreviousSection} />
        </div>
        <Fab size="large" color="primary">
          {isPlaying ? (
            <IconPause size="large" onClick={pause} color="white" />
          ) : (
            <IconPlay size="large" onClick={play} color="white" />
          )}
        </Fab>

        <div className="Controls__Forward">
          <IconNext fontSize="default" onClick={gotoNextSection} />
        </div>

        <LoopConnected />
      </div>
    </div>
  );
}

Controls.propTypes = {
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  gotoNextSection: PropTypes.func.isRequired,
  gotoPreviousSection: PropTypes.func.isRequired,
  changeTrack: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  trackList: PropTypes.array.isRequired,
  selectedTrackIndex: PropTypes.number.isRequired,
  songCuid: PropTypes.string.isRequired,
  hasSheetMusic: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  onClickMute: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default Controls;
