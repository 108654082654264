import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import querystring from 'querystring';

import { withStyles } from '@material-ui/core/styles';

import * as S from './Login.styled';
import Logo from '../../Theme/Logo/Logo';
import Link from '../../Form/Link/Link';
import { authInit } from '../../../amplify.init';
import { CognitoAuth } from 'amazon-cognito-auth-js';
// import googleIcon from '../google-icon.svg';
// import facebookIcon from '../facebook-icon.svg';
import Popup from '../../Layout/Popup/Popup';
import CircularProgress from '@material-ui/core/CircularProgress';
// const socialParams = authInit(window.location.hostname);
import useBreakpoint from '../../hooks/useBreakpoint';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

const Login = props => {
  const {
    onClickLogin,
    // onGoogleLogin,
    // onFacebookLogin,
    errorMsg,
    isPostingCredentials,
    onPostLogin,
    onClickRegister,
    onDismissError,
    location = { search: '?', hash: '' },
  } = props;

  const { isMediumOrAbove } = useBreakpoint();
  const urlParams = querystring.parse(location.search.substring(1));
  const hashParams = querystring.parse(location.hash.substring(1));
  const initialUsername = urlParams.username;
  const { verify_email } = urlParams;
  const { access_token } = hashParams;
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState('');
  const [showEmailPopup, setShowEmailPopup] = useState(verify_email === 'todo');

  async function parseResponseLogin() {
    const initParams = authInit(window.location.hostname);
    const socialParams = {
      ClientId: initParams.clientId,
      UserPoolId: initParams.userPoolId,
      AppWebDomain: initParams.domain,
      TokenScopesArray: initParams.scope,
      RedirectUriSignIn: initParams.redirectSignIn,
      RedirectUriSignOut: initParams.redirectSignOut,
      ResponseType: initParams.responseType,
    };

    // doing this because Amplify (at this time of writing) does not have good support for social logins and so here we need to initialise the AWS-SDK version of the Auth module so we can parse our web response and log the user in after social log in.
    const cognitoAuthClient = new CognitoAuth(socialParams);

    cognitoAuthClient.userhandler = {
      // if the login from parsing url works
      onSuccess: function(result) {
        onPostLogin();
        console.log('SUCCESS');
      },
      onFailure: function(err) {
        console.log(err);
      },
    };
    // Inspecting URL for credentials + signing user in if present
    await cognitoAuthClient.parseCognitoWebResponse(window.location.href);
  }

  /* eslint-disable */
  useEffect(() => {
    if (access_token) {
      parseResponseLogin();
    }
  }, [access_token]);
  /* eslint-enable */

  const handleSubmit = event => {
    event && event.preventDefault();
    const { state: { from } = { from: {} } } = location;
    onClickLogin(username, password, from.pathname);
  };

  const handleRegisterClick = event => {
    event.preventDefault();
    onClickRegister();
  };

  //   const handleSubmitGoogle = event => {
  //     event.preventDefault();
  //     onGoogleLogin();
  //   };
  //
  //   const handleSubmitFacebook = event => {
  //     event.preventDefault();
  //     onFacebookLogin();
  //   };

  const handleHidePopop = () => {
    setShowEmailPopup(false);
  };

  const handleHideErrorMessage = () => {
    // setShowErrorMsg(false);
    // console.log('Hiding Error');
    onDismissError();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <S.Login>
      <S.Inputs>
        <S.Logo>
          <Logo size={isMediumOrAbove ? 'large' : 'small'} />
        </S.Logo>

        <S.Username>
          <TextField
            style={{ width: '100%' }}
            label="Email"
            required
            value={initialUsername}
            variant="outlined"
            onChange={({ target }) =>
              setUsername(target.value.trim().toLowerCase())
            }
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </S.Username>

        <S.Password>
          <TextField
            style={{ width: '100%', marginTop: 10 }}
            type="password"
            label="Password"
            required
            variant="outlined"
            value={password}
            onChange={({ target }) => setPassword(target.value.trim())}
            onKeyDown={handleKeyDown}
          />
        </S.Password>

        <S.ForgotPassword>
          <Link to="/request-reset">Forgot password?</Link>
        </S.ForgotPassword>

        <S.LoginButton>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}>
            {isPostingCredentials ? (
              <CircularProgress
                size={26}
                color="secondary"
                style={{ color: 'white' }}
              />
            ) : (
              'Login'
            )}
          </Button>
        </S.LoginButton>

        <S.RegisterButton>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleRegisterClick}>
            Register
          </Button>
        </S.RegisterButton>
      </S.Inputs>

      {/* <S.SocialMediaLogin> */}
      {/*   <Button */}
      {/*     style={{ marginTop: 10, width: '80%' }} */}
      {/*     variant="outlined" */}
      {/*     size="large" */}
      {/*     color="primary" */}
      {/*     onClick={handleSubmitGoogle}> */}
      {/*     <img */}
      {/*       src={googleIcon} */}
      {/*       width="25" */}
      {/*       style={{ marginRight: 8 }} */}
      {/*       alt="Google Icon" */}
      {/*     /> */}
      {/*     Google Login */}
      {/*   </Button> */}
      {/*   <Button */}
      {/*     style={{ marginTop: 20, width: '80%' }} */}
      {/*     variant="outlined" */}
      {/*     size="large" */}
      {/*     color="primary" */}
      {/*     onClick={handleSubmitFacebook}> */}
      {/*     <img */}
      {/*       src={facebookIcon} */}
      {/*       width="25" */}
      {/*       style={{ marginRight: 8 }} */}
      {/*       alt="Facebook Icon" */}
      {/*     /> */}
      {/*     Facebook Login */}
      {/*   </Button> */}
      {/* </S.SocialMediaLogin> */}

      <Popup
        show={showEmailPopup}
        title="Confirmation Email Sent"
        imageName="trio"
        body="Please confirm your email address by clicking the link in your inbox"
        buttonText="Okay"
        onDismiss={handleHidePopop}
      />

      <Popup
        show={!!errorMsg}
        title="Oops"
        imageName="conductor"
        body="There was a problem logging in, please try again"
        buttonText="Okay"
        onDismiss={handleHideErrorMessage}
      />
    </S.Login>
  );
};

Login.propTypes = {
  errorMsg: PropTypes.string,
  isPostingCredentials: PropTypes.bool.isRequired,
  hasFailedLogin: PropTypes.bool.isRequired,
  onClickLogin: PropTypes.func.isRequired,
  onGoogleLogin: PropTypes.func,
  onFacebookLogin: PropTypes.func,
  onPostLogin: PropTypes.func,
  onClickRegister: PropTypes.func.isRequired,
  onDismissError: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default withStyles(styles)(Login);
