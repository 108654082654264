import React from 'react';
import PropTypes from 'prop-types';
import * as S from './InputError.styled';

export default function InputError({ children }) {
  return (
    <S.InputError>
      <S.InputError__Content>{children}</S.InputError__Content>
    </S.InputError>
  );
}

InputError.propTypes = {
  children: PropTypes.string,
};
