import styled from 'styled-components';

export const PageOverlay = styled.div`
  background-color: white;
  position: fixed;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available; // fixes IOS player
  z-index: 1;
  will-change: transform;
  /* transform: translateX(100vw); */
  transform: ${props =>
    props.isVisible ? 'translateX(0vw)' : 'translateX(100vw)'};
  transition: transform 225ms ease-in-out;

  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
`;
