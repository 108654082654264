import styled from 'styled-components';
import { color } from '../../design-tokens';

export const InputError = styled.div`
  font-family: Platform Web;
  position: fixed;
`;

export const InputError__Content = styled.div`
  font-size: 1em;
  color: ${color.red100};
`;
