import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      iterlimit="10"
      d="M57.5,92.5H19c-7.695,0-14-6.306-14-14v-7
  c0-7.694,6.305-14,14-14h38.5V19c0-7.694,6.305-14,14-14h7c7.689,0,14,6.306,14,14v38.5H131c7.689,0,14,6.306,14,14v7
  c0,7.694-6.311,14-14,14H92.473L92.5,131c0,7.694-6.311,14-14,14h-7c-7.695,0-14-6.306-14-14"
    />
  </g>,
  150,
  150,
);
