import React from 'react';
import PropTypes from 'prop-types';
import * as S from './IconSVG.styled';

const SIZES = {
  small: 16,
  medium: 24,
  large: 36,
  xLarge: 48,
};

function IconSVG({
  children,
  id,
  testId,
  style,
  className,
  size,
  width,
  height,
  ...props
}) {
  const calculatedWidth = width || SIZES[size] || SIZES.medium;
  const calculatedHeight = height || SIZES[size] || SIZES.medium;

  return (
    <S.IconSVG
      id={id}
      testId={testId}
      className={className}
      style={style}
      width={calculatedWidth}
      height={calculatedHeight}
      {...props}>
      {children}
    </S.IconSVG>
  );
}

IconSVG.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xLarge', '', undefined]),
  width: PropTypes.number,
  height: PropTypes.number,
};

export default IconSVG;
