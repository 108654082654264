import { path } from 'ramda';
import { asList, getFirst } from '../storeUtil';
import isString from 'lodash/isString';

export const getActiveSongCuid = (state = {}) => {
  const { songCuid } = state.player;

  return songCuid || '';
};
export const getActiveTrackIndex = (state = {}) => {
  const { selectedTrackIndex = 0 } = state.player;
  return selectedTrackIndex;
};

export const getActiveSong = (state = {}) => {
  const songCuid = getActiveSongCuid(state);
  return getSongByCuid(songCuid, state);
};

export const getActiveGuideCuid = (state = {}) => {
  const trackIndex = getActiveTrackIndex(state);
  const songDocument = getActiveSong(state);
  const trackCuid = path(['trackOrder', trackIndex], songDocument);
  const guideCuid = path(['tracks', trackCuid, 'guideCuid'], songDocument);
  return guideCuid || getFirst(songDocument.guides).guideCuid;
};

export const getActiveGuide = (state = {}) => {
  const activeGuideCuid = getActiveGuideCuid(state);
  const songDocument = getActiveSong(state);
  const guide = songDocument.guides[activeGuideCuid];
  return guide;
};

export const getSongByCuid = (songCuid, state) => {
  return getMySong(songCuid, state);
};

export const getSections = (state = {}) => {
  const guide = getActiveGuide(state);
  const sections = asList(guide.sections) || [];
  return sections;
};

export const getMeta = (state = {}) => {
  const song = getActiveSong(state);
  const meta = song.meta || [];
  return { ...meta };
};

export const getTrackUrls = (state = {}) => {
  const song = getActiveSong(state);
  const parts = song.parts || [];
  const tracks = parts.map(part => part.url);
  return [...tracks];
};

export const getNextSectionPosition = (currentPositionSeconds, state) => {
  const sections = getSections(state);
  const currentPositionMillis = currentPositionSeconds * 1000;
  return (
    sections
      .map(section => section.position)
      .find(position => position > currentPositionMillis) / 1000
  );
};

export const getPreviousSectionPosition = (currentPositionSeconds, state) => {
  const sections = getSections(state);
  const currentPositionAdjusted = currentPositionSeconds - 0.5;
  const currentPositionMillis = currentPositionAdjusted * 1000;
  return (
    sections
      .map(section => section.position)
      .reverse()
      .find(position => position < currentPositionMillis) / 1000
  );
};

export const getMySongs = state => {
  const myMusic = state.myMusic || state;
  const { mySongCuids = [], songs } = myMusic;
  const mySongs = mySongCuids
    .filter(isString)
    .map(songCuid => songs[songCuid] || {});
  return mySongs;
};

export const getMySong = (songCuid, state) => {
  const myMusic = state.myMusic || state;
  return myMusic.songs[songCuid] || {};
};

export const getSongsByCuid = (songCuids = [], state) => {
  const myMusic = state.myMusic || state;
  const allSongs = myMusic.songs || [];
  return songCuids.map(songCuid => allSongs[songCuid]);
};

export const getNextMyMusicSongCuid = (currentSongCuid, groupCuid, state) => {
  const myMusic = state.myMusic || state;
  const { mySongCuids = [] } = myMusic;
  const i = mySongCuids.indexOf(currentSongCuid);
  return mySongCuids[i + 1];
};

export const getTrack = (songCuid, trackCuid, state) => {
  const song = getMySong(songCuid, state);
  const track = song.tracks[trackCuid];
  return track;
};

export const getTrackList = (songCuid, state) => {
  const song = getMySong(songCuid, state);
  const { tracks, trackOrder = [] } = song;
  const trackList = trackOrder.map(trackCuid => tracks[trackCuid]);
  return trackList;
};

export const getTracksForGuide = (songCuid, guideCuid, state) => {
  const trackList = getTrackList(songCuid, state) || [];
  return trackList.filter(track => track.guideCuid === guideCuid);
};

export const getGuide = (songCuid, guideCuid, state) => {
  const song = getMySong(songCuid, state);
  const guide = song.guides[guideCuid];
  return guide;
};

export const getSection = (songCuid, guideCuid, position, state) => {
  return getGuide(songCuid, guideCuid, state)?.sections?.[position];
};

export const isFetchingSongList = state => {
  const myMusic = state.myMusic || state;
  return myMusic.isFetchingSongList;
};

export const getNumFilesRemaining = state => {
  const myMusic = state.myMusic || state;
  const { uploadStatus } = myMusic;

  return uploadStatus ? uploadStatus.filesRemaining : 0;
};

export const isUploadInProgress = state => getNumFilesRemaining(state) > 0;

export const getUploadFileName = state => {
  const myMusic = state.myMusic || state;
  const { uploadStatus } = myMusic;

  return uploadStatus ? uploadStatus.filename : '';
};

export const getFileset = (filesetCuid, state) => {
  const myMusic = state.myMusic || state;
  return myMusic.filesets[filesetCuid] || {};
};

export const getFileRecord = (filesetCuid, fileCuid, state) => {
  const fileset = getFileset(filesetCuid, state) || {};
  const { fileRecords = {} } = fileset;

  return fileRecords[fileCuid];
};

export const getFileUrl = (fileCuid, filesetCuid, state) => {
  const myMusic = state.myMusic || state;

  return path(['filesetUrls', filesetCuid, fileCuid], myMusic);
};

export const getFilesetUrls = (filesetCuid, state) => {
  const myMusic = state.myMusic || state;
  return myMusic.filesetUrls[filesetCuid] || {};
};

export const getTrackUrlsByCuid = (songCuid, state) => {
  const song = getSongByCuid(songCuid, state);
  const { filesetCuid, trackOrder = [], tracks } = song;
  const filesetUrls = getFilesetUrls(filesetCuid, state) || {};
  const fileUrls = trackOrder.map(trackCuid => {
    const track = tracks[trackCuid];
    const { fileCuid } = track;
    const fileUrl = filesetUrls[fileCuid];
    return fileUrl;
  });
  return fileUrls;
};

export const isEditable = (songCuid, state) => {
  const myMusic = state.myMusic || state;
  const { mySongCuids = [] } = myMusic;
  return mySongCuids.includes(songCuid);
};

export const getGroupsForSong = (songCuid, state) => {
  const myMusic = state.myMusic || state;
  const { songGroups } = myMusic;
  return songGroups[songCuid];
};

export const isPostingShareSong = state => {
  const myMusic = state.myMusic || state;
  return myMusic.isPostingShareSong;
};

export const getNextSongCuid = (currentSongCuid, state = {}) => {
  const player = state.player || {};
  const { songCuid } = player;
  return songCuid;
};

export const hasSheetMusic = (songCuid, state = {}) => {
  const song = getSongByCuid(songCuid, state);
  return Object.keys(song.sheetMusic).length > 0;
};
