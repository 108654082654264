import { connect } from 'react-redux';
import SheetMusicIcon from './SheetMusicIcon';

import {
  getSongByCuid,
  getFilesetUrls,
  getFileRecord,
  hasSheetMusic,
} from '../../../store/myMusic/myMusic.selectors';
import { asList } from '../../../store/storeUtil';

const mapStateToProps = (state, ownProps) => {
  const { songCuid } = ownProps;
  const song = getSongByCuid(songCuid, state);

  const { filesetCuid, sheetMusic = {} } = song;
  const filesetUrls = getFilesetUrls(filesetCuid, state);
  const sheet = asList(sheetMusic)[0] || {};
  const sheetMusicUrl = filesetUrls[sheet.fileCuid];
  const pdfFileRecord = getFileRecord(filesetCuid, sheet.fileCuid, state) || {};

  return {
    sheetMusicUrl,
    hasSheetMusic: hasSheetMusic(songCuid, state),
    pdfFileName: pdfFileRecord.filename,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => {},
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SheetMusicIcon);
