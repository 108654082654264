import { connect } from 'react-redux';
import BillingDetails from './BillingDetails';
import { showModal } from './../../../../store/modal/modal.actions';
import { fetchSubscriptionThunk } from '../../../../store/billing/billing.thunks';
import {
  getBillingDetails,
  isCancellingSubscription,
  isResubscribing,
} from './../../../../store/billing/billing.selectors';

const mapStateToProps = state => {
  return {
    subscription: getBillingDetails(state),
    isResubscribing: isResubscribing(state),
    isCancellingSubscription: isCancellingSubscription(state),
  };
};

const mapDispatchToProps = dispatch => ({
  doFetchSubscription: () => {
    dispatch(fetchSubscriptionThunk());
  },
  onClickCancel: end_date => {
    dispatch(showModal('CANCEL_SUBSCRIPTION', { end_date }));
  },
  onClickResubscribe: renewal_date => {
    dispatch(showModal('RESUBSCRIBE', { renewal_date }));
  },
  onClickOpenStripe: source => {
    dispatch(showModal('STRIPE_MODAL', { source }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingDetails);
