import React from 'react';
import AppContext from './AppContext';

export default function withAppContext(Component) {
  return props => (
    <AppContext.Consumer>
      {context => <Component {...context} {...props} />}
    </AppContext.Consumer>
  );
}
