import React, { useState, useEffect } from 'react';
import AudioPlayer from '../../../webaudio/AudioPlayer';

// This function takes a component...
export default (WrappedComponent, selectData) => {
  // ...and returns another component...
  return function AudioProgressSubscriber(props) {
    const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);
    const [bufferChunks, setBufferChunks] = useState([]);

    useEffect(() => {
      const subscriberId = AudioPlayer.subscribe(audioDetails => {
        handleChange(audioDetails);
      });

      return () => {
        subscriberId && AudioPlayer.unsubscribe(subscriberId);
      };
    }, []);

    function handleChange(audioDetails) {
      setCurrentTimeSeconds(audioDetails.currentTimeSeconds);
      setBufferChunks(audioDetails.bufferChunks);
    }

    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    return (
      <WrappedComponent
        currentTimeSeconds={currentTimeSeconds}
        bufferChunks={bufferChunks}
        {...props}
      />
    );
  };
};
