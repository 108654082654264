import React from 'react';
import PropTypes from 'prop-types';

import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import { choirpal_red_80, choirpal_white } from '../../Theme/colors';
import './PlayPause.css';

function PlayPause({ isPlaying, isEnabled, size, onPlay, onPause, color }) {
  const handleClick = e => {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  };

  const sizeMultiplier = size === 'small' ? 1 : 2;

  const iconStyle = {
    height: 26 * sizeMultiplier,
    fontSize: 30 * sizeMultiplier,
    userSelect: 'none',
    color: color === 'primary' ? choirpal_red_80 : choirpal_white,
  };
  return (
    <div className="PlayPause">
      {isPlaying ? (
        <Pause style={iconStyle} onClick={handleClick} />
      ) : (
        <PlayArrow style={iconStyle} onClick={handleClick} />
      )}
    </div>
  );
}

PlayPause.propTypes = {
  isPlaying: PropTypes.bool,
  isEnabled: PropTypes.bool,
  size: PropTypes.string,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  color: PropTypes.string,
};

export default PlayPause;
