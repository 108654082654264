import {
  APP_INIT,
  APP_UNLOAD,
  API_MISMATCH,
  API_VERSION_STARTED,
  API_VERSION_SUCCESS,
  API_VERSION_FAILED,
} from './app.events';

export const appInit = () => {
  return {
    type: APP_INIT,
  };
};

export const appUnload = () => {
  return {
    type: APP_UNLOAD,
  };
};

export const apiMismatch = () => {
  return {
    type: API_MISMATCH,
  };
};

export const fetchApiVersionStarted = () => {
  return {
    type: API_VERSION_STARTED,
  };
};

export const fetchApiVersionSuccess = response => {
  return {
    type: API_VERSION_SUCCESS,
    response: response,
  };
};

export const fetchApiVersionFailed = () => {
  return {
    type: API_VERSION_FAILED,
  };
};
