import { path } from 'ramda';
import {
  POST_SUBSCRIPTION_STARTED,
  POST_SUBSCRIPTION_SUCCESS,
  POST_SUBSCRIPTION_FAIL,
  FETCH_SUBSCRIPTION_STARTED,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAIL,
  FETCH_COUPON_STARTED,
  FETCH_COUPON_SUCCESS,
  FETCH_COUPON_FAIL,
  CANCEL_SUBSCRIPTION_STARTED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  RESUBSCRIBE_STARTED,
  RESUBSCRIBE_SUCCESS,
  RESUBSCRIBE_FAIL,
  CLEAR_MODAL_MSGS,
  CLEAR_STRIPE_ERRORS,
} from './billing.events';

const initialState = {
  isPostingSubscription: false,
  isFetchingSubscription: false,
  isUpdatingSubscription: false,
  isCancellingSubscription: false,
  isFetchingCoupon: false,
  isResubscribing: false,
  errorMsg: null,
  successMsg: null,
  coupon: {},
  couponError: null,
  sub: {},
};

export const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SUBSCRIPTION_STARTED: {
      return { ...state, isPostingSubscription: true };
    }
    case POST_SUBSCRIPTION_SUCCESS: {
      const { subscription, update } = path(['data'], action);
      let successMsg;
      if (update) {
        // existing subscription
        successMsg = 'Card details successfully updated';
      } else {
        if (subscription.trial_ending) {
          // new subscription with trial
          successMsg = `You have successfully started a trial with Choirpal Pro which will end on ${subscription.trial_ending}`;
        } else {
          // new subscription no trial
          successMsg = 'Welcome to Choirpal Pro!';
        }
      }

      return {
        ...state,
        sub: subscription || {},
        isPostingSubscription: false,
        successMsg,
      };
    }
    case POST_SUBSCRIPTION_FAIL: {
      const { update } = path(['data'], action);
      let errorMsg;
      if (update) {
        errorMsg =
          'We were unable to update your card details, please try again.';
      } else {
        errorMsg = 'Payment failed, please try again.';
      }
      return {
        ...state,
        errorMsg,
        isPostingSubscription: false,
      };
    }

    case CLEAR_MODAL_MSGS: {
      return {
        ...state,
        errorMsg: null,
        successMsg: null,
        coupon: {},
      };
    }
    case CLEAR_STRIPE_ERRORS: {
      return {
        ...state,
        couponError: null,
        errorMsg: null,
      };
    }
    case FETCH_SUBSCRIPTION_STARTED: {
      return { ...state, isFetchingSubscription: true };
    }
    case FETCH_SUBSCRIPTION_SUCCESS: {
      const { subscription } = path(['data'], action);

      return {
        ...state,
        sub: subscription || {},
        isFetchingSubscription: false,
      };
    }
    case FETCH_SUBSCRIPTION_FAIL: {
      return { ...state, isFetchingSubscription: false };
    }

    case FETCH_COUPON_STARTED: {
      return { ...state, isFetchingCoupon: true };
    }
    case FETCH_COUPON_SUCCESS: {
      const { coupon } = path(['data'], action);

      return {
        ...state,
        coupon: coupon || {},
        isFetchingCoupon: false,
      };
    }
    case FETCH_COUPON_FAIL: {
      const { error } = path(['data'], action);
      return {
        ...state,
        couponError: error,
        isFetchingCoupon: false,
      };
    }

    case CANCEL_SUBSCRIPTION_STARTED: {
      return { ...state, isCancellingSubscription: true };
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const { subscription } = path(['data'], action);

      return {
        ...state,
        sub: subscription || {},
        isCancellingSubscription: false,
      };
    }
    case CANCEL_SUBSCRIPTION_FAIL: {
      const { error } = path(['data'], action);
      return {
        ...state,
        errorMsg: error || null,
        isCancellingSubscription: false,
      };
    }
    case RESUBSCRIBE_STARTED: {
      return { ...state, isResubscribing: true };
    }
    case RESUBSCRIBE_SUCCESS: {
      const { subscription } = path(['data'], action);

      return {
        ...state,
        sub: subscription || {},
        isResubscribing: false,
      };
    }
    case RESUBSCRIBE_FAIL: {
      const { error } = path(['data'], action);
      return {
        ...state,
        errorMsg: error || null,
        isResubscribing: false,
      };
    }
    default:
      return state;
  }
};
