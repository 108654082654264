import React from 'react';
import PropTypes from 'prop-types';
import * as S from './SectionHeader.styled';
import H3 from '../../Typography/Heading/H3';

function SectionHeader({ title, subTitle }) {
  return (
    <S.SectionHeader>
      <S.InnerContainer>
        <S.Heading>
          <H3>{title}</H3>
        </S.Heading>
        <S.NumSongs>{subTitle}</S.NumSongs>
      </S.InnerContainer>
    </S.SectionHeader>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

export default SectionHeader;
