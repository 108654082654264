import { connect } from 'react-redux';
import { updateSong } from '../../../store/myMusic/myMusic.actions';
import { getMySong } from '../../../store/myMusic/myMusic.selectors';
import EditSongDetails from './EditSongDetails';

const mapStateToProps = (state, ownProps) => {
  const { songCuid } = ownProps;
  const song = getMySong(songCuid, state);

  return {
    song,
  };
};

const mapDispatchToProps = dispatch => ({
  onSave: song => {
    dispatch(updateSong(song));
    // dispatch(addMediaRoute(song.songCuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSongDetails);
