import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/modal.actions';
import { getBillingDetails } from '../../../store/billing/billing.selectors';
import StripeModal from './StripeModal';
import { clearModalMsgs } from '../../../store/billing/billing.actions';

const mapStateToProps = (state, ownProps) => {
  const { successMsg = '' } = getBillingDetails(state);

  return {
    successMsg,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickCancel: () => {
    dispatch(hideModal());
    dispatch(clearModalMsgs());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeModal);
