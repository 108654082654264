import styled from 'styled-components';
import { choirpal_red_40 } from '../../Theme/colors';

export const GuideListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  text-align: center;
  box-shadow: 0px 0px 8px ${choirpal_red_40};
  border-radius: 4px;
  box-sizing: border-box;
  padding-right: 8px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
`;

export const PlayPause = styled.div``;
export const Filename = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 50%;
  width: 100%;
  overflow: hidden;
  /*font-weight: bold;*/
  font-size: 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  overflow: hidden;
  font-size: 13px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: flex-end;
  height: 20px;
`;
