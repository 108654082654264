import styled from 'styled-components';

export const GroupsList = styled.div`
  width: 100%;
`;

export const NoGroups = styled.div`
  align-items: center;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ListPadding = styled.div`
  min-height: ${props => (props.native ? '190px' : '60px')};
`;
