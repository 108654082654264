import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import { getErrorMsg } from '../../../store/user/user.selectors';
import { resetConfirmThunk } from '../../../store/user/user.thunks';

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
});

const mapDispatchToProps = dispatch => ({
  setNewPassword: (email, code, password) => {
    console.log('setNewPassword');
    dispatch(resetConfirmThunk(email, code, password));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
