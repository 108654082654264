import React from 'react';
import PropTypes from 'prop-types';
import './LoginFrame.css';

export default function LoginFrame({ children }) {
  return (
    <div className="LoginFrame">
      <div className="LoginFrame__Content">{children}</div>
    </div>
  );
}

LoginFrame.propTypes = {
  children: PropTypes.object,
};
