// Action Types
export const PLAYER_INIT = 'player/player-init';
export const CLEAR_PLAYER = 'player/clear-player';
export const OPEN_SONG = 'player/open-song';
export const PLAY_START = 'player/play-start';
export const MAXIMISE_PLAYER = 'player/maximise';
export const MINIMISE_PLAYER = 'player/minimise';
export const CHANGE_TRACK = 'player/change-track';
export const ENABLE_TOOL = 'player/enable-tool';
export const DISABLE_TOOL = 'player/disable-tool';
