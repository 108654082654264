import styled from 'styled-components';
import { color } from '../../design-tokens';
import { Link as ReduxLink } from 'react-router-dom';

export const Link = styled(ReduxLink)`
  font-family: 'Platform Web';
  :link {
    text-decoration: none;
    color: ${color.red100};
  }

  :visited {
    text-decoration: none;
    color: ${color.red100};
  }

  :hover {
    text-decoration: underline;
  }

  :active {
    text-decoration: underline;
  }
`;
