import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
// import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Carousel from 'react-material-ui-carousel';
import * as S from '../HintModal/HintModal.styled';
import H1 from '../../Typography/Heading/H1';
import Dialog from '@material-ui/core/Dialog';
import Screenshot from '../../Site/Screenshot/Screenshot';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export default function WelcomeHintModal({ onClickClose = () => {} }) {
  const [isVisible, setIsVisible] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const isLastPage = () => activePage === pages.length - 1;
  const getButtonText = () => (isLastPage() ? "Let's Go" : 'Next');
  const pages = [pageOne(), pageTwo(), pageThree()];

  function handleCloseClick() {
    onClickClose && onClickClose();
    setIsVisible(false);
  }

  function handleClickNext() {
    if (isLastPage()) {
      onClickClose();
    } else {
      setActivePage(activePage + 1);
    }
  }

  function handleChangeCarousel(pageIndex) {
    setActivePage(pageIndex);
  }

  function pageOne() {
    return (
      <S.HintView key="pageOne">
        <H1>Welcome to Choirpal</H1>
        <p>Make playlists with all your teach tracks</p>
        <Screenshot variant="playlist" size="small" />
      </S.HintView>
    );
  }

  function pageTwo() {
    return (
      <S.HintView key="pageTwo">
        <H1>Bytesize Learning</H1>
        <p>Learn faster by adding lyrics and rehearsal marks</p>
        <Screenshot variant="player" size="small" />
      </S.HintView>
    );
  }

  function pageThree() {
    return (
      <S.HintView key="pageThree">
        <H1>Attach the Sheet Music</H1>
        <p>Keep your sheets and tracks in one convenient location</p>
        <Screenshot variant="sheetMusic" size="small" />
      </S.HintView>
    );
  }

  return (
    <Dialog
      open={isVisible}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={fullScreen}
      onBackdropClick={handleCloseClick}
      onEscapeKeyDown={handleCloseClick}
      TransitionComponent={Transition}>
      <S.HintContainer>
        <S.HintInnerContainer>
          <Carousel
            index={activePage}
            autoPlay={false}
            animation="slide"
            style={{ width: '100%' }}
            onChange={handleChangeCarousel}>
            {pages}
          </Carousel>
          <Button
            onClick={handleClickNext}
            style={{
              margin: 20,
              width: '80%',
              marginTop: 20,
              maxWidth: '320px',
            }}
            variant="contained"
            color="primary"
            size="large">
            {getButtonText()}
          </Button>
        </S.HintInnerContainer>
      </S.HintContainer>
    </Dialog>
  );
}

WelcomeHintModal.propTypes = {
  onClickClose: PropTypes.func,
};
