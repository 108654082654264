import {
  UPDATE_SONG,
  ADD_TRACK,
  ADD_SHEET_MUSIC,
  DELETE_SHEET_MUSIC,
  DELETE_TRACK,
  ADD_GUIDE,
  DELETE_GUIDE,
  STORE_FILESET,
  ADD_SECTION,
  NUDGE_SECTION,
  DELETE_SECTION,
  ADD_PAGE,
} from '../myMusic/myMusic.events';
import { getMySong } from '../myMusic/myMusic.selectors';
import { postSongThunk, fetchFileUrlsThunk } from '../myMusic/myMusic.thunks';

export const StudioMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  const { dispatch } = store;
  switch (action.type) {
    case UPDATE_SONG: {
      const { song } = action;
      dispatch(postSongThunk(song));

      break;
    }
    case ADD_TRACK: {
      const { songCuid } = action.data;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case DELETE_TRACK: {
      const { songCuid } = action.data;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case ADD_SHEET_MUSIC: {
      const { songCuid } = action.data;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case DELETE_SHEET_MUSIC: {
      const { songCuid } = action.data;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case ADD_GUIDE: {
      const { songCuid } = action.data;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case ADD_SECTION: {
      const { songCuid } = action;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case NUDGE_SECTION: {
      const { songCuid } = action;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case DELETE_SECTION: {
      const { songCuid } = action;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case ADD_PAGE: {
      const { songCuid } = action;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case DELETE_GUIDE: {
      const { songCuid } = action.data;
      const song = getMySong(songCuid, state);
      dispatch(postSongThunk(song));

      break;
    }
    case STORE_FILESET: {
      const { fileset } = action;
      // const song = getMySong(songCuid, state);
      dispatch(fetchFileUrlsThunk(fileset));

      break;
    }
    default: {
      break;
    }
  }
};
