import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AudioPlayer from '../../../webaudio/AudioPlayer';
import Loading from '../../Layout/Loading/Loading';
import PageHeader from '../PageHeader/PageHeader';
import Pages from '../Pages/Pages';
import Section from '../Section/Section';
import * as S from './Song.styled';

const throttledLogger = throttle((...params) => {
  console.log(...params);
}, 2000);
throttledLogger();

function Song(props) {
  const {
    songCuid,
    sections,
    seek,
    isEditable = false,
    hasViewedWelcomeHint,
    sectionsByPage,
    activeToolName,
    onChangeLyrics,
    onChangePageTitle,
    onClickDeleteSection,
    onClickAddPage,
    onClickDeletePage,
    onClickNudgeSection,
    onClickAddSection,
    onClickShowEditorHint,
    onClickStartEditing,
  } = props;

  const [currentTimeMillis, setCurrentTimeMillis] = useState(0);
  const [songDurationMillis, setSongDurationMillis] = useState(0);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  useEffect(() => {
    if (sections.length === 1 && isEditable) {
      setIsEditModeEnabled(true);
    }
  }, [sections, isEditable]);

  const getActivePage = currentTimeMillis => {
    const firstSectionsOnPageReversed = sectionsByPage
      .map(page => page[0])
      .reverse();
    const pagesFromEnd = firstSectionsOnPageReversed.findIndex(
      firstSection => firstSection.position <= currentTimeMillis,
    );
    return firstSectionsOnPageReversed.length - pagesFromEnd - 1;
  };

  const activePage = getActivePage(currentTimeMillis);
  const activeSectionPosition = findActiveSection(currentTimeMillis, sections);

  useEffect(() => {
    const subscriberId = AudioPlayer.subscribe(audioStatus => {
      setCurrentTimeMillis(audioStatus.currentTimeMillis);
      setSongDurationMillis(audioStatus.durationMillis);

      if (isAudioLoading !== audioStatus.isLoading) {
        setIsAudioLoading(audioStatus.isLoading);
      }
    });

    return () => {
      AudioPlayer.unsubscribe(subscriberId);
    };
  }, [
    activeSectionPosition,
    activePage,
    sections,
    setIsAudioLoading,
    isAudioLoading,
  ]);

  useEffect(() => {
    if (!hasViewedWelcomeHint && sections.length === 1) {
      onClickShowEditorHint();
    }
  }, [hasViewedWelcomeHint, sections, onClickShowEditorHint]);

  const handleChangeLyrics = (position, lyrics) => {
    onChangeLyrics(position, lyrics);
  };

  const handleChangePageTitle = (position, pageTitle) => {
    onChangePageTitle(position, pageTitle);
  };

  const handleClickDeleteSection = position => {
    onClickDeleteSection(position);
  };

  const handleClickDeletePage = position => {
    onClickDeletePage(position);
  };

  const handleEnterToAddSection = position => {
    onClickAddSection(position);
  };

  const handleClickToggleEditMode = (enable = true) => {
    setIsEditModeEnabled(Boolean(isEditable && enable));
  };

  function findActiveSection(currentTimeMillis, sections) {
    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];
      const { position } = section;
      if (currentTimeMillis >= position) {
        return section.position;
      }
    }
    return null;
  }

  function longestSectionDuration(sections = []) {
    return Math.max(...sections.map(s => s.duration));
  }

  const renderPage = (pageSections, index) => {
    const { pageTitle, position } = pageSections[0];
    return (
      <div className="Groups" key={songCuid + index}>
        <PageHeader
          pageTitle={pageTitle || 'Page 1'}
          position={position}
          isEditable={isEditable}
          isEditModeEnabled={isEditModeEnabled}
          onChangePageTitle={handleChangePageTitle}
          onClickDeletePage={handleClickDeletePage}
          onClickToggleEditMode={handleClickToggleEditMode}
          onClickStartEditing={onClickStartEditing}
          activeToolName={activeToolName}
          isFirstPage={index === 0}
        />
        {pageSections.map(renderSection)}
      </div>
    );
  };

  const renderSection = (section, indexOnPage) => {
    const { lyrics, position, isPageBreak, indexInSong } = section;
    const endPosition =
      sections[indexInSong + 1]?.position || songDurationMillis;

    return (
      <Section
        key={songCuid + position}
        currentTimeMillis={currentTimeMillis}
        widthDuration={longestSectionDuration(sections)}
        lyrics={lyrics}
        indexOnPage={indexOnPage}
        position={position}
        endPosition={endPosition}
        seek={seek}
        isActive={position === activeSectionPosition}
        isEditable={isEditable}
        isEditModeEnabled={isEditModeEnabled}
        activeToolName={activeToolName}
        isPageBreak={isPageBreak}
        onChangeLyrics={handleChangeLyrics}
        onClickAddSection={onClickAddSection}
        onClickDeleteSection={handleClickDeleteSection}
        onClickAddPage={onClickAddPage}
        onClickDeletePage={onClickDeletePage}
        onClickNudgeSection={onClickNudgeSection}
        onEnterToAddSection={handleEnterToAddSection}
        onClickToggleEditMode={handleClickToggleEditMode}
      />
    );
  };

  return (
    <S.Song>
      <Pages activePage={activePage} songCuid={songCuid}>
        {isAudioLoading
          ? sectionsByPage.map((foo, index) => (
              <Loading key={`loading-indicator-${index}`} />
            ))
          : sectionsByPage.map(renderPage)}
      </Pages>
    </S.Song>
  );
}

Song.propTypes = {
  songCuid: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  sectionsByPage: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  seek: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  activeToolName: PropTypes.string.isRequired,
  hasViewedWelcomeHint: PropTypes.bool.isRequired,
  onChangeLyrics: PropTypes.func.isRequired,
  onChangePageTitle: PropTypes.func.isRequired,
  onClickDeleteSection: PropTypes.func.isRequired,
  onClickAddPage: PropTypes.func.isRequired,
  onClickDeletePage: PropTypes.func.isRequired,
  onClickNudgeSection: PropTypes.func.isRequired,
  onClickAddSection: PropTypes.func.isRequired,
  onClickShowEditorHint: PropTypes.func.isRequired,
  onClickStartEditing: PropTypes.func.isRequired,
};
export default Song;
