import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import './PartSelectorTabs.css';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'red',
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
});

function PartSelectorTabs(props) {
  const handleChange = (event, index) => {
    onChange(index);
  };

  const { classes, selectedTrackIndex = 0, labels, onChange } = props;

  const partSelectorClasses = classNames('PartSelectorTabs', classes.root);

  // TODO: value is being persisted from previous song
  const calculatedSelectedTrackIndex =
    selectedTrackIndex < labels.length ? selectedTrackIndex : 0;

  return (
    <div className={partSelectorClasses}>
      <AppBar
        position="static"
        color="default"
        classes={{ root: classes.appBarRoot }}>
        <Tabs
          value={calculatedSelectedTrackIndex}
          onChange={handleChange}
          classes={{ root: classes.tabsRoot }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto">
          {labels.map((label, index) => (
            <Tab label={label || 'track-' + index} key={index} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

PartSelectorTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.array.isRequired,
  selectedTrackIndex: PropTypes.number.isRequired,
};

export default withStyles(styles)(PartSelectorTabs);
