import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M9.999,19.756c0-13.145,9.703-18.526,21.553-11.949
    l99.559,55.242c11.854,6.569,11.854,17.328,0,23.904l-99.559,55.248c-11.85,6.562-21.553,1.19-21.553-11.963V54.39"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="139.999"
      y1="150"
      x2="139.999"
      y2="0"
    />
  </g>,
  150,
  150,
);
