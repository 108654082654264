import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
// import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import * as S from './OkCancelModal.styled';
import Conductor from '../../Theme/Conductor/Conductor';
import Trio from '../../Theme/Trio/Trio';
import H1 from '../../Typography/Heading/H1';
import Dialog from '@material-ui/core/Dialog';

const avaiableImages = {
  conductor: Conductor,
  trio: Trio,
};

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export default function OkCancelModal({
  title,
  imageName,
  body = '',
  okButtonText = 'Ok',
  cancelButtonText = 'Cancel',
  onClickOk,
  onClickCancel,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsVisible(true);
  }, []);

  function handleOkClick() {
    onClickOk && onClickOk();
    setIsVisible(false);
  }
  function handleCancelClick() {
    onClickCancel && onClickCancel();
    setIsVisible(false);
  }
  const ImageComponent = avaiableImages[imageName] || Conductor;
  return (
    <Dialog
      open={isVisible}
      fullScreen={fullScreen}
      onBackdropClick={handleCancelClick}
      onEscapeKeyDown={handleCancelClick}
      TransitionComponent={Transition}>
      <S.OkCancelContainer>
        <S.OkCancelView>
          <ImageComponent size="xsmall" />
          <H1 size="small">{title}</H1>
          <S.OkCancelBody>{body}</S.OkCancelBody>
          <Button
            onClick={handleOkClick}
            style={{ margin: 20, width: '80%', marginTop: 80 }}
            variant="contained"
            color="primary"
            size="large">
            {okButtonText}
          </Button>
          <Button
            onClick={handleCancelClick}
            style={{ margin: 20, width: '80%', marginTop: 10 }}
            variant="outlined"
            color="primary"
            size="large">
            {cancelButtonText}
          </Button>
        </S.OkCancelView>
      </S.OkCancelContainer>
    </Dialog>
  );
}

OkCancelModal.propTypes = {
  title: PropTypes.string,
  imageName: PropTypes.string,
  body: PropTypes.any,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onClickOk: PropTypes.func,
  onClickCancel: PropTypes.func,
};
