import { connect } from 'react-redux';
import Loop from './Loop';
import { getLoopingMode } from '../../../store/settings/settings.selectors';
import { toggleLooping } from '../../../store/settings/settings.actions';

const mapStateToProps = state => {
  return {
    loopingMode: getLoopingMode(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(toggleLooping()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Loop);
