import styled from 'styled-components';

export const ComingSoon = styled.div`
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;

  /* Disable side scrolling */
  overflow-x: hidden;
  width: 100%;
`;

export const ComingSoon_PageContainer = styled.div`
  width: 94%;
  max-width: 1100px;
  padding: 0px 20px;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
`;

export const ComingSoon_Title = styled.div`
  padding: 16px;
  text-align: left;
  max-width: 420px;
  font-family: 'Platform Web';
  font-weight: 500;
  color: var(--choirpal-blue-100);
  font-size: 42px;
`;
