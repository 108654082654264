import styled, { css } from 'styled-components';

export const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-content: center;
`;

export const Dot = styled.div`
  position: relative;
  width: 25px;
  height: 20px;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  outline: none;
  transition: opacity 0.25s;

  :focus {
    outline: 0;
  }
  :active {
    background-color: transparent;
  }

  user-select: none;

  ::after {
    position: absolute;
    top: 6px;
    left: 6px;
    display: block;
    width: 10px;
    height: 10px;
    background: gray;
    border-radius: 50%;
    content: '';

    ${props =>
      props.isActive &&
      css`
        top: 3px;
        left: 5px;
        width: 13px;
        height: 13px;
        background: black;
        box-shadow: 0 2px 5px 1px gray;
      `}
  }
`;
