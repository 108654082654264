import { connect } from 'react-redux';
import Plans from './Plans';
import { showModal } from '../../../store/modal/modal.actions';
import { isLoggedIn } from '../../../store/user/user.selectors';
import { push } from 'connected-react-router';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
  };
};

const mapDispatchToProps = dispatch => ({
  doProClick: () => {
    dispatch(showModal('STRIPE_MODAL'));
  },
  doRegisterClick: () => {
    dispatch(push('/register'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Plans);
