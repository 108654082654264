import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';

import PopupView from '../../Layout/PopupView/PopupView';

function Popup({
  show = true,
  title = '',
  imageName,
  body,
  buttonText = 'OK',
  onDismiss,
  listItems,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDismiss = () => {
    onDismiss && onDismiss();
  };

  return (
    <Dialog
      open={show}
      fullWidth={true}
      fullScreen={fullScreen}
      onBackdropClick={handleDismiss}
      onEscapeKeyDown={handleDismiss}>
      <PopupView
        title={title}
        imageName={imageName}
        body={body}
        buttonText={buttonText}
        onDismiss={handleDismiss}
        listItems={listItems}
      />
    </Dialog>
  );
}

Popup.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  imageName: PropTypes.string,
  body: PropTypes.any,
  buttonText: PropTypes.string,
  onDismiss: PropTypes.func,
  listItems: PropTypes.array,
};

export default Popup;
