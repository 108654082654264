import styled from 'styled-components';
// import { color, fontFamily, fontSize } from '../../design-tokens';

export const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;

  /* Disable side scrolling */
  overflow-x: hidden;
  width: 100%;
`;

export const ContentFrame_PageContainer = styled.div`
  width: 94%;
  max-width: 1100px;
  padding: 0px 20px;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
`;
