import { connect } from 'react-redux';
import { groupsRoute, registerRoute } from '../../routes';
import TopNav from './TopNav';
import { getCurrentCredentialsThunk } from '../../../store/user/user.thunks';
import { isLoggedIn } from '../../../store/user/user.selectors';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
  };
};

const mapDispatchToProps = {
  doRefreshSession: getCurrentCredentialsThunk,
  onClickLogin: groupsRoute,
  onClickRegister: registerRoute,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopNav);
