import React from 'react';

function DebugPage() {
  return (
    <div className="">
      <div>foo</div>
    </div>
  );
}

export default DebugPage;
