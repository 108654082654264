import { connect } from 'react-redux';
import { CheckoutForm } from './CheckoutForm';
import {
  postStripeThunk,
  fetchCouponThunk,
} from '../../../../store/billing/billing.thunks';
import { hideModal } from '../../../../store/modal/modal.actions';
import {
  getBillingDetails,
  getCoupon,
  getErrorMsg,
  getSuccessMsg,
  isFetchingCoupon,
  isPostingSubscription,
} from '../../../../store/billing/billing.selectors';
import {
  clearStripeErrors,
  clearModalMsgs,
} from '../../../../store/billing/billing.actions';

const mapStateToProps = state => {
  return {
    errorMsg: getErrorMsg(state),
    successMsg: getSuccessMsg(state),
    coupon: getCoupon(state),
    subscription: getBillingDetails(state),
    isPostingSubscription: isPostingSubscription(state),
    isFetchingCoupon: isFetchingCoupon(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onClickPostStripe: (stripe, card, source, couponCode) => {
    dispatch(postStripeThunk(stripe, card, source, couponCode));
  },
  clearErrorMsg: () => {
    dispatch(clearStripeErrors());
  },
  doCouponClick: couponCode => {
    dispatch(fetchCouponThunk(couponCode));
  },
  doCloseClick: () => {
    dispatch(hideModal());
    dispatch(clearModalMsgs());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutForm);
