import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuggestionsInput from '../../Form/SuggestionsInput/SuggestionsInput';
import Button from '@material-ui/core/Button';
import * as S from './EditTrack.styled';
import H2 from '../../Typography/Heading/H2';

const partSuggestions = [
  'recording',
  'all',
  'bass',
  'baritone',
  'lead',
  'tenor',
  'alto',
  'mezzo',
  'soprano',
  'piano',
  'guitar',
  'accompaniment',
];

const subPartSuggestions = ['1', '2', '3', 'solo', 'descant'];
const mixSuggestions = ['prominent', 'missing'];

function EditTrack(props) {
  const [voicePart, setVoicePart] = useState(props.voicePart);
  const [subPart, setSubPart] = useState(props.subPart);
  const [audioMix, setAudioMix] = useState(props.audioMix);

  // function handlePartChange() {}
  // function handleSubPartChange() {}
  // function handleMixChange() {}

  function handleSave() {
    props.onSave(voicePart, subPart, audioMix);
  }

  return (
    <S.EditTrackRoot>
      <H2>{props.filename}</H2>
      <S.VoicePart>
        <SuggestionsInput
          label="Voice Part"
          required
          initialValue={voicePart}
          availableSuggestions={partSuggestions}
          onChange={setVoicePart}
        />
      </S.VoicePart>

      <S.SubPart>
        <SuggestionsInput
          label="Sub Part"
          initialValue={subPart}
          availableSuggestions={subPartSuggestions}
          size="medium"
          onChange={setSubPart}
        />
      </S.SubPart>

      <S.AudioMix>
        <SuggestionsInput
          label="Audio Mix"
          initialValue={audioMix}
          availableSuggestions={mixSuggestions}
          size="large"
          onChange={setAudioMix}
        />
      </S.AudioMix>

      <Button
        fullWidth={true}
        style={{ marginTop: 24 }}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleSave()}>
        Save
      </Button>
    </S.EditTrackRoot>
  );
}

EditTrack.propTypes = {
  filename: PropTypes.string,
  voicePart: PropTypes.string.isRequired,
  subPart: PropTypes.string.isRequired,
  audioMix: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditTrack;
