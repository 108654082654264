import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SectionHeader from '../../Layout/SectionHeader/SectionHeader';
import SheetMusicIconConnected from '../../Studio/SheetMusicIcon/SheetMusicIconConnected';
import IconMusicNote from '../../Theme/Icons/IconMusicNote';
import IconMusicNotes from '../../Theme/Icons/IconMusicNotes';
import { color } from '../../design-tokens';

import * as S from './RecentlyPlayedSongs.styled';

function RecentlyPlayedSongs({
  onClickSong,
  isPlaylistActive,
  recentlyPlayedSongs,
  currentSongCuid,
}) {
  const handleClick = songCuid => {
    onClickSong(songCuid);
  };
  const visibleRecentlyPlayedSongs = recentlyPlayedSongs.slice(0, 5);

  return (
    <S.RecentlyPlayedSongs>
      {visibleRecentlyPlayedSongs.length > 0 && (
        <SectionHeader title="Recently Played" />
      )}
      <S.List>
        <List
          component="nav"
          aria-label="recently played songs"
          style={{ padding: 0 }}>
          {visibleRecentlyPlayedSongs.map((song, index) => {
            const isActive = currentSongCuid === song.songCuid;
            const IconMusic = isActive ? IconMusicNotes : IconMusicNote;
            return (
              <React.Fragment key={song.songCuid}>
                <ListItem button onClick={() => handleClick(song.songCuid)}>
                  <ListItemAvatar style={{ minWidth: 44 }}>
                    <IconMusic
                      style={{
                        color:
                          currentSongCuid === song.songCuid ? color.red60 : '',
                      }}
                    />
                  </ListItemAvatar>
                  <S.Song isActive={isActive}>{song.meta.songTitle}</S.Song>
                  <ListItemSecondaryAction>
                    <SheetMusicIconConnected songCuid={song.songCuid} />
                  </ListItemSecondaryAction>
                </ListItem>
                {/* {index < visibleRecentlyPlayedSongs.length - 1 && <Divider />} */}
              </React.Fragment>
            );
          })}
        </List>
      </S.List>
    </S.RecentlyPlayedSongs>
  );
}

RecentlyPlayedSongs.propTypes = {
  onClickSong: PropTypes.func.isRequired,
  recentlyPlayedSongs: PropTypes.array.isRequired,
  currentSongCuid: PropTypes.string.isRequired,
  isPlaylistActive: PropTypes.bool.isRequired,
};

export default RecentlyPlayedSongs;
