import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import H2 from '../../Typography/Heading/H2';
import Link from '../../Form/Link/Link';
import BottomInfo from '../../Layout/BottomInfo/BottomInfo';
import googleIcon from '../google-icon.svg';
import facebookIcon from '../facebook-icon.svg';
import Popup from '../../Layout/Popup/Popup';

import * as S from './Register.styled';

const Register = ({
  errorMsg,
  cognitoRegister,
  onGoogleLogin,
  onFacebookLogin,
  hideErrorAction,
  isPostingRegistrationDetails,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsConditionsAccepted, setTermsConditionsAccepted] = useState(false);

  const validateForm = event => {
    return (
      email.length > 0 && password.length > 0 && password === confirmPassword
    );
  };

  const handleLoginGoogle = event => {
    event.preventDefault();
    onGoogleLogin();
  };

  const handleLoginFacebook = event => {
    event.preventDefault();
    onFacebookLogin();
  };

  const handleHideErrorMessage = () => {
    hideErrorAction();
  };
  const handleSubmit = async event => {
    event.preventDefault();
    cognitoRegister(email, password);
  };

  const prompts = [
    'Try searching your inbox for a confirmation from choirpal',
    "Try the 'Forgotten Password' link if you already have an account",
    'Try a different email',
  ];

  return (
    <S.Register>
      <H2>Create an account</H2>

      <S.Register__Inputs>
        <TextField
          label="Email"
          required
          variant="outlined"
          style={{ marginTop: 10 }}
          value={email}
          onChange={({ target }) => setEmail(target.value.trim().toLowerCase())}
        />

        <TextField
          label="Password"
          type="password"
          style={{ marginTop: 10 }}
          variant="outlined"
          required
          value={password}
          onChange={({ target }) => setPassword(target.value.trim())}
        />
        <TextField
          label="Confirm Password"
          type="password"
          style={{ marginTop: 10 }}
          variant="outlined"
          required
          value={confirmPassword}
          onChange={({ target }) => setConfirmPassword(target.value.trim())}
        />
      </S.Register__Inputs>

      <S.Register__TermsAndConditions>
        <Checkbox
          checked={termsConditionsAccepted}
          color="primary"
          onChange={({ target }) => {
            setTermsConditionsAccepted(!termsConditionsAccepted);
          }}
          value="termsConditionsAccepted"
        />
        <span>I agree with the </span>
        <Link to="/terms-and-conditions">Terms & Conditions</Link>
      </S.Register__TermsAndConditions>

      <S.Register__Inputs>
        <Button
          style={{ marginTop: 40 }}
          variant="contained"
          size="large"
          color="primary"
          disabled={!validateForm}
          onClick={handleSubmit}>
          {isPostingRegistrationDetails ? (
            <CircularProgress
              size={26}
              color="secondary"
              style={{ color: 'white' }}
            />
          ) : (
            'Register'
          )}
        </Button>
      </S.Register__Inputs>

      <S.Register__SocialMediaRegistration>
        <Button
          style={{ marginTop: 16, width: '80%' }}
          variant="outlined"
          size="large"
          color="primary"
          onClick={handleLoginGoogle}>
          <img
            src={googleIcon}
            width="25"
            style={{ marginRight: 8 }}
            alt="Google Icon"
          />
          Google Login
        </Button>
        <Button
          style={{ marginTop: 16, width: '80%' }}
          variant="outlined"
          size="large"
          color="primary"
          onClick={handleLoginFacebook}>
          <img
            src={facebookIcon}
            width="25"
            style={{ marginRight: 8 }}
            alt="Facebook Icon"
          />
          Facebook Login
        </Button>
      </S.Register__SocialMediaRegistration>

      <BottomInfo>
        <span>Already have an account? </span>
        <Link to="/login">Login</Link>
      </BottomInfo>

      <Popup
        show={!!errorMsg}
        title="Oops, we have a problem"
        imageName="conductor"
        body="Have you already registered?"
        buttonText="Okay"
        onDismiss={handleHideErrorMessage}
        listItems={prompts}
      />
    </S.Register>
  );
};

Register.propTypes = {
  errorMsg: PropTypes.string,
  newUser: PropTypes.object,
  isPostingRegistrationDetails: PropTypes.bool.isRequired,
  cognitoRegister: PropTypes.func.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
  onFacebookLogin: PropTypes.func.isRequired,
  hideErrorAction: PropTypes.func.isRequired,
};

export default Register;
