import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import GroupsList from './GroupsList';
import { showModal } from '../../../store/modal/modal.actions';
import { hasViewedHint } from '../../../store/modal/modal.selectors';
import { getCognitoId } from '../../../store/user/user.selectors';
import {
  getGroupList,
  isFetchingGroups,
} from '../../../store/groups/groups.selectors';
import { fetchGroupsDBThunk } from '../../../store/groups/groups.thunks';

const mapStateToProps = state => ({
  groupsList: getGroupList(state),
  cognitoId: getCognitoId(state),
  isFetchingGroups: isFetchingGroups(state),
  hasViewedWelcomeHint: hasViewedHint('WELCOME_HINT', state),
});

const mapDispatchToProps = dispatch => ({
  onClick: groupCuid => {
    dispatch(push('/groups/' + groupCuid));
  },
  doFetchGroups: () => dispatch(fetchGroupsDBThunk()),
  // onLoad: () => dispatch(showHintThunk('')),
  onClickShowWelcomeHint: () => {
    dispatch(showModal('WELCOME_HINT', {}));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupsList);
