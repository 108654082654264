import { connect } from 'react-redux';
import { path } from 'ramda';
import ShareSong from './ShareSong';
import {
  getMySong,
  getGroupsForSong,
  isPostingShareSong,
} from '../../../store/myMusic/myMusic.selectors';
import { getGroupList } from '../../../store/groups/groups.selectors';
import {
  shareSongGroupsThunk,
  fetchSongGroupsThunk,
} from '../../../store/myMusic/myMusic.thunks';
import { createGroupRoute } from '../../routes';

const mapStateToProps = (state, ownProps) => {
  const songCuid = path(['match', 'params', 'songCuid'], ownProps);
  const song = getMySong(songCuid, state) || {};
  const groupListShared = getGroupsForSong(songCuid, state);
  const isPosting = isPostingShareSong(state);

  return {
    songCuid,
    songTitle: song.meta.songTitle,
    groupList: getGroupList(state),
    groupListShared,
    isPosting,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickShare: (songCuid, groupCuids) => {
    dispatch(shareSongGroupsThunk(songCuid, groupCuids));
  },
  onClickAddGroup: () => {
    dispatch(createGroupRoute());
  },
  doFetchSongGroups: songCuid => {
    dispatch(fetchSongGroupsThunk(songCuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareSong);
