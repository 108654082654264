import styled from 'styled-components';
import { color, breakpoint, fontFamily } from '../../design-tokens';

export const Footer = styled.div`
  background-color: ${color.green};
  margin-top: 20px;
  width: 100%;
`;

export const Footer_Container = styled.div`
  max-width: 1100px;
  margin: 0px auto;
`;

export const Footer_Title = styled.h2`
  margin-top: 20px;
  font-family: ${fontFamily.regular};
  font-weight: 300;
  color: ${color.white};
  font-size: 20px;
`;

export const Footer_ContentContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const Footer_Content = styled.div`
  max-width: 450px;
`;

export const Footer_Divider = styled.div`
  background-color: ${color.white};
  height: 1px;
  width: 100%;
  opacity: 0.3;
`;

export const Footer_Footer = styled.div`
  padding: 14px 16px 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoint.medium}) {
    flex-direction: row-reverse;
  }
`;

export const Footer_CopyrightNotice = styled.div`
  font-family: ${fontFamily.regular};
  font-weight: 300;
  color: ${color.white};
  font-size: 16px;
`;

export const Footer_Body = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: 0px;
  }
`;
