import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import querystring from 'querystring';
import PageError from '../../Form/PageError/PageError';
import BottomInfo from '../../Layout/BottomInfo/BottomInfo';
import Link from '../../Form/Link/Link';
import H2 from '../../Typography/Heading/H2';
import Text from '../../Typography/Text/Text';
import * as SS from '../Auth.styled';

const ResetPassword = ({
  errorMsg,
  setNewPassword,
  location = { search: '?' },
}) => {
  const urlParams = querystring.parse(location.search.substring(1));
  const [email] = useState(urlParams.user_name);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code] = useState(urlParams.confirmation_code);

  const validateResetForm = event => {
    return password.length > 0 && password === confirmPassword;
  };

  const handleConfirmClick = async event => {
    event.preventDefault();
    validateResetForm(event);
    setNewPassword(email, code, password);
  };

  return (
    <SS.AuthPage>
      {errorMsg && (
        <PageError>
          <i />
          {errorMsg}
        </PageError>
      )}

      <SS.AuthLogo />

      <H2>Reset Password</H2>

      <Text>
        To reset the password for the account <b>{email}</b>
        <br />
        fill in the details below :
      </Text>

      <SS.AuthInputs>
        <TextField
          style={{ width: '100%' }}
          type="password"
          label="Password"
          required
          variant="outlined"
          value={password}
          onChange={({ target }) => setPassword(target.value.trim())}
        />

        <TextField
          style={{ width: '100%', marginTop: 10 }}
          type="password"
          label="Confirm Password"
          required
          variant="outlined"
          value={confirmPassword}
          onChange={({ target }) => setConfirmPassword(target.value.trim())}
        />
      </SS.AuthInputs>

      <SS.AuthButtons>
        <Button
          style={{ width: '80%' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleConfirmClick}>
          Reset Password
        </Button>
      </SS.AuthButtons>

      <BottomInfo>
        <Text>Remember your login details?</Text>
        <Link style={{ marginLeft: '5px' }} to="/login">
          Login here
        </Link>
      </BottomInfo>
    </SS.AuthPage>
  );
};

ResetPassword.propTypes = {
  errorMsg: PropTypes.string,
  setNewPassword: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default ResetPassword;
