import PropTypes from 'prop-types';
import React from 'react';
import { fontSize } from '../../design-tokens';
import * as S from './Text.styled';

function Text({ size = 'medium', onClick = () => {}, children, style = {} }) {
  return (
    <S.Text fontSize={fontSize[size]} onClick={onClick} style={{ ...style }}>
      {children}
    </S.Text>
  );
}

Text.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default Text;
