import { connect } from 'react-redux';
import AppVersion from './AppVersion';
import { getApiVersion } from '../../../store/app/app.selectors';
import { version } from '../../../../package.json';

const mapStateToProps = state => {
  return { version, apiVersion: getApiVersion(state) };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppVersion);
