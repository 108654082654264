import { path } from 'ramda';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  TOKEN_REFRESHED,
  REGISTER_STARTED,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  HIDE_ERROR,
  LOGIN_STARTED,
  FETCH_USER_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
} from './user.events';

const initialState = {
  isPostingCredentials: false,
  isPostingRegistrationDetails: false,
  createdTimestamp: null,
  tokenExpiry: 0,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { data: user } = action;
      const payload =
        path(['signInUserSession', 'idToken', 'payload'], user) || {};
      // eslint-disable-next-line
      const { email, exp } = payload;
      const cognitoId = payload['cognito:username'];
      return {
        ...state,
        email,
        cognitoId,
        error: false,
        tokenExpiry: exp,
        isPostingCredentials: false,
      };
    }
    case TOKEN_REFRESHED: {
      const {
        idToken: { payload },
      } = action.data;
      const { exp, email } = payload;
      return {
        ...state,
        tokenExpiry: exp,
        email,
      };
    }
    case HIDE_ERROR: {
      return {
        ...state,
        error: false,
        errorMsg: '',
      };
    }
    case LOGIN_FAILED: {
      const { errorMsg } = action.data;
      return {
        ...state,
        error: true,
        errorMsg,
        isPostingCredentials: false,
      };
    }
    case REGISTER_SUCCESS: {
      const { data: newUser } = action;
      return newUser;
    }
    case LOGIN_STARTED: {
      return { ...state, isPostingCredentials: true };
    }
    case REGISTER_STARTED: {
      return { ...state, isPostingRegistrationDetails: true };
    }
    case REGISTER_FAILED: {
      const { errorMsg } = action.data;
      return {
        ...state,
        error: true,
        errorMsg,
        isPostingRegistrationDetails: false,
      };
    }
    case FETCH_USER_SUCCESS: {
      return { ...state, createdTimestamp: action.data.createdTimestamp };
    }
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case LOGOUT_FAILED: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
