import { connect } from 'react-redux';
import { showModal } from '../../../store/modal/modal.actions';
import {
  getActiveSongCuid,
  getMeta,
  isEditable,
} from '../../../store/myMusic/myMusic.selectors';
import { maximisePlayer } from '../../../store/player/player.actions';
import { songDetailsRoute } from '../../routes';
// import { push } from 'connected-react-router';
import PlayerTitleBar from './PlayerTitleBar';

const mapStateToProps = state => {
  const meta = getMeta(state);
  const songCuid = getActiveSongCuid(state);
  const { songTitle } = meta;
  return { songCuid, songTitle, isEditable: isEditable(songCuid, state) };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickTitle: songCuid => {
    dispatch(maximisePlayer(songCuid));
  },
  onClickMaximise: songCuid => {
    dispatch(maximisePlayer(songCuid));
  },
  onClickMinimise: songCuid => {
    ownProps.history.goBack();
  },
  onClickSongDetails: songCuid => {
    dispatch(songDetailsRoute(songCuid));
  },
  onClickHelp: () => {
    dispatch(showModal('EDITOR_HINT'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerTitleBar);
