import React from 'react';
import PropTypes from 'prop-types';
// import TopNav from '../TopNav/TopNav';
// import Hero from '../../Theme/Hero/Hero';
// import HeroTitleDescription from '../HeroTitleDescription/HeroTitleDescription';
// import RegisterInterest from '../RegisterInterest/RegisterInterest';

import './HeroGrid.css';

export default function HeroGrid({ HeroTitle, HeroImage, HeroRegister }) {
  return (
    <div className="HeroGrid">
      <div className="HeroGrid_Title">
        <HeroTitle />
      </div>
      <div className="HeroGrid_HeroImage">
        <HeroImage />
      </div>
      <div className="HeroGrid_Register">
        <HeroRegister />
      </div>
    </div>
  );
}

HeroGrid.propTypes = {
  HeroTitle: PropTypes.func.isRequired,
  HeroImage: PropTypes.func.isRequired,
  HeroRegister: PropTypes.func.isRequired,
};
