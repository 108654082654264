import styled from 'styled-components';
import { color, fontFamily, fontSize } from 'src/ui/design-tokens';

export const RecentlyPlayedSongs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  color: ${color.red100};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.large};
`;

export const Song = styled.div`
  font-size: ${fontSize.xLarge};
  color: ${props => (props.isActive ? color.red60 : undefined)};
  font-weight: ${props => (props.isActive ? 400 : 300)};
  padding: 8px 0px;
`;
