import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logoSvg from './Choirpal.svg';
import { homeUrlAfterLogin } from '../../routes';

export default function Logo({ classes, size, isLoggedIn }) {
  const width = size === 'large' ? 250 : 85;
  return (
    <Link
      className="Logo"
      style={{ WebkitTapHighlightColor: 'transparent' }}
      to={{
        pathname: isLoggedIn ? homeUrlAfterLogin : '/',
        search: '',
        hash: '',
        state: { fromLogo: true },
      }}>
      <img
        className={classes}
        style={{ width, marginTop: 0, marginBottom: 0 }}
        src={logoSvg}
        alt="Choirpal Logo"
      />
    </Link>
  );
}

Logo.propTypes = {
  classes: PropTypes.array,
  size: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};
