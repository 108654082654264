import { path } from 'ramda';
import { connect } from 'react-redux';
import EditGroup from './EditGroup';
import {
  getGroup,
  isPostingGroup,
} from '../../../store/groups/groups.selectors';
import { updateGroup } from '../../../store/groups/groups.actions';
import {
  postGroupDBThunk,
  fetchMembersThunk,
  fetchGroupInvitesThunk,
} from '../../../store/groups/groups.thunks';

const mapStateToProps = (state, ownProps) => {
  const groupCuid = path(['match', 'params', 'groupCuid'], ownProps);
  const group = getGroup(groupCuid, state);

  return { group, isPostingGroup: isPostingGroup(state) };
};

const mapDispatchToProps = dispatch => ({
  onSave: group => {
    dispatch(updateGroup(group));
    dispatch(postGroupDBThunk(group));
  },
  doFetchMembersDB: groupCuid => {
    dispatch(fetchMembersThunk(groupCuid));
  },
  doFetchInvitesDB: groupCuid => {
    dispatch(fetchGroupInvitesThunk(groupCuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditGroup);
