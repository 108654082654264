import { push } from 'connected-react-router';

export const homeUrlAfterLogin = '/groups';
export const loginRoute = () => push(`/login`);
export const registerRoute = () => push(`/register`);
export const myMusicRoute = () => push(`/my-music`);
export const groupsRoute = () => push(`/groups`);
export const addSongRoute = songCuid => push(`/my-music/songs/add`);
export const songDetailsRoute = songCuid =>
  push(`/my-music/${songCuid}/song/details`);
export const shareSongRoute = songCuid => push(`/my-music/${songCuid}/share`);
// export const addMediaRoute = songCuid => push(`/my-music/${songCuid}/addMedia`);
export const editGroupRoute = groupCuid => push(`/group/${groupCuid}/edit`);
export const createGroupRoute = () => push('/group/create');
export const myAccountRoute = () => push('/my-account');
export const privacyPolicyRoute = () => push('/privacy');
