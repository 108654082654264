import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dots from '../../Form/Dots/Dots';
import Portal from '../../Layout/Portal/Portal';
import * as S from './Pages.styled';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { isTextFieldFocused } from '../../../util/domUtil';

const MIN_PAGE_WIDTH = 350;
const MAGIC_NUMBER = isTextFieldFocused()
  ? window.innerHeight
  : window.innerHeight - 210;

function calculateMaxVisiblePages() {
  return Math.floor(window.innerWidth / MIN_PAGE_WIDTH) || 1;
}

function calculatePageWidth(numVisiblePages = calculateMaxVisiblePages()) {
  return Math.floor(window.innerWidth / numVisiblePages);
}

function Pages({ activePage = 0, children, songCuid }) {
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [dragStartTime, setDragStartTime] = useState(new Date());
  const [movingIndex, setMovingIndex] = useState(0);
  const [snappedIndex, setSnappedIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [maxVisiblePages, setMaxVisiblePages] = useState(
    calculateMaxVisiblePages(),
  );
  const [pageWidth, setPageWidth] = useState(calculatePageWidth());

  const numPagesInSong = children.length;
  const numVisiblePages =
    numPagesInSong < maxVisiblePages ? numPagesInSong : maxVisiblePages;
  const maxPageIndex =
    numPagesInSong > numVisiblePages ? numPagesInSong - numVisiblePages : 0;

  /* eslint-disable */
  // Auto-Scroll
  useEffect(() => {
    if (isPageVisible(activePage)) {
      return;
    }
    const nextSnappedIndex = Math.min(maxPageIndex, activePage);
    setMovingIndex(nextSnappedIndex);
    setSnappedIndex(nextSnappedIndex);
  }, [songCuid, activePage]);

  const isPageVisible = pageIndex =>
    pageIndex >= snappedIndex && pageIndex < snappedIndex + numVisiblePages;

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /* eslint-enable */

  const handleResize = () => {
    setPageWidth(calculatePageWidth());
    setMaxVisiblePages(calculateMaxVisiblePages());
  };

  function getDragX(event, isTouch) {
    return isTouch ? event.touches[0].pageX : event.pageX;
  }

  function getDragY(event, isTouch) {
    return isTouch ? event.touches[0].pageY : event.pageY;
  }

  function handleDragStart(event, isTouch) {
    const dragStartX = getDragX(event, isTouch);
    const dragStartY = getDragY(event, isTouch);

    setDragStartX(dragStartX);
    setDragStartY(dragStartY);
    setDragStartTime(new Date());
    setIsTransitioning(false);
  }

  function handleDragMove(event, isTouch) {
    const currentX = getDragX(event, isTouch);
    const currentY = getDragY(event, isTouch);
    const offsetX = dragStartX - currentX;
    const offsetY = dragStartY - currentY;
    if (Math.abs(offsetX) < 15 || Math.abs(offsetY) > Math.abs(offsetX)) {
      return;
    }
    const percentageOffset = offsetX / pageWidth;
    const newIndex = snappedIndex + percentageOffset;

    setMovingIndex(newIndex);
  }

  function handleDragEnd() {
    const timeElapsed = new Date().getTime() - dragStartTime.getTime();
    const offset = snappedIndex - movingIndex;
    const velocity = Math.round((offset / timeElapsed) * 10000);
    let newIndex = Math.round(movingIndex);

    if (Math.abs(velocity) > 5) {
      newIndex = velocity < 0 ? snappedIndex + 1 : snappedIndex - 1;
    }

    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= numPagesInSong - maxVisiblePages) {
      newIndex = numPagesInSong - maxVisiblePages;
    }

    setDragStartX(0);
    setMovingIndex(newIndex);
    setSnappedIndex(newIndex);
    setIsTransitioning(true);
  }

  const gotoPage = pageNumber => {
    if (pageNumber < 0) {
      return;
    }

    const nextIndex = Math.min(maxPageIndex, pageNumber);
    setMovingIndex(nextIndex);
    setSnappedIndex(nextIndex);
    setIsTransitioning(true);
  };

  const nextClicked = () => {
    gotoPage(snappedIndex + 1);
  };

  const previousClicked = () => {
    gotoPage(snappedIndex - 1);
  };

  function renderButtons() {
    // If there are no pages, show 3 faded dots to hint that there could be pages
    return (
      <S.PagesButtons style={{ opacity: numPagesInSong <= 1 ? 0.1 : 1 }}>
        <S.PagesForwardBack>
          <ChevronLeftIcon fontSize="small" onClick={nextClicked} />
        </S.PagesForwardBack>
        <Dots
          numDots={numPagesInSong <= 1 ? 3 : numPagesInSong}
          selectedDot={snappedIndex}
          numSelectedDots={maxVisiblePages}
          dotClicked={gotoPage}
        />
        <S.PagesForwardBack>
          <ChevronRightIcon fontSize="small" onClick={previousClicked} />
        </S.PagesForwardBack>
      </S.PagesButtons>
    );
  }

  const slidesStyles = {
    width: `${pageWidth * children.length}px`,
    transform: `translateX(${-1 * movingIndex * (100 / children.length)}%)`,
  };

  return (
    <S.Pages style={{ height: MAGIC_NUMBER }}>
      <S.PagesTouchable
        style={{ height: MAGIC_NUMBER }}
        onTouchStart={event => handleDragStart(event, true)}
        onTouchMove={event => handleDragMove(event, true)}
        onTouchEnd={() => handleDragEnd(true)}>
        <S.Slides style={slidesStyles} isTransitioning={isTransitioning}>
          {children.map((element, i) => (
            <S.Slide
              key={i}
              style={{
                width: pageWidth,
                display: 'flex',
                height: MAGIC_NUMBER,
              }}>
              {element}
              <br />
              <br />
              <br />
            </S.Slide>
          ))}
        </S.Slides>
      </S.PagesTouchable>

      <Portal elemId="controls-dots-container">{renderButtons()}</Portal>
    </S.Pages>
  );
}

Pages.propTypes = {
  activePage: PropTypes.number, // Based on the audio position
  children: PropTypes.array.isRequired,
  songCuid: PropTypes.string.isRequired,
};

export default Pages;
