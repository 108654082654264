import { connect } from 'react-redux';
import GroupMenu from './GroupMenu';
import { getGroup } from '../../../store/groups/groups.selectors';
import { showModal } from '../../../store/modal/modal.actions';
import { editGroupRoute } from '../../routes';

const mapStateToProps = (state, ownProps) => {
  const { groupCuid } = ownProps;
  const group = getGroup(groupCuid, state);
  const { groupName, isAdmin = false } = group;

  return {
    groupCuid,
    groupName,
    isAdmin,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickDelete: (groupCuid, groupName) => {
    dispatch(showModal('DELETE_GROUP', { groupCuid, groupName }));
  },
  onClickMembers: groupCuid => {
    dispatch(editGroupRoute(groupCuid));
  },
  onClickLeaveGroup: (groupCuid, groupName) => {
    dispatch(showModal('LEAVE_GROUP', { groupCuid, groupName }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupMenu);
