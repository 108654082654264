import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './PageOverlay.styled';

function PageOverlay({ isVisible, children }) {
  const [isVisibleState, setIsVisibleState] = useState(false);

  useEffect(() => {
    setIsVisibleState(isVisible);
  }, [isVisible]);

  return <S.PageOverlay isVisible={isVisibleState}>{children}</S.PageOverlay>;
}

PageOverlay.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.object,
};

export default PageOverlay;
