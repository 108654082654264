import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <g>
      <path
        fill="none"
        strokeWidth="6"
        strokeMiterlimit="10"
        d="M80,48c0,3.85,3.15,7,7,7h31
      c3.85,0,4.772-2.228,2.05-4.95l-35.1-35.101C82.228,12.227,80,13.149,80,17V48z"
      />
    </g>
    <g>
      <path
        fill="none"
        strokeWidth="6"
        strokeMiterlimit="10"
        d="M65,5L34,5c-7.7,0-14,6.3-14,14v112
      c0,7.7,6.3,14,14,14l82,0c7.7,0,14-6.3,14-14V70"
      />
    </g>
  </g>,
  150,
  150,
);
