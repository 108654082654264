import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutFormConnected from './CheckoutFormConnected';
import { getStripeKey } from '../../../../amplify.init';

const stripeKey = getStripeKey();
const stripePromise = loadStripe(stripeKey);

const Checkout = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutFormConnected />
    </Elements>
  );
};

export default Checkout;
