import React, { useState, useEffect } from 'react';
import pubsub from 'pubsub-js';

export default WrappedComponent => {
  return function FileUploadSubscriber(props) {
    const [bytesUploaded, setBytesUploaded] = useState(0);
    const [totalBytes, setTotalBytes] = useState(0);

    useEffect(() => {
      const subscriberId = pubsub.subscribe(
        'UPLOAD_PROGRESS_CHANNEL',
        (topic, message) => {
          message && handleMessage(message);
        },
      );

      return () => {
        subscriberId && pubsub.unsubscribe(subscriberId);
      };
    }, []);

    function handleMessage({ loaded, total }) {
      setBytesUploaded(loaded);
      setTotalBytes(total);
    }

    return (
      <WrappedComponent
        {...props}
        bytesUploaded={bytesUploaded || 0}
        fileSizeBytes={totalBytes || 0}
      />
    );
  };
};
