import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectModalConfig } from '../../../store/modal/modal.selectors';

import PopupConnected from '../Popup/PopupConnected';
import OkCancelModalConnected from '../../Modal/OkCancelModal/OkCancelModalConnected';
import DeleteSongModalConnected from '../../Modal/DeleteSongModal/DeleteSongModalConnected';
import DeleteGroupModalConnected from '../../Modal/DeleteGroupModal/DeleteGroupModalConnected';
import LeaveGroupModalConnected from '../../Modal/LeaveGroupModal/LeaveGroupModalConnected';
import WelcomeHintModalConnected from '../../Modal/WelcomeHintModal/WelcomeHintModalConnected';
import CancelSubscriptionModalConnected from '../../Modal/CancelSubscriptionModal/CancelSubscriptionModalConnected';
import StripeModalConnected from '../../Modal/StripeModal/StripeModalConnected';
import ResubscribeModalConnected from '../../Modal/ResubscribeModal/ResubscribeModalConnected';

const MODAL_COMPONENTS = {
  POPUP: PopupConnected,
  OK_CANCEL: OkCancelModalConnected,
  DELETE_SONG: DeleteSongModalConnected,
  DELETE_GROUP: DeleteGroupModalConnected,
  LEAVE_GROUP: LeaveGroupModalConnected,
  WELCOME_HINT: WelcomeHintModalConnected,
  EDITOR_HINT: PopupConnected,
  CANCEL_SUBSCRIPTION: CancelSubscriptionModalConnected,
  RESUBSCRIBE: ResubscribeModalConnected,
  STRIPE_MODAL: StripeModalConnected,
};

const RootModal = ({ modalConfig }) => {
  if (!modalConfig) {
    return null;
  }
  const { modalType, modalProps } = modalConfig;

  const customProps = { modalType, ...modalProps };

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...customProps} />;
};

RootModal.propTypes = {
  modalConfig: PropTypes.object,
};

const mapStateToProps = state => ({
  modalConfig: selectModalConfig(state),
});

export default connect(
  mapStateToProps,
  null,
)(RootModal);
