import { connect } from 'react-redux';
import ProgressBar from './ProgressBar';
import AudioProgressSubscriber from '../../Audio/AudioPlayerSubscriber/AudioProgressSubscriber';
import AudioPlayerSubscriber from '../../Audio/AudioPlayerSubscriber/AudioPlayerSubscriber';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioPlayerSubscriber(AudioProgressSubscriber(ProgressBar)));
