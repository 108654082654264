import styled from 'styled-components';
import { fontFamily, fontSize } from '../../design-tokens';

import { SMALL_BREAKPOINT } from '../../hooks/useBreakpoint';

export const HintContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* align-items: center; */
  width: 100%;
  min-width: ${SMALL_BREAKPOINT}px;
  /* max-width: 400px; */
  font-family: ${fontFamily.regular};
`;

export const HintInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > * {
    &:first-child {
      width: 100%;
    }
  }
`;

export const HintView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const HintBody = styled.div`
  margin-top: 20;
  width: 80%;
  text-align: center;
  font-size: 20;
`;

export const DotsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Subtitle = styled.div`
  font-size: 20px;
`;

export const Hints = styled.ul`
  list-style-type: circle;
  font-size: ${fontSize.large};
  text-align: start;
  line-height: 1.5;
`;

export const Hint = styled.li``;
