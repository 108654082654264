import { appReducer } from './app/app.reducer';
import { playerReducer } from './player/player.reducer';
import { userReducer } from './user/user.reducer';
import { settingsReducer } from './settings/settings.reducer';
import { myMusicReducer } from './myMusic/myMusic.reducer';
import { groupsReducer } from './groups/groups.reducer';
import { modalReducer } from './modal/modal.reducer';
import { billingReducer } from './billing/billing.reducer';

export const reducers = {
  app: appReducer,
  myMusic: myMusicReducer,
  player: playerReducer,
  user: userReducer,
  settings: settingsReducer,
  groups: groupsReducer,
  modal: modalReducer,
  billing: billingReducer,
};
