import styled from 'styled-components';

export const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  text-align: center;
  padding-bottom: 100px;
`;

export const Logo = styled.div``;
export const Username = styled.div`
  margin-top: 28px;
`;
export const Password = styled.div``;

export const Inputs = styled.div`
  text-align: center;
  width: 80%;
  margin: 0px auto;
`;

export const LoginButton = styled.div`
  margin-top: 28px;
  width: 100%;

  * {
    width: 100%;
  }
`;

export const RegisterButton = styled.div`
  margin-top: 24px;
  width: 100%;

  * {
    width: 100%;
  }
`;

export const ForgotPassword = styled.div`
  margin-top: 10px;
  margin-left: 14px;
  width: 80%;
  text-align: left;
`;

//
// export const SocialMediaLogin = styled.div`
//   /* Disabling Social Login for now */
//   display: none;
//   /*display: flex;*/
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
// `;
