import styled, { keyframes } from 'styled-components';
import {
  choirpal_grey_light,
  choirpal_grey_mid,
  choirpal_yellow_100,
} from '../../Theme/colors';

export const FileUploadProgressBarContainer = styled.div`
  height: 100vh;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  min-width: 300px;
  max-width: 800px;
`;

export const FileUploadProgressBar = styled.div`
  border: 1px solid ${choirpal_grey_light};
  /*width: 100%;*/
  /* display: ${props => (props.isVisible ? 'flex' : 'none')}; */
  flex-direction: column;
  border-radius: 2px;
  position: relative;
  height: 32px;
  font-size: 14px;
  margin: 6px;
  min-width: 300px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.div`
  animation: ${rotate} 3s linear infinite;
  height: 24px;
  width: 24px;
`;

export const Filename = styled.div`
  margin-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 4px;
  height: 28px;
  align-items: center;
`;
export const ProgressBarContainer = styled.div`
  background-color: ${choirpal_grey_light};
  position: relative;
  overflow: hidden;
  height: 4px;
  bottom: 0px;
`;

export const FilesRemaining = styled.div`
  width: 100px;
  padding-right: 8px;
  white-space: nowrap;
  text-align: right;
  color: ${choirpal_grey_mid};
`;

export const ProgressBar = styled.div`
  background-color: yellow;
  position: absolute;
  width: 100%;
  background-color: ${choirpal_yellow_100};
  height: 4px;
  bottom: 0px;
  border-radius: 1px;
  transform: ${props => `translateX(${props.percentUploaded - 100}%)`};
  transition: transform 200ms;
`;
