import styled from 'styled-components';

export const EditTrackRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;
export const VoicePart = styled.div`
  /*max-width: 375px;*/
  width: 100%;
  text-align: right;
`;

export const SubPart = styled.div`
  /*max-width: 375px;*/
  width: 100%;
  text-align: right;
`;

export const AudioMix = styled.div`
  /*max-width: 375px;*/
  width: 100%;
  text-align: right;
`;
