export const LOGIN_STARTED = 'user/loginStarted';
export const LOGIN_SUCCESS = 'user/loginSuccess';
export const LOGIN_FAILED = 'user/loginFailed';

export const LOGOUT_STARTED = 'user/logoutStarted';
export const LOGOUT_SUCCESS = 'user/logoutSuccess';
export const LOGOUT_FAILED = 'user/logoutFailed';

export const REGISTER_SUCCESS = 'user/registerSuccess';
export const REGISTER_STARTED = 'user/registerStarted';
export const REGISTER_FAILED = 'user/registerFailed';

export const HIDE_ERROR = 'user/hideError';

export const FETCH_USER_STARTED = 'user/fetchUserStarted';
export const FETCH_USER_SUCCESS = 'user/fetchUserSuccess';
export const FETCH_USER_FAIL = 'user/fetchUserFail';

export const TOKEN_REFRESHED = 'user/tokenRefreshed';
