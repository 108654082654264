import { asList } from '../storeUtil';

import {
  hardcodedGroupsList,
  hardcodedSongLists,
  choirpalTeamGroup,
} from './groups.hardcoded';

const hardcodedGroups = hardcodedGroupsList.reduce((accum, group) => {
  accum[group.groupCuid] = group;
  return accum;
}, {});

export const selectGroupSongDocumentList = (groupCuid, state) => {
  const groupSongCuidList = getGroupSongCuidList(groupCuid, state);
  const { songs } = state.myMusic;
  const groupSongDocuments = groupSongCuidList
    .map(songCuid => {
      return songs[songCuid];
    })
    .filter(songDocument => !!songDocument);
  return groupSongDocuments;
};

export const getGroupList = state => {
  const groups = state.groups || state;
  return canViewHardcodedGroups(state)
    ? hardcodedGroupsList.concat(Object.values(groups.groupsById))
    : choirpalTeamGroup.concat(Object.values(groups.groupsById));
};

export const canViewHardcodedGroups = state => {
  const { createdTimestamp } = state.user;
  return createdTimestamp && createdTimestamp < '2020';
};

export const getGroup = (groupCuid, state) => {
  const groups = state.groups || state;
  const allGroups = { ...hardcodedGroups, ...groups.groupsById };
  return allGroups[groupCuid] || {};
};

export const getMemberList = (groupCuid, state) => {
  const groups = state.groups || state;
  const { membersByGroupId } = groups;
  return asList(membersByGroupId[groupCuid]);
};

export const getInviteList = (groupCuid, state) => {
  const groups = state.groups || state;
  const { invitesByGroupId } = groups;
  return asList(invitesByGroupId[groupCuid]);
};

// TODO: what pattern to use when filtering
// New function like this or extra filter param
export const getPendingInviteList = (groupCuid, state) =>
  getInviteList(groupCuid, state).filter(
    invite => invite.status !== 'accepted',
  );
export const getUserInviteList = state => {
  const groups = state.groups || state;
  const { myInvitesList } = groups;
  return myInvitesList || [];
};

export const getGroupSongCuidList = (groupCuid, state) => {
  return (
    hardcodedSongLists[groupCuid] ||
    state.groups.songCuidsByGroupId[groupCuid] ||
    []
  );
};

export const getNextGroupSongCuid = (currentSongCuid, groupCuid, state) => {
  const songCuids = getGroupSongCuidList(groupCuid, state);
  const i = songCuids.indexOf(currentSongCuid);
  return songCuids[i + 1];
};

export const isPostingGroup = state => {
  const groups = state.groups || state;
  return groups.isPostingGroup;
};

export const isFetchingGroups = state => {
  const groups = state.groups || state;
  return groups.isFetchingGroups;
};

export const isFetchingGroupSongs = state => {
  const groups = state.groups || state;
  return groups.isFetchingGroupSongs;
};

export const isPostingGroupAvatar = state => {
  const groups = state.groups || state;
  return groups.isPostingGroupAvatar;
};

export const getGroupError = state => {
  const groups = state.groups || state;
  return groups.error;
};
