import styled from 'styled-components';

import { SMALL_BREAKPOINT } from '../../../hooks/useBreakpoint';

export const CheckoutContainer = styled.div`
  font-family: Platform Web;
  height: 100%;
  width: 100%;
`;

export const CouponCode = styled.div`
  text-align: center;
  padding: 2em;
  width: 50%;
`;

export const Basket = styled.div`
  margin: 0 auto;
  min-width: ${SMALL_BREAKPOINT};
  padding-bottom: 2em;
`;

export const CardDetails = styled.div`
  padding: 1em;
  background-color: white;
`;

export const Success = styled.div`
  padding: 2em;
`;
