import { connect } from 'react-redux';
import SongListItem from './SongListItem';
import { playSongThunk } from '../../../store/player/player.thunks';
import {
  getSongByCuid,
  getFilesetUrls,
  getFileset,
  getFileRecord,
} from '../../../store/myMusic/myMusic.selectors';

import { asList } from '../../../store/storeUtil';
import withAppContext from '../../../withAppContext';

const mergeProps = (state, { dispatch, ownProps }) => {
  const { songCuid, groupCuid, history } = ownProps;

  const song = getSongByCuid(songCuid, state);
  const { filesetCuid, meta = {}, sheetMusic = {} } = song;
  const fileset = getFileset(filesetCuid, state);
  const filesetUrls = getFilesetUrls(filesetCuid, state);
  const { songTitle, writtenBy, recordedBy } = meta;
  const sheet = asList(sheetMusic)[0] || {};
  const sheetMusicUrl = filesetUrls[sheet.fileCuid];
  const pdfFileRecord = getFileRecord(filesetCuid, sheet.fileCuid, state) || {};

  return {
    songCuid,
    song,
    fileset,
    filesetUrls,
    songTitle,
    writtenBy,
    sheetMusicUrl,
    pdfFileName: pdfFileRecord.filename,
    recordedBy,
    onClickSong: songCuid => {
      playSongThunk(
        songCuid,
        'GROUP_PLAYLIST',
        groupCuid,
        state,
        dispatch,
        history,
      );
    },
  };
};

export default withAppContext(
  connect(
    state => state,
    (dispatch, ownProps) => ({ dispatch, ownProps }),
    mergeProps,
  )(SongListItem),
);
