import { connect } from 'react-redux';
import AppAfterLogin from './AppAfterLogin';
import { isLoggedIn } from './store/user/user.selectors';
import { getCurrentSongCuid } from './store/player/player.selectors';
import { getCurrentCredentialsThunk } from './store/user/user.thunks';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
    songCuid: getCurrentSongCuid(state),
  };
};

const mapDispatchToProps = dispatch => ({
  doRefreshSession: () => dispatch(getCurrentCredentialsThunk()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppAfterLogin);
