import { connect } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import { isLoggedIn, hasFailedLogin } from '../../../store/user/user.selectors';

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
    isAuthInProgress: !isLoggedIn(state) && !hasFailedLogin(state),
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(ProtectedRoute);
