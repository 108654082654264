// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SectionHeader from '../../Layout/SectionHeader/SectionHeader';
import AddMember from '../AddMember/AddMember';
import * as S from './MemberList.styled';

function MemberList({
  groupCuid,
  group,
  onInviteMembers,
  onDeleteMember,
  onDeleteInvite,
  members = [],
  invites = [],
}) {
  const [isVisibleAddMembers, setIsVisibleAddMembers] = useState(false);

  const handleAddMembersClick = () => {
    setIsVisibleAddMembers(true);
  };

  const handleSendInviteClick = emailArray => {
    setIsVisibleAddMembers(false);

    if (emailArray.length > 0) {
      onInviteMembers(groupCuid, emailArray, group.groupName);
    }
  };

  const handleDeleteMemberClick = userCuid => {
    onDeleteMember(groupCuid, userCuid);
  };

  const handleDeleteInviteClick = groupInviteCuid => {
    onDeleteInvite(groupCuid, groupInviteCuid);
  };

  const handleCancel = () => {
    setIsVisibleAddMembers(false);
  };

  return (
    <S.MemberList>
      <AddMember
        isVisible={isVisibleAddMembers}
        onClickSend={handleSendInviteClick}
        onClickCancel={handleCancel}
      />

      <S.ListContainer>
        <SectionHeader title="Members" subTitle={`${members.length} members`} />

        <Button
          style={{ marginTop: 10, width: '100%' }}
          size="large"
          color="primary"
          fullWidth={true}
          onClick={handleAddMembersClick}>
          <AddIcon
            style={{ height: '40px', width: '40px', marginRight: '12px' }}
          />
          Add Member(s)
        </Button>

        <S.List>
          {members.map(({ email, userCuid, isAdmin }) => (
            <S.ListItem key={userCuid}>
              <S.Email>{email}</S.Email>

              {!isAdmin ? (
                <S.DeleteButton>
                  <IconButton
                    color="secondary"
                    aria-label="Edit Song"
                    onClick={() => handleDeleteMemberClick(userCuid)}>
                    <Delete />
                  </IconButton>
                </S.DeleteButton>
              ) : (
                <Chip
                  style={{ marginTop: 5, marginRight: 5 }}
                  label="Admin"
                  color={'secondary'}
                  // className={classes.chip}
                />
              )}
            </S.ListItem>
          ))}
        </S.List>
      </S.ListContainer>

      <S.ListContainer>
        <SectionHeader
          title="Pending Invites"
          subTitle={`${invites.length} invites`}
        />
        <S.List>
          {invites.map(({ email, status, groupInviteCuid }) => (
            <S.ListItem key={email}>
              <S.ChipAndEmail>
                <Chip
                  key={email}
                  style={{ marginTop: 5, marginRight: 5 }}
                  label={status}
                  color="primary"
                  size="small"
                  variant={status === 'invited' ? 'outlined' : 'default'}
                  // className={classes.chip}
                />
                <S.Email>{email}</S.Email>
              </S.ChipAndEmail>
              <S.DeleteButton>
                <IconButton
                  color="secondary"
                  aria-label="Delete Invite"
                  onClick={() => handleDeleteInviteClick(groupInviteCuid)}>
                  <Delete />
                </IconButton>
              </S.DeleteButton>
            </S.ListItem>
          ))}
        </S.List>
      </S.ListContainer>
    </S.MemberList>
  );
}

MemberList.propTypes = {
  onInviteMembers: PropTypes.func.isRequired,
  onDeleteMember: PropTypes.func.isRequired,
  onDeleteInvite: PropTypes.func.isRequired,
  groupCuid: PropTypes.string.isRequired,
  group: PropTypes.object,
  members: PropTypes.array.isRequired,
  invites: PropTypes.array.isRequired,
};

export default MemberList;
