import { set, lensPath } from 'ramda';

export const byId = (id = '', arr = []) => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  const out = arr.reduce((accum, obj) => {
    const key = String(obj[id]);
    if (key) accum[key] = obj;
    return accum;
  }, {});
  return out;
};

export const asList = (idMap = {}) => {
  if (Array.isArray(idMap)) {
    return idMap;
  }
  return Object.keys(idMap).map(key => idMap[key]);
};

export const getFirst = (idMap = {}) => {
  if (Array.isArray(idMap)) {
    return idMap[0];
  }
  return Object.values(idMap || {})[0];
};

export const cuidFakerFactory = () => {
  let counter = 0;
  return () => {
    const out = 'cuid_' + counter++;
    return out;
  };
};

export const setPath = (path, val, obj) => set(lensPath(path), val, obj);
