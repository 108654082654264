import { version } from '../../package.json';

const CHOIRPAL_STATE_KEY = 'choirpal-state';

// Only return the state if the package version is a match
export const getPersistedState = () => {
  if (process.env.REACT_APP_USE_PERSISTED_STATE === 'false') {
    return undefined;
  }
  const persistedStateString = localStorage.getItem(CHOIRPAL_STATE_KEY);
  const persistedStateObject = JSON.parse(persistedStateString) || {};
  const persistedStateVersioned = persistedStateObject[version];
  return persistedStateVersioned;
};

export const setPersistedState = state => {
  // Only save some parts of the state object
  const { router, gaMetaReducer, player, ...savableState } = state;

  // Don't save the currently playing song as it's tricky to reopen it after a page reload
  const persistedStateObject = {
    [version]: { ...savableState, player: { ...player, songCuid: '' } },
  };
  const persistedStateString = JSON.stringify(persistedStateObject);
  localStorage.setItem(CHOIRPAL_STATE_KEY, persistedStateString);
};
