import { connect } from 'react-redux';
import RequestReset from './RequestReset';
import { getErrorMsg } from '../../../store/user/user.selectors';
import { resetThunk } from '../../../store/user/user.thunks';

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onRequestReset: username => {
    dispatch(resetThunk(username));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestReset);
