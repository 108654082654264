export const ADD_SONG = 'myMusic/addSong';
export const UPDATE_SONG = 'myMusic/updateSong';
export const UPDATE_SONG_META = 'myMusic/updateSongMeta';
export const DELETE_SONG_STARTED = 'myMusic/deleteSongStarted';
export const DELETE_SONG_SUCCESS = 'myMusic/deleteSongSuccess';
export const DELETE_SONG_FAILED = 'myMusic/deleteSongFailed';
export const SHARE_SONG_STARTED = 'myMusic/shareSongStarted';
export const SHARE_SONG_SUCCESS = 'myMusic/shareSongSuccess';
export const SHARE_SONG_FAIL = 'myMusic/shareSongFailed';
export const FETCH_SONG_GROUPS_STARTED = 'myMusic/fetchSongGroupsStarted';
export const FETCH_SONG_GROUPS_FAIL = 'myMusic/fetchSongGroupsFail';
export const FETCH_SONG_GROUPS_SUCCESS = 'myMusic/fetchSongGroupsSucess';

// Tracks
export const ADD_TRACK = 'myMusic/addTrack';
export const UPDATE_TRACK = 'myMusic/updateTrack';
export const DELETE_TRACK = 'myMusic/deleteTrack';

// Sheet Music
export const ADD_SHEET_MUSIC = 'myMusic/addSheetMusic';
export const DELETE_SHEET_MUSIC = 'myMusic/deleteSheetMusic';

export const FETCH_SONGS_STARTED = 'myMusic/fetchSongsStarted';
export const FETCH_SONGS_SUCCESS = 'myMusic/fetchSongsSuccess';
export const FETCH_SONGS_FAIL = 'myMusic/fetchSongsFail';
export const POST_SONG_STARTED = 'myMusic/postSongStarted';
export const POST_SONG_SUCCESS = 'myMusic/postSongSuccess';
export const POST_SONG_FAIL = 'myMusic/postSongFail';

// filesets
export const POST_FILESET_STARTED = 'myMusic/postFilesetStarted';
export const POST_FILESET_SUCCESS = 'myMusic/postFilesetSuccess';
export const POST_FILESET_FAIL = 'myMusic/postFilesetFail';
export const STORE_FILESET = 'myMusic/storeFileset';

export const SET_UPLOAD_STATUS = 'myMusic/setUploadStatus';

// Guide
export const ADD_GUIDE = 'myMusic/addGuide';
export const DELETE_GUIDE = 'myMusic/deleteGuide';
export const ADD_TRACKS_TO_GUIDE = 'myMusic/addTracksToGuide';
export const ADD_SECTION = 'myMusic/addSection';
export const DELETE_SECTION = 'myMusic/deleteSection';
export const NUDGE_SECTION = 'myMusic/nudgeSection';
export const ADD_PAGE = 'myMusic/addPage';
export const DELETE_PAGE = 'myMusic/deletePage';

export const ADD_FILESET = 'myMusic/addFileset';
export const STORE_SONG = 'myMusic/storeSong';
export const STORE_FILESET_URLS = 'myMusic/storeFilesetUrls';
