import { connect } from 'react-redux';
import { CouponInput } from './CouponInput';
import { fetchCouponThunk } from '../../../../store/billing/billing.thunks';

import {
  getCoupon,
  getCouponError,
  isFetchingCoupon,
} from '../../../../store/billing/billing.selectors';
import { clearStripeErrors } from '../../../../store/billing/billing.actions';

const mapStateToProps = state => {
  return {
    coupon: getCoupon(state),
    couponError: getCouponError(state),
    isFetchingCoupon: isFetchingCoupon(state),
  };
};

const mapDispatchToProps = dispatch => ({
  doCouponClick: couponCode => {
    dispatch(fetchCouponThunk(couponCode));
  },
  doClearCouponError: () => {
    dispatch(clearStripeErrors());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CouponInput);
