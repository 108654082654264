import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M98.805,45.109
    c16.508,0,29.891,13.382,29.891,29.891s-13.383,29.891-29.891,29.891"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M118.269,97.677
    c-1.346,27.529-5.073,47.323-9.464,47.323c-5.524,0-10-31.342-10-70s4.476-70,10-70c4.39,0,8.118,19.794,9.464,47.324"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M149.06,50.704
    c4.516,7.003,7.137,15.343,7.137,24.296c0,8.951-2.62,17.29-7.135,24.292"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M173.047,36.122
    c8.124,9.949,13.149,23.694,13.149,38.878c0,15.08-4.958,28.742-12.985,38.676"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M108.805,145l-45-30h-23c-6.628,0-12-5.373-12-12
    v-3h-8.143c-3.787,0-6.857-3.358-6.857-7.5v-35c0-4.142,3.07-7.5,6.857-7.5h8.143v-3c0-6.627,5.372-12,12-12h23h0.124l44.876-30"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="63.805"
      y1="35"
      x2="63.805"
      y2="85"
    />
  </g>,
  200,
  150,
);
