import React from 'react';
import PropTypes from 'prop-types';
import FilePicker from '../../Form/FilePicker/FilePicker';
import * as S from './AvatarPicker.styled';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '../../Form/Avatar/Avatar';
import IconAccount from '../../Theme/Icons/IconAccount';

function AvatarPicker({
  onFileSelected,
  avatarUrl,
  isUploading,
  canEdit,
  avatarId,
}) {
  const handleFileSelected = file => {
    // file[0] because FilePicker takes in an array
    onFileSelected(file[0], avatarId);
  };

  return (
    <S.AvatarPicker>
      <FilePicker
        onFileSelected={handleFileSelected}
        allowMultiple={false}
        acceptFileTypes=".jpeg, .png">
        {isUploading ? (
          <CircularProgress size={36} color="primary" />
        ) : canEdit ? (
          <IconButton style={{}} size="medium" color="primary" component="span">
            {avatarUrl ? (
              <Avatar alt="Avatar" src={avatarUrl} />
            ) : (
              <IconAccount size="large" />
            )}
          </IconButton>
        ) : (
          <Avatar alt="Avatar" src={avatarUrl} />
        )}
      </FilePicker>
    </S.AvatarPicker>
  );
}

AvatarPicker.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string,
  avatarId: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default AvatarPicker;
