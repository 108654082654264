import apiInit, { getIdentityId } from '../api';

const { get, post, del } = apiInit('songs');

export const fetchMySongs = async () => get(`/songs`);

export const postSongDocument = async songDocument =>
  post(`/songs/${songDocument.songCuid}`, songDocument);

export const fetchSongDocument = async songCuid => get(`/songs/${songCuid}`);

export const deleteSong = async songCuid => del(`/songs/${songCuid}`);

export const shareSongGroups = async (songCuid, groupCuids) =>
  post('/songs/share/groups', {
    songCuid,
    groupCuids,
  });

//  Return groups that the song has been shared with
export const fetchSongGroups = async songCuid =>
  get(`/songs/${songCuid}/groups`);

export const postFileRecord = async fileRecord => {
  const identityId = await getIdentityId();
  console.log(identityId);
  post(`/file`, { fileRecord, identityId });
};
export const postFileset = async fileset => post(`/fileset`, fileset);

export const fetchFileset = async filesetCuid => get(`/fileset/${filesetCuid}`);
