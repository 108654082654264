import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M65,125.015C65,136.321,57.068,145,45.623,145
    C34.174,145,25,136.343,25,125.036c0-11.299,9.066-19.994,20.516-19.994"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="65"
      y1="125.015"
      x2="65"
      y2="25.087"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M120.027,5.251C125,4.102,125,7.1,125,10.098"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M120.027,5.251c0,0-40.027,8.799-45.027,9.843
    c-4.984,1.04-10,4.286-10,9.993"
    />
    <polyline
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      points="125,10.098 124.988,40.188 65,55.065  "
    />
  </g>,
  150,
  150,
);
