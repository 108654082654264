import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import React from 'react';
// import PersonOutline from '@material-ui/icons/PersonOutline';
import Logo from '../../Theme/Logo/Logo';
import './TopAppBar.css';

const excludedList = ['/', '/groups', '/my-music', '/my-account'];

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    fontSize: 'large',
  },
});

function TopAppBar({
  classes,
  onClickLogout,
  onClickBack,
  isLoggedIn,
  pathname,
}) {
  const hasBackButton = !excludedList.includes(pathname);

  function handleBackClick() {
    hasBackButton && onClickBack();
  }
  return (
    <div className="Homepage__AppTitleContainer">
      <div style={{ opacity: hasBackButton ? 1 : 0 }}>
        <IconButton
          aria-label="Repeat"
          className={classes.button}
          onClick={handleBackClick}>
          <KeyboardArrowLeft />
        </IconButton>
      </div>
      <div className="Homepage__AppTitle">
        <Logo
          size="small"
          classes={['LoginHome__Logo']}
          isLoggedIn={isLoggedIn}
        />
      </div>
      <div>
        <Button
          style={{ width: '80%' }}
          color="secondary"
          size="large"
          onClick={onClickLogout}>
          Sign&nbsp;Out
        </Button>
      </div>
    </div>
  );
}

TopAppBar.propTypes = {
  classes: PropTypes.object,
  pathname: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  onClickLogout: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
};

/*

// <IconButton
//   aria-label="Repeat"
//   className={classes.button}
//   onClick={() => console.log('')}>
//   <PersonOutline />
// </IconButton>

        */

export default withStyles(styles)(TopAppBar);
