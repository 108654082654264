import styled from 'styled-components';

export const StudioToolbar = styled.div`
  /* width: 100vw; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

`;

export const HorizontalScrollable = styled.section`
  display: flex;
  min-width: 100%;
  min-height: 40px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ToolbarElement = styled.div`
  margin: 0px 4px;
`;

export const ToolText = styled.span`
  margin-left: 4px;
`;
