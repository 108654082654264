import { connect } from 'react-redux';
import LeaveGroupModal from './LeaveGroupModal';
import { leaveGroupThunk } from '../../../store/groups/groups.thunks';
import { getGroup } from '../../../store/groups/groups.selectors';
import { hideModal } from '../../../store/modal/modal.actions';
import { groupsRoute } from '../../routes';

const mapStateToProps = (state, ownProps) => {
  const { groupCuid } = ownProps;
  const group = getGroup(groupCuid, state);
  const { groupName } = group;

  return {
    groupCuid,
    groupName,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickOk: groupCuid => {
    dispatch(leaveGroupThunk(groupCuid));
    dispatch(hideModal());
    dispatch(groupsRoute());
  },
  onClickCancel: groupCuid => {
    dispatch(hideModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaveGroupModal);
