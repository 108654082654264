import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './EditGuide.styled';
import H2 from '../../Typography/Heading/H2';
import H3 from '../../Typography/Heading/H3';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import CommentIcon from '@material-ui/icons/Comment';

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

function EditGuide({
  onClickSave,
  songCuid,
  guide,
  trackList = [],
  classes,
  fileRecords,
}) {
  const [guideTitleState, setGuideTitleState] = useState(
    guide.guideTitle || '',
  );
  const [selectedTracks, setSelectedTracks] = useState(
    guide.selectedTracks || {},
  );

  /* eslint-disable */
  useEffect(() => {
    setGuideTitleState(guide.guideTitle);
  }, [guide]);

  useEffect(() => {
    setGuideTitleState(guide.guideTitle);
  }, [trackList]);

  useEffect(() => {
    const initialSelectedTracks = trackList.reduce((accum, track) => {
      accum[track.trackCuid] = track.guideCuid === guide.guideCuid;
      return accum;
    }, {});
    setSelectedTracks(initialSelectedTracks);
  }, [trackList]);
  /* eslint-enable */

  const handleSaveGuideClick = () => {
    const updatedGuide = {
      ...guide,
      guideTitle: guideTitleState,
    };
    return onClickSave(songCuid, updatedGuide, selectedTracks);
  };

  function handleToggle(trackCuid) {
    const isToggled = selectedTracks[trackCuid];
    const updatedSelectedTracks = {
      ...selectedTracks,
      [trackCuid]: !isToggled,
    };

    setSelectedTracks(updatedSelectedTracks);
  }

  function isAllTracksSelected() {
    return numTracksSelected() === trackList.length;
  }
  function numTracksSelected() {
    return Object.keys(selectedTracks).reduce((accumulator, currentValue) => {
      const isSelected = selectedTracks[currentValue];
      return isSelected ? accumulator + 1 : accumulator;
    }, 0);
  }

  function handleToggleAllClick() {
    isAllTracksSelected() ? deselectAllTracks() : selectAllTracks();
  }

  function selectAllTracks(trueFalse = true) {
    const updatedSelectedTracks = trackList.reduce(
      (accumulator, currentValue) => {
        if (trueFalse) {
          accumulator[currentValue.trackCuid] = true;
        }
        return accumulator;
      },
      {},
    );
    setSelectedTracks(updatedSelectedTracks);
  }
  function deselectAllTracks() {
    selectAllTracks(false);
  }

  function renderTrackList() {
    return (
      <List className={classes.root}>
        {trackList.map(track => {
          const { trackCuid, fileCuid } = track;
          const labelId = `checkbox-list-label-${trackCuid}`;
          const fileRecord = fileRecords[fileCuid] || {};
          const { filename } = fileRecord;

          return (
            <ListItem
              key={trackCuid}
              role={undefined}
              dense
              button
              onClick={() => handleToggle(trackCuid)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedTracks[trackCuid] || false}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={filename} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="Comments">
                  {/* <CommentIcon /> */}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <S.EditGuide>
      <H2>Learning Guide</H2>

      <S.GuideTitle>
        <TextField
          style={{ marginTop: 10, width: '100%' }}
          label="Guide Title"
          placeholder="e.g. 'Altos and Sopranos'"
          required
          variant="standard"
          margin="normal"
          value={guideTitleState || ''}
          autoFocus={!guideTitleState}
          onChange={({ target }) => {
            setGuideTitleState(target.value);
          }}
        />
      </S.GuideTitle>

      <S.Tracks>
        {' '}
        <S.Header>
          <Checkbox
            edge="start"
            checked={isAllTracksSelected()}
            tabIndex={-1}
            disableRipple
            onClick={handleToggleAllClick}
            inputProps={{ 'aria-labelledby': '' }}
          />
          <H3>Choose Tracks</H3>
        </S.Header>
        {renderTrackList()}
      </S.Tracks>

      <S.SaveButton>
        <Button
          style={{ minWidth: '180px', width: '100%' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSaveGuideClick}>
          Save
        </Button>
      </S.SaveButton>
    </S.EditGuide>
  );
}

EditGuide.propTypes = {
  onClickSave: PropTypes.func.isRequired,
  songCuid: PropTypes.string.isRequired,
  guide: PropTypes.object,
  trackList: PropTypes.array.isRequired,
  fileRecords: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(EditGuide);
