import { connect } from 'react-redux';
import AvatarPicker from '../../Form/AvatarPicker/AvatarPicker';

import { postGroupAvatarThunk } from '../../../store/groups/groups.thunks';
import {
  isPostingGroupAvatar,
  getGroup,
} from '../../../store/groups/groups.selectors';

const mapStateToProps = (state, ownProps) => {
  const { groupCuid } = ownProps;
  const group = getGroup(groupCuid, state) || {};
  const { isAdmin, imageUrl } = group;
  return {
    isUploading: isPostingGroupAvatar(state),
    avatarId: groupCuid,
    canEdit: isAdmin || false,
    avatarUrl: imageUrl,
  };
};

const mapDispatchToProps = dispatch => ({
  onFileSelected: (file, groupCuid) => {
    dispatch(postGroupAvatarThunk(file, groupCuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvatarPicker);
