import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import choirpalMuiTheme from './choirpalMuiTheme';

export default WrappedComponent => {
  return props => (
    <MuiThemeProvider theme={choirpalMuiTheme}>
      <WrappedComponent {...props} />
    </MuiThemeProvider>
  );
};
