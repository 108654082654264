import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import BottomNav from './BottomNav';
import { logoutThunk } from '../../../store/user/user.thunks';
import { isPro } from '../../../store/billing/billing.selectors';

const mapStateToProps = (state, ownProps) => ({
  pathname: ownProps.pathname,
  isProMember: isPro(state),
});

const mapDispatchToProps = dispatch => ({
  onNavSelected: route => {
    route && dispatch(push(route));
  },
  onClick: () => {
    dispatch(logoutThunk());
    dispatch(push('/login'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BottomNav);
