import React from 'react';
import PropTypes from 'prop-types';
// import * as S from './DeleteGroupModal.styled';
import OkCancelModal from './../OkCancelModal/OkCancelModal';

const CancelSubscriptionModal = ({ end_date, onClickOk, onClickCancel }) => {
  const handleOkClick = () => {
    onClickOk && onClickOk();
  };
  const handleCancelClick = () => {
    onClickCancel && onClickCancel();
  };

  return OkCancelModal({
    title: 'Cancel Choirpal Pro subscription??',
    imageName: 'trio',
    body: (
      <div>
        Are you sure you want to cancel? Your Choirpal Pro subscription will end
        on :<strong> {end_date} </strong>
      </div>
    ),
    okButtonText: 'Yes, cancel subscription',
    cancelButtonText: 'No, continue my subscription!',
    onClickOk: handleOkClick,
    onClickCancel: handleCancelClick,
  });
};

CancelSubscriptionModal.propTypes = {
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  end_date: PropTypes.string.isRequired,
};

export default CancelSubscriptionModal;
