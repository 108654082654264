import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import * as S from './PageHeader.styled';
import H3 from '../../Typography/Heading/H3';
import { LYRICS_TOOL } from '../../../store/player/player.tools';
import { isEditable } from '../../../store/myMusic/myMusic.selectors';

function PageHeader(props) {
  const {
    onChangePageTitle,
    onClickToggleEditMode,
    position,
    onClickStartEditing,
    activeToolName,
    // isFirstPage,
    isEditModeEnabled,
  } = props;
  const [pageTitle, setPageTitle] = useState(props.pageTitle);
  const inputRef = useRef();

  const handleBlurPageTitle = () => {
    onChangePageTitle(position, pageTitle);
  };

  const handleChangePageTitle = evt => {
    setPageTitle(evt.target.value);
  };

  const handleKeypress = evt => {
    const { key } = evt;
    if (key === 'Enter') {
      // Force the focus out
      inputRef.current.blur();
    }
  };

  const handleFocus = evt => {
    evt.target.select();
  };

  const handleClickStartEditing = () => {
    onClickStartEditing();
    onClickToggleEditMode(!isEditModeEnabled);
  };

  return (
    <S.PageHeader>
      {isEditable && (
        <S.StartEditing>
          <Fab
            variant="extended"
            color="secondary"
            size="medium"
            aria-label="Start Editing"
            onClick={handleClickStartEditing}>
            &nbsp; {isEditModeEnabled ? 'Finish Editing' : 'Start Editing'}
            &nbsp;
          </Fab>
        </S.StartEditing>
      )}
      <S.Content onDoubleClick={() => onClickToggleEditMode()}>
        {isEditable && <S.Spacer></S.Spacer>}
        <H3>
          {isEditModeEnabled && activeToolName === LYRICS_TOOL ? (
            <S.TitleInput
              ref={inputRef}
              value={pageTitle}
              onBlur={handleBlurPageTitle}
              onChange={handleChangePageTitle}
              onKeyPress={handleKeypress}
              onFocus={handleFocus}
            />
          ) : (
            <React.Fragment>{pageTitle}</React.Fragment>
          )}
        </H3>
      </S.Content>
    </S.PageHeader>
  );
}

PageHeader.propTypes = {
  position: PropTypes.number.isRequired,
  onChangePageTitle: PropTypes.func.isRequired,
  onClickDeletePage: PropTypes.func.isRequired,
  onClickToggleEditMode: PropTypes.func.isRequired,
  onClickStartEditing: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  activeToolName: PropTypes.string.isRequired,
  // isFirstPage: PropTypes.bool.isRequired,
  isEditModeEnabled: PropTypes.bool.isRequired,
};

export default PageHeader;
