import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Logo from '../../Theme/Logo/Logo';
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks';
import useBreakpoint from '../../hooks/useBreakpoint';
import { Link } from 'react-scroll';
import './TopNav.css';
import { isBillingEnabled } from '../../../util/featureToggle';

export default function TopNav({
  classes,
  onClickLogin,
  onClickRegister,
  isLoggedIn,
  doRefreshSession,
}) {
  useEffect(() => {
    !isLoggedIn && doRefreshSession();
  }, [isLoggedIn, doRefreshSession]);

  const { isMediumOrAbove } = useBreakpoint();

  return (
    <React.Fragment>
      <div className="TopNav">
        <div className="TopNav__LogoContainer">
          <Logo size="small" />
        </div>

        <div className="TopNav__LoginSocialMedia">
          <div className="TopNav__LoginContainer">
            {isBillingEnabled() && (
              <Button color="secondary" size="medium">
                <Link to="pricing">Pricing</Link>
              </Button>
            )}

            <Button
              // style={{ width: '40%' }}
              color="secondary"
              // variant="outlined"
              size="medium"
              style={{ marginRight: 20 }}
              onClick={onClickLogin}>
              Sign&nbsp;In
            </Button>
            <Button
              // style={{ width: '60%' }}
              variant="contained"
              color="primary"
              size="medium"
              onClick={onClickRegister}>
              Try&nbsp;Now
            </Button>
          </div>
          {isMediumOrAbove && (
            <div className="TopNav__SocialMediaContainer">
              <SocialMediaLinks />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

TopNav.propTypes = {
  classes: PropTypes.array,
  onClickLogin: PropTypes.func.isRequired,
  onClickRegister: PropTypes.func.isRequired,
  doRefreshSession: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};
