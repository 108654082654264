export const hardcodedGroupsList = [
  {
    groupCuid: 'ck72g7n8q00003h5q7860e5ga',
    groupName: 'Sample Songs & Tutorials',
    adminName: '',
    imageUrl:
      'https://s3-eu-west-1.amazonaws.com/media.choirf.ly/logos/choirpal/android-red-icon-192x192.png',
  },
  {
    groupCuid: 'cjmdkvixf00003t61hldatlf0',
    groupName: 'EY Choir',
    adminName: 'Donal Kearney',
    imageUrl:
      'https://s3-eu-west-1.amazonaws.com/media.choirf.ly/logos/EY-logo.jpg',
  },
  {
    groupCuid: 'cjmdkw25p00003t66op3k9zzu',
    groupName: 'Cór Fingal',
    adminName: 'Eoin Conway',
    imageUrl:
      'https://s3-eu-west-1.amazonaws.com/media.choirf.ly/logos/FYC-background.png',
  },
  {
    groupCuid: 'cjmdkxay200003t660yz6u0gd',
    groupName: 'Fingal Youth Choir',
    adminName: 'Eoin Conway',
    imageUrl:
      'https://s3-eu-west-1.amazonaws.com/media.choirf.ly/logos/FCC-logo.png',
  },
  {
    groupCuid: 'cjozu8dgg00003k618hohyte1',
    groupName: 'BlingMasters',
    adminName: 'Rebecca Gilbert',
    imageUrl:
      'https://s3-eu-west-1.amazonaws.com/media.choirf.ly/logos/Bling-logo.jpg',
  },
];

export const choirpalTeamGroup = [
  {
    groupCuid: 'ck72g7n8q00003h5q7860e5ga',
    groupName: 'Sample Songs & Tutorials',
    adminName: '',
    imageUrl:
      'https://s3-eu-west-1.amazonaws.com/media.choirf.ly/logos/choirpal/android-red-icon-192x192.png',
  },
];
export const hardcodedSongLists = {
  // Choirpal Team
  ck72g7n8q00003h5q7860e5ga: [
    'ck6vitk6l00op3h5qjoawrm2y',
    'cjmw3yj3f00003h5pw182d4h7',
    'cjmghfu1300003r5uog75mq8l',
    'cjmgh52kb00003h5o2dafpxfa',
    'cjrsnnafk00003k5xmll8x82k',
  ],
  // EY
  cjmdkvixf00003t61hldatlf0: [
    'ck16uzt6k00003g5qktc4osni',
    'cjwv2nbtk00003g5rukl7uo7i',
    'cjrsp3xfb00003k5xrccsr1qs',
    'cjrsnnafk00003k5xmll8x82k',
    'cjrslolnb00003k5xlnpzgeph',
    'cjrsfadat00003g5oxwy9bcwz',
    'cjog22bha00003h5kp8kytrgs',
    'cjmgh52kb00003h5o2dafpxfa',
    'cjmghfu1300003r5uog75mq8l',
    'cjltjly0t00003f5xk2dtqjzp',
    'cjltkq39100003f5xzryqy86p',
    'cjltkuwz500003f5xfa4o9goo',
    'cjltl4esu00003f5xvwgeunkd',
  ],
  // Cor Fingal
  cjmdkw25p00003t66op3k9zzu: [
    'cjn1ukxv900003h5k99byhhj8',
    'cjn1sctvv00003r5yaw6dyaii',
    'cjn1pmw1a00003h5ls51eo1uj',
    'cjn0jc4d300003h5hsdr9wxe9',
    'cjmn74cjt00003t5ycr23jrin',
    'cjltjly0t00003f5xk2dtqjzp',
    'cjltkq39100003f5xzryqy86p',
    'cjltkuwz500003f5xfa4o9goo',
    'cjltl4esu00003f5xvwgeunkd',
  ],
  // Fingal Youth Choir
  cjmdkxay200003t660yz6u0gd: [
    'cjqqry85y00003g5rwhiprsy0',
    'cjqmkz2bm00003g5rhcketvwf',
    'cjog22bha00003h5kp8kytrgs',
    'cjn0dl7on00003h5kw0dtt6r3',
    'cjn04hzau00003h5l2nehfy2y',
    'cjmw3yj3f00003h5pw182d4h7',
    'cjltsptde00003f5xw9m5bach',
    'cjlyz0jy600003t5uie6ipjxy',
    'cjltjly0t00003f5xk2dtqjzp',
    'cjltkq39100003f5xzryqy86p',
    'cjltkuwz500003f5xfa4o9goo',
    'cjltl4esu00003f5xvwgeunkd',
  ],
  // Blingmasters
  cjozu8dgg00003k618hohyte1: [
    'cjullns6k00003h5x836eyi1f',
    'cjrtt2pql00003k5xg9btqo79',
    'cjrtgmvlq00003g5vnrd6tjq4',
    'cjqqvod8l00003q63kptmx0b3',
    'cjozttf7500003k5sfamqp0c2',
    'cjp8cekpv00003h5uyknsffb8',
    'cjpaaatwk00003h5lipb7jqef',
  ],
};
