import React from 'react';
import PropTypes from 'prop-types';
// import * as S from './PdfCard.styled';
import * as SS from 'src/ui/Theme/Shared.styled';

import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';

import IconButton from '@material-ui/core/IconButton';
import prettyBytes from 'pretty-bytes';

function PdfCard(props) {
  const {
    songCuid,
    sheetMusicCuid,
    filename = '',
    sizeInBytes,
    onClick,
    onClickDelete,
    pdfUrl,
  } = props;

  const handleDeleteClick = () => {
    onClickDelete(songCuid, sheetMusicCuid);
  };
  const formattedFileSize = Number.isInteger(sizeInBytes)
    ? prettyBytes(sizeInBytes)
    : '';

  const handlePdfClick = () => {
    onClick(pdfUrl, filename);
  };

  return (
    <SS.Card>
      <SS.RowHeader onClick={handlePdfClick}>
        <InsertDriveFile color="primary" />
      </SS.RowHeader>
      <SS.RowContent onClick={handlePdfClick}>
        <SS.RowContentPrimary>{filename}</SS.RowContentPrimary>
        <SS.RowContentSecondary style={{ flexDirection: 'row-reverse' }}>
          {formattedFileSize}
        </SS.RowContentSecondary>
      </SS.RowContent>
      <SS.RowFooter>
        <IconButton
          color="secondary"
          aria-label="Delete Pdf"
          onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </SS.RowFooter>
    </SS.Card>
  );
}

PdfCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  songCuid: PropTypes.string,
  sheetMusicCuid: PropTypes.string,
  pdfUrl: PropTypes.string,
  filename: PropTypes.string,
  sizeInBytes: PropTypes.string,
};

export default PdfCard;
