import { connect } from 'react-redux';
import { path } from 'ramda';
import EditGuide from './EditGuide';
import withAppContext from '../../../withAppContext';

import {
  addGuide,
  addTracksToGuide,
} from '../../../store/myMusic/myMusic.actions';
import { newGuide } from '../../../store/myMusic/myMusic.model';
import {
  getGuide,
  getTrackList,
  getMySong,
  getFileset,
} from '../../../store/myMusic/myMusic.selectors';

const mapStateToProps = (state, ownProps) => {
  const songCuid = path(['match', 'params', 'songCuid'], ownProps);
  const guideCuid = path(['match', 'params', 'guideCuid'], ownProps);
  const guide = getGuide(songCuid, guideCuid, state) || newGuide();
  const song = getMySong(songCuid, state);
  const { filesetCuid } = song;
  const fileset = getFileset(filesetCuid, state);
  const { fileRecords = {} } = fileset;

  return {
    songCuid,
    guide,
    trackList: getTrackList(songCuid, state),
    fileRecords,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickSave: (songCuid, guide, selectedTracks) => {
    dispatch(addTracksToGuide(songCuid, guide.guideCuid, selectedTracks));
    dispatch(addGuide(songCuid, guide));
    ownProps.history.goBack();
  },
});

export default withAppContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditGuide),
);
