import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Arch from '../Arch/Arch';
import Screenshot from '../Screenshot/Screenshot';
import TitleDescription from '../TitleDescription/TitleDescription';

import './HomepageSection.css';

export default function HomepageSection({
  title,
  description,
  isRowReversed = false,
  color,
  image,
}) {
  return (
    <div
      className={classnames('HomepageSection', {
        'HomepageSection--reversed': isRowReversed,
      })}>
      <div className="HomepageSection_ContentContainer">
        <TitleDescription title={title} description={description} />
      </div>
      <div className="HomepageSection_ImageContainer">
        <Screenshot variant={image} color="red" />

        <div
          className={classnames('HomepageSection_ArchContainer', {
            'HomepageSection_ArchContainer--reversed': isRowReversed,
          })}>
          <Arch color={color} />
        </div>
      </div>
    </div>
  );
}

HomepageSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isRowReversed: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
