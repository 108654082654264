import { connect } from 'react-redux';
import MyAccountPage from './MyAccountPage';
import { getEmailAddress } from '../../../store/user/user.selectors';
import {
  getBillingDetails,
  isFetchingSubscription,
} from '../../../store/billing/billing.selectors';
import { showModal } from '../../../store/modal/modal.actions';
import { fetchSubscriptionThunk } from '../../../store/billing/billing.thunks';
import { logoutThunk } from '../../../store/user/user.thunks';
import { privacyPolicyRoute } from '../../routes.js';
import { push } from 'connected-react-router';
const mapStateToProps = state => {
  return {
    email: getEmailAddress(state),
    subscription: getBillingDetails(state),
    isFetchingSubscription: isFetchingSubscription(state),
  };
};

const mapDispatchToProps = dispatch => ({
  doFetchSubscription: () => {
    dispatch(fetchSubscriptionThunk());
  },
  doProClick: () => {
    dispatch(showModal('STRIPE_MODAL'));
  },
  // onClickOpenStripe: source => {
  //   dispatch(showModal('STRIPE_MODAL', source));
  // },
  doBillingDetailsClick: () => {
    dispatch(push('/my-account/billing'));
  },
  onPrivacyClick: () => {
    dispatch(privacyPolicyRoute());
  },
  onClickLogout: () => {
    dispatch(logoutThunk());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAccountPage);
