import styled from 'styled-components';

export const Plans = styled.div`
  font-family: Platform Web;
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-flow: row wrap;
`;

export const Card = styled.div`
  max-width: 350px;
  padding: 1em;
`;

export const Image = styled.div`
  max-height: 210px;
`;

export const Button = styled.div`
  margin: 0 auto;
  padding: 1em;
`;

export const Price = styled.div`
  min-height: 140px;
  padding: 1em;
`;

export const Benefits = styled.div`
  padding: 0.5em;
  min-height: 50px;
`;
