import styled from 'styled-components';
import { choirpal_grey_light } from '../../Theme/colors';

export const SuggestionsContainer = styled.div`
  font-size: 1.5em;
  text-align: left;
  color: palevioletred;
`;
export const InputContainer = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;

export const SuggestionsInputRoot = styled.div`
  /*max-width: 375px;*/
  text-align: center;
  align-items: start;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${choirpal_grey_light};
  width: 100%;
  margin-bottom: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`;
