import styled from 'styled-components';

export const IconSVG = styled.span.attrs(({ testId }) => ({
  'data-testid': testId,
}))`
  flex: 0 0 ${props => `${props.width}px`};
  display: flex;
  position: relative;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};

  svg {
    width: ${props => `${props.width}px`};
    height: ${props => `${props.height}px`};
  }
`;
