import React from 'react';
import PropTypes from 'prop-types';
// import * as S from './Avatar.styled';
import { makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import avatarFallback from '../../Theme/Images/android-red-icon-192x192.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function Avatar(props) {
  const classes = useStyles();

  const { alt, src } = props;
  const opacity = src ? 1 : 0.5;

  return (
    <MuiAvatar
      alt={alt}
      src={src || avatarFallback}
      className={classes.large}
      style={{ opacity }}
    />
  );
}

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  size: PropTypes.string,
};

export default Avatar;
