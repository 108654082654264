import MyAccountPageConnected from './ui/Account/MyAccountPage/MyAccountPageConnected';
import FeedbackPage from './ui/Contact/Feedback/FeedbackPage';
import DebugPage from './ui/Debug/DebugPage';
import CreateGroupConnected from './ui/Groups/CreateGroup/CreateGroupConnected';
import EditGroupConnected from './ui/Groups/EditGroup/EditGroupConnected';
import GroupViewConnected from './ui/Groups/GroupView/GroupViewConnected';
import GroupsListConnected from './ui/Screens/GroupsList/GroupsListConnected';
import AddMediaConnected from './ui/Studio/AddMedia/AddMediaConnected';
import EditGuideConnected from './ui/Studio/EditGuide/EditGuideConnected';
import MySongsConnected from './ui/Studio/MySongs/MySongsConnected';
import ShareSongConnected from './ui/Studio/ShareSong/ShareSongConnected';

export const protectedRoutes = {
  '/': GroupsListConnected,
  '/groups': GroupsListConnected,
  '/groups/:groupCuid': GroupViewConnected,
  '/group/create': CreateGroupConnected,
  '/group/:groupCuid/edit': EditGroupConnected,

  '/my-music': MySongsConnected,
  // '/my-music/:songCuid/edit': EditSongDetailsConnected,
  '/my-music/:songCuid/song/details': AddMediaConnected,
  '/my-music/:songCuid/share': ShareSongConnected,
  // '/my-music/:songCuid/addMedia': AddMediaConnected,
  '/my-music/:songCuid/guide/:guideCuid': EditGuideConnected,

  '/feedback': FeedbackPage,
  '/my-account': MyAccountPageConnected,

  '/debug': DebugPage,
};
