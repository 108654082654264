import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M87.388,45.109
    c16.508,0,29.891,13.382,29.891,29.891s-13.383,29.891-29.891,29.891"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M106.852,97.677
    C105.506,125.206,101.778,145,97.388,145c-5.524,0-10-31.342-10-70s4.476-70,10-70c4.391,0,8.118,19.794,9.464,47.324"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M97.388,145l-45-30h-23c-6.627,0-12-5.373-12-12v-3
    H9.245c-3.787,0-6.857-3.358-6.857-7.5v-35c0-4.142,3.07-7.5,6.857-7.5h8.143v-3c0-6.627,5.373-12,12-12h23h0.124L97.388,5"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="52.388"
      y1="35"
      x2="52.388"
      y2="85"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="137.643"
      y1="45.537"
      x2="197.612"
      y2="105.506"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="137.643"
      y1="105.506"
      x2="197.612"
      y2="45.537"
    />
  </g>,
  200,
  150,
);
