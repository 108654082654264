import styled from 'styled-components';

export const MediaPicker = styled.div`
  display: flex;
`;

export const AddTrackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: white;
  width: 100%;
  font-size: 24px;
`;
