import { API_VERSION_SUCCESS } from './app.events';

const initialState = {
  isTextFieldFocused: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_VERSION_SUCCESS: {
      const { response } = action;
      return { ...state, ...response };
    }
    default:
      return state;
  }
};
