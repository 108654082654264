import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GroupsListItem from '../../Groups/GroupsListItem/GroupsListItem';
import RecentlyPlayedSongsConnected from '../../Studio/RecentlyPlayedSongs/RecentlyPlayedSongsConnected';
import Loading from '../../Layout/Loading/Loading';
import * as S from './GroupsList.styled';
import SectionHeader from '../../Layout/SectionHeader/SectionHeader';
import H2 from '../../Typography/Heading/H2';
import Trio from '../../Theme/Trio/Trio';

import List from '@material-ui/core/List';
import { isNative } from '../../../util/envUtil';

function GroupsList({
  cognitoId,
  onClick,
  doFetchGroups,
  groupsList,
  hasLoginFailed,
  isFetchingGroups,
  hasViewedWelcomeHint,
  onClickShowWelcomeHint,
}) {
  /* eslint-disable */
  useEffect(() => {
    if (!hasViewedWelcomeHint) {
      onClickShowWelcomeHint();
    }
    cognitoId && doFetchGroups();
  }, []);
  /* eslint-enable */

  if (isFetchingGroups && groupsList && !groupsList.length) {
    return <Loading />;
  }

  const renderListItem = (groupMeta, index) => {
    const { groupCuid, groupName, imageUrl } = groupMeta;
    return (
      <GroupsListItem
        key={groupCuid}
        groupCuid={groupCuid}
        groupName={groupName}
        onClick={() => onClick(groupCuid)}
        imageUrl={imageUrl}
      />
    );
  };

  const renderList = groupList => {
    return (
      <List>
        {groupList.map(renderListItem)}
        {hasLoginFailed && <li>oops, login has failed</li>}

        {!groupList.length && (
          <S.NoGroups>
            <H2 size="small">Add your first group</H2>

            <Trio size="xsmall" />
          </S.NoGroups>
        )}
      </List>
    );
  };
  return (
    <S.GroupsList>
      <RecentlyPlayedSongsConnected />
      <SectionHeader title="Groups" />
      {renderList(groupsList)}
      {isNative() ? <S.ListPadding native={true} /> : <S.ListPadding />}
    </S.GroupsList>
  );
}

GroupsList.propTypes = {
  cognitoId: PropTypes.string,
  hasLoginFailed: PropTypes.bool,
  isFetchingGroups: PropTypes.bool,
  groupsList: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  doFetchGroups: PropTypes.func.isRequired,
  hasViewedWelcomeHint: PropTypes.bool,
  onClickShowWelcomeHint: PropTypes.func,
};

export default GroupsList;
