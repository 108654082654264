import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isPrd, isStaging } from '../../../util/envUtil';
import Hero from '../../Theme/Hero/Hero';
import HeroTitleDescription from '../HeroTitleDescription/HeroTitleDescription';
import RegisterInterest from '../RegisterInterest/RegisterInterest';
import HeroGrid from '../HeroGrid/HeroGrid';
import HomepageSection from '../HomepageSection/HomepageSection';
import LoginButtonsConnected from '../LoginButtons/LoginButtonsConnected';
import PlansConnected from '../../Account/Plans/PlansConnected';
import * as S from './ComingSoon.styled';
import { isBillingEnabled } from '../../../util/featureToggle';

const lang = {
  playerDescription: `
    The ChoirPal player is designed to make practice better.
    Listen to your part on its own, or your part with the choir in the background. Or listen to the full choir.
    Navigate songs easily with our song maps, allowing you to skip to a specific phrase or verse quickly.
  `,
  playlistsDescription: `
    Create shared playlists that your choir can stream directly to their device of choice, Phone, Tablet or Laptop.
  `,
  uploadTracksDescription: `
    Upload practice tracks with your choir easily or purchase high quality learning tracks from our Marketplace.
    You can sell your own tracks on the Marketplace too.
  `,
};

export default function ComingSoon({ classes }) {
  return (
    <React.Fragment>
      {!isPrd() && !isStaging() && false && <LoginButtonsConnected />}
      <HeroGrid
        HeroTitle={HeroTitleDescription}
        HeroImage={Hero}
        HeroRegister={RegisterInterest}
      />
      <div />
      <HomepageSection
        title="Audio Player"
        description={lang.playerDescription}
        isRowReversed={true}
        color="red"
        image="player"
      />

      <HomepageSection
        title="Shared Playlists"
        description={lang.playlistsDescription}
        isRowReversed={false}
        color="yellow"
        image="playlist"
      />

      <HomepageSection
        title="Upload Tracks"
        description={lang.uploadTracksDescription}
        isRowReversed={true}
        color="pink"
        image="songUpload"
      />
      {isBillingEnabled() && (
        <Fragment>
          <S.ComingSoon_Title>Pricing</S.ComingSoon_Title>
          <PlansConnected />
        </Fragment>
      )}
    </React.Fragment>
  );
}

ComingSoon.propTypes = {
  classes: PropTypes.array,
};
