import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import H3 from '../../Typography/Heading/H3';

import * as S from './SuggestionsInput.styled';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    color: '#FFF',
  },
  chip: {
    margin: theme.spacing(1),
    color: '#FFF',
  },
});

function SuggestionsInput({
  onChange,
  initialValue = '',
  availableSuggestions,
  label,
  required,
  size = 'large',
  classes,
}) {
  const [enteredText, setEnteredText] = useState(initialValue);
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef(null);

  function handleAddClick() {
    setEnteredText('');
    setIsEditMode(true);
    setTimeout(() => inputRef.current.focus(), 0);
  }

  function handleChange(val = '') {
    setEnteredText(val.trim().toLowerCase());
    onChange(val.trim().toLowerCase());
  }

  function handleChipClick(suggestion) {
    setEnteredText(suggestion.toLowerCase());
    onChange(suggestion.trim().toLowerCase());
  }

  return (
    <S.SuggestionsInputRoot>
      <S.TitleContainer>
        <H3 fontSize="small">{label}</H3>
        <IconButton
          color="secondary"
          aria-label="Open Sheet Music"
          onClick={handleAddClick}>
          <Add fontSize="small" />
        </IconButton>
      </S.TitleContainer>
      <S.Divider />

      <S.InputContainer isVisible={isEditMode}>
        <TextField
          style={{ minWidth: '310px' }}
          label={label}
          placeholder={label}
          required={required}
          variant={size === 'large' ? 'outlined' : 'standard'}
          margin="dense"
          helperText=""
          value={enteredText}
          inputRef={inputRef}
          onChange={({ target }) => {
            handleChange(target.value);
          }}
        />
      </S.InputContainer>
      <S.SuggestionsContainer>
        {availableSuggestions.map(suggestion => (
          <Chip
            key={suggestion}
            style={{ marginTop: 5, marginRight: 5 }}
            label={suggestion.toLowerCase()}
            color={
              enteredText === suggestion.toLowerCase() ? 'primary' : 'secondary'
            }
            onClick={foo => handleChipClick(suggestion.toLowerCase())}
            className={classes.chip}
          />
        ))}
      </S.SuggestionsContainer>
    </S.SuggestionsInputRoot>
  );
}

SuggestionsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool,
  availableSuggestions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SuggestionsInput);
