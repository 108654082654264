import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import * as S from './CouponInput.styled';

export const CouponInput = ({
  coupon,
  couponError,
  doCouponClick,
  isFetchingCoupon,
  doClearCouponError,
  has_trialed,
}) => {
  const [couponCode, setCouponCode] = useState('');
  const onClickApply = couponCode => {
    doCouponClick(couponCode);
  };
  const onCouponFocus = () => {
    doClearCouponError();
  };
  return (
    <Fragment>
      {Object.keys(coupon).length === 0 ? (
        <S.CouponCode>
          <TextField
            style={{
              width: '50%%',
              marginTop: 10,
            }}
            type="text"
            label="Coupon Code"
            placeholder="15Off"
            helperText="This will be applied on payment"
            size="small"
            onChange={({ target }) =>
              setCouponCode(target.value.trim().toUpperCase())
            }
            onFocus={() => onCouponFocus()}
          />

          {couponCode && (
            <Button
              style={{ margin: 20, width: '20%' }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => onClickApply(couponCode)}
              disabled={!couponCode}>
              {isFetchingCoupon ? <CircularProgress size={24} /> : <>Apply</>}
            </Button>
          )}
          <Fragment>
            {couponError ? (
              <Alert
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1rem',
                }}
                severity="warning">
                {couponError}
              </Alert>
            ) : (
              <Fragment>
                {!has_trialed && (
                  <Alert
                    severity="success"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1rem',
                    }}>
                    14-day free trial applied!
                  </Alert>
                )}
              </Fragment>
            )}
          </Fragment>
        </S.CouponCode>
      ) : (
        <S.CouponCode>
          <Alert
            severity="success"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1rem',
            }}>
            <b>{coupon.name}</b> coupon of {coupon.percentOff} % off has been
            applied.
          </Alert>
        </S.CouponCode>
      )}
    </Fragment>
  );
};

CouponInput.propTypes = {
  coupon: PropTypes.object,
  couponError: PropTypes.string,
  doCouponClick: PropTypes.func,
  doClearCouponError: PropTypes.func,
  isFetchingCoupon: PropTypes.bool,
  has_trialed: PropTypes.bool,
};
