import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as S from './TopSticky.styled';

export default function TopSticky({ children, scrollingElem = {} }) {
  // const [isVisible, setIsVisible] = useState(true);
  // const [height, setHeight] = useState(0);
  const stickyElem = useRef(null);

  /* eslint-disable */

  //   useEffect(() => {
  //     const bounceOffset = 60;
  //
  //     let previousScrollPosition = 0;
  //     let isScrollingForward = true;
  //     let wasScrollingForward = true;
  //     let inflectionPoint = 0;
  //     let drift = 0;
  //     let isIn = true;
  //     setHeight(stickyElem.current.clientHeight);
  //
  //     function handleScroll() {
  //       const { scrollTop } = scrollingElem.current || 0;
  //
  //       isScrollingForward = scrollTop > previousScrollPosition;
  //
  //       if (isScrollingForward !== wasScrollingForward) {
  //         inflectionPoint = scrollTop;
  //       }
  //       drift = Math.abs(scrollTop - inflectionPoint);
  //
  //       if (isScrollingForward && drift > height + bounceOffset) {
  //         isIn = false;
  //       }
  //
  //       if (!isScrollingForward && drift > height + bounceOffset) {
  //         isIn = true;
  //       }
  //
  //       if (scrollTop < height) {
  //         isIn = true;
  //       }
  //       setIsVisible(isIn);
  //
  //       previousScrollPosition = scrollTop;
  //       wasScrollingForward = isScrollingForward;
  //     }
  //
  //     scrollingElem.current.addEventListener('scroll', handleScroll);
  //
  //     return () => {
  //       if (scrollingElem.current) {
  //         scrollingElem.current.removeEventListener('scroll', handleScroll);
  //       }
  //     };
  //   }, [children]);
  /* eslint-enable */

  return (
    <React.Fragment>
      <div style={{ minHeight: 0 }} className="TopSticky__TopPadder" />
      <S.TopSticky ref={stickyElem} isIn={true}>
        {children}
      </S.TopSticky>
    </React.Fragment>
  );
}

TopSticky.propTypes = {
  children: PropTypes.object,
  scrollingElem: PropTypes.object,
};
