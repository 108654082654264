import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createMiddleware, createMetaReducer } from 'redux-beacon';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import GoogleAnalyticsGtag from '@redux-beacon/google-analytics-gtag';

import { reducers } from './initReducers';
import { BlobMiddleware } from './middleware/BlobMiddleware';
import { AudioMiddleware } from './middleware/AudioMiddleware';
import { LocalStorageMiddleware } from './middleware/LocalStorageMiddleware';
import { StudioMiddleware } from './middleware/StudioMiddleware';
import { PreloaderMiddleware } from './middleware/PreloaderMiddleware';
import { eventsMap, trackingId } from '../analytics/analyticsEvent';
import { getPersistedState } from './persistedStateUtil';

const ga = GoogleAnalyticsGtag(trackingId);

const gaMiddleware = createMiddleware(eventsMap, ga);
const gaMetaReducer = createMetaReducer(eventsMap, ga);

export default function(history) {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const middleware = [
    routerMiddleware(history),
    BlobMiddleware,
    LocalStorageMiddleware,
    AudioMiddleware,
    StudioMiddleware,
    PreloaderMiddleware,
    gaMiddleware,
    thunk,
  ];

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  const rootReducer = combineReducers({
    ...reducers,
    gaMetaReducer,
    router: connectRouter(history),
  });
  return createStore(rootReducer, getPersistedState(), enhancer);
}
