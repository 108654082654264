import React from 'react';
import PropTypes from 'prop-types';

import TwitterIconInverse from './Twitter-Icon--inverse.svg';
import FacebookIconInverse from './Facebook-Icon--inverse.svg';
import InstagramIconInverse from './Instagram-Icon--inverse.svg';

import TwitterIcon from './Twitter-Icon.svg';
import FacebookIcon from './Facebook-Icon.svg';
import InstagramIcon from './Instagram-Icon.svg';

import './SocialMediaLinks.css';

export default function SocialMediaLinks({ invert }) {
  return (
    <div className="SocialMediaLinks">
      <div className="SocialMediaLinks_Instagram">
        <a href="https://www.instagram.com/choirpalapp/" target="_window">
          <img
            src={invert ? InstagramIconInverse : InstagramIcon}
            style={{ width: '20px' }}
            alt="Instagram"
          />
        </a>
      </div>
      <div className="SocialMediaLinks_Facebook">
        <a href="https://www.facebook.com/choirpalapp/" target="_window">
          <img
            src={invert ? FacebookIconInverse : FacebookIcon}
            style={{ width: '20px' }}
            alt="Facebook"
          />
        </a>
      </div>
      <div className="SocialMediaLinks_Twitter">
        <a href="https://www.twitter.com/palchoir/" target="_window">
          <img
            src={invert ? TwitterIconInverse : TwitterIcon}
            style={{ width: '20px' }}
            alt="Twitter"
          />
        </a>
      </div>
    </div>
  );
}

SocialMediaLinks.propTypes = {
  invert: PropTypes.bool,
};
