import { connect } from 'react-redux';
import { showModal } from '../../../store/modal/modal.actions';
import { hasViewedHint } from '../../../store/modal/modal.selectors';
import {
  getActiveSong,
  getActiveSongCuid,
  getMeta,
  isEditable,
} from '../../../store/myMusic/myMusic.selectors';
import {
  addPageThunk,
  addSectionThunk,
  deletePageThunk,
  deleteSectionThunk,
  nudgeSectionThunk,
} from '../../../store/myMusic/myMusic.thunks';
import {
  getGuideByTrackIndex,
  groupSections,
} from '../../../store/myMusic/song.selectors';
import {
  getActiveToolName,
  getSelectedTrackIndex,
} from '../../../store/player/player.selectors';
import { SECTIONS_TOOL } from '../../../store/player/player.tools';
import { enableTool } from '../../../store/player/player.actions';

import { asList } from '../../../store/storeUtil';
import Song from './Song';

const mapStateToProps = state => {
  const songCuid = getActiveSongCuid(state);
  const song = getActiveSong(state);
  const selectedTrackIndex = getSelectedTrackIndex(state);
  const guide = getGuideByTrackIndex(selectedTrackIndex, song);
  const { sections = {} } = guide;
  const sectionList = asList(sections);
  sectionList.forEach((section, index) => (section.indexInSong = index));
  const sectionsByPage = groupSections(sectionList);
  const activeToolName = getActiveToolName(state);

  // const selectedTrack = getSelectedTrackByIndex(selectedTrackIndex, state);
  return {
    songCuid,
    meta: getMeta(state),
    sections: sectionList,
    sectionsByPage,
    config: song,
    isEditable: isEditable(songCuid, state),
    activeToolName,
    hasViewedWelcomeHint: hasViewedHint('EDITOR_HINT', state),
  };
};

const mapDispatchToProps = {
  onChangeLyrics: addSectionThunk,
  onChangePageTitle: addPageThunk,
  onClickDeleteSection: deleteSectionThunk,
  onClickAddPage: addPageThunk,
  onClickDeletePage: deletePageThunk,
  onClickNudgeSection: nudgeSectionThunk,
  onClickAddSection: addSectionThunk,
  onClickShowEditorHint: () => showModal('EDITOR_HINT'),
  onClickStartEditing: () => enableTool(SECTIONS_TOOL),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Song);
