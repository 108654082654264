import styled from 'styled-components';

export const ModalContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 100;
`;

export const Modal = styled.div`
  width: 100%;
  min-width: auto;
  max-width: auto;
  height: 100%;
  min-height: auto;
  max-height: auto;
  background-color: white;
  will-change: transform;
  transform: ${props =>
    props.isVisible ? 'translateY(0%)' : 'translateY(100%)'};
  transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
  pointer-events: initial;
  overflow-y: auto;
`;
