import React from 'react';
import PropTypes from 'prop-types';
import heroSvg from './Hero.svg';

import * as S from './Hero.styled';

export default function Hero({ classes }) {
  return (
    <S.Hero>
      <img className={classes} src={heroSvg} alt="Choirpal Hero" />
    </S.Hero>
  );
}

Hero.propTypes = {
  classes: PropTypes.array,
};
