import { connect } from 'react-redux';
import {
  getMySong,
  getMySongs,
  isFetchingSongList,
} from '../../../store/myMusic/myMusic.selectors';
import { fetchMySongsThunk } from '../../../store/myMusic/myMusic.thunks';
import { playSongThunk } from '../../../store/player/player.thunks';
import { getCognitoId } from '../../../store/user/user.selectors';
import withAppContext from '../../../withAppContext';
import { songDetailsRoute } from '../../routes';
import MySongs from './MySongs';

const mergeProps = (state, { dispatch, ownProps }) => {
  const { history } = ownProps;

  const onClickSong = songCuid => {
    const song = getMySong(songCuid, state) || {};
    const numTracks = Object.keys(song.tracks).length;
    if (numTracks > 0) {
      playSongThunk(
        songCuid,
        'MY_MUSIC_PLAYLIST',
        undefined,
        state,
        dispatch,
        history,
      );
    } else {
      dispatch(songDetailsRoute(songCuid));
    }
  };

  return {
    cognitoId: getCognitoId(state),
    songList: Object.values(getMySongs(state)),
    isFetchingSongList: isFetchingSongList(state),
    onClickSong: onClickSong,
    onClickEdit: songCuid => dispatch(songDetailsRoute(songCuid)),
    doFetchSongs: () => dispatch(fetchMySongsThunk()),
    onClickIcon: onClickSong,
  };
};

export default withAppContext(
  connect(
    state => state,
    (dispatch, ownProps) => ({ dispatch, ownProps }),
    mergeProps,
  )(MySongs),
);
