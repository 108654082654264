import styled from 'styled-components';
import { choirpal_grey_dark } from '../../Theme/colors';

export const AppVersion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0.1; // iOS Cordova Hack
  color: ${choirpal_grey_dark};
  font-size: 8px;
`;

