import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M140,19.756c0-13.145-9.701-18.526-21.551-11.949
    L18.891,63.049c-11.854,6.569-11.854,17.328,0,23.904l99.559,55.248c11.85,6.562,21.551,1.19,21.551-11.963V54.39"
    />
    <line
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      x1="10"
      y1="150"
      x2="10"
      y2="0"
    />
  </g>,
  150,
  150,
);
