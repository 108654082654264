import { connect } from 'react-redux';
import PlayPause from './PlayPause';
import { getMeta } from '../../../store/myMusic/myMusic.selectors';
import AudioPlayerSubscriber from '../../Audio/AudioPlayerSubscriber/AudioPlayerSubscriber';
import AudioPlayer from '../../../webaudio/AudioPlayer';

const mapStateToProps = state => {
  return {
    songTitle: getMeta(state).title,
  };
};

const mapDispatchToProps = dispatch => ({
  onPlay: () => {
    AudioPlayer.play();
  },
  onPause: () => AudioPlayer.pause(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioPlayerSubscriber(PlayPause));
