import { TOGGLE_LOOPING, TOGGLE_MUTE } from './settings.events';

const loopingOptions = ['DEFAULT', 'LOOP_SONG'];

const initialState = {
  loopingMode: 'DEFAULT',
};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOOPING: {
      const { loopingMode } = state;
      const newIndex =
        (loopingOptions.indexOf(loopingMode) + 1) % loopingOptions.length;
      return {
        ...state,
        loopingMode: loopingOptions[newIndex],
      };
    }
    case TOGGLE_MUTE: {
      const { isMuted = false } = state;
      return {
        ...state,
        isMuted: !isMuted,
      };
    }
    default:
      return state;
  }
};
