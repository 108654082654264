import styled from 'styled-components';
import { color, fontSize } from '../design-tokens';

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  text-align: center;
  box-shadow: 0px 0px 8px ${color.red40};
  border-radius: 4px;
  box-sizing: border-box;
  padding-right: 8px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  overflow: hidden;
  font-size: 13px;
`;

export const RowContentPrimary = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 50%;
  width: 100%;
  overflow: hidden;
  /*font-weight: bold;*/
  font-size: 16px;
`;

export const RowContentSecondary = styled.div`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 50%;
  width: 100%;
  overflow: hidden;
  color: ${color.grey};
  /*font-weight: bold;*/
  font-size: ${fontSize.small};
`;

export const RowHeader = styled.div`
  height: 100%;
  width: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RowFooter = styled.div`
  height: 100%;
  width: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
