import { openSong, maximisePlayer } from './player.actions';
import { getNextSongCuid } from './player.selectors';
import AudioPlayer from '../../webaudio/AudioPlayer';
import { getTrackUrlsByCuid, getMySong } from '../myMusic/myMusic.selectors';

export const playSongThunk = (
  songCuid,
  playlistType,
  playlistCuid,
  state,
  dispatch,
  history,
  shouldMaximisePlayer = true,
) => {
  const trackUrls = getTrackUrlsByCuid(songCuid, state);
  dispatch(
    openSong(songCuid, playlistType, playlistCuid, shouldMaximisePlayer),
  );

  // Set the lockscreen song title
  const songDocument = getMySong(songCuid, state);
  const lockscreenTitle = 'ChoirPal - ' + songDocument?.meta?.songTitle;
  window.document.title = lockscreenTitle;

  // Only maximise if triggered by a user click
  if (shouldMaximisePlayer) {
    dispatch(maximisePlayer(songCuid));
  }

  AudioPlayer.loadAudio(trackUrls, () => {
    // On ended Callback
    const nextSongCuid = getNextSongCuid(
      songCuid,
      playlistType,
      playlistCuid,
      state,
    );

    // Stop if at the end of the playlist
    if (!nextSongCuid) {
      return;
    }

    if (isPlayerMaximised(history)) {
      // Remove the last song from the browser history
      // This lets the back button minimise the player instead of going to the previous song
      history.goBack();
      setTimeout(() => dispatch(maximisePlayer(nextSongCuid)), 0);
    }

    playSongThunk(
      nextSongCuid,
      playlistType,
      playlistCuid,
      state,
      dispatch,
      history,
      false,
    );
  });
  AudioPlayer.play();
};

const isPlayerMaximised = history => history?.location?.hash?.length >= 10;
