import { createMuiTheme } from '@material-ui/core/styles';
import { color } from '../design-tokens';

export default createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: color.red100,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#fff',
    },
    secondary: {
      light: color.blue20,
      main: color.blue60, // '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: color.yellow80, // #ffcc00',
    },
    // error: will use the default color,
  },
  typography: {
    useNextVariants: true,
  },
});
