import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import iconSvg from './white-icon.svg';

export default function ChoirpalIcon({ classes, size }) {
  const width = size === 'large' ? 250 : 120;
  return (
    <Link
      className="ChoirpalIcon"
      style={{ WebkitTapHighlightColor: 'transparent' }}
      to={{
        pathname: '/',
        search: '',
        hash: '',
        state: { fromChoirpalIcon: true },
      }}>
      <img
        className={classes}
        style={{ width, marginTop: 0, marginBottom: 0 }}
        src={iconSvg}
        alt="Choirpal ChoirpalIcon"
      />
    </Link>
  );
}

ChoirpalIcon.propTypes = {
  classes: PropTypes.array,
  size: PropTypes.string,
};
