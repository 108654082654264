import styled from 'styled-components';
import { choirpal_grey_light } from '../../Theme/colors';

export const SectionHeader = styled.div`
  display: flex;
  padding: 0px 16px;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid ${choirpal_grey_light};
  width: 100%;
`;

export const NumSongs = styled.div`
  margin-left: 8px;
`;

export const Heading = styled.div``;
