import styled from 'styled-components';

export const ShareSong = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 650px;
  padding: 16px;
  box-sizing: border-box;
`;

export const Groups = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const ShareButton = styled.div`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;
