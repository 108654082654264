import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SongConnected from '../Song/SongConnected';
import PlayerTitleBarConnected from '../PlayerTitleBar/PlayerTitleBarConnected';
import ControlsConnected from '../Controls/ControlsConnected';
import AudioPlayer from '../../../webaudio/AudioPlayer';
import * as S from './Player.styled';
import { isTextFieldFocused } from '../../../util/domUtil';

function Player(props) {
  const { onLoad } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const seek = position => {
    AudioPlayer.seek(position / 1000);
  };

  return (
    <S.Player>
      <PlayerTitleBarConnected isMaximised={true} history={props.history} />

      <S.SongContainer style={{ height: window.innerHeight - 220 }}>
        <SongConnected seek={seek} />
      </S.SongContainer>
      {!isTextFieldFocused() && (
        <S.ControlsContainer>
          <ControlsConnected />
        </S.ControlsContainer>
      )}
    </S.Player>
  );
}

Player.propTypes = {
  openSong: PropTypes.func.isRequired,
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default Player;
