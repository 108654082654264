import styled from 'styled-components';

export const AppViewport = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: thin;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 1100px;
  /* TopSticky offset */
  padding-top: 44px;

  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;
