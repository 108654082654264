import React from 'react';
import PropTypes from 'prop-types';
import './PageError.css';

export default function PageError({ children }) {
  return (
    <div className="PageError">
      <div className="PageError__Content">{children}</div>
    </div>
  );
}

PageError.propTypes = {
  children: PropTypes.object,
};
