import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

export const SMALL_BREAKPOINT = 320;
export const MEDIUM_BREAKPOINT = 720;
export const LARGE_BREAKPOINT = 1024;

const getDeviceConfig = width => {
  const isSmallOrBelow = width < MEDIUM_BREAKPOINT;
  const isMediumOrAbove = !isSmallOrBelow;
  let size;
  if (width < SMALL_BREAKPOINT) {
    size = 'xs';
  } else if (width >= SMALL_BREAKPOINT && width < MEDIUM_BREAKPOINT) {
    size = 'sm';
  } else if (width >= MEDIUM_BREAKPOINT && width < LARGE_BREAKPOINT) {
    size = 'md';
  } else if (width >= LARGE_BREAKPOINT) {
    size = 'lg';
  }
  return { isMediumOrAbove, size };
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth),
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function() {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
