import React from 'react';
import PropTypes from 'prop-types';
import * as S from './AppVersion.styled';

function AppVersion({ version = '', apiVersion = '' }) {
  return (
    <S.AppVersion>
      <div>{`ui ${version} api ${apiVersion}`}</div>
      <div>{window.location.hostname}</div>
    </S.AppVersion>
  );
}

AppVersion.propTypes = {
  version: PropTypes.string,
  apiVersion: PropTypes.string,
};

export default AppVersion;
