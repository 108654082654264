import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { cp2_pink } from '../../Theme/colors';
import * as S from './AddButton.styled';
import IconAdd from '../../Theme/Icons/IconAdd';

const styles = {
  root: {
    width: '100%',
    color: 'orange',
    backgroundColor: cp2_pink,
    colorPrimary: 'brown',
  },
};

function AddButton({ onAddClick, text }) {
  return (
    <S.AddButton>
      <Fab
        variant={text ? 'extended' : 'round'}
        color="primary"
        aria-label="Add"
        onClick={() => onAddClick()}>
        <IconAdd color="white" />
        {text && `\u00A0 ${text}`}
      </Fab>
    </S.AddButton>
  );
}

AddButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  onAddClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddButton);
