import { connect } from 'react-redux';
import ConfirmSignup from './ConfirmSignup';
import { getErrorMsg } from '../../../store/user/user.selectors';
import { registerConfirmThunk } from '../../../store/user/user.thunks';

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
});

const mapDispatchToProps = dispatch => ({
  onConfirmSignup: (username, confirmationCode) => {
    dispatch(registerConfirmThunk(username, confirmationCode));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmSignup);
