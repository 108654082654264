import styled from 'styled-components';
import { color, fontSize } from '../../design-tokens';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopToolbar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 8px;
  align-items: center;
`;

export const StudioToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: -16px;
  right: 16px;

  pointer-events: none;

  * {
    pointer-events: auto;
  }
`;

export const BottomToolbar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100px;
  pointer-events: none;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 4px;
  min-width: 48px;
`;

export const SectionFooter = styled.div`
  height: 15%;
  display: flex;
  justify-content: space-between;
  font-size: 0.5em;
  margin-bottom: 0.5rem;
`;

export const SectionPosition = styled.div`
  font-size: 0.6rem;
  margin-left: 0.3rem;
`;

export const SectionInput = styled.input`
  margin: 10px 8px;
  /*border: 0px;*/
  border: 1px solid orange;
  text-align: center;
  background: transparent;
  font-size: ${fontSize.medium};
`;

export const EditButton = styled.div`
  /* position: absolute; */
  /* right: -20px; */
  min-width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DisableEditing = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;

const boxShadow = {
  standout: `box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`,
};

export const BottomToolbarRow = styled.div`
  /* min-height: 55px; */
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  /* align-items: center; */
  pointer-events: none;

  * {
    pointer-events: auto;
  }
`;

export const BreakSectionButton = styled.div`
  ${boxShadow.standout} /* z-index: 1; */
  /* margin-top: 6px; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
`;

export const UndoBreakSection = styled.div`
  ${boxShadow.standout} /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
`;

export const PageBreak = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: -8px;
  /* font-size: 8px; */
`;

export const PageBreakDivider = styled.div`
  margin-left: 16px;
  height: 1px;
  width: 50%;
  background-color: ${color.grey};
`;

export const SectionContent = styled.div`
  background-color: ${props => (props.isActive ? color.red40 : color.white)};
  color: ${props => (props.isActive ? color.darkGrey : color.darkGrey)};
  /* width: ${props => `${props.width}%`}; */
  width: 100%;
  height: 55px;
  padding: 0px 0px 0px;
  margin: 10px 0px 10px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 0px 8px ${color.red40};

  font-family: 'Platform Web';
  position: relative;
  cursor: pointer;
`;

export const SectionBody = styled.div`
  color: ${props => (props.isActive ? color.darkGrey : color.grey)};
  font-weight: ${props => (props.isActive ? 400 : 300)};
  font-size: ${fontSize.medium};
  height: 85%;
  margin-top: 10px;
`;

export const Caret = styled.div.attrs(props => ({
  style: {
    left: props.offsetPercent + '%',
  },
}))`
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: ${color.red100};
`;

export const CaretCut = styled.div`
  position: absolute;
  bottom: -16px;
  left: -8px;
  /* margin-top: 6px; */
`;

export const DeleteSection = styled.div`
  z-index: 1;
  position: absolute;
  margin-top: 6px;
  bottom: 48px;
  left: -44px;
`;

export const LeftSpacer = styled.div`
  position: relative;
  min-width: 32px;
`;

export const BreakLine = styled.div`
  position: absolute;
  /* left: 66px; */
  left: 0px;
  height: 70px;
  bottom: 5px;
  width: 1px;
  background-color: ${color.red100};
`;

export const Nudge = styled.div`
  position: absolute;
  top: -16px;
  left: -47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NudgeBack = styled.div`
  /* height: 48px; */
  min-width: 48px;
`;

export const NudgeForward = styled.div`
  /* height: 48px; */
  min-width: 48px;
`;

export const NudgeText = styled.div`
  font-size: 12px;
  width: 24px;
`;
