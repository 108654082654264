import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import SongListItem from '../SongListItem/SongListItem';
import EmptyListPlaceholder from '../../Layout/EmptyListPlaceholder/EmptyListPlaceholder';
import * as S from './MySongs.styled';
import Loading from '../../Layout/Loading/Loading';
import { isNative } from '../../../util/envUtil';

function MySongs(props) {
  const {
    cognitoId,
    onClickSong,
    onClickEdit,
    onClickIcon,
    songList,
    isFetchingSongList,
    doFetchSongs,
  } = props;

  /* eslint-disable */
  useEffect(() => {
    cognitoId && doFetchSongs();
    return () => {};
  }, [cognitoId]);
  /* eslint-enable */

  if (isFetchingSongList && songList && !songList.length) {
    return <Loading />;
  }

  const handleIconClick = songCuid => {
    onClickIcon(songCuid);
  };

  const renderListItem = (song, index) => {
    const { songCuid } = song;
    const { songTitle, writtenBy, recordedBy } = song.meta;
    return (
      <SongListItem
        key={songCuid}
        songCuid={songCuid}
        songTitle={songTitle}
        writtenBy={writtenBy}
        recordedBy={recordedBy}
        onClickSong={() => onClickSong(songCuid)}
        onClickEdit={() => onClickEdit(index)}
        onClickIcon={() => handleIconClick(songCuid)}
        isEditable={true}
      />
    );
  };
  return (
    <S.MySongs>
      <List>{songList.map(renderListItem)}</List>
      {!songList.length && (
        <EmptyListPlaceholder>Add your first song</EmptyListPlaceholder>
      )}
      {isNative() ? <S.ListPadding native={true} /> : <S.ListPadding />}
    </S.MySongs>
  );
}

MySongs.propTypes = {
  cognitoId: PropTypes.string,
  songList: PropTypes.array,
  isFetchingSongList: PropTypes.bool,
  onClickSong: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickIcon: PropTypes.func.isRequired,
  doFetchSongs: PropTypes.func.isRequired,
};

export default MySongs;
