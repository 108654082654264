import styled from 'styled-components';
import { isNative } from '../../../util/envUtil';

export const Player = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ControlsContainer = styled.div`
  bottom: 0px;
  position: ${isNative() ? 'fixed' : 'relative'};
  width: 100%;
`;
export const SongContainer = styled.div`
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 400px;
`;
