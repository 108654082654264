import { connect } from 'react-redux';
import Register from './Register';
import {
  getErrorMsg,
  getTempUser,
  isPostingRegistrationDetails,
} from '../../../store/user/user.selectors';
import { hideError } from '../../../store/user/user.actions';
import {
  registerThunk,
  doGoogleLogin,
  doFacebookLogin,
} from '../../../store/user/user.thunks';

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
  newUser: getTempUser(state),
  isPostingRegistrationDetails: isPostingRegistrationDetails(state),
});

const mapDispatchToProps = dispatch => ({
  cognitoRegister: (username, password) => {
    dispatch(registerThunk(username, password));
  },
  onGoogleLogin: () => {
    dispatch(doGoogleLogin());
  },
  onFacebookLogin: () => {
    dispatch(doFacebookLogin());
  },
  hideErrorAction: () => {
    dispatch(hideError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Register);
