import React from 'react';
import PropTypes from 'prop-types';
import * as S from './EmptyListPlaceholder.styled';
import H2 from '../../Typography/Heading/H2';
import Trio from '../../Theme/Trio/Trio';

function EmptyListPlaceholder({ children }) {
  return (
    <S.EmptyListPlaceholder>
      <H2 size="small">{children}</H2>

      <Trio size="xsmall" />
    </S.EmptyListPlaceholder>
  );
}

EmptyListPlaceholder.propTypes = {
  children: PropTypes.node,
};

export default EmptyListPlaceholder;
