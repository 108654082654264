import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './GroupView.styled';
import SongListConnected from '../../Studio/SongList/SongListConnected';
import SectionHeader from '../../Layout/SectionHeader/SectionHeader';
import GroupHeader from '../GroupHeader/GroupHeader';
import Loading from '../../Layout/Loading/Loading';
import EmptyListPlaceholder from '../../Layout/EmptyListPlaceholder/EmptyListPlaceholder';

function GroupView(props) {
  const {
    numSongs,
    groupName,
    adminName,
    groupCuid,
    isAdmin,
    isFetchingGroupSongs,
    memberList,
    doFetchGroupSongs,
    doFetchMembers,
  } = props;

  useEffect(() => {
    doFetchGroupSongs && doFetchGroupSongs(groupCuid);
    doFetchMembers && doFetchMembers(groupCuid);
  }, [doFetchGroupSongs, doFetchMembers, groupCuid]);

  return (
    <S.GroupView>
      <GroupHeader
        groupName={groupName}
        adminName={adminName}
        groupCuid={groupCuid}
        isAdmin={isAdmin}
        memberList={memberList}
      />
      <SectionHeader title="Playlist" subTitle={numSongs + ' songs'} />
      {numSongs ? (
        <SongListConnected groupCuid={groupCuid} />
      ) : isFetchingGroupSongs ? (
        <Loading />
      ) : (
        <EmptyListPlaceholder>No songs yet</EmptyListPlaceholder>
      )}
    </S.GroupView>
  );
}

GroupView.propTypes = {
  groupName: PropTypes.string.isRequired,
  adminName: PropTypes.string.isRequired,
  groupCuid: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  isFetchingGroupSongs: PropTypes.bool,
  memberList: PropTypes.array,
  numSongs: PropTypes.number.isRequired,
  doFetchGroupSongs: PropTypes.func.isRequired,
  doFetchMembers: PropTypes.func.isRequired,
};

export default GroupView;
