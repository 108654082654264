import { saveFile } from '../../db/db';

export const BlobMiddleware = store => next => action => {
  switch (action.type) {
    case 'ADD_TRACK': {
      console.log('write blob to db');
      const { cuid, file } = action.data;
      saveFile(cuid, file);
      break;
    }
    default: {
      break;
    }
  }
  next(action);
};
