import * as E from './settings.events';
import { Auth, API } from 'aws-amplify';

export const toggleLooping = () => ({ type: E.TOGGLE_LOOPING });
export const toggleIsMuted = () => ({ type: E.TOGGLE_MUTE });

const addSettingsDB = async settings => {
  const user = await Auth.currentAuthenticatedUser();
  const attributes = await Auth.userAttributes(user);
  const sub = attributes[0];
  const { Value } = sub;
  const token = user.signInUserSession.idToken.jwtToken;
  const apiName = 'settings';
  const path = '/settings';
  const params = {
    body: {
      cognitoId: Value,
      settings: settings,
    },
    headers: {
      Authorization: token,
    },
  };
  return API.post(apiName, path, params);
};
export const addSettingsDBThunk = settings => dispatch => {
  return addSettingsDB(settings)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const readSettingsDB = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const attributes = await Auth.userAttributes(user);
  const sub = attributes[0];
  const { Value } = sub;
  const token = user.signInUserSession.idToken.jwtToken;
  const apiName = 'settings';
  const path = '/settings/' + Value;
  const params = {
    headers: {
      Authorization: token,
    },
  };
  return API.get(apiName, path, params);
};
export const readSettingsDBThunk = () => dispatch => {
  return readSettingsDB()
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};
