import React from 'react';
import FeedbackCtaButton from '../FeedbackCtaButton/FeedbackCtaButton';
import AppVersionConnected from '../../Site/AppVersion/AppVersionConnected';
import './FeedbackPage.css';

function FeedbackPage() {
  return (
    <div className="FeedbackPage">
      <h1 className="FeedbackPage__Title">Help us Help you!</h1>
      <div className="FeedbackPage__Button">
        <FeedbackCtaButton />
      </div>
      <div className="FeedbackPage__Email">
        <a className="mailto" href="mailto:kevin@choirpal.com">
          kevin@choirpal.com
        </a>
      </div>
      <div className="FeedbackPage__Version">
        <AppVersionConnected />
      </div>
    </div>
  );
}

export default FeedbackPage;
