import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/HelpOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import React from 'react';
import SongMenuConnected from '../../Studio/SongMenu/SongMenuConnected';
import PlayPauseConnected from '../PlayPause/PlayPauseConnected';
import * as S from './PlayerTitleBar.styled';
// import EditIcon from '@material-ui/icons/Edit';
// import IconButton from '@material-ui/core/IconButton';

function PlayerTitleBar({
  songCuid = '',
  isMaximised,
  isEditable,
  songTitle,
  onClickMinimise,
  onClickMaximise,
  onClickTitle,
  onClickSongDetails,
  onClickHelp,
}) {
  const handleMaxMinClick = () => {
    if (isMaximised) {
      onClickMinimise(songCuid);
    } else {
      onClickMaximise(songCuid);
    }
  };

  const handleTitleClick = () => {
    onClickTitle(songCuid);
  };

  return (
    <S.PlayerTitleBar>
      <S.MaxMinToggle isMaximised={isMaximised} onClick={handleMaxMinClick}>
        <KeyboardArrowUpIcon fontSize="small" />
      </S.MaxMinToggle>
      <S.SongTitle onClick={handleTitleClick}>{songTitle}</S.SongTitle>

      <S.RightIcon isVisible={isEditable}>
        {isMaximised ? (
          <>
            <IconButton
              // aria-owns={anchorEl ? 'SongMenu__Menu' : undefined}
              aria-haspopup="true"
              color="inherit"
              aria-label="Editor Help"
              onClick={onClickHelp}>
              <HelpIcon />
            </IconButton>
            <SongMenuConnected songCuid={songCuid} />
          </>
        ) : (
          <PlayPauseConnected size="small" />
        )}
      </S.RightIcon>
    </S.PlayerTitleBar>
  );
}

PlayerTitleBar.propTypes = {
  isMaximised: PropTypes.bool,
  isEditable: PropTypes.bool,
  songCuid: PropTypes.string,
  songTitle: PropTypes.string,
  onClickMinimise: PropTypes.func,
  onClickMaximise: PropTypes.func,
  onClickTitle: PropTypes.func,
  onClickSongDetails: PropTypes.func,
  onClickHelp: PropTypes.func,
};

export default PlayerTitleBar;
