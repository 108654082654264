import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './ShareSong.styled';
import H2 from '../../Typography/Heading/H2';
import H3 from '../../Typography/Heading/H3';
import Loading from '../../Layout/Loading/Loading';

import { withStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import AddIcon from '@material-ui/icons/Add';

// import CommentIcon from '@material-ui/icons/Comment';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 650,
    backgroundColor: theme.palette.background.paper,
  },
});

function ShareSong({
  onClickShare,
  onClickAddGroup,
  songCuid,
  songTitle,
  groupList,
  groupListShared,
  classes,
  doFetchSongGroups,
  isPosting,
}) {
  const [selectedGroups, setSelectedGroups] = useState({});

  useEffect(() => {
    if (groupListShared) {
      const updatedSelectedGroups = groupListShared.reduce(
        (accum, groupCuid) => {
          accum[groupCuid] = true;
          return accum;
        },
        {},
      );
      setSelectedGroups(updatedSelectedGroups);
    }
  }, [groupListShared]);

  useEffect(() => {
    doFetchSongGroups(songCuid);
  }, [songCuid, doFetchSongGroups]);

  const handleShareSongClick = () => {
    const selectedGroupList = Object.keys(selectedGroups).filter(
      groupCuid => selectedGroups[groupCuid],
    );
    return onClickShare(songCuid, selectedGroupList);
  };
  const handleAddGroupClick = () => {
    return onClickAddGroup();
  };

  function handleToggle(groupCuid) {
    const isToggled = selectedGroups[groupCuid];
    const out = { ...selectedGroups, [groupCuid]: !isToggled };
    setSelectedGroups(out);
  }

  function renderGroupList() {
    const groupListFiltered = groupList.filter(group => {
      return group.isAdmin || group.adminName;
    });
    return (
      <List className={classes.root}>
        {groupListFiltered.map(group => {
          const { groupCuid, groupName } = group;
          const labelId = `checkbox-list-label-${groupCuid}`;
          return (
            <ListItem
              key={groupCuid}
              role={undefined}
              dense
              button
              onClick={() => handleToggle(groupCuid)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedGroups[groupCuid] || false}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={groupName} />
              <ListItemSecondaryAction>
                {/* <IconButton edge="end" aria-label="Comments"> */}
                {/*   <CommentIcon /> */}
                {/* </IconButton> */}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <S.ShareSong>
      <H2>Share Song</H2>
      {songTitle}

      <S.Groups>
        <H3>Choose Groups</H3>
        {groupList && groupListShared ? renderGroupList() : <Loading />}
      </S.Groups>

      <Button
        style={{ marginTop: 10, width: '100%' }}
        size="large"
        color="primary"
        fullWidth={true}
        onClick={handleAddGroupClick}>
        <AddIcon
          style={{ height: '40px', width: '40px', marginRight: '12px' }}
        />
        Create Group
      </Button>

      <S.ShareButton>
        <Button
          style={{ minWidth: '180px', width: '100%' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleShareSongClick}>
          {isPosting ? (
            <CircularProgress
              size={26}
              color="secondary"
              style={{ color: 'white' }}
            />
          ) : (
            <>
              <ShareIcon className={classes.leftIcon} />
              &nbsp; Share Song
            </>
          )}
        </Button>
      </S.ShareButton>
    </S.ShareSong>
  );
}

ShareSong.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickShare: PropTypes.func.isRequired,
  onClickAddGroup: PropTypes.func.isRequired,
  doFetchSongGroups: PropTypes.func.isRequired,
  songCuid: PropTypes.string.isRequired,
  songTitle: PropTypes.string.isRequired,
  groupList: PropTypes.array,
  groupListShared: PropTypes.array,
  isPosting: PropTypes.bool,
};

export default withStyles(styles)(ShareSong);
