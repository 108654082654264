import { path } from 'ramda';
import cuid from 'cuid';
import { byId } from '../../storeUtil';
import hardcodedSongs from '../../groups/allSongs';

const hardcodedSongsByCuid = byId('songCuid', hardcodedSongs);

export default function(songIn) {
  const songCuid = path(['meta', 'songCuid'], songIn) || cuid();
  const guideCuid = cuid();
  const trackList = songIn.parts.map(generateTrack);
  const trackOrder = trackList.map(track => track.trackCuid);
  const tracks = byId('trackCuid', trackList);
  const { sections = [] } = songIn;
  const migratedSections = sections.map(section => {
    const { position, text } = section;
    return { position, lyrics: text };
  });
  const pages = sections
    .filter(section => !!section.title)
    .map(({ position, title }) => {
      return { position, pageTitle: title };
    });
  const hardcodedSong = hardcodedSongsByCuid[songCuid] || {};
  const { writtenBy, recordedBy, sheetMusicUrl } = hardcodedSong;
  const sheetMusic = { sheetMusicCuid: cuid(), fileCuid: sheetMusicUrl };
  const sheetMusicByCuid = byId('sheetMusicCuid', [sheetMusic]);
  const sheetMusicOrder = [sheetMusic.sheetMusicCuid];

  const songOut = {
    __schemaVersion: '2.1.0',
    songCuid,
    filesetCuid: null,
    createdTimestamp: null,
    updatedTimestamp: null,
    meta: {
      songTitle: path(['meta', 'title'], songIn),
      writtenBy,
      recordedBy,
    },
    guides: {
      [guideCuid]: {
        guideCuid: guideCuid,
        guideTitle: '',
        sections: byId('position', migratedSections),
        pages: byId('position', pages),
      },
    },
    tracks,
    trackOrder,
    sheetMusic: sheetMusicByCuid,
    sheetMusicOrder,
  };
  return songOut;

  function generateTrack(part) {
    const { title, url } = part;
    return {
      trackCuid: cuid(),
      fileCuid: url,
      voicePart: title,
      subPart: '',
      audioMix: '',
      guideCuid,
      url,
    };
  }
}
