import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import GuideListItem from './GuideListItem';
import { addTrack, deleteGuide } from '../../../store/myMusic/myMusic.actions';

import {
  getGuide,
  getTracksForGuide,
  getTrackUrlsByCuid,
} from '../../../store/myMusic/myMusic.selectors';
import { newGuide } from '../../../store/myMusic/myMusic.model';
import { openSong, maximisePlayer } from '../../../store/player/player.actions';
import AudioPlayer from '../../../webaudio/AudioPlayer';

const mergeProps = (state, { dispatch, ownProps }) => {
  const { songCuid, guideCuid } = ownProps;
  const guide = getGuide(songCuid, guideCuid, state) || newGuide();
  const selectedTracks = getTracksForGuide(songCuid, guideCuid, state);
  const { guideTitle } = guide;

  return {
    songCuid,
    guideCuid,
    guideTitle,
    selectedTracks,
    trackUrls: getTrackUrlsByCuid(songCuid, state),

    onClickEdit: (songCuid, guideCuid) =>
      dispatch(push(`/my-music/${songCuid}/guide/${guideCuid}`)),

    onCreate: (shortname, durationSeconds, file) => {
      addTrack(shortname, durationSeconds, file);
      push('/studio/tracks');
    },

    onClickPlay: (songCuid, trackUrls) => {
      dispatch(openSong(songCuid));
      dispatch(maximisePlayer(songCuid));

      AudioPlayer.loadAudio(trackUrls);
      AudioPlayer.play();
    },
    onClickDelete: (songCuid, guideCuid) =>
      dispatch(deleteGuide(songCuid, guideCuid)),
  };
};

export default connect(
  state => state,
  (dispatch, ownProps) => ({ dispatch, ownProps }),
  mergeProps,
)(GuideListItem);
