import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageOverlay from '../../Layout/PageOverlay/PageOverlay';
import PlayerConnected from '../Player/PlayerConnected';

function PlayerOverlay(props) {
  const { isMaximised, songCuid, hashCuid, doOpenSong, trackUrls = [] } = props;

  /* eslint-disable */
  useEffect(() => {
    // On page refresh, take hashCuid and put it in the store
    // Also, create the AudioPlayers once we have the urls
    if (
      hashCuid &&
      hashCuid !== songCuid &&
      trackUrls.length > 0 &&
      trackUrls[0]
    ) {
      if (!songCuid) {
        doOpenSong(hashCuid);
      }
    }
  }, [hashCuid, trackUrls]);
  /* eslint-enable */

  return (
    songCuid && (
      <PageOverlay isVisible={isMaximised}>
        <PlayerConnected history={props.history} />
      </PageOverlay>
    )
  );
}

PlayerOverlay.propTypes = {
  isMaximised: PropTypes.bool,
  songCuid: PropTypes.string,
  hashCuid: PropTypes.string,
  doOpenSong: PropTypes.func,
  trackUrls: PropTypes.array,
};

export default PlayerOverlay;
