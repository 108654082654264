import React from 'react';
import PropTypes from 'prop-types';
import * as S from './SheetMusicIcon.styled';
// import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';
import IconDocument from '../../Theme/Icons/IconDocument';
import { downloadSheetMusic } from '../../../util/pdfUtil';

function SheetMusicIcon({ sheetMusicUrl, pdfFileName = '', hasSheetMusic }) {
  function handleClickSheetMusic(e) {
    e.stopPropagation();
    downloadSheetMusic(sheetMusicUrl, pdfFileName);
  }

  return (
    <S.SheetMusicIcon>
      {hasSheetMusic && (
        <IconButton
          color="secondary"
          className=""
          aria-label="Open Sheet Music"
          onClick={handleClickSheetMusic}>
          <IconDocument size="medium" />
        </IconButton>
      )}
    </S.SheetMusicIcon>
  );
}

SheetMusicIcon.propTypes = {
  sheetMusicUrl: PropTypes.string,
  pdfFileName: PropTypes.string,
  hasSheetMusic: PropTypes.bool.isRequired,
};

export default SheetMusicIcon;
