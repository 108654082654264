import useEventListener from '@use-it/event-listener';
import 'normalize.css';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { protectedRoutes } from './App.routes';
import * as S from './AppAfterLogin.styled';
import './fonts/platform/1812-HFOOQN.css';
import ProtectedRouteConnected from './ui/Auth/ProtectedRoute/ProtectedRouteConnected';
import AddButtonConnected from './ui/Layout/AddButton/AddButton.connected';
import AppViewport from './ui/Layout/AppViewport/AppViewport';
import BottomNavConnected from './ui/Layout/BottomNav/BottomNavConnected';
import RootModalConnected from './ui/Layout/RootModal/RootModalConnected';
import TopAppBarConnected from './ui/Layout/TopAppBar/TopAppBarConnected';
import TopSticky from './ui/Layout/TopSticky/TopSticky';
import PlayerOverlayConnected from './ui/Player/PlayerOverlay/PlayerOverlayConnected';
import PlayerTitleBarConnected from './ui/Player/PlayerTitleBar/PlayerTitleBarConnected';
import ProgressBarConnected from './ui/Player/ProgressBar/ProgressBarConnected';
import FileUploadProgressBarConnected from './ui/Studio/FileUploadProgressBar/FileUploadProgressBarConnected';
import './ui/Theme/fonts.css';
import { isTextFieldFocused as getIsTextFieldFocused } from './util/domUtil';
// import { isNative } from './util/envUtil';

function AppAfterLogin({
  doRefreshSession,
  isLoggedIn,
  songCuid,
  history,
  location,
}) {
  const { pathname } = location;
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

  // Hide the BottomNav when the virtual keyboard is visible
  // This is still a bit of a dark art and could use some refactoring I think.
  useEventListener('resize', () => {
    setIsTextFieldFocused(getIsTextFieldFocused());
  });

  useEffect(() => {
    setIsTextFieldFocused(getIsTextFieldFocused());
  }, [pathname]);

  // After an f5 refresh, reload the session so login is not required again
  useEffect(() => {
    !isLoggedIn && doRefreshSession();
  }, [isLoggedIn, doRefreshSession]);

  return (
    <React.Fragment>
      <S.GlobalStyle />
      <TopSticky>
        <TopAppBarConnected pathname={pathname} />
      </TopSticky>
      <AppViewport path={pathname}>
        <Switch>
          {Object.keys(protectedRoutes).map(url => {
            return (
              <ProtectedRouteConnected
                exact
                path={url}
                key={url}
                component={protectedRoutes[url]}
              />
            );
          })}
        </Switch>
        <FileUploadProgressBarConnected />

        <S.App__BottomPadder></S.App__BottomPadder>
      </AppViewport>

      <PlayerOverlayConnected history={history} />
      <RootModalConnected />

      {!isTextFieldFocused && (
        <S.BottomContainer>
          <S.FloatingActionButtonRoot id="floating-action-button-root">
            <Switch>
              <ProtectedRouteConnected
                exact
                path="/my-music"
                component={() => (
                  <AddButtonConnected type="addSong" text="Add Song" />
                )}
              />
              <ProtectedRouteConnected
                exact
                path={['/', '/groups']}
                component={() => (
                  <>
                    <AddButtonConnected type="joinGroup" text="Join Group" />
                    <AddButtonConnected type="addGroup" text="Add Group" />
                  </>
                )}
              />
            </Switch>
          </S.FloatingActionButtonRoot>

          {songCuid && <ProgressBarConnected showTimes={false} />}
          {songCuid && <PlayerTitleBarConnected />}
          <BottomNavConnected pathname={pathname} />
        </S.BottomContainer>
      )}
    </React.Fragment>
  );
}

AppAfterLogin.propTypes = {
  doRefreshSession: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  songCuid: PropTypes.string.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default AppAfterLogin;
