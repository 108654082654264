import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// import CloseIcon from '@material-ui/icons/Close';
import _gtag from '../../../analytics/analyticsEvent';
import { audioTimestamp, mmSS } from '../../../util/audioTimestamp';
// import Fab from '@material-ui/core/Fab';
import AudioPlayer, { tickSizeMillis } from '../../../webaudio/AudioPlayer';
import * as S from './Section.styled';
import IconCut from '../../Theme/Icons/IconCut';
// import IconDocument from '../../Theme/Icons/IconDocument';
// import IconEdit from '../../Theme/Icons/IconEdit';
// import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import StudioToolbarConnected from '../../Studio/StudioToolbar/StudioToolbarConnected';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PlayPauseConnected from '../PlayPause/PlayPauseConnected';

import {
  LYRICS_TOOL,
  SECTIONS_TOOL,
  PAGES_TOOL,
} from '../../../store/player/player.tools';

const NUDGE_OFFSET_MILLIS = 150;

function Section(props) {
  const {
    seek,
    isActive,
    position,
    endPosition,
    currentTimeMillis,
    indexOnPage,
    activeToolName,
    onChangeLyrics,
    isEditable,
    isEditModeEnabled,
    onClickAddPage,
    onClickDeletePage,
    onClickNudgeSection,
    onClickAddSection,
    onClickDeleteSection,
    onEnterToAddSection,
    onClickToggleEditMode,
  } = props;

  const [lyrics, setLyrics] = useState(props.lyrics);
  const [, setIsHovered] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef();

  const isFirstSection = position === 0;
  const isFirstOnPage = indexOnPage === 0;
  const duration = endPosition - position;
  const caretOffsetMillis = currentTimeMillis - position;
  const caretOffsetPercent = (caretOffsetMillis / duration) * 100;

  // Loop the audio if the user is still typing
  useEffect(() => {
    if (
      isActive &&
      isInputFocused &&
      currentTimeMillis > endPosition - tickSizeMillis * 3
    ) {
      seek(position + 5);
    }
  }, [
    currentTimeMillis,
    position,
    endPosition,
    seek,
    isActive,
    isInputFocused,
  ]);

  const handleClickSection = e => {
    _gtag('event', 'SectionClick');

    console.log('handleClickSection');

    seek(position);
    if (!isEditModeEnabled) {
      AudioPlayer.play();
    }
  };

  const handleDoubleClick = e => {
    if (!isEditable) {
      return;
    }

    onClickToggleEditMode(true);
  };

  const handleClickNudgeForward = e => {
    e.stopPropagation();
    onClickNudgeSection(position, NUDGE_OFFSET_MILLIS); //
  };

  const handleClickNudgeBack = e => {
    e.stopPropagation();
    onClickNudgeSection(position, -NUDGE_OFFSET_MILLIS); //
  };

  // const handleClickAddPageBreak = () => onClickAddPage(position);
  // const handleClickDeletePage = () => onClickDeletePage(position);
  const handleChangePageBreakCheckbox = () => {
    isFirstOnPage ? onClickDeletePage(position) : onClickAddPage(position);
  };

  const handleBlurLyrics = () => {
    onChangeLyrics(position, lyrics);
    setIsInputFocused(false);
  };

  const handleChangeLyrics = evt => {
    const { value } = evt.target;
    setLyrics(value);
  };

  const handleKeypress = evt => {
    const { key } = evt;
    if (key === 'Enter') {
      // Force the focus out
      inputRef.current.blur();
      onEnterToAddSection(currentTimeMillis);
    }
  };

  const handleClickAddSection = e => {
    onClickAddSection(currentTimeMillis);
    e.stopPropagation();
  };

  const handleClickDeleteSection = e => {
    onClickDeleteSection(position);
    e.stopPropagation();
  };

  const handleFocusLyrics = event => {
    event.target.select();
    event.stopPropagation();
    setIsInputFocused(true);
    seek(position);
  };

  // const handleClickEditSection = e => {
  //   onClickToggleEditMode(true);
  //   e.stopPropagation();
  //   if (!isActive) {
  //     seek(position);
  //   }
  // };

  // Disabling the section width adjustment as it wasn't really working very well
  // width = 90;
  return (
    <S.Section
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {isEditModeEnabled && isActive && (
        <S.StudioToolbarContainer>
          <StudioToolbarConnected />
        </S.StudioToolbarContainer>
      )}

      {isEditModeEnabled && activeToolName === PAGES_TOOL && (
        <S.PageBreak>
          <FormControlLabel
            control={
              <Checkbox
                checked={isFirstOnPage}
                onChange={handleChangePageBreakCheckbox}
                name="checkedB"
                color="primary"
              />
            }
            label={isFirstOnPage ? 'Page Break' : 'Page Break'}
          />
        </S.PageBreak>
      )}

      {isEditModeEnabled && (
        <S.TopToolbar>
          {false &&
            isActive &&
            !isFirstSection &&
            activeToolName === SECTIONS_TOOL && (
              <S.Nudge>
                <S.NudgeBack>
                  <IconButton size="small" onClick={handleClickNudgeBack}>
                    <ArrowBackIcon />
                  </IconButton>
                </S.NudgeBack>

                <S.NudgeText>{!isFirstSection ? 'nudge' : ''}</S.NudgeText>

                <S.NudgeForward>
                  <IconButton size="small" onClick={handleClickNudgeForward}>
                    <ArrowForwardIcon />
                  </IconButton>
                </S.NudgeForward>
              </S.Nudge>
            )}
        </S.TopToolbar>
      )}

      <S.ContentContainer>
        {isActive && isEditModeEnabled && (
          <S.LeftSpacer>
            <PlayPauseConnected size="medium" color="primary" />
          </S.LeftSpacer>
        )}
        <S.SectionContent
          onClick={handleClickSection}
          onDoubleClick={handleDoubleClick}
          isActive={isActive}>
          {isEditModeEnabled &&
            isActive &&
            activeToolName === SECTIONS_TOOL &&
            !isFirstSection && (
              <S.BreakLine>
                <S.Nudge>
                  <S.NudgeBack>
                    <IconButton size="small" onClick={handleClickNudgeBack}>
                      <ArrowBackIcon />
                    </IconButton>
                  </S.NudgeBack>

                  <S.NudgeForward>
                    <IconButton size="small" onClick={handleClickNudgeForward}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </S.NudgeForward>
                </S.Nudge>
                {/* <S.CaretCut>
                  <IconCut size="small" />
                </S.CaretCut> */}
              </S.BreakLine>
            )}

          {isActive && isEditModeEnabled && (
            <S.Caret offsetPercent={caretOffsetPercent}>
              {/* {activeToolName === SECTIONS_TOOL && (
                <S.CaretCut>
                  <IconCut size="small" />
                </S.CaretCut>
              )} */}
            </S.Caret>
          )}
          {isEditModeEnabled && activeToolName === LYRICS_TOOL ? (
            <S.SectionInput
              ref={inputRef}
              value={lyrics}
              // onClick={e => e.stopPropagation()}
              onChange={handleChangeLyrics}
              onFocus={handleFocusLyrics}
              onBlur={handleBlurLyrics}
              onKeyPress={handleKeypress}
              placeholder="add lyrics"
              autoFocus={isActive}
            />
          ) : (
            <S.SectionBody isActive={isActive}>
              {lyrics || '( no lyrics )'}
            </S.SectionBody>
          )}
          <S.SectionFooter>
            <S.SectionPosition>
              {isEditModeEnabled
                ? mmSS(position)
                : audioTimestamp(position / 1000)}
            </S.SectionPosition>
          </S.SectionFooter>
        </S.SectionContent>
        <S.EditButton>
          {/* {isHovered && isEditable && !isEditModeEnabled && (
            <IconEdit size="small" onClick={handleClickEditSection} />
          )}
          {isEditModeEnabled && isActive && (
            <S.DisableEditing>
              <LockOpenIcon
                size="small"
                color="primary"
                onClick={() => onClickToggleEditMode(false)}
              />
            </S.DisableEditing>
          )} */}
        </S.EditButton>
      </S.ContentContainer>

      {isEditModeEnabled && isActive && (
        <S.BottomToolbar>
          {activeToolName === SECTIONS_TOOL && (
            <S.BottomToolbarRow>
              {!isFirstSection && (
                <S.UndoBreakSection>
                  <Button
                    style={{ backgroundColor: 'white' }}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    aria-label="Add"
                    onClick={handleClickDeleteSection}>
                    <UndoIcon />
                    &nbsp; unbreak
                  </Button>
                </S.UndoBreakSection>
              )}

              <S.BreakSectionButton>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  aria-label="Add"
                  onClick={handleClickAddSection}>
                  <IconCut />
                  &nbsp; Break &nbsp;
                </Button>
              </S.BreakSectionButton>
            </S.BottomToolbarRow>
          )}

          {/* <S.BottomToolbarRow>
            <Fab
              variant="extended"
              color="secondary"
              size="large"
              aria-label="Finish"
              onClick={() => onClickToggleEditMode(false)}>
              &nbsp; Finish Editing &nbsp;
            </Fab>
          </S.BottomToolbarRow> */}
        </S.BottomToolbar>
      )}
    </S.Section>
  );
}

Section.propTypes = {
  position: PropTypes.number.isRequired,
  endPosition: PropTypes.number.isRequired,
  currentTimeMillis: PropTypes.number.isRequired,
  indexOnPage: PropTypes.number.isRequired,
  widthDuration: PropTypes.number.isRequired,
  lyrics: PropTypes.string.isRequired,
  seek: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  isEditModeEnabled: PropTypes.bool,
  activeToolName: PropTypes.string.isRequired,
  onClickAddPage: PropTypes.func.isRequired,
  onClickDeletePage: PropTypes.func.isRequired,
  onChangeLyrics: PropTypes.func.isRequired,
  onClickAddSection: PropTypes.func.isRequired,
  onClickDeleteSection: PropTypes.func.isRequired,
  onClickNudgeSection: PropTypes.func.isRequired,
  onEnterToAddSection: PropTypes.func.isRequired,
  onClickToggleEditMode: PropTypes.func.isRequired,
};

export default Section;
