import React from 'react';
import PropTypes from 'prop-types';
// import * as S from './DeleteGroupModal.styled';
import OkCancelModal from '../OkCancelModal/OkCancelModal';

const DeleteGroupModal = ({
  groupCuid,
  groupName,
  onClickOk,
  onClickCancel,
}) => {
  const handleOkClick = () => {
    onClickOk && onClickOk(groupCuid);
  };
  const handleCancelClick = () => {
    onClickCancel && onClickCancel();
  };

  return OkCancelModal({
    title: 'Delete Group?',
    imageName: 'trio',
    body: (
      <div>
        <div>Are you sure you want to delete the group:</div>
        <strong>{groupName}</strong>
      </div>
    ),
    okButtonText: 'Delete',
    cancelButtonText: 'Cancel',
    onClickOk: handleOkClick,
    onClickCancel: handleCancelClick,
  });
};

DeleteGroupModal.propTypes = {
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  groupCuid: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
};

export default DeleteGroupModal;
