import { connect } from 'react-redux';
import MediaPicker from './MediaPicker';
import { s3UploadFilesThunk } from '../../../store/myMusic/myMusic.thunks';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  onFilesSelected: (fileList, songCuid, type) => {
    dispatch(s3UploadFilesThunk(fileList, songCuid, type));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaPicker);
