import { getNextGroupSongCuid } from '../groups/groups.selectors';
import {
  getNextMyMusicSongCuid,
  getSongsByCuid,
} from '../myMusic/myMusic.selectors';
import { SECTIONS_TOOL } from '../player/player.tools';

export const getSelectedTrackIndex = (state = {}) => {
  const player = state.player || {};
  const { selectedTrackIndex } = player;
  return selectedTrackIndex;
};

export const getCurrentSongCuid = (state = {}) => {
  const player = state.player || {};
  const { songCuid } = player;
  return songCuid;
};

export const getNextRecentlyPlayedSongCuid = (
  currentSongCuid,
  playlistCuid,
  state = {},
) => {
  const player = state.player || {};
  const { recentlyPlayedSongCuids } = player;
  const index = recentlyPlayedSongCuids.indexOf(currentSongCuid);
  return recentlyPlayedSongCuids[index + 1];
};

const nextSongSelectors = {
  GROUP_PLAYLIST: getNextGroupSongCuid,
  MY_MUSIC_PLAYLIST: getNextMyMusicSongCuid,
  RECENTLY_PLAYED_PLAYLIST: getNextRecentlyPlayedSongCuid,
};

export const getNextSongCuid = (
  currentSongCuid,
  playlistType,
  playlistCuid,
  state,
) => {
  const selector = nextSongSelectors[playlistType];
  return selector(currentSongCuid, playlistCuid, state);
};

export const getActiveToolName = (state = {}) => {
  const player = state.player || {};
  const { activeToolName } = player;
  return activeToolName || SECTIONS_TOOL;
};

export const getRecentlyPlayedSongs = (state = {}) => {
  const player = state.player || {};
  const { recentlyPlayedSongCuids } = player;
  return getSongsByCuid(recentlyPlayedSongCuids, state);
};

export const getPlaylistType = (state = {}) => {
  const player = state.player || {};
  return player.playlistType;
};
