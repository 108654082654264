import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/modal.actions';
import Popup from './Popup';

const popupTypes = {
  EDITOR_HINT: {
    title: 'Song Editor hint',
    imageName: 'playerScreenshot',
    listItems: [
      'Use the Sections tool to break the song into chunks',
      'Then add some lyrics',
      'Finally, use the Pages tool to split the song over multiple screens',
    ],
  },
};

const mapStateToProps = (state, ownProps) => {
  const customProps = popupTypes[ownProps.modalType] || {};
  return { ...ownProps, ...customProps };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDismiss: () => {
    dispatch(hideModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Popup);
