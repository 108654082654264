import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M20,95V19c0-7.696,6.3-14,13.999-14h6.999
    C48.696,5,55,11.304,55,19v112c0,7.699-6.304,14-14.002,14h-6.999C26.3,145,20,137.699,20,130"
    />
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M95,95V19c0-7.696,6.3-14,13.999-14h6.999
    C123.696,5,130,11.304,130,19v112c0,7.699-6.304,14-14.002,14h-6.999C101.3,145,95,137.699,95,130"
    />
  </g>,
  150,
  150,
);
