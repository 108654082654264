import styled from 'styled-components';

export const EditGroup = styled.div`
  width: 100%;
`;
export const EditGroupDetails = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
`;

export const EditGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  padding: 24px;
`;

export const EditGroupForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GroupName = styled.div`
  width: 100%;
`;

export const Save = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const MemberListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  will-change: opacity;
  opacity: ${props => (props.isVisible ? 1 : 0.2)};
  transition: opacity 500ms ease-in-out;
`;
