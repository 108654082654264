import styled from 'styled-components';

export const MySongs = styled.div`
  text-align: left;
  width: 100%;
`;

export const ListPadding = styled.div`
  min-height: ${props => (props.native ? '190px' : '60px')};
`;
