import {
  ADD_SONG,
  UPDATE_SONG,
  UPDATE_SONG_META,
  ADD_TRACK,
  ADD_SHEET_MUSIC,
  DELETE_SHEET_MUSIC,
  ADD_GUIDE,
  ADD_SECTION,
  DELETE_SECTION,
  NUDGE_SECTION,
  ADD_PAGE,
  DELETE_PAGE,
  DELETE_GUIDE,
  UPDATE_TRACK,
  DELETE_SONG_STARTED,
  DELETE_SONG_SUCCESS,
  DELETE_SONG_FAILED,
  DELETE_TRACK,
  FETCH_SONGS_STARTED,
  FETCH_SONGS_SUCCESS,
  FETCH_SONGS_FAIL,
  FETCH_SONG_GROUPS_STARTED,
  FETCH_SONG_GROUPS_SUCCESS,
  FETCH_SONG_GROUPS_FAIL,
  POST_SONG_STARTED,
  POST_SONG_SUCCESS,
  POST_SONG_FAIL,
  POST_FILESET_STARTED,
  POST_FILESET_SUCCESS,
  POST_FILESET_FAIL,
  STORE_FILESET,
  SHARE_SONG_STARTED,
  SHARE_SONG_SUCCESS,
  SHARE_SONG_FAIL,
  SET_UPLOAD_STATUS,
  ADD_TRACKS_TO_GUIDE,
  ADD_FILESET,
  STORE_SONG,
  STORE_FILESET_URLS,
} from './myMusic.events';

const a = event => () => ({ type: event });

export const addSong = song => ({ type: ADD_SONG, song });

export const shareSongStarted = a(SHARE_SONG_STARTED);
export const shareSongSuccess = (songCuid, groupCuid) => ({
  type: SHARE_SONG_SUCCESS,
  sharePair: { songCuid, groupCuid },
});
export const shareSongFail = a(SHARE_SONG_FAIL);

export const updateSong = song => ({
  type: UPDATE_SONG,
  song: { ...song, updatedDate: new Date().toISOString() },
});

export const updateSongMeta = (songCuid, meta) => ({
  type: UPDATE_SONG_META,
  data: { songCuid, meta, updatedDate: new Date().toISOString() },
});

export const deleteSongStarted = a(DELETE_SONG_STARTED);
export const deleteSongFailed = a(DELETE_SONG_FAILED);
export const deleteSongSuccess = songCuid => ({
  type: DELETE_SONG_SUCCESS,
  songCuid,
});

export const deleteTrack = (songCuid, trackCuid) => ({
  type: DELETE_TRACK,
  data: { songCuid, trackCuid },
});

export const addTrack = (songCuid, track, filesetCuid) => ({
  type: ADD_TRACK,
  data: {
    songCuid,
    track,
    filesetCuid,
    updatedDate: new Date().toISOString(),
  },
});

export const addSheetMusic = (songCuid, sheetMusic, filesetCuid) => ({
  type: ADD_SHEET_MUSIC,
  data: {
    songCuid,
    sheetMusic,
    filesetCuid,
    updatedDate: new Date().toISOString(),
  },
});

export const deleteSheetMusic = (songCuid, sheetMusicCuid) => ({
  type: DELETE_SHEET_MUSIC,
  data: { songCuid, sheetMusicCuid },
});

export const addSection = (songCuid, guideCuid, section) => ({
  type: ADD_SECTION,
  songCuid,
  guideCuid,
  section,
});

export const deleteSection = (songCuid, guideCuid, currentTimeMillis) => ({
  type: DELETE_SECTION,
  songCuid,
  guideCuid,
  currentTimeMillis,
});

export const nudgeSection = (songCuid, guideCuid, position, offset) => ({
  type: NUDGE_SECTION,
  songCuid,
  guideCuid,
  position,
  offset,
});

export const addPage = (songCuid, guideCuid, position, pageTitle) => ({
  type: ADD_PAGE,
  payload: { songCuid, guideCuid, position, pageTitle },
});

export const deletePage = (songCuid, guideCuid, position) => ({
  type: DELETE_PAGE,
  payload: { songCuid, guideCuid, position },
});

export const updateTrack = (songCuid, track) => ({
  type: UPDATE_TRACK,
  data: {
    songCuid,
    track,
    updatedDate: new Date().toISOString(),
  },
});

export const addFileset = fileset => ({
  type: ADD_FILESET,
  fileset,
});

export const addGuide = (songCuid, guide) => ({
  type: ADD_GUIDE,
  data: {
    songCuid,
    guide,
  },
});

export const deleteGuide = (songCuid, guideCuid) => ({
  type: DELETE_GUIDE,
  data: {
    songCuid,
    guideCuid,
  },
});

export const fetchSongsStarted = a(FETCH_SONGS_STARTED);

export const fetchSongsSuccess = response => ({
  type: FETCH_SONGS_SUCCESS,
  data: {
    songList: response,
    lastFetchDate: new Date(),
  },
});

export const fetchSongsFail = a(FETCH_SONGS_FAIL);

export const fetchSongGroupsStarted = a(FETCH_SONG_GROUPS_STARTED);

export const fetchSongGroupSuccess = (songCuid, response) => ({
  type: FETCH_SONG_GROUPS_SUCCESS,
  data: {
    songCuid,
    groupList: response,
    lastFetchDate: new Date(),
  },
});

export const fetchSongGroupsFail = a(FETCH_SONG_GROUPS_FAIL);

export const postSongStarted = a(POST_SONG_STARTED);

export const postSongSuccess = response => ({
  type: POST_SONG_SUCCESS,
  data: {
    song: response,
  },
});

export const postSongFail = response => ({
  type: POST_SONG_FAIL,
});

export const setUploadStatus = (filesRemaining, filename) => ({
  type: SET_UPLOAD_STATUS,
  filesRemaining,
  filename,
});

export const addTracksToGuide = (songCuid, guideCuid, trackList) => ({
  type: ADD_TRACKS_TO_GUIDE,
  songCuid,
  guideCuid,
  trackList,
});

export const postFilesetStarted = a(POST_FILESET_STARTED);
export const postFilesetSuccess = fileset => ({
  type: POST_FILESET_SUCCESS,
  fileset,
});
export const postFilesetFailed = a(POST_FILESET_FAIL);
export const storeFileset = fileset => ({
  type: STORE_FILESET,
  fileset,
});

export const storeSong = songDocument => ({
  type: STORE_SONG,
  songDocument,
});
export const storeFilesetUrls = (filesetCuid, urls) => ({
  type: STORE_FILESET_URLS,
  filesetCuid,
  urls,
});
