import { connect } from 'react-redux';
import PlayerOverlay from './PlayerOverlay';
import { PLAYER_INIT } from '../../../store/player/player.events';
import { getCurrentSongCuid } from '../../../store/player/player.selectors';

import { openSong, maximisePlayer } from '../../../store/player/player.actions';
import AudioPlayer from '../../../webaudio/AudioPlayer';
import { getTrackUrlsByCuid } from '../../../store/myMusic/myMusic.selectors';

function getCuidFromHash() {
  const { hash } = window.location;
  const index = hash.indexOf('=') + 1;
  return index ? hash.slice(index) : '';
}

const mergeProps = (state, { dispatch, ownProps }) => {
  const hashCuid = getCuidFromHash();
  const trackUrls = getTrackUrlsByCuid(hashCuid, state);

  // TODO: some song cuids aren't matching their equivalent in allSongs.json
  const isMaximised = hashCuid.length > 10;

  return {
    songCuid: getCurrentSongCuid(state),
    hashCuid,
    isMaximised,
    trackUrls,
    history: ownProps.history,

    onLoad: () => {
      dispatch({ type: PLAYER_INIT });
    },
    doOpenSong: songCuid => {
      dispatch(openSong(songCuid));
      dispatch(maximisePlayer(songCuid));
      const trackUrls = getTrackUrlsByCuid(songCuid, state);

      AudioPlayer.loadAudio(trackUrls);

      // Not allowed play audio on Safari on load.
      // Need the user to click play
      // AudioPlayer.play();
    },
  };
};

export default connect(
  state => state,
  (dispatch, ownProps) => ({ dispatch, ownProps }),
  mergeProps,
)(PlayerOverlay);
