import {
  postSubscriptionDB,
  fetchSubscriptionDB,
  fetchCouponDB,
  cancelSubscriptionDB,
  updateSubscriptionDB,
  resubscribeDB,
} from './billing.api';
import {
  postSubscriptionStarted,
  postSubscriptionSuccess,
  postSubscriptionFail,
  fetchSubscriptionStarted,
  fetchSubscriptionSuccess,
  fetchSubscriptionFail,
  fetchCouponStarted,
  fetchCouponSuccess,
  fetchCouponFail,
  cancelSubscriptionStarted,
  cancelSubscriptionSuccess,
  cancelSubscriptionFail,
  resubscribeStarted,
  resubscribeSuccess,
  resubscribeFail,
} from './billing.actions';
import { hideModal } from '../modal/modal.actions';
import { transformResponse } from '../../util/billingUtil';

export const postStripeThunk = (
  stripe,
  card,
  source,
  couponCode,
) => async dispatch => {
  const token = await stripe.createToken(card);

  if (token.error) {
    dispatch(postSubscriptionFail(token.error.message));
  } else {
    if (source) {
      // customer already subscribed
      const update = true;
      dispatch(postSubscriptionStarted());
      updateSubscriptionDB(token.token)
        .then(subscription => {
          subscription = transformResponse(subscription);
          dispatch(postSubscriptionSuccess(subscription, update));
        })
        .catch(err => {
          console.error(err);
          postSubscriptionFail(err.body, update);
        });
    } else {
      // new customer
      dispatch(postSubscriptionStarted());
      postSubscriptionDB(token.token, couponCode)
        .then(subscription => {
          // dispatch(hideModal('STRIPE_MODAL'));
          console.log(subscription);
          subscription = transformResponse(subscription);
          console.log('subscriptionTransformed', subscription);
          dispatch(postSubscriptionSuccess(subscription));
        })
        .catch(err => {
          console.error(err);
          dispatch(postSubscriptionFail());
        });
    }
  }
};

export const fetchSubscriptionThunk = () => async dispatch => {
  dispatch(fetchSubscriptionStarted());
  return fetchSubscriptionDB()
    .then(subscription => {
      subscription = transformResponse(subscription);
      dispatch(fetchSubscriptionSuccess(subscription));
    })
    .catch(err => {
      console.error(err);
      dispatch(fetchSubscriptionFail());
    });
};

export const fetchCouponThunk = couponCode => async dispatch => {
  dispatch(fetchCouponStarted());
  return fetchCouponDB(couponCode)
    .then(response => {
      dispatch(fetchCouponSuccess(response));
    })
    .catch(err => {
      console.error(err);
      dispatch(fetchCouponFail(err.response.data));
    });
};

export const cancelSubscriptionThunk = () => async dispatch => {
  dispatch(cancelSubscriptionStarted());
  return cancelSubscriptionDB()
    .then(subscription => {
      subscription = transformResponse(subscription);
      dispatch(cancelSubscriptionSuccess(subscription));
      dispatch(hideModal('CANCEL_SUBSCRIPTION'));
    })
    .catch(err => {
      console.error(err);
      dispatch(cancelSubscriptionFail());
    });
};

export const resubscribeThunk = () => async dispatch => {
  dispatch(resubscribeStarted());
  return resubscribeDB()
    .then(subscription => {
      subscription = transformResponse(subscription);
      dispatch(resubscribeSuccess(subscription));
      dispatch(hideModal('RESUBSCRIBE'));
    })
    .catch(err => {
      console.error(err);
      dispatch(resubscribeFail(err.response.data));
    });
};
