import styled from 'styled-components';

export const MyAccountPage = styled.div`
  font-family: Platform Web;
  height: 100%;
  width: 100%;
`;

export const MyAccountPage__Button = styled.div`
  margin-top: 5vh;
`;

export const MyAccountPage__Alert = styled.div`
  padding: 1em;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
`;

export const Email = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  align-items: center;
`;

export const UserAvatar = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 1em;
  align-items: center;
`;
