import styled from 'styled-components';
import { color, fontFamily, fontSize } from '../../design-tokens';

export const AddMember = styled.div`
  display: flex;
  color: ${color.red100};
  font-family: ${fontFamily.regular};
  font-size: ${fontSize.medium};
`;

export const TextAreaContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const AddMemberView = styled.div`
  height: 100%;
  width: 100%;
  min-width: 300px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AddMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 200px;

  /*margin-top: 80px;*/
`;

export const SendInvitesButton = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  justify-content: center;
`;
