import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import HomeIcon from '@material-ui/icons/Home';
// import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GroupIcon from '@material-ui/icons/Group';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
// import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
// import SearchIcon from '@material-ui/icons/Search';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import StudioIcon from '@material-ui/icons/SettingsVoice';
import PersonIcon from '@material-ui/icons/Person';
import { cp2_pink } from '../../Theme/colors';
import { isMyMusicEnabled } from '../../../util/featureToggle';
import { isNative } from '../../../util/envUtil';
import * as S from './BottomNav.styled';

const useStyles = makeStyles({
  root: {
    width: '100%',
    color: 'orange',
    backgroundColor: cp2_pink,
    colorPrimary: 'brown',
  },
});

// const routes = ['/groups', '/my-music', '/feedback'];
const routes = ['/groups', '/my-music', '/my-account'];

function BottomNav(props) {
  const classes = useStyles();
  const { pathname, onNavSelected } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  // const routes = isProMember ? routesFree : routesPro;
  // After a page refresh, set the selected bottom nav button
  useEffect(() => {
    const initialRoute =
      routes.filter(route => pathname.startsWith(route))[0] || routes[0];
    initialRoute && setSelectedIndex(routes.indexOf(initialRoute));
  }, [pathname]);

  const handleChange = (event, selectedIndex) => {
    setSelectedIndex(selectedIndex);
    const route = routes[selectedIndex];

    onNavSelected(route);
  };

  return (
    <S.BottomNav>
      <BottomNavigation
        value={selectedIndex}
        onChange={handleChange}
        showLabels={true}
        className={classes.root}>
        <BottomNavigationAction
          label="Groups"
          icon={<GroupIcon />}
          disableRipple={isNative()}
        />
        {isMyMusicEnabled() && (
          <BottomNavigationAction
            label="My Music"
            disableRipple={isNative()}
            icon={<LibraryMusicIcon />}
          />
        )}

        <BottomNavigationAction
          label="My Account"
          icon={<PersonIcon />}
          disableRipple={isNative()}
        />
      </BottomNavigation>
    </S.BottomNav>
  );
}

BottomNav.propTypes = {
  onNavSelected: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  isProMember: PropTypes.bool.isRequired,
};

export default BottomNav;
