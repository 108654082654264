import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import './fonts/platform/1812-HFOOQN.css';
import 'normalize.css';
import * as S from './App.styled';
import './ui/Theme/fonts.css';
import { isPrd, isNative } from './util/envUtil';
import AppAfterLoginConnected from './AppAfterLoginConnected';
import DebugPage from './ui/Debug/DebugPage';
import LoginConnected from './ui/Auth/Login/LoginConnected';
// import LoginHomeConnected from './ui/Auth/LoginHome/LoginHomeConnected';
import RegisterConnected from './ui/Auth/Register/RegisterConnected';
import RequestResetConnected from './ui/Auth/RequestReset/RequestResetConnected';
import ConfirmSignupConnected from './ui/Auth/ConfirmSignup/ConfirmSignupConnected';
import ResetPasswordConnected from './ui/Auth/ResetPassword/ResetPasswordConnected';
import withMuiTheme from './ui/Theme/withMuiTheme';
import LoginFrame from './ui/Layout/LoginFrame/LoginFrame';
import ContentFrame from './ui/Layout/ContentFrame/ContentFrame';
import ComingSoon from './ui/Site/ComingSoon/ComingSoon';
import AppVersionConnected from './ui/Site/AppVersion/AppVersionConnected';
import AppInitConnected from './ui/Site/AppInit/AppInitConnected';
import PrivacyPolicyPage from './ui/Site/Privacy/PrivacyPolicy';

function App({ history, location, isLoggedIn }) {
  return (
    <S.App>
      <Switch>
        {/* Cordova Initial Url */}
        <Redirect from="*/www/index.html" to="/" />

        <Route
          exact
          path="/"
          component={
            isLoggedIn ? AppAfterLoginConnected : contentFrame(ComingSoon)
          }
        />
        <Route
          exact
          path="/privacy"
          component={contentFrame(PrivacyPolicyPage)}
        />

        <Route exact path="/login" component={loginFrame(LoginConnected)} />
        <Route
          exact
          path="/request-reset"
          component={loginFrame(RequestResetConnected)}
        />
        <Route
          exact
          path="/set-new-password"
          component={loginFrame(ResetPasswordConnected)}
        />
        <Route
          exact
          path="/register"
          component={loginFrame(RegisterConnected)}
        />
        <Route
          exact
          path="/confirm"
          component={loginFrame(ConfirmSignupConnected)}
        />

        <Route exact path="/debug" component={DebugPage} />

        <Route component={AppAfterLoginConnected} />
      </Switch>
      {isPrd() || isNative() || (
        <div style={{ position: 'fixed', top: '0px', left: '0px' }}>
          <AppVersionConnected />
        </div>
      )}
      <AppInitConnected />
    </S.App>
  );
}

const loginFrame = Component => props => (
  <LoginFrame>
    <Component {...props} />
  </LoginFrame>
);
const contentFrame = Component => props => (
  <ContentFrame>
    <Component {...props} />
  </ContentFrame>
);

App.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};

export default withMuiTheme(App);
