export const transformResponse = response => {
  const dateFormatter = unixDate => {
    if (!unixDate) {
      return null;
    } else {
      return new Date(unixDate * 1000).toDateString();
    }
  };
  if (!response) {
    return {};
  } else {
    if (Object.keys(response).length === 0) {
      console.log('no response');
      return {};
    } else {
      const {
        start_date,
        current_period_end,
        trial_end = null,
        ...responseUpdated
      } = response;
      return {
        ...responseUpdated,
        sub_start: dateFormatter(start_date),
        sub_end: dateFormatter(current_period_end),
        trial_ending: dateFormatter(trial_end),
      };
    }
  }
};
