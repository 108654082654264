import {
  OPEN_SONG,
  CHANGE_TRACK,
  ENABLE_TOOL,
  DISABLE_TOOL,
  CLEAR_PLAYER,
} from './player.events';
import { push } from 'connected-react-router';

export const openSong = (
  songCuid,
  playlistType,
  playlistCuid,
  wasClicked = false,
) => {
  return {
    type: OPEN_SONG,
    data: { songCuid, playlistType, playlistCuid, wasClicked },
  };
};
export const changeTrack = trackIndex => {
  return {
    type: CHANGE_TRACK,
    trackIndex,
  };
};

export const maximisePlayer = songCuid => {
  return push('#song=' + songCuid);
};

export const minimisePlayer = () => {
  return push('#');
};

export const clearPlayer = () => {
  return {
    type: CLEAR_PLAYER,
  };
};

export const enableTool = activeToolName => {
  return {
    type: ENABLE_TOOL,
    activeToolName,
  };
};

export const disableTool = () => {
  return {
    type: DISABLE_TOOL,
  };
};
