import { connect } from 'react-redux';
import { hideModal } from '../../../store/modal/modal.actions';
import { getSongByCuid } from '../../../store/myMusic/myMusic.selectors';
import { deleteSongThunk } from '../../../store/myMusic/myMusic.thunks';
import {
  clearPlayer,
  minimisePlayer,
} from '../../../store/player/player.actions';
import { getCurrentSongCuid } from '../../../store/player/player.selectors';
import AudioPlayer from '../../../webaudio/AudioPlayer';
import { myMusicRoute } from '../../routes';
import DeleteSongModal from './DeleteSongModal';

const mapStateToProps = (state, ownProps) => {
  const { songCuid } = ownProps;
  const songDocument = getSongByCuid(songCuid, state);
  const { songTitle } = songDocument.meta;
  const playingSongCuid = getCurrentSongCuid(state);

  return {
    songCuid,
    songTitle,
    playingSongCuid,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickOk: (songCuid, playingSongCuid) => {
    if (songCuid === playingSongCuid) {
      AudioPlayer.pause();
      dispatch(minimisePlayer());
      dispatch(clearPlayer());
    }
    dispatch(deleteSongThunk(songCuid));
    dispatch(hideModal());
    dispatch(myMusicRoute());
  },
  onClickCancel: songCuid => {
    dispatch(hideModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteSongModal);
