import { connect } from 'react-redux';
import Player from './Player';
import { PLAYER_INIT } from '../../../store/player/player.events';
import AudioPlayer from '../../../webaudio/AudioPlayer';

const mapStateToProps = (state, ownProps) => {
  return { history: ownProps.history };
};

const mapDispatchToProps = dispatch => ({
  openSong: action => dispatch(action),
  play: () => AudioPlayer.play(),
  pause: () => AudioPlayer.pause(),
  onLoad: () => {
    dispatch({ type: PLAYER_INIT });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Player);
