import React from 'react';
import PropTypes from 'prop-types';
// import { detect } from 'detect-browser';
import * as S from './FilePicker.styled';

// const browserDetails = detect();

// iOS crashes if capture=true
// const CAPTURE_UNSUPPORTED = ['ios', 'android'];
const isCaptureSupported = false;

function FilePicker({
  onFileSelected,
  children,
  allowMultiple = true,
  acceptFileTypes,
}) {
  const handleFileSelected = event => {
    const selectedFile = event.target.files;
    return onFileSelected(selectedFile);
  };

  return (
    <S.FilePicker>
      <label>
        <input
          id="AddTrack__HiddenInput"
          type="file"
          accept={acceptFileTypes}
          multiple={allowMultiple}
          capture={isCaptureSupported}
          onChange={handleFileSelected}
          style={{ display: 'none' }}
        />
        {children}
      </label>
    </S.FilePicker>
  );
}

FilePicker.propTypes = {
  children: PropTypes.node.isRequired, // can't be a button, must be a div/span etc
  onFileSelected: PropTypes.func.isRequired,
  allowMultiple: PropTypes.bool,
  acceptFileTypes: PropTypes.string,
};

export default FilePicker;
