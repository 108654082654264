import { Storage, Auth } from 'aws-amplify';
import pubsub from 'pubsub-js';

const URL_EXPIRATION_7_DAYS = 7 * 24 * 60 * 60;

export async function s3FileUpload(file, fileCuid) {
  const key = await Storage.put(fileCuid, file, {
    contentType: file.type,
    level: 'protected',
    progressCallback(progress) {
      const { loaded, total } = progress;
      pubsub.publish('UPLOAD_PROGRESS_CHANNEL', { loaded, total });
      console.log('Upload progress: ', loaded / total + '/100');
    },
  });
  return key;
}

export async function s3Delete(fileCuid) {
  await Storage.remove(fileCuid, { level: 'protected' })
    .then(result => {
      // console.log({ result });
    })
    .catch(err => console.error(err));
}

export function s3GetUrl(fileCuid, callback) {
  Storage.get(fileCuid, { level: 'protected' })
    .then(url => {
      callback && callback(url);
    })
    .catch(err => console.error(err));
}

export async function fetchS3Url(fileRecord) {
  // Refresh auth token to get a non expired s3 url
  await Auth.currentSession();
  // For the hardcoded songs, we're using the url as the fileCuid so no lookup needed.
  const { fileCuid, identityId } = fileRecord;
  if (!fileCuid) {
    return; // some fileCuids returning null causing next IF to fail
  }
  if (fileCuid.startsWith('https://')) {
    return new Promise((resolve, reject) => resolve(fileCuid));
  } else {
    return Storage.get(fileCuid, {
      level: 'protected',
      identityId,
      expires: URL_EXPIRATION_7_DAYS,
    });
  }
}

export function fetchS3UrlWithRetries(
  fileRecord,
  retriesRemaining,
  onRetry = () => {},
) {
  return fetchS3Url(fileRecord).catch(function(error) {
    if (retriesRemaining === 1) throw error;
    onRetry();
    setTimeout(
      () => fetchS3UrlWithRetries(fileRecord, retriesRemaining - 1),
      100,
    );
  });
}

export function fetchS3Urls(fileRecordList = [], onRetry = () => {}) {
  const promises = fileRecordList.map(fileRecord =>
    fetchS3UrlWithRetries(fileRecord, 3, onRetry),
  );
  return Promise.all(promises);
}
