import { Auth, API } from 'aws-amplify';
import axios from 'axios';

// const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: '',
  headers: { 'X-Custom-Header': 'foobar', Accept: 'application/json' },
});

const isMocked = process.env.REACT_APP_USE_MOCK_SERVER === 'true';

const buildReq = async body => {
  const guest = await Auth.currentCredentials();
  if (!guest.authenticated) {
    // don't send authorization token with api
    return { body };
  }
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return {
    headers: {
      Authorization: token,
    },
    body,
  };
};

export const getIdentityId = async () => {
  const credentials = await Auth.currentCredentials();
  const identityId = credentials._identityId;
  return identityId;
};

const awsApiInit = apiName => {
  return {
    get: async (path, body) => API.get(apiName, path, await buildReq(body)),
    post: async (path, body) => API.post(apiName, path, await buildReq(body)),
    put: async (path, body) => API.put(apiName, path, await buildReq(body)),
    del: async (path, body) => API.del(apiName, path, await buildReq(body)),
  };
};

const mockApiInit = () => {
  return {
    get: async (path, body) =>
      instance.get('/api' + path, body).then(response => response.data),
    post: async (path, body) =>
      instance.post('/api' + path, body).then(response => response.data),
    put: async (path, body) =>
      instance.put('/api' + path, body).then(response => response.data),
    del: async (path, body) =>
      instance.delete('/api' + path, body).then(response => response.data),
  };
};

export default isMocked ? mockApiInit : awsApiInit;
