import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { choirpal_blue_100 } from '../../Theme/colors';

const fontSizes = {
  small: 32,
  medium: 44,
  large: 56,
};

const StyledH1 = styled.h1`
  font-family: 'Platform Web';
  font-weight: 500;
  color: ${choirpal_blue_100};
  font-size: ${props => props.fontSize}px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default function H1({ children, className, size = 'small' }) {
  const fontSize = fontSizes[size];
  return (
    <StyledH1 fontSize={fontSize} className={className}>
      {children}
    </StyledH1>
  );
}

H1.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.array,
  size: PropTypes.string,
};
