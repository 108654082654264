import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import './LoginButtons.css';

function LoginButtons({ onClickRegister, onClickLogin }) {
  return (
    <div className="LoginButtons">
      <div className="LoginButtons__Container">
        <Button
          style={{ width: '100px' }}
          color="secondary"
          size="small"
          onClick={onClickLogin}>
          Log&nbsp;In
        </Button>
        <Button
          style={{ width: '175px' }}
          variant="contained"
          size="small"
          color="primary"
          onClick={onClickRegister}>
          Get choirpal free
        </Button>
      </div>
    </div>
  );
}

LoginButtons.propTypes = {
  onClickRegister: PropTypes.func,
  onClickLogin: PropTypes.func,
};

export default LoginButtons;
