import { splitAt } from 'ramda';
import { asList, getFirst } from '../storeUtil';

export const getGuideByTrackIndex = (trackIndex = 0, song = {}) => {
  const { tracks } = song;
  if (!tracks) return [];

  const trackList = asList(tracks);
  const track = trackList[trackIndex] || {};
  const { guideCuid } = track;
  const { guides } = song;
  const guide = guides[guideCuid] || getFirst(guides) || {};
  return guide;
};

export const getDefaultGuide = (song = {}) => {
  const { guides } = song;
  return getFirst(guides);
};

// This was hard :(
export function groupSectionsByPage(pages = [], sections = []) {
  if (pages.length === 0 || sections.length === 0) {
    return [];
  }

  const sectionsByPage = pages.map(page => []);

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const { position } = section;
    for (let j = 0; j < pages.length; j++) {
      const pageEnd = j === pages.length - 1 ? Infinity : pages[j + 1].position;
      if (position < pageEnd) {
        sectionsByPage[j].push(section);
        break;
      }
    }
  }

  return sectionsByPage;
}

export const groupSections = (sections = []) =>
  chunksWhere(section => section?.isPageBreak, sections);

function chunksWhere(condition, array = []) {
  const matrix = [];
  let unchunked = array;
  for (let i = array.length - 1; i >= 0; i--) {
    const element = array[i];
    if (condition(element) || i === 0) {
      const [remaining, chunk] = splitAt(i, unchunked);
      matrix.push(chunk);
      unchunked = remaining;
    }
  }
  return matrix.reverse();
}
