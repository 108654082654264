import { connect } from 'react-redux';
import { getActiveToolName } from '../../../store/player/player.selectors';
import { enableTool, disableTool } from '../../../store/player/player.actions';
import StudioToolbar from './StudioToolbar';

import { getActiveSongCuid } from '../../../store/myMusic/myMusic.selectors';

const mapStateToProps = state => {
  const songCuid = getActiveSongCuid(state);

  const activeToolName = getActiveToolName(state);
  return { songCuid, activeToolName };
};

const mapDispatchToProps = dispatch => ({
  onClickEnableTool: toolName => {
    dispatch(enableTool(toolName));
  },
  onClickDisableTool: toolName => {
    dispatch(disableTool(toolName));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudioToolbar);
