import styled from 'styled-components';

export const Register = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-top: 25px;
  padding-bottom: 125px;
`;

export const Register__Inputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0px auto;
  text-align: center;
  margin-top: 10px;
`;

export const Register__Input = styled.div`
  margin-top: 10px;
`;

export const RegisterButton = styled.div`
  width: 80%;
`;

export const Register__TermsAndConditions = styled.div`
  display: none;
  font-size: 0.825rem;
`;

export const Register__SocialMediaRegistration = styled.div`
  display: none;
  /*display: flex;*/
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
