import generateCuid from 'cuid';
import _gtag from '../analytics/analyticsEvent';

const refreshRateHz = 60;
export const tickSizeMillis = 1000 / refreshRateHz;

const state = {
  subscribers: {},
  timerId: null,
  srcList: [],
  audioElems: [],
  isLoading: [],
  selectedTrackIndex: 0,
  loop: false,
  isMuted: false,
  onEndedCallback: () => {},
};

const loadAudio = (srcList = [], onEndedCallback = () => {}) => {
  pause();
  state.srcList = srcList;
  state.audioElems = srcList.map(createAudioElem);
  state.isLoading = srcList.map(() => true);
  state.selectedTrackIndex = 0;
  state.onEndedCallback = onEndedCallback;

  loadTrack(0);
};

const loadTrack = trackIndex => {
  const audioElem = state.audioElems[trackIndex];
  if (audioElem) {
    audioElem.src = state.srcList[trackIndex];
  }
};

const createAudioElem = (src, index) => {
  const audioElem = document.createElement('audio');
  audioElem.id = 'audio-player';
  audioElem.controls = 'controls';
  // audioElem.src = src;
  audioElem.type = 'audio/mpeg';
  audioElem.loop = state.loop;
  audioElem.preload = false;
  audioElem.onerror = () => {
    // TODO: better error handling
    console.error(audioElem.error.code);
    _gtag('event', 'audio-load-error', { code: audioElem.error.code });
  };
  audioElem.onended = () => {
    state.onEndedCallback();
  };
  audioElem.onplay = () => {};
  audioElem.oncanplay = () => {
    state.isLoading[index] = false;
  };
  audioElem.oncanplaythrough = () => {};

  return audioElem;
};

const play = () => {
  _gtag('event', 'play');

  const track = state.audioElems[state.selectedTrackIndex];

  if (track) {
    track.play().catch(e => {
      console.error(e);
      _gtag('event', 'audio-playback-error', { code: e });

      alert('Sorry, there was a problem playing that particular file');
    });
    state.timerId && window.clearInterval(state.timerId);

    state.timerId = window.setInterval(() => {
      refreshMutedState();
      emit();
    }, 1000 / refreshRateHz);
  }
  emit();
};

const pause = trackIndex => {
  _gtag('event', 'pause');
  state.audioElems.forEach(audioElem => audioElem.pause());
  window.clearInterval(state.timerId);
  emit();
};

const seek = positionSeconds => {
  if (Number.isNaN(positionSeconds)) {
    return;
  }
  state.audioElems[state.selectedTrackIndex].currentTime = positionSeconds;
  emit();
};

const loop = (isLoopEnabled = false) => {
  const { audioElems } = state;
  state.loop = isLoopEnabled;
  audioElems.forEach(audioElem => (audioElem.loop = isLoopEnabled));
};

const mute = (isMuted = false) => {
  state.isMuted = isMuted;
  refreshMutedState();
};

const refreshMutedState = () =>
  state.audioElems.forEach(audioElem => (audioElem.muted = state.isMuted));

const subscribe = callback => {
  const id = generateCuid();
  state.subscribers[id] = callback;
  return id;
};

const unsubscribe = id => {
  delete state.subscribers[id];
};

const emit = () => {
  const { subscribers, selectedTrackIndex } = state;
  const ids = Object.keys(subscribers).sort();
  const currentTimeSeconds = getCurrentTimeSeconds();
  const currentTimeMillis = Math.round(currentTimeSeconds * 1000);
  const currentTrack = getCurrentTrack();
  const bufferChunks = [];

  let duration = NaN;
  let isPlaying = false;
  if (currentTrack) {
    const { buffered } = currentTrack;
    duration = currentTrack.duration;
    isPlaying = !currentTrack.paused;

    for (let i = 0; i < buffered.length; i++) {
      const chunk = {
        start: buffered.start(i),
        end: buffered.end(i),
      };
      bufferChunks.push(chunk);
    }
  }

  ids.forEach(id => {
    const subscriber = subscribers[id];
    if (subscriber) {
      subscriber({
        currentTimeSeconds,
        currentTimeMillis,
        isPlaying,
        bufferChunks,
        duration,
        durationMillis: duration * 1000,
        selectedTrackIndex: selectedTrackIndex,
        isLoading: state.isLoading[selectedTrackIndex],
      });
    }
  });
};

const getCurrentTimeSeconds = () => {
  const audioElem = state.audioElems[state.selectedTrackIndex];
  return audioElem ? audioElem.currentTime : 0;
};

export const getCurrentTimeMillis = () =>
  Math.round(getCurrentTimeSeconds() * 1000);

const changeTrack = newTrackIndex => {
  try {
    const oldTrack = state.audioElems[state.selectedTrackIndex];
    const newTrack = state.audioElems[newTrackIndex];
    state.selectedTrackIndex = newTrackIndex;

    if (!newTrack.src) {
      loadTrack(newTrackIndex);
    }

    pause();
    play();

    newTrack.currentTime = oldTrack.currentTime;

    newTrack.addEventListener(
      'canplay',
      () => {
        play();
        newTrack.currentTime = oldTrack.currentTime;
      },
      { once: true },
    );
    emit();
  } catch (e) {
    console.error('Error in changeTrack()', e);
  }
};

const position = () => {
  return state.audioElems[state.selectedTrackIndex].currentTime;
};

const getCurrentTrack = () => {
  return state.audioElems[state.selectedTrackIndex];
};

export default {
  loadAudio,
  loadTrack,
  play,
  pause,
  seek,
  loop,
  mute,
  subscribe,
  unsubscribe,
  changeTrack,
  position,
};
