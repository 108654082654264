import apiInit from '../api';

const { post, get, del } = apiInit('groups');

export const postSubscriptionDB = async (token, couponCode) => {
  return post('/billing/subscribe', { token, couponCode });
};

export const fetchSubscriptionDB = async () => get('/billing');

export const fetchCouponDB = async couponCode =>
  get(`/billing/coupon/${couponCode}`);

export const cancelSubscriptionDB = async () => {
  return del('/billing/unsubscribe');
};

export const updateSubscriptionDB = async token => {
  return post('/billing/update', { token });
};

export const resubscribeDB = async () => {
  return post('/billing/update', { resubscribe: true });
};
