import styled from 'styled-components';

export const PlayerTitleBar = styled.div`
  background-color: var(--choirpal-red-100);
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  line-height: 50px;
  user-select: none;
`;

export const MaxMinToggle = styled.div`
  color: var(--choirpal-white);
  font-size: 36px;
  padding: 0px 13px;
  transition: transform 150ms ease-in;
  transform: rotate(${props => (props.isMaximised ? '180deg' : '0deg')});
`;

export const RightIcon = styled.div`
  color: var(--choirpal-white);
  /* width: 50px; */
  padding: 8px;
  display: flex;
  align-items: center;
`;

export const SongTitle = styled.div`
  font-family: 'Platform Web';
  font-weight: 400;
  color: var(--choirpal-white);
  font-size: large;
  overflow: hidden;
`;
