import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './MyAccountPage.styled';
import PlansConnected from '../Plans/PlansConnected';
import BillingDetailsConnected from '../Billing/BillingDetails/BillingDetailsConnected';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Avatar,
  Divider,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  // FormControlLabel,
  // Link,
} from '@material-ui/core';
import SectionHeader from '../../Layout/SectionHeader/SectionHeader';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
  LockRounded,
  ExpandLess,
  ExpandMore,
  ExitToApp,
} from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { cp1_red } from '../../Theme/colors';
import H3 from '../../Typography/Heading/H3';
import PremiumBadge from '../PremiumBadge/PremiumBadge.js';

import Loading from '../../Layout/Loading/Loading';

import { isBillingEnabled } from '../../../util/featureToggle';

function MyAccountPage(props) {
  const {
    email,
    doFetchSubscription,
    isFetchingSubscription,
    subscription,
    onClickLogout,
    onPrivacyClick,
  } = props;
  const [open, setOpen] = useState(false);
  const { status, cancel_at_period_end, trial_ending } = subscription;
  // boolean for warning symbol
  const warning =
    cancel_at_period_end || status === 'incomplete' || status === 'past_due';
  useEffect(() => {
    doFetchSubscription && doFetchSubscription();
  }, [doFetchSubscription]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handlePrivacyClick = () => {
    onPrivacyClick();
  };
  const handleLogoutClick = () => {
    onClickLogout();
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      borderTopWidth: '1px',
    },
    avatar: {
      backgroundColor: cp1_red,
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
  }));
  const classes = useStyles();
  return (
    <S.MyAccountPage>
      <SectionHeader title="My Account" />
      {isFetchingSubscription ? (
        <Loading />
      ) : (
        <Fragment>
          <S.Profile>
            <S.UserAvatar>
              <Avatar alt="" sizes="large" className={classes.avatar} />
              <S.Email>
                <H3>{email}</H3>

                {isBillingEnabled() && <PremiumBadge status={status} />}
              </S.Email>
            </S.UserAvatar>

            {status === 'trialing' && isBillingEnabled() && (
              <S.MyAccountPage__Alert>
                You are currently on a trial which will end on{' '}
                <b>{trial_ending}</b>
                {!cancel_at_period_end && (
                  <Fragment>
                    , at which point your card will be charged.
                  </Fragment>
                )}
              </S.MyAccountPage__Alert>
            )}
          </S.Profile>
          <Divider />

          <Fragment>
            {isBillingEnabled() && (
              <Fragment>
                <List className={classes.root}>
                  <ListItem button onClick={handleClick}>
                    <ListItemAvatar>
                      <ReceiptIcon fontSize="large" color="primary" />
                    </ListItemAvatar>
                    <ListItemText primary="Billing" />
                    {open ? (
                      <ExpandLess />
                    ) : (
                      <Fragment>
                        {warning && <WarningIcon color="primary" />}
                        <ExpandMore />
                      </Fragment>
                    )}
                  </ListItem>
                </List>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem>
                      {status ? (
                        <Fragment>
                          <BillingDetailsConnected />
                        </Fragment>
                      ) : (
                        <PlansConnected
                          has_trialed={subscription.has_trialed}
                        />
                      )}
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            )}

            <List className={classes.root}>
              <ListItem button onClick={handlePrivacyClick}>
                <ListItemAvatar>
                  <LockRounded fontSize="large" color="primary" />
                </ListItemAvatar>
                <ListItemText primary="Privacy Policy" />
              </ListItem>
            </List>

            <List className={classes.root}>
              <ListItem button onClick={handleLogoutClick}>
                <ListItemAvatar>
                  <ExitToApp fontSize="large" color="primary" />
                </ListItemAvatar>
                <ListItemText primary="Log Out" />
              </ListItem>
            </List>
          </Fragment>
        </Fragment>
      )}
    </S.MyAccountPage>
  );
}

MyAccountPage.propTypes = {
  email: PropTypes.string.isRequired,
  subscription: PropTypes.object,
  isFetchingSubscription: PropTypes.bool,
  doFetchSubscription: PropTypes.func.isRequired,
  doBillingDetailsClick: PropTypes.func.isRequired,
  onClickLogout: PropTypes.func.isRequired,
  onPrivacyClick: PropTypes.func.isRequired,
};

export default MyAccountPage;
