import downloadjs from 'downloadjs';
import _gtag from '../analytics/analyticsEvent';

export const downloadSheetMusic = (pdfUrl, filename) => {
  _gtag('event', 'DownloadSheetMusic');

  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf('iPhone') !== -1 ||
    navigator.platform.indexOf('iPad') !== -1 ||
    navigator.platform.indexOf('iPod') !== -1
  ) {
    window.open(pdfUrl);
  } else {
    downloadjs(pdfUrl, filename);
  }
};
