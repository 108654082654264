import { connect } from 'react-redux';
import { showModal } from '../../../store/modal/modal.actions';
import { getSongByCuid } from '../../../store/myMusic/myMusic.selectors';
import { shareSongRoute, songDetailsRoute } from '../../routes';
import SongMenu from './SongMenu';

const mapStateToProps = (state, ownProps) => {
  const { songCuid } = ownProps;
  const songDocument = getSongByCuid(songCuid, state);
  const { songTitle } = songDocument.meta;

  return {
    songCuid,
    songTitle,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickSongDetails: songCuid => {
    dispatch(songDetailsRoute(songCuid));
  },
  onClickShare: songCuid => {
    dispatch(shareSongRoute(songCuid));
  },
  onClickDelete: (songCuid, songTitle) => {
    dispatch(showModal('DELETE_SONG', { songCuid, songTitle }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SongMenu);
