import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      stroke="#FF7276"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M5,145c2.341-33.25,15.28-51.417,31.555-61.808"
    />
    <path
      fill="none"
      stroke="#FF7276"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M31.513,145H145
    c-2.362-33.25-15.291-51.417-31.576-61.808"
    />
    <g>
      <path
        fill="#FF7276"
        d="M73.939,11c18.97,0,34.403,15.428,34.403,34.392c0,18.958-15.433,34.382-34.403,34.382
      c-18.958,0-34.382-15.424-34.382-34.382C39.558,26.428,54.981,11,73.939,11 M73.939,5C51.627,5,33.558,23.079,33.558,45.392
      c0,22.297,18.069,40.382,40.382,40.382c22.323,0,40.403-18.085,40.403-40.382C114.342,23.079,96.263,5,73.939,5L73.939,5z"
      />
    </g>
  </g>,
  150,
  150,
);
