import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Dots.styled';

function Dots(props) {
  const { numDots, selectedDot, numSelectedDots, dotClicked } = props;

  const dots = [];

  for (let i = 0; i < numDots; i++) {
    const isActive = i >= selectedDot && i < selectedDot + numSelectedDots;

    dots.push(
      <S.Dot
        key={`dot-${i}`}
        onClick={event => dotClicked(i)}
        isActive={isActive}
      />,
    );
  }

  return <S.Dots>{dots}</S.Dots>;
}

Dots.propTypes = {
  numDots: PropTypes.number.isRequired,
  selectedDot: PropTypes.number.isRequired,
  numSelectedDots: PropTypes.number.isRequired,
  dotClicked: PropTypes.func.isRequired,
};

export default Dots;
