import {
  fetchApiVersionStarted,
  fetchApiVersionSuccess,
  fetchApiVersionFailed,
} from './app.actions';
import { fetchApiVersion } from './app.api';

export const fetchApiVersionThunk = () => (dispatch, getState) => {
  dispatch(fetchApiVersionStarted());
  return fetchApiVersion()
    .then(response => {
      dispatch(fetchApiVersionSuccess(response));
    })
    .catch(error => {
      console.error(error);
      dispatch(fetchApiVersionFailed());
    });
};
