import CachedIcon from '@material-ui/icons/Cached';
import PropTypes from 'prop-types';
import React from 'react';
import * as S from './FileUploadProgressBar.styled';

function FileUploadProgressBar({
  filename,
  bytesUploaded,
  fileSizeBytes,
  numFilesRemaining,
}) {
  const percentUploaded = (100 * bytesUploaded) / fileSizeBytes;
  const isVisible = numFilesRemaining > 0;
  return (
    <S.FileUploadProgressBarContainer isVisible={isVisible}>
      <S.FileUploadProgressBar>
        <S.DetailsContainer>
          <S.Icon>
            <CachedIcon
              color="primary"
              aria-label="Uploading"
              style={{ transform: 'scale(1, -1)' }}
            />
          </S.Icon>
          <S.Filename>{filename}</S.Filename>
          <S.FilesRemaining>{numFilesRemaining} files left</S.FilesRemaining>
        </S.DetailsContainer>
        <S.ProgressBarContainer>
          <S.ProgressBar percentUploaded={percentUploaded || 0} />
        </S.ProgressBarContainer>
      </S.FileUploadProgressBar>
    </S.FileUploadProgressBarContainer>
  );
}

FileUploadProgressBar.propTypes = {
  filename: PropTypes.string.isRequired,
  bytesUploaded: PropTypes.number.isRequired,
  fileSizeBytes: PropTypes.number.isRequired,
  numFilesRemaining: PropTypes.number.isRequired,
};

export default FileUploadProgressBar;
