import { values, fromPairs } from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import { trackPageView, trackEvent } from '@redux-beacon/google-analytics-gtag';
import { isPrd } from '../util/envUtil';
import { version } from '../../package.json';

import * as appEvents from '../store/app/app.events';
import * as settingsEvents from '../store/settings/settings.events';
import * as userEvents from '../store/user/user.events';
import * as playerEvents from '../store/player/player.events';
import * as myMusicEvents from '../store/myMusic/myMusic.events';
import * as groupsEvents from '../store/groups/groups.events';

export const trackingId = 'UA-125077738-1';

const _gtag = isPrd()
  ? window.gtag ||
    (() => {
      console.info('gtag not available');
    })
  : () => null;

export default _gtag;

// TODO: Better place or way to do this?
_gtag('event', 'version', { version });

const pageViewMap = {
  [LOCATION_CHANGE]: trackPageView(action => {
    // console.log('trackPageView', action);
    return {
      path: action.payload.pathname,
    };
  }),
};

const genericEvent = trackEvent((actionObject, prevState, nextState) => {
  // console.log('actionObject', actionObject);
  const [category, action] = actionObject.type.split('/');
  return {
    category,
    action,
    // label: '',
    // value: '',
  };
});

const generateEventsMap = reduxEvents =>
  fromPairs(values(reduxEvents).map(n => [n, genericEvent]));

export const eventsMap = isPrd()
  ? {
      ...pageViewMap,
      ...generateEventsMap(appEvents),
      ...generateEventsMap(myMusicEvents),
      ...generateEventsMap(playerEvents),
      ...generateEventsMap(settingsEvents),
      ...generateEventsMap(userEvents),
      ...generateEventsMap(groupsEvents),
    }
  : {};
