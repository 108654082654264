import React from 'react';
import PropTypes from 'prop-types';

import './TitleDescription.css';

export default function TitleDescription({ title, description }) {
  return (
    <div className="TitleDescription">
      <h2 className="TitleDescription__Title">{title}</h2>
      <p className="TitleDescription__Description">{description}</p>
    </div>
  );
}

TitleDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
