import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { choirpal_blue_100 } from '../../Theme/colors';

const fontSizes = {
  small: 26,
  medium: 38,
  large: 50,
};

const StyledH2 = styled.h2`
  font-family: 'Platform Web';
  font-weight: 400;
  color: ${choirpal_blue_100};
  font-size: ${props => props.fontSize}px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default function H2({ children, className, size = 'small' }) {
  const fontSize = fontSizes[size];
  return (
    <StyledH2 fontSize={fontSize} className={className}>
      {children}
    </StyledH2>
  );
}

H2.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.array,
  size: PropTypes.string,
};
