import styled, { createGlobalStyle } from 'styled-components';

export const BottomContainer = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
`;

export const FloatingActionButtonRoot = styled.div`
  position: absolute;
  right: 0px;
  top: -70px;

  margin-bottom: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  z-index: 99999999999999;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    /* Disables pull to refresh */
    /*overflow: hidden;*/

    position: fixed;
    margin: 0;
    height: 100%;
    width: 100%;
  }
`;

export const App__BottomPadder = styled.div`
  min-height: 50px;
  // Foo
`;
