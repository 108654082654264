// posting subscription to DB
export const POST_SUBSCRIPTION_STARTED = 'billing/postSubscriptionStarted';
export const POST_SUBSCRIPTION_SUCCESS = 'billing/postSubscriptionSuccess';
export const POST_SUBSCRIPTION_FAIL = 'billing/postSubscriptionFail';

// fetching coupon from stripe
export const FETCH_COUPON_STARTED = 'billing/fetchCouponStarted';
export const FETCH_COUPON_SUCCESS = 'billing/fetchCouponSuccess';
export const FETCH_COUPON_FAIL = 'billing/fetchCouponFail';

// initial stripe token call failed
export const POST_STRIPE_FAIL = 'billing/postStripeFail';

// fetching from DB
export const FETCH_SUBSCRIPTION_STARTED = 'billing/fetchSubscriptionStarted';
export const FETCH_SUBSCRIPTION_SUCCESS = 'billing/fetchSubscriptionSuccess';
export const FETCH_SUBSCRIPTION_FAIL = 'billing/fetchSubscriptionFail';

export const CANCEL_SUBSCRIPTION_STARTED = 'billing/cancelSubscriptionStarted';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'billing/cancelSubscriptionSuccess';
export const CANCEL_SUBSCRIPTION_FAIL = 'billing/cancelSubscriptionFail';

export const RESUBSCRIBE_STARTED = 'billing/resubscribeStarted';
export const RESUBSCRIBE_SUCCESS = 'billing/resubscribeSuccess';
export const RESUBSCRIBE_FAIL = 'billing/resubscribeFail';

// hide modal error/success messages
export const CLEAR_MODAL_MSGS = 'billing/clearModalMsgs';

// clear coupon
export const CLEAR_STRIPE_ERRORS = 'billing/clearStripeErrors';
