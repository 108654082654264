// import generateCuid from 'cuid';
import { batch } from 'react-redux';
import {
  postSongDocument,
  fetchMySongs,
  fetchSongDocument,
  deleteSong,
  shareSongGroups,
  postFileRecord,
  postFileset,
  fetchSongGroups,
} from './myMusic.api';
import {
  isFetchingSongList,
  getMySong,
  getFileset,
  getTrack,
  getActiveSongCuid,
  getActiveGuideCuid,
} from './myMusic.selectors';
import { getCognitoId } from '../user/user.selectors';
import { s3FileUpload, s3Delete, fetchS3Urls } from '../s3/s3-utils';
import AudioPlayer, { getCurrentTimeMillis } from '../../webaudio/AudioPlayer';
import {
  fetchSongsStarted,
  fetchSongsSuccess,
  fetchSongsFail,
  fetchSongGroupsStarted,
  fetchSongGroupSuccess,
  fetchSongGroupsFail,
  postSongStarted,
  postSongSuccess,
  postSongFail,
  deleteSongStarted,
  deleteSongSuccess,
  deleteSongFailed,
  shareSongStarted,
  shareSongSuccess,
  shareSongFail,
  setUploadStatus,
  addTrack,
  addSheetMusic,
  deleteTrack,
  postFilesetStarted,
  postFilesetSuccess,
  storeFileset,
  storeSong,
  storeFilesetUrls,
  addSection,
  deleteSection,
  nudgeSection,
  addPage,
  deletePage,
  deleteSheetMusic,
} from './myMusic.actions';
import { getIdentityId } from '../api';
import throttle from 'lodash/throttle';

import {
  newTrack,
  newFileRecord,
  newFileset,
  newFilesetWithout,
  newSection,
  newSheetMusic,
} from './myMusic.model';
import { hardcodedSongs } from './myMusic.hardcodedSongs';
import migrate from './myMusic.migrate';
import { asList } from '../storeUtil';

export const fetchSongThunk = song => dispatch => {
  return fetchSongDocument(song)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchMySongsThunk = () => (dispatch, getState) => {
  const state = getState();
  const isAlreadyFetching = isFetchingSongList(state);
  if (isAlreadyFetching) {
    return;
  }
  const cognitoId = getCognitoId(state);
  dispatch(fetchSongsStarted());
  return fetchMySongs(cognitoId)
    .then(response => {
      response.forEach(song => {
        dispatch(storeSong(song.songDocument));
        song.fileset && dispatch(storeFileset(song.fileset));
      });
      dispatch(fetchSongsSuccess(response));
    })
    .catch(error => {
      console.error(error);
      dispatch(fetchSongsFail());
    });
};

export const fetchSongGroupsThunk = songCuid => dispatch => {
  dispatch(fetchSongGroupsStarted());
  return fetchSongGroups(songCuid)
    .then(response => {
      dispatch(fetchSongGroupSuccess(songCuid, response));
    })
    .catch(error => {
      console.error(error);
      dispatch(fetchSongGroupsFail());
    });
};

export const deleteSongThunk = songCuid => dispatch => {
  dispatch(deleteSongStarted());
  return deleteSong(songCuid)
    .then(response => {
      dispatch(deleteSongSuccess(songCuid));
    })
    .catch(error => {
      console.error(error);
      dispatch(deleteSongFailed);
    });
};

export const s3UploadThunk = (file, path, callback) => dispatch => {
  return s3FileUpload(file, path, callback)
    .then(response => {
      console.log('Key', response);
    })
    .catch(err => {
      console.error(err);
    });
};

export const s3UploadFilesThunk = (fileList = [], songCuid, type) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const song = getMySong(songCuid, state);
  const { filesetCuid } = song;
  let fileset = getFileset(filesetCuid, state);
  // Loop through selected files
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);

    // Display # files remaining
    const numFilesRemaining = fileList.length - i;
    dispatch(setUploadStatus(numFilesRemaining, file.name));

    // Create file record
    const identityId = await getIdentityId();
    const fileRecord = newFileRecord(file, identityId);
    await postFileRecord(fileRecord);

    // Upload media to S3
    await s3FileUpload(file, fileRecord.fileCuid);

    // Create new fileset
    fileset = newFileset(fileRecord, fileset);
    dispatch(postFilesetStarted);
    await postFileset(fileset);
    dispatch(postFilesetSuccess(fileset));
    dispatch(storeFileset(fileset));

    // Add Track and fileset to song
    if (type === 'AUDIO_TRACK') {
      const track = newTrack(fileRecord.fileCuid);

      dispatch(addTrack(songCuid, track, fileset.filesetCuid));
    }
    if (type === 'SHEET_MUSIC') {
      const sheetMusic = newSheetMusic(fileRecord.fileCuid);

      dispatch(addSheetMusic(songCuid, sheetMusic, fileset.filesetCuid));
    }
  }
  dispatch(setUploadStatus(0, ''));
};

export const fetchFileUrlsThunk = fileset => async (dispatch, getState) => {
  const { filesetCuid, fileRecords = {} } = fileset;
  const fileRecordList = asList(fileRecords);

  const urlsList = await fetchS3Urls(fileRecordList);
  const filesetUrls = {};
  fileRecordList.forEach((fileRecord, i) => {
    filesetUrls[fileRecord.fileCuid] = urlsList[i];
  });
  dispatch(storeFilesetUrls(filesetCuid, filesetUrls));
};

export const s3DeleteThunk = (filename, callback) => dispatch => {
  return s3Delete(filename, callback)
    .then(response => {
      console.log('Deleted: ', response);
    })
    .catch(err => {
      console.error(err);
    });
};

const throttledPostSongDocument = throttle(postSongDocument, 2000);

export const postSongThunk = songDocument => (dispatch, getState) => {
  const cognitoId = getCognitoId(getState());
  dispatch(postSongStarted());
  return throttledPostSongDocument(songDocument, cognitoId)
    .then(response => {
      dispatch(postSongSuccess(response));
    })
    .catch(error => {
      console.error(error);
      dispatch(postSongFail());
    });
};

export const deleteMediaThunk = (songCuid, trackCuid) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const song = getMySong(songCuid, state);
  const { filesetCuid } = song;
  const track = getTrack(songCuid, trackCuid, state);
  const { fileCuid } = track;
  const fileset = getFileset(filesetCuid, state);
  const newFileset = newFilesetWithout(fileCuid, fileset);

  dispatch(postFilesetStarted());

  await postFileset(newFileset);
  dispatch(postFilesetSuccess(newFileset));
  dispatch(storeFileset(newFileset));

  song.filesetCuid = newFileset.filesetCuid;

  await s3Delete(fileCuid);
  dispatch(deleteTrack(songCuid, trackCuid));
};

export const deleteSheetMusicThunk = (songCuid, sheetMusicCuid) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const song = getMySong(songCuid, state);
  const { filesetCuid } = song;
  const sheet = song.sheetMusic[sheetMusicCuid];
  const { fileCuid } = sheet;
  const fileset = getFileset(filesetCuid, state);
  const newFileset = newFilesetWithout(fileCuid, fileset);

  dispatch(postFilesetStarted());

  await postFileset(newFileset);
  dispatch(postFilesetSuccess(newFileset));
  dispatch(storeFileset(newFileset));

  song.filesetCuid = newFileset.filesetCuid;

  await s3Delete(fileCuid);
  dispatch(deleteSheetMusic(songCuid, sheetMusicCuid));
};

export const shareSongGroupsThunk = (
  songCuid,
  groupCuids,
) => async dispatch => {
  dispatch(shareSongStarted());
  return shareSongGroups(songCuid, groupCuids)
    .then(response => {
      dispatch(shareSongSuccess(songCuid, groupCuids));
      console.log(response);
    })
    .catch(err => {
      dispatch(shareSongFail());
      console.log(err);
    });
};

export const storeHardcodedSongsThunk = tutorial => dispatch => {
  let hardcodedSongsList;
  if (tutorial) {
    // only load the tutorial
    hardcodedSongsList = hardcodedSongs.ck6vitk6l00op3h5qjoawrm2y;
  }
  hardcodedSongsList = asList(hardcodedSongs);

  // Reduce number of renders
  batch(() => {
    hardcodedSongsList.forEach(song => storeHardcodedSong(song, dispatch));
  });
};

export const storeHardcodedSong = (song, dispatch) => {
  const migratedSong = migrate(song);
  const trackList = asList(migratedSong.tracks);
  const sheetMusicList = asList(migratedSong.sheetMusic);

  const fileRecordList = [...trackList, ...sheetMusicList].map(
    objectToFileRecord,
  );

  let fileset = newFileset();
  fileRecordList.forEach(fileRecord => {
    fileset = newFileset(fileRecord, fileset);
  });
  migratedSong.filesetCuid = fileset.filesetCuid;
  dispatch(storeFileset(fileset));
  // dispatch(storeFilesetUrls(fileset.filesetCuid, filesetUrls));
  dispatch(storeSong(migratedSong));
};

function objectToFileRecord(track) {
  const fileRecord = newFileRecord();
  fileRecord.fileCuid = track.fileCuid;

  return fileRecord;
}

export const addSectionThunk = (
  position = getCurrentTimeMillis(),
  lyrics = '',
) => (dispatch, getState) => {
  const state = getState();

  const guideCuid = getActiveGuideCuid(state);
  const songCuid = getActiveSongCuid(state);
  const section = newSection(position, lyrics);
  dispatch(addSection(songCuid, guideCuid, section));
};

export const deleteSectionThunk = (
  currentTimeMillis = getCurrentTimeMillis(),
) => (dispatch, getState) => {
  const state = getState();

  const guideCuid = getActiveGuideCuid(state);
  const songCuid = getActiveSongCuid(state);
  dispatch(deleteSection(songCuid, guideCuid, currentTimeMillis));
};

export const nudgeSectionThunk = (position, offset) => (dispatch, getState) => {
  const state = getState();

  AudioPlayer.seek((position + offset) / 1000);

  const guideCuid = getActiveGuideCuid(state);
  const songCuid = getActiveSongCuid(state);
  dispatch(nudgeSection(songCuid, guideCuid, position, offset));
};

export const addPageThunk = (position, pageTitle = 'Title') => (
  dispatch,
  getState,
) => {
  const state = getState();
  const songCuid = getActiveSongCuid(state);

  const guideCuid = getActiveGuideCuid(state);
  dispatch(addPage(songCuid, guideCuid, position, pageTitle));
};

export const deletePageThunk = position => (dispatch, getState) => {
  const state = getState();

  const guideCuid = getActiveGuideCuid(state);
  const songCuid = getActiveSongCuid(state);
  dispatch(deletePage(songCuid, guideCuid, position));
};

// const throttledExample = throttle(position => console.log({ position }), 2000);
