import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Conductor from '../../Theme/Conductor/Conductor';
import Trio from '../../Theme/Trio/Trio';
import H1 from '../../Typography/Heading/H1';
import * as S from './PopupView.styled';
// import PlayerScreenshot from '../../Site/Screenshot/Screenshot--player.png';
import Screenshot from '../../Site/Screenshot/Screenshot';

const avaiableImages = {
  conductor: Conductor,
  trio: Trio,
  playerScreenshot: () => <Screenshot variant="player" size="small" />,
};

export default function PopupView({
  title,
  imageName,
  body,
  buttonText,
  onDismiss,
  listItems,
}) {
  function handleClick() {
    onDismiss();
  }
  const ImageComponent = avaiableImages[imageName] || Conductor;
  return (
    <S.PopupView>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <H1 size="small">{title}</H1>
        <ImageComponent size="xsmall" />
        <p
          style={{
            marginTop: 20,
            width: '80%',
            textAlign: 'center',
            fontSize: 20,
          }}>
          {body}
        </p>
        {listItems && (
          <ul
            style={{
              marginTop: 5,
              width: '80%',
              textAlign: 'left',
              fontSize: 18,
            }}>
            {listItems.map((item, i) => (
              <li style={{ marginTop: 5 }} key={i}>
                {item}
              </li>
            ))}
          </ul>
        )}
        {buttonText && (
          <Button
            onClick={handleClick}
            style={{ margin: 20, width: '80%' }}
            variant="contained"
            color="primary"
            size="large">
            {buttonText}
          </Button>
        )}
      </div>
    </S.PopupView>
  );
}

PopupView.propTypes = {
  title: PropTypes.string,
  imageName: PropTypes.string,
  body: PropTypes.any,
  buttonText: PropTypes.string,
  onDismiss: PropTypes.func,
  listItems: PropTypes.array,
};
