import styled from 'styled-components';

export const ArchImage = styled.img`
  min-width: 320px;
  height: 800px;
  /*max-width: 275px;*/
  /*width: 100%;*/
`;

export const Arch = styled.div``;
