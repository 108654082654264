import { dissoc } from 'ramda';
import { version } from '../../../package.json';
import generateCuid from 'cuid';
import { byId } from '../storeUtil';

export function newSong() {
  const defaultGuide = newGuide();
  return {
    __schemaVersion: version,
    songCuid: generateCuid(),
    filesetCuid: null,
    createdTimestamp: null,
    updatedTimestamp: null,
    meta: { songTitle: '', writtenBy: '', recordedBy: '' },
    tracks: {},
    trackOrder: [],
    sheetMusic: {},
    sheetMusicOrder: [],
    guides: byId('guideCuid', [defaultGuide]),
  };
}

export function newTrack(fileCuid) {
  return {
    trackCuid: generateCuid(),
    fileCuid,
    voicePart: '',
    subPart: '',
    audioMix: '',
    guideCuid: '',
  };
}

export function newSheetMusic(fileCuid) {
  return {
    sheetMusicCuid: generateCuid(),
    fileCuid,
  };
}

export function newGuide(guideCuid) {
  const sectionList = [newSection()];
  return {
    guideCuid: guideCuid || generateCuid(),
    guideTitle: '',
    sections: byId('position', sectionList),
  };
}

export const newSection = (
  position = 0,
  lyrics = 'add lyrics',
  isPageBreak = false,
  pageTitle = '',
) => ({
  position,
  lyrics,
  isPageBreak,
  pageTitle,
});

export function newFileRecord(file = {}, identityId) {
  return {
    fileCuid: generateCuid(),
    filename: file.name,
    sizeInBytes: file.size,
    mimeType: file.type,
    lastModified: new Date(file.lastModified || 0).toISOString(),
    identityId,
  };
}

export function newFileset(fileRecord, fileset = {}) {
  const records = fileset.fileRecords || {};
  const out = {
    filesetCuid: generateCuid(),
    fileRecords: { ...records },
  };
  if (fileRecord) out.fileRecords[fileRecord.fileCuid] = fileRecord;
  return out;
}

export function newFilesetWithout(fileCuid, fileset = {}) {
  const { fileRecords } = fileset;
  const fileRecordsWithout = dissoc(fileCuid, fileRecords);
  const out = {
    filesetCuid: generateCuid(),
    fileRecords: fileRecordsWithout,
  };
  return out;
}
