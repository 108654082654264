import { connect } from 'react-redux';
import Login from './Login';
import {
  getErrorMsg,
  isPostingCredentials,
  hasFailedLogin,
} from '../../../store/user/user.selectors';
import { registerRoute } from '../../routes';
import {
  loginThunk,
  doGoogleLogin,
  doFacebookLogin,
  doPostLogin,
} from '../../../store/user/user.thunks';
import { hideError } from '../../../store/user/user.actions';

const mapStateToProps = state => ({
  errorMsg: getErrorMsg(state),
  isPostingCredentials: isPostingCredentials(state),
  hasFailedLogin: hasFailedLogin(state),
});

const mapDispatchToProps = dispatch => ({
  onClickLogin: (username, password, route) => {
    dispatch(loginThunk(username, password, route));
  },
  onGoogleLogin: () => {
    dispatch(doGoogleLogin());
  },
  onFacebookLogin: () => {
    dispatch(doFacebookLogin());
  },
  onPostLogin: () => {
    dispatch(doPostLogin());
  },
  onDismissError: () => {
    dispatch(hideError());
  },
  onClickRegister: () => {
    dispatch(registerRoute());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
