import styled from 'styled-components';

export const Song = styled.div`
  background-color: white;
  width: 100%;
  /*display: flex;*/
  flex-direction: column;
  font-size: 20px;
  padding-bottom: 100px;
  /*max-width: 600px;*/
  margin: 0px auto;
  user-select: none;
`;

export const EditToggle = styled.div`
  position: absolute;
  top: 56px;
  right: 8px;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4px;
`;
