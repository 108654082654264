import {
  ADD_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  INVITE_MEMBERS_STARTED,
  INVITE_MEMBERS_SUCCESS,
  INVITE_MEMBERS_FAIL,
  FETCH_GROUPS_STARTED,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAIL,
  POST_GROUP_STARTED,
  POST_GROUP_SUCCESS,
  POST_GROUP_FAIL,
  DELETE_GROUP_STARTED,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  LEAVE_GROUP_STARTED,
  LEAVE_GROUP_SUCCESS,
  LEAVE_GROUP_FAIL,
  DELETE_MEMBER_STARTED,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  ACCEPT_INVITE_STARTED,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAIL,
  FETCH_MEMBERS_STARTED,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAIL,
  FETCH_INVITES_STARTED,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_FAIL,
  FETCH_USER_INVITES_STARTED,
  FETCH_USER_INVITES_SUCCESS,
  FETCH_USER_INVITES_FAIL,
  DELETE_INVITE_STARTED,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAIL,
  FETCH_GROUP_SONGS_STARTED,
  FETCH_GROUP_SONGS_SUCCESS,
  FETCH_GROUP_SONGS_FAIL,
  POST_GROUP_AVATAR_STARTED,
  POST_GROUP_AVATAR_SUCCESS,
  POST_GROUP_AVATAR_FAIL,
  HIDE_ERROR,
} from './groups.events';

const a = event => () => ({ type: event });
const emptyAction = event => () => ({ type: event });
const errorAction = event => () => ({ type: event, error: true });

// Creating a group
export const postGroupDB = group => ({ type: ADD_GROUP, group });
export const postGroupStarted = a(POST_GROUP_STARTED);
export const postGroupSuccess = response => ({
  type: POST_GROUP_SUCCESS,
  data: {
    group: response,
  },
});
export const postGroupFail = response => ({
  type: POST_GROUP_FAIL,
  data: {
    error: response,
  },
});

export const hideError = () => {
  return {
    type: HIDE_ERROR,
    data: null,
  };
};

// Deleting a group
export const deleteGroupDB = groupCuid => ({
  type: DELETE_GROUP,
  data: {
    groupCuid,
  },
});
export const deleteGroupStarted = a(DELETE_GROUP_STARTED);
export const deleteGroupSuccess = groupCuid => ({
  type: DELETE_GROUP_SUCCESS,
  data: {
    groupCuid,
  },
});
export const deleteGroupFail = a(DELETE_GROUP_FAILED);

// leaving a group as a member
export const leaveGroupStarted = a(LEAVE_GROUP_STARTED);
export const leaveGroupSuccess = groupCuid => ({
  type: LEAVE_GROUP_SUCCESS,
  data: {
    groupCuid,
  },
});
export const leaveGroupFail = a(LEAVE_GROUP_FAIL);

export const inviteMembersStarted = a(INVITE_MEMBERS_STARTED);
export const inviteMembersSuccess = (invites, groupCuid) => ({
  type: INVITE_MEMBERS_SUCCESS,
  data: {
    invites,
    groupCuid,
  },
});
export const inviteMembersFail = a(INVITE_MEMBERS_FAIL);

export const deleteGroupInviteStarted = a(DELETE_INVITE_STARTED);
export const deleteGroupInviteSuccess = (groupCuid, groupInviteCuid) => ({
  type: DELETE_INVITE_SUCCESS,
  data: {
    groupCuid,
    groupInviteCuid,
  },
});
export const deleteGroupInviteFail = a(DELETE_INVITE_FAIL);

export const deleteMemberStarted = a(DELETE_MEMBER_STARTED);
export const deleteMemberSuccess = (groupCuid, userCuid) => ({
  type: DELETE_MEMBER_SUCCESS,
  data: {
    groupCuid,
    userCuid,
  },
});
export const deleteMemberFail = a(DELETE_MEMBER_FAIL);

export const fetchGroupsStarted = a(FETCH_GROUPS_STARTED);
export const fetchGroupsFail = a(FETCH_GROUPS_FAIL);

export const fetchGroupsSuccess = response => ({
  type: FETCH_GROUPS_SUCCESS,
  data: {
    groupList: response,
    lastFetchDate: new Date(),
  },
});

export const fetchGroupSongsStarted = emptyAction(FETCH_GROUP_SONGS_STARTED);
export const fetchGroupSongsFail = errorAction(FETCH_GROUP_SONGS_FAIL);

export const fetchGroupSongsSuccess = (groupCuid, response) => ({
  type: FETCH_GROUP_SONGS_SUCCESS,
  payload: {
    groupSongList: response,
    groupCuid,
    lastFetchDate: new Date(),
  },
});

export const acceptInviteStarted = a(ACCEPT_INVITE_STARTED);
export const acceptInviteFail = a(ACCEPT_INVITE_FAIL);
export const acceptInviteSuccess = a(ACCEPT_INVITE_SUCCESS);

export const fetchMembersStarted = a(FETCH_MEMBERS_STARTED);
export const fetchMembersFail = a(FETCH_MEMBERS_FAIL);
export const fetchMembersSuccess = (groupCuid, response) => ({
  type: FETCH_MEMBERS_SUCCESS,
  data: {
    memberList: response,
    groupCuid,
    lastFetchDate: new Date(),
  },
});

export const addGroup = group => ({ type: ADD_GROUP, group });

export const updateGroup = group => ({
  type: UPDATE_GROUP,
  group: { ...group, updatedDate: new Date().toISOString() },
});

export const fetchInvitesStarted = a(FETCH_INVITES_STARTED);
export const fetchInvitesSuccess = (groupCuid, response) => ({
  type: FETCH_INVITES_SUCCESS,
  data: {
    inviteList: response,
    groupCuid,
    lastFetchDate: new Date(),
  },
});
export const fetchInvitesFail = a(FETCH_INVITES_FAIL);

export const fetchUserInvitesStarted = a(FETCH_USER_INVITES_STARTED);
export const fetchUserInvitesSuccess = response => ({
  type: FETCH_USER_INVITES_SUCCESS,
  data: {
    myInvites: response,
    lastFetchDate: new Date(),
  },
});
export const fetchUserInvitesFail = a(FETCH_USER_INVITES_FAIL);

export const postGroupAvatarStarted = a(POST_GROUP_AVATAR_STARTED);
export const postGroupAvatarSuccess = response => ({
  type: POST_GROUP_AVATAR_SUCCESS,
  data: {
    avatarUrl: response.avatarUrl,
    groupCuid: response.groupCuid,
    lastFetchDate: new Date(),
  },
});
export const postGroupAvatarFail = a(POST_GROUP_AVATAR_FAIL);
