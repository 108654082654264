export const hardcodedSongs = {
  cjkzugkpi00003t5radj5bk76: require('../../songs/and-so-to-sleep-again.json'),
  cjkzui88l00003t5r08us8qsw: require('../../songs/youve-got-a-friend-in-me.json'),
  cjltjly0t00003f5xk2dtqjzp: require('../../songs/FAM_VoxGuide1_180827.json'),
  cjltkq39100003f5xzryqy86p: require('../../songs/FAM_VoxGuide2_180827.json'),
  cjltkuwz500003f5xfa4o9goo: require('../../songs/FAM_VoxGuide3_180827.json'),
  cjltl4esu00003f5xvwgeunkd: require('../../songs/FAM_VoxGuide3_MidiPianoTrack_180827.json'),
  cjltsptde00003f5xw9m5bach: require('../../songs/FYC-Stille-Nacht.json'),
  cjlyz0jy600003t5uie6ipjxy: require('../../songs/FYC-Chilcott.json'),
  cjmgh52kb00003h5o2dafpxfa: require('../../songs/FAM-Angels.json'),
  cjmghfu1300003r5uog75mq8l: require('../../songs/FAM-Baba_Yetu.json'),
  cjmn74cjt00003t5ycr23jrin: require('../../songs/FAM-We-Three-Kings.json'),
  cjmw3yj3f00003h5pw182d4h7: require('../../songs/FYC-Fade.json'),
  cjn04hzau00003h5l2nehfy2y: require('../../songs/FYC-Ave_Verum.json'),
  cjn0dl7on00003h5kw0dtt6r3: require('../../songs/FYC-Sicut_Cervus.json'),
  cjn0jc4d300003h5hsdr9wxe9: require('../../songs/As_Dew_In_Aprille.json'),
  cjn1pmw1a00003h5ls51eo1uj: require('../../songs/CF_Deo_Gracias.json'),
  cjn1sctvv00003r5yaw6dyaii: require('../../songs/CF_There_Is_No_Rose.json'),
  cjn1ukxv900003h5k99byhhj8: require('../../songs/CF_This_Little_Babe.json'),
  cjog22bha00003h5kp8kytrgs: require('../../songs/FAM-Every-Time.json'),
  cjozttf7500003k5sfamqp0c2: require("../../songs/BM-When-You're-Smiling.json"),
  cjp8cekpv00003h5uyknsffb8: require('../../songs/BM-RoarFirework-Medley.json'),
  cjpaaatwk00003h5lipb7jqef: require('../../songs/BM-Orange-Colored-Sky.json'),
  cjqqvod8l00003q63kptmx0b3: require('../../songs/BM-Smile.json'),
  cjqmkz2bm00003g5rhcketvwf: require('../../songs/FYC-Ubi-Caritas.json'),
  cjqqry85y00003g5rwhiprsy0: require('../../songs/FYC-Til-Ungdommen.json'),
  cjrsfadat00003g5oxwy9bcwz: require('../../songs/EY-Lean-On-Me.json'),
  cjrslolnb00003k5xlnpzgeph: require('../../songs/EY-Sweet-Rivers.json'),
  cjrsnnafk00003k5xmll8x82k: require('../../songs/EY-Both-Sides-Now.json'),
  cjrsp3xfb00003k5xrccsr1qs: require('../../songs/EY-Somebody-To-Love.json'),
  cjrtgmvlq00003g5vnrd6tjq4: require('../../songs/BM-Come-Fly-With-Me.json'),
  cjrtt2pql00003k5xg9btqo79: require('../../songs/BM-Rolling-In-The-Deep.json'),
  cjullns6k00003h5x836eyi1f: require("../../songs/BM-Don't-Rain-On-My-Parade.json"),
  cjwv2nbtk00003g5rukl7uo7i: require('../../songs/FYC-Cest-la-vie.json'),
  ck16uzt6k00003g5qktc4osni: require('../../songs/EY-HeyDay.json'),
  ck6vitk6l00op3h5qjoawrm2y: require('../../songs/Choirpal_Tutorial.json'),
};
