import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './BillingDetails.styled';
import Button from '@material-ui/core/Button';
import { Grid, Typography, CircularProgress, Chip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const BillingDetails = ({
  onClickCancel,
  onClickResubscribe,
  onClickOpenStripe,
  isResubscribing,
  isCancellingSubscription,
  subscription,
}) => {
  const [showCard, setShowCard] = useState(false);
  const {
    source,
    sub_start,
    sub_end,
    cancel_at_period_end,
    status,
  } = subscription;

  const handleResubscribe = () => {
    onClickResubscribe(sub_end);
  };
  const handleOpenStripe = () => {
    onClickOpenStripe(source);
  };
  const handleCancel = () => {
    onClickCancel(sub_end);
  };

  const cardNumber = source => {
    return `${source.brand} ************ ${source.last4}`;
  };
  const cardExpiry = source => {
    return `${source.exp_month}/${source.exp_year}`;
  };

  return (
    <Fragment>
      <S.BillingDetails>
        {status === 'active' || status === 'trialing' ? (
          // active member
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Pro Member Since:</Typography>
              <Fragment>{sub_start}</Fragment>
            </Grid>
            {!cancel_at_period_end ? (
              // has member cancelled?
              <Fragment>
                <Grid item xs={12}>
                  <Typography variant="h6">Next Billing Date:</Typography>
                  <Fragment>{sub_end}</Fragment>
                </Grid>
                {isCancellingSubscription ? (
                  <Grid item xs={12}>
                    <CircularProgress></CircularProgress>
                  </Grid>
                ) : (
                  <Fragment>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenStripe()}>
                        Update Payment Method
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleCancel()}>
                        Cancel Subscription
                      </Button>
                    </Grid>
                  </Fragment>
                )}

                {source.last4 ? (
                  <Grid item xs={12}>
                    {showCard ? (
                      <Fragment>
                        <Chip
                          color="primary"
                          label={cardNumber(source)}
                          avatar={
                            <CreditCardIcon
                              style={{ backgroundColor: 'transparent' }}
                            />
                          }
                          disabled
                        />
                        <Typography variant="body2">
                          Expiry Date: {cardExpiry(source)}
                        </Typography>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => setShowCard(false)}>
                          Hide
                        </Button>
                      </Fragment>
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        size="small"
                        onClick={() => setShowCard(true)}>
                        Show current card details
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <h4>New details will load shortly...</h4>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            ) : (
              // member has cancelled
              <Fragment>
                <Grid item xs={6}>
                  <Alert
                    style={{
                      display: 'flex',
                      // alignItems: 'center',
                      // justifyContent: 'center',
                      fontSize: '1rem',
                    }}
                    variant="outlined"
                    severity="warning">
                    <Typography variant="h6">
                      Subscription will end :
                    </Typography>
                    <Fragment>{sub_end}</Fragment>
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  {isResubscribing ? (
                    <CircularProgress></CircularProgress>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleResubscribe()}>
                      Resubscribe to Choirpal Pro
                    </Button>
                  )}
                </Grid>
              </Fragment>
            )}
          </Grid>
        ) : (
          status === 'incomplete' ||
          (status === 'past_due' && (
            <Fragment>
              <Alert
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.25rem',
                }}
                severity="warning">
                Your subscription was not renewed, please update your card
                details to avoid cancellation.
              </Alert>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleOpenStripe()}>
                Update Payment Method
              </Button>
            </Fragment>
          ))
        )}
      </S.BillingDetails>
    </Fragment>
  );
};

BillingDetails.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickResubscribe: PropTypes.func.isRequired,
  onClickOpenStripe: PropTypes.func.isRequired,
  isResubscribing: PropTypes.bool.isRequired,
  isCancellingSubscription: PropTypes.bool.isRequired,
  subscription: PropTypes.object.isRequired,
};

export default BillingDetails;
