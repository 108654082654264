import styled from 'styled-components';

export const EditSongDetails = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;
`;

export const EditSongDetails__Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  padding: 24px;
`;

export const EditSongDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SongTitle = styled.div`
  width: 100%;
`;

export const WrittenBy = styled.div`
  margin-top: 12px;
`;

export const RecordedBy = styled.div`
  margin-top: 12px;
`;

export const Save = styled.div`
  margin-top: 32px;
  width: 100%;
`;
