export const fontSize = {
  xxxxxLarge: '6rem', // 96px (ie h1)
  xxxxLarge: '4rem', // 64px (ie h2)
  xxxLarge: '3rem', // 48px (ie h3)
  xxLarge: '2rem', // 32px (ie h4)
  xLarge: '1.25rem', // 20px (ie h5)
  large: '1.125rem', // 18px (ie h6)
  medium: '1rem', // 16px (ie body)
  small: '0.875rem', // 14px (ie smaller body)
  xSmall: '0.75rem', // 12px
};

export const fontFamily = {
  regular: 'Platform Web',
};

export const lineHeight = {
  xxxxxLarge: '1.197916667', // 115px (ie h1)
  xxxxLarge: '1.1875', // 76px (ie h2)
  xxxLarge: '1.1875', // 57px (ie h3)
  xxLarge: '1.1875', // 38px (ie h4)
  xLarge: '1.2', // 24px (ie h5)
  large: '1.222222', // 22px (ie h6)
  medium: '1.25', // 20px (ie body)
  small: '1.214285714', // 17px (ie smaller body)
  xSmall: '1.166666667', // 14px
};

export const breakpoint = {
  xSmall: '450px',
  small: '500px',
  medium: '600px',
  large: '900px',
  xLarge: '1025px',
};

export const color = {
  darkGrey: '#262626',
  grey: '#7F7F7F',
  lightGrey: '#E4E6E8',

  red100: '#ff6b6f',
  red80: '#ff898b',
  red60: '#ffa8a9',
  red40: '#ffc5c6',
  red20: '#ffe1e1',

  pink100: '#ffe5d9',
  pink80: '#ffeae1',
  pink60: '#fff0e8',
  pink40: '#fff5ef',
  pink20: '#fffaf8',

  blue100: '#1d428a',
  blue80: '#4b69a1',
  blue60: '#798eba',
  blue40: '#a5b4d1',
  blue20: '#d3d9e8',

  yellow100: '#ffc56e',
  yellow80: '#ffd08c',
  yellow60: '#ffdcaa',
  yellow40: '#ffe8c6',
  yellow20: '#fff4e3',

  green: '#708690',

  white: '#FFFFFF',

  // 'clr-primary': '#5f6368',
  // 'clr-secondary': '#D8AA96',
  // 'clr-tertiary': '#fafafa',
  // 'clr-black': '#807182',
  // 'clr-error': '#F7B1AB',
};
