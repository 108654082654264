import React from 'react';
import PropTypes from 'prop-types';
import FilePicker from '../../Form/FilePicker/FilePicker';
import * as S from './MediaPicker.styled';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

function MediaPicker({
  onFilesSelected,
  songCuid,
  label,
  type,
  allowMultiple,
  acceptFileTypes,
}) {
  const handleFileSelected = fileList => {
    onFilesSelected(fileList, songCuid, type);
  };

  return (
    <S.MediaPicker>
      <FilePicker
        onFileSelected={handleFileSelected}
        allowMultiple={allowMultiple}
        acceptFileTypes={acceptFileTypes}>
        <Button
          style={{ marginTop: 10, width: '100%' }}
          size="large"
          color="primary"
          component="span"
          fullWidth={true}>
          <AddIcon
            style={{ height: '40px', width: '40px', marginRight: '12px' }}
          />
          {label}
        </Button>
      </FilePicker>
    </S.MediaPicker>
  );
}

MediaPicker.propTypes = {
  onFilesSelected: PropTypes.func.isRequired,
  songCuid: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  acceptFileTypes: PropTypes.string,
  allowMultiple: PropTypes.bool,
};

export default MediaPicker;
