import React from 'react';
import PropTypes from 'prop-types';
import SongListItemConnected from '../SongListItem/SongListItemConnected';
import List from '@material-ui/core/List';
import * as S from '../MySongs/MySongs.styled';
import { isNative } from '../../../util/envUtil';

function SongList({ groupCuid, songDocumentList }) {
  const renderListItem = (songDocument, index) => {
    const { songCuid } = songDocument;
    return (
      <SongListItemConnected
        key={songCuid}
        songCuid={songCuid}
        groupCuid={groupCuid}
      />
    );
  };

  return (
    <List>
      {songDocumentList.map(renderListItem)}
      {isNative() ? <S.ListPadding native={true} /> : <S.ListPadding />}
    </List>
  );
}

SongList.propTypes = {
  groupCuid: PropTypes.string,
  songDocumentList: PropTypes.array,
};

export default SongList;
