import React from 'react';
import createSvgIcon from '../IconSVG/createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      fill="none"
      strokeWidth="6"
      strokeMiterlimit="10"
      d="M10,19.756C10,6.611,19.702,1.23,31.551,7.807
  l99.56,55.242c11.853,6.569,11.853,17.328,0,23.904l-99.56,55.248C19.702,148.763,10,143.391,10,130.238V54.39"
    />
  </g>,
  150,
  150,
);
