import { path, dissoc } from 'ramda';

import {
  ADD_GROUP,
  UPDATE_GROUP,
  POST_GROUP_STARTED,
  POST_GROUP_SUCCESS,
  POST_GROUP_FAIL,
  FETCH_GROUPS_FAIL,
  FETCH_GROUPS_STARTED,
  FETCH_GROUPS_SUCCESS,
  INVITE_MEMBERS_FAIL,
  INVITE_MEMBERS_STARTED,
  INVITE_MEMBERS_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_STARTED,
  DELETE_GROUP_SUCCESS,
  LEAVE_GROUP_STARTED,
  LEAVE_GROUP_SUCCESS,
  LEAVE_GROUP_FAIL,
  DELETE_GROUP_FAILED,
  DELETE_MEMBER_STARTED,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  DELETE_INVITE_STARTED,
  DELETE_INVITE_SUCCESS,
  DELETE_INVITE_FAIL,
  FETCH_MEMBERS_STARTED,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAIL,
  FETCH_INVITES_STARTED,
  FETCH_INVITES_FAIL,
  FETCH_INVITES_SUCCESS,
  FETCH_USER_INVITES_STARTED,
  FETCH_USER_INVITES_SUCCESS,
  FETCH_USER_INVITES_FAIL,
  FETCH_GROUP_SONGS_STARTED,
  FETCH_GROUP_SONGS_SUCCESS,
  FETCH_GROUP_SONGS_FAIL,
  POST_GROUP_AVATAR_STARTED,
  POST_GROUP_AVATAR_SUCCESS,
  POST_GROUP_AVATAR_FAIL,
  HIDE_ERROR,
} from './groups.events';

import { byId, setPath } from '../storeUtil';

const initialState = {
  groupsById: {},
  membersByGroupId: {},
  invitesByGroupId: {},
  songCuidsByGroupId: {},
  myInvitesList: [],
  userGroupPair: null,
  isPostingGroup: false,
  isPostingGroupAvatar: false,
  isFetchingGroups: false,
  isInvitingMember: false,
  isDeletingGroup: false,
  isLeavingGroup: false,
  isDeletingMember: false,
  isFetchingMembers: false,
  isFetchingGroupSongs: false,
};
export const groupsReducer = (state = initialState, action) => {
  const groupsById = { ...state.groupsById };
  const membersByGroupId = { ...state.membersByGroupId };
  const invitesByGroupId = { ...state.invitesByGroupId };
  switch (action.type) {
    // create new group

    case ADD_GROUP: {
      const { group } = action;
      const { groupCuid } = group;
      groupsById[groupCuid] = group;
      return { ...state, groupsById };
    }
    case UPDATE_GROUP: {
      const { group } = action;
      const { groupCuid } = group;
      groupsById[groupCuid] = group;
      return { ...state, groupsById };
    }
    case POST_GROUP_STARTED: {
      return { ...state, isPostingGroup: true };
    }
    case POST_GROUP_SUCCESS: {
      return { ...state, isPostingGroup: false };
    }
    case POST_GROUP_FAIL: {
      const { error } = action.data;
      return {
        ...state,
        error: error,
        isPostingGroup: false,
      };
    }

    case HIDE_ERROR: {
      return {
        ...state,
        error: '',
      };
    }

    // FETCH ALL OF MY GROUPS

    case FETCH_GROUPS_STARTED: {
      return { ...state, isFetchingGroups: true };
    }
    case FETCH_GROUPS_SUCCESS: {
      const { groupList, lastFetchDate } = path(['data'], action);
      groupList.forEach(group => {
        const { groupCuid } = group;
        groupsById[groupCuid] = group;
      });
      return { ...state, lastFetchDate, isFetchingGroups: false, groupsById };
    }
    case FETCH_GROUPS_FAIL: {
      return { ...state, isFetchingGroups: false };
    }

    case FETCH_INVITES_STARTED: {
      return { ...state, isFetchingInvites: true };
    }
    case FETCH_INVITES_SUCCESS: {
      const { inviteList, groupCuid, lastFetchDate } = path(['data'], action);

      invitesByGroupId[groupCuid] = byId('groupInviteCuid', inviteList);
      return {
        ...state,
        lastFetchDate,
        isFetchingInvites: false,
        invitesByGroupId,
      };
    }
    case FETCH_INVITES_FAIL: {
      return { ...state, isFetchingInvites: false };
    }

    case FETCH_USER_INVITES_STARTED: {
      return { ...state, isFetchingUserInvites: true };
    }
    case FETCH_USER_INVITES_SUCCESS: {
      const { myInvites = [], lastFetchDate } = path(['data'], action);
      return {
        ...state,
        lastFetchDate,
        isFetchingUserInvites: false,
        myInvitesList: myInvites || [],
      };
    }
    case FETCH_USER_INVITES_FAIL: {
      return { ...state, isFetchingUserInvites: false };
    }

    case INVITE_MEMBERS_STARTED: {
      return { ...state, isInvitingMember: true };
    }

    case INVITE_MEMBERS_SUCCESS: {
      const { invites = [], groupCuid } = path(['data'], action);

      const existingInvites = invitesByGroupId[groupCuid] || {};
      const updatedInvites = byId('groupInviteCuid', invites);

      const updatedInvitesByGroupId = {
        ...invitesByGroupId,
        [groupCuid]: { ...existingInvites, ...updatedInvites },
      };
      return {
        ...state,
        isInvitingMember: false,
        invitesByGroupId: updatedInvitesByGroupId,
      };
    }
    case INVITE_MEMBERS_FAIL: {
      return { ...state, isInvitingMember: false };
    }

    // DELETE GROUP
    case DELETE_GROUP: {
      return { ...state };
    }
    case DELETE_GROUP_STARTED: {
      return { ...state, isDeletingGroup: true };
    }
    case DELETE_GROUP_SUCCESS: {
      const { groupCuid } = action.data;
      const updatedGroupsById = dissoc(groupCuid, groupsById);
      return {
        ...state,
        isDeletingGroup: false,
        groupsById: updatedGroupsById,
      };
    }
    case DELETE_GROUP_FAILED: {
      return { ...state, isDeletingGroup: false };
    }

    // LEAVE GROUP

    case LEAVE_GROUP_STARTED: {
      return { ...state, isLeavingGroup: true };
    }
    case LEAVE_GROUP_SUCCESS: {
      const { groupCuid } = action.data;
      const updatedGroupsById = dissoc(groupCuid, groupsById);
      return {
        ...state,
        isLeavingGroup: false,
        groupsById: updatedGroupsById,
      };
    }
    case LEAVE_GROUP_FAIL: {
      return { ...state, isLeavingGroup: false };
    }

    // REMOVE USER FROM GROUP
    case DELETE_MEMBER_STARTED: {
      return { ...state, isDeletingMember: true };
    }
    case DELETE_MEMBER_SUCCESS: {
      const { userCuid, groupCuid } = path(['data'], action);
      const members = membersByGroupId[groupCuid];
      membersByGroupId[groupCuid] = dissoc(userCuid, members);

      return { ...state, isDeletingMember: false, membersByGroupId };
    }
    case DELETE_MEMBER_FAIL: {
      return { ...state, isDeletingMember: false };
    }

    // REMOVE INVITE FROM GROUP
    case DELETE_INVITE_STARTED: {
      return { ...state, isDeletingMember: true };
    }
    case DELETE_INVITE_SUCCESS: {
      const { groupInviteCuid, groupCuid } = path(['data'], action);
      const oldInvites = invitesByGroupId[groupCuid] || {};
      invitesByGroupId[groupCuid] = dissoc(groupInviteCuid, oldInvites);

      return { ...state, isDeletingInvite: false, invitesByGroupId };
    }
    case DELETE_INVITE_FAIL: {
      return { ...state, isDeletingInvite: false };
    }

    // FETCH ALL USERS IN GROUP
    case FETCH_MEMBERS_STARTED: {
      return { ...state, isFetchingUsers: true };
    }
    case FETCH_MEMBERS_SUCCESS: {
      const { memberList, groupCuid, lastFetchDate } = path(['data'], action);
      membersByGroupId[groupCuid] = byId('userCuid', memberList);
      return {
        ...state,
        isFetchingUsers: false,
        lastFetchDate,
        membersByGroupId,
      };
    }
    case FETCH_MEMBERS_FAIL: {
      return { ...state, isFetchingUsers: false };
    }

    case FETCH_GROUP_SONGS_STARTED: {
      return { ...state, isFetchingGroupSongs: true };
    }

    case FETCH_GROUP_SONGS_SUCCESS: {
      const { payload } = action;
      const { groupCuid, groupSongList = [] } = payload;
      const groupSongCuids = groupSongList.map(
        song => song.songDocument.songCuid,
      );
      const songCuidsByGroupId = setPath(
        [groupCuid],
        groupSongCuids,
        state.songCuidsByGroupId,
      );

      return { ...state, isFetchingGroupSongs: false, songCuidsByGroupId };
    }

    case FETCH_GROUP_SONGS_FAIL: {
      return { ...state, isFetchingGroupSongs: false };
    }

    case POST_GROUP_AVATAR_STARTED: {
      return { ...state, isPostingGroupAvatar: true };
    }
    case POST_GROUP_AVATAR_SUCCESS: {
      const { avatarUrl, groupCuid } = path(['data'], action);
      const { groupsById } = state;
      const currentGroup = groupsById[groupCuid];
      const updatedGroup = { ...currentGroup, imageUrl: avatarUrl };
      groupsById[groupCuid] = updatedGroup;
      return { ...state, isPostingGroupAvatar: false, groupsById };
    }
    case POST_GROUP_AVATAR_FAIL: {
      return { ...state, isPostingGroupAvatar: false };
    }
    default:
      return state;
  }
};
