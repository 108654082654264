import { connect } from 'react-redux';
import { isLoggedIn } from '../../../store/user/user.selectors';
import { logoutThunk } from '../../../store/user/user.thunks';
import withAppContext from '../../../withAppContext';
import TopAppBar from './TopAppBar';

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: isLoggedIn(state),
    pathname: ownProps.pathname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickLogout: () => {
    dispatch(logoutThunk());
  },
  onClickBack: () => {
    ownProps.history.goBack();
  },
});

export default withAppContext(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopAppBar),
);
