import React from 'react';
import PropTypes from 'prop-types';
import * as S from './StudioToolbar.styled';
import { color } from '../../design-tokens';

// import SplitIcon from '@material-ui/icons/CallSplit';
import IconCut from '../../Theme/Icons/IconCut';
import PagesIcon from '@material-ui/icons/ViewColumn';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {
  LYRICS_TOOL,
  SECTIONS_TOOL,
  PAGES_TOOL,
} from '../../../store/player/player.tools';

function StudioToolbar(props) {
  const { onClickEnableTool } = props;
  const activeToolName = props.activeToolName || SECTIONS_TOOL;

  const handleToolClick = (event, clickedToolName) => {
    // console.log({ clickedToolName });
    // activeToolName === clickedToolName
    //   ? onClickDisableTool(clickedToolName)
    //   : onClickEnableTool(clickedToolName);
    if (clickedToolName) {
      onClickEnableTool(clickedToolName);
    }
  };

  return (
    <S.StudioToolbar>
      <ToggleButtonGroup
        value={activeToolName || SECTIONS_TOOL}
        size="small"
        exclusive
        onChange={handleToolClick}
        aria-label="text alignment">
        <ToggleButton
          value="SECTIONS_TOOL"
          size="small"
          onChange={() => {
            handleToolClick(SECTIONS_TOOL);
          }}>
          <IconCut color={color.grey} size="small" fontSize="small" />
          <S.ToolText>sections</S.ToolText>
        </ToggleButton>

        <ToggleButton
          value="LYRICS_TOOL"
          size="small"
          onChange={() => {
            handleToolClick(LYRICS_TOOL);
          }}>
          <TextFieldsIcon fontSize="small" />
          <S.ToolText>lyrics</S.ToolText>
        </ToggleButton>

        <ToggleButton
          value="PAGES_TOOL"
          size="small"
          onChange={() => {
            handleToolClick(PAGES_TOOL);
          }}>
          <PagesIcon fontSize="small" />
          <S.ToolText>pages</S.ToolText>
        </ToggleButton>
      </ToggleButtonGroup>
    </S.StudioToolbar>
  );
}

StudioToolbar.propTypes = {
  songCuid: PropTypes.string.isRequired,
  activeToolName: PropTypes.string.isRequired,
  onClickEnableTool: PropTypes.func.isRequired,
  onClickDisableTool: PropTypes.func.isRequired,
};

export default StudioToolbar;
