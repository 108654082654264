import {
  OPEN_SONG,
  CHANGE_TRACK,
  ENABLE_TOOL,
  DISABLE_TOOL,
  CLEAR_PLAYER,
} from './player.events';
import { setPath } from '../storeUtil';

const initialState = {
  songCuid: '',
  playlistType: '',
  playlistCuid: '',
  selectedTrackIndex: 0,
  activeToolName: '',
  recentlyPlayedSongCuids: [],
};

export const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SONG: {
      const { data } = action;
      const { songCuid, wasClicked } = data;
      const { recentlyPlayedSongCuids = [] } = state;
      const tempSongCuids = recentlyPlayedSongCuids
        .filter(c => c !== songCuid)
        .filter(Boolean);

      // Only update the list if play was triggered by the user, not auto-played
      const updatedSongCuids = wasClicked
        ? [songCuid, ...tempSongCuids]
        : recentlyPlayedSongCuids;

      return {
        ...state,
        ...data,
        activeToolName: '',
        recentlyPlayedSongCuids: updatedSongCuids,
      };
    }
    case CHANGE_TRACK:
      return setPath(['selectedTrackIndex'], action.trackIndex, state);
    case ENABLE_TOOL:
      return setPath(['activeToolName'], action.activeToolName, state);
    case DISABLE_TOOL:
      return setPath(['activeToolName'], '', state);
    case CLEAR_PLAYER:
      return initialState;
    default:
      return state;
  }
};
