import React, { Fragment } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';
import { Grid, CircularProgress } from '@material-ui/core';
import Text from '../../../Typography/Text/Text';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as S from './CheckoutForm.styled';
import CouponInputConnected from '../CouponInput/CouponInputConnected';
import InputError from '../../../Form/InputError/InputError';
import { Alert } from '@material-ui/lab';
import H3 from '../../../Typography/Heading/H3';
import SectionHeader from '../../../Layout/SectionHeader/SectionHeader';
export const CheckoutForm = ({
  subscription,
  onClickPostStripe,
  doCloseClick,
  errorMsg,
  successMsg,
  coupon,
  isPostingSubscription,
  clearErrorMsg,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { source } = subscription;
  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#ff898b',
        color: '#4b69a1',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          backgroundColor: 'transparent',
          color: '#4b69a1',
        },
        '::placeholder': { color: '#4b69a1' },
      },
      invalid: {
        iconColor: '#ff898b',
        color: '#4b69a1',
      },
    },
  };

  const handleSubmit = event => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    onClickPostStripe(stripe, card, source, coupon.id);
  };
  const onClickClose = () => {
    doCloseClick();
  };
  const onCardFocus = () => {
    errorMsg && clearErrorMsg();
  };

  const renderSuccess = () => {
    return (
      <S.Success>
        <Alert
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.1rem',
          }}
          severity="success">
          <H3>Success!</H3>
          {successMsg}
        </Alert>
        <Button
          style={{ margin: 20, width: '30%' }}
          variant="outlined"
          color="secondary"
          onClick={() => onClickClose()}>
          Close
        </Button>
      </S.Success>
    );
  };

  const renderCardInput = () => {
    return (
      <S.CardDetails>
        <CardElement
          options={CARD_OPTIONS}
          onFocus={() => onCardFocus()}
          onChange={() => onCardFocus()}
        />
        {errorMsg && <InputError>{errorMsg}</InputError>}
        <br />
      </S.CardDetails>
    );
  };

  const renderSubscribe = () => {
    return (
      <Fragment>
        <SectionHeader title="Basket" />
        <S.Basket>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Period</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Total €</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Text size="xSmall">Choirpal Pro </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="xSmall">3 months</Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="xSmall">1</Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text size="xSmall">8.97</Text>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </S.Basket>
        <SectionHeader title="Card Details" />
        {renderCardInput()}
        <CouponInputConnected has_trialed={subscription.has_trialed} />
        <Fragment>
          <p style={{ margin: '0 auto' }}>
            By confirming your subscription, you allow Choirpal to charge your
            card for this payment and future payments in accordance with their
            terms.
          </p>
          <hr />
          <Button
            type="submit"
            style={{ margin: 20, width: '80%' }}
            variant="contained"
            color="primary"
            disabled={!stripe || isPostingSubscription}>
            {isPostingSubscription ? (
              <CircularProgress></CircularProgress>
            ) : (
              <Fragment>Subscribe</Fragment>
            )}
          </Button>
          <Button
            style={{ margin: 20, width: '30%' }}
            variant="outlined"
            color="secondary"
            onClick={() => onClickClose()}>
            Close
          </Button>
          <hr />
          <LockIcon /> Secure Checkout Powered by Stripe
        </Fragment>
      </Fragment>
    );
  };

  return (
    <S.CheckoutContainer>
      {!successMsg ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              {!source ? (
                <Fragment>{renderSubscribe()}</Fragment>
              ) : (
                <Fragment>
                  {renderCardInput()}
                  <Fragment>
                    <br />* This card will be used for payment starting next
                    billing period.
                    <hr />
                    <Button
                      type="submit"
                      style={{ margin: 20, width: '30%' }}
                      variant="contained"
                      color="primary"
                      disabled={!stripe || isPostingSubscription}>
                      {isPostingSubscription ? (
                        <CircularProgress></CircularProgress>
                      ) : (
                        <Fragment>Update</Fragment>
                      )}
                    </Button>
                    <Button
                      style={{ margin: 20, width: '30%' }}
                      variant="outlined"
                      color="secondary"
                      onClick={() => onClickClose()}>
                      Close
                    </Button>
                  </Fragment>
                </Fragment>
              )}
            </form>
          </Grid>
        </Grid>
      ) : (
        renderSuccess()
      )}
    </S.CheckoutContainer>
  );
};
CheckoutForm.propTypes = {
  onClickPostStripe: PropTypes.func,
  doCouponClick: PropTypes.func,
  doCloseClick: PropTypes.func,
  coupon: PropTypes.object,
  subscription: PropTypes.object,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  isPostingSubscription: PropTypes.bool,
  isFetchingCoupon: PropTypes.bool,
  clearErrorMsg: PropTypes.func.isRequired,
};
