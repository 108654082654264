import React from 'react';
import PropTypes from 'prop-types';

import './HeroTitleDescription.css';

export default function HeroTitleDescription({ classes }) {
  return (
    <div className="HeroTitleDescription">
      <h1 className="HeroTitleDescription__Title">
        Your choir anytime, anywhere
      </h1>
      <p className="HeroTitleDescription__Description">
        Practise from any device with an audio player built just for choirs. All
        your rehearsal tracks and sheet music in one place. Schedule your
        rehearsals and message your choir with the brand new ChoirPal app.
      </p>
    </div>
  );
}

HeroTitleDescription.propTypes = {
  classes: PropTypes.array,
};
