import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
// import * as S from './SongMenu.styled.js';

function GroupMenu({
  groupCuid,
  groupName,
  isAdmin,
  onClickDelete,
  onClickMembers,
  onClickLeaveGroup,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleButtonClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleClickDelete(e) {
    e.stopPropagation();
    onClickDelete(groupCuid, groupName);
    handleClose();
  }
  function handleClickMembers(e) {
    e.stopPropagation();
    onClickMembers(groupCuid);
    handleClose();
  }
  function handleClickLeaveGroup(e) {
    e.stopPropagation();
    onClickLeaveGroup(groupCuid, groupName);
    handleClose();
  }
  return (
    <div style={{ color: 'inherit' }}>
      <IconButton
        aria-owns={anchorEl ? 'SongMenu__Menu' : undefined}
        aria-haspopup="true"
        color="inherit"
        aria-label="Song Context Menu"
        onClick={handleButtonClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="SongMenu__Menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {isAdmin ? (
          [
            <MenuItem key="delete-group" onClick={handleClickDelete}>
              Delete Group
            </MenuItem>,
            <MenuItem key="view-member" onClick={handleClickMembers}>
              View Members
            </MenuItem>,
          ]
        ) : (
          <MenuItem onClick={handleClickLeaveGroup}>Leave Group</MenuItem>
        )}
      </Menu>
    </div>
  );
}

GroupMenu.propTypes = {
  groupCuid: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickMembers: PropTypes.func.isRequired,
  onClickLeaveGroup: PropTypes.func.isRequired,
};

export default GroupMenu;
