import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { addTrack } from '../../../store/myMusic/myMusic.actions';
import {
  getFileRecord,
  getMySong,
  getTrack,
} from '../../../store/myMusic/myMusic.selectors';
import {
  deleteMediaThunk,
  s3UploadThunk,
} from '../../../store/myMusic/myMusic.thunks';
import { s3GetUrl } from '../../../store/s3/s3-utils';
import TrackPlayer from './TrackPlayer';

const mapStateToProps = (state, routerProps) => {
  const { trackCuid, songCuid } = routerProps;
  const song = getMySong(songCuid, state);
  const track = getTrack(songCuid, trackCuid, state) || {};
  const { filesetCuid } = song;
  const { fileCuid } = track;
  const fileRecord = getFileRecord(filesetCuid, fileCuid, state);
  return {
    songCuid,
    track,
    s3GetUrl,
    fileRecord,
  };
};

const mapDispatchToProps = {
  onCancel: () => push('/studio/tracks'),
  onCreate: (shortname, durationSeconds, file) => {
    addTrack(shortname, durationSeconds, file);
    push('/studio/tracks');
  },
  onAddTrack: (songCuid, track) => addTrack(songCuid, track),
  onDelete: (songCuid, trackCuid) => deleteMediaThunk(songCuid, trackCuid),
  onFileSelected: (file, trackCuid) => s3UploadThunk(file, trackCuid),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackPlayer);
