import styled from 'styled-components';

export const EditGuide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

export const Tracks = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const SaveButton = styled.div`
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const GuideTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;
