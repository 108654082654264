import React from 'react';
import PropTypes from 'prop-types';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import PlayPause from '../../Player/PlayPause/PlayPause';
import * as S from './GuideListItem.styled';
import Chip from '@material-ui/core/Chip';

function GuideListItem(props) {
  const {
    guideCuid,
    songCuid,
    guideTitle = 'Untitled Guide',
    selectedTracks = [],
    trackUrls = [],
    onClickDelete,
    onClickEdit,
    onClickPlay,
  } = props;

  const handleDeleteClick = evt => {
    evt.preventDefault();
    onClickDelete(songCuid, guideCuid);
  };
  const handleEditClick = evt => {
    evt.preventDefault();
    onClickEdit(songCuid, guideCuid);
  };
  const handlePlayClick = () => {
    onClickPlay(songCuid, trackUrls);
  };

  return (
    <S.GuideListItem>
      <PlayPause
        isPlaying={false}
        onPlay={handlePlayClick}
        onPause={() => null}
        color="primary"
      />
      <S.ContentContainer onClick={handleEditClick}>
        <S.Filename>{guideTitle}</S.Filename>
        <S.Labels>
          {selectedTracks.map(track => {
            const { voicePart, subPart } = track;
            return (
              <Chip
                key={track.trackCuid}
                label={voicePart + ' ' + subPart}
                color="primary"
                style={{ height: '20px', marginLeft: '2px' }}
                onClick={handleEditClick}
              />
            );
          })}
        </S.Labels>
      </S.ContentContainer>

      <S.ButtonContainer>
        <Edit onClick={handleEditClick} />
        <Delete onClick={handleDeleteClick} />
      </S.ButtonContainer>
    </S.GuideListItem>
  );
}

GuideListItem.propTypes = {
  guideCuid: PropTypes.string.isRequired,
  songCuid: PropTypes.string.isRequired,
  guideTitle: PropTypes.string.isRequired,
  selectedTracks: PropTypes.array.isRequired,
  trackUrls: PropTypes.array.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickPlay: PropTypes.func.isRequired,
};

export default GuideListItem;
