import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useEventListener from '@use-it/event-listener';

function AppInit({ onLoad, onUnload }) {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEventListener('beforeunload', () => {
    onUnload();
  });

  return null;
}

AppInit.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
};

export default AppInit;
