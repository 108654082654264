import Amplify, { Storage } from 'aws-amplify';
import awsConfigs from './aws.config.json';
import { isNative } from './util/envUtil';

export function authInit(hostname) {
  const awsConfig = getAwsConfig(hostname);
  const { cognito, oauth } = awsConfig;
  const oauthConfig = {
    // Domain name TODO: change to choirpal
    domain: oauth.DOMAIN,
    // Authorized scopes
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn: window.location.origin + '/login',
    // Sign out URL
    redirectSignOut: window.location.origin + '/login',
    // options: { AdvancedSecurityDataCollectionFlag: false },
    responseType: 'token',
    clientId: cognito.APP_CLIENT_ID,
    userPoolId: cognito.USER_POOL_ID,
    identityPoolId: cognito.IDENTITY_POOL_ID,
  };
  return oauthConfig;
}

export default function amplifyInit(hostname) {
  const awsConfig = getAwsConfig(hostname);
  const { cognito, s3, apiGateway } = awsConfig;

  Amplify.Logger.LOG_LEVEL = 'WARN';

  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region: cognito.REGION,
      userPoolId: cognito.USER_POOL_ID,
      userPoolWebClientId: cognito.APP_CLIENT_ID,
      identityPoolId: cognito.IDENTITY_POOL_ID,
      clientId: cognito.APP_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: 'settings',
          endpoint: apiGateway.URL,
          region: apiGateway.REGION,
        },
        {
          name: 'songs',
          endpoint: apiGateway.URL,
          region: apiGateway.REGION,
        },
        {
          name: 'users',
          endpoint: apiGateway.URL,
          region: apiGateway.REGION,
        },
        {
          name: 'groups',
          endpoint: apiGateway.URL,
          region: apiGateway.REGION,
        },
      ],
    },
    Storage: {
      region: s3.REGION,
      bucket: s3.BUCKET,
      identityPoolId: s3.IDENTITY_POOL_ID,
    },
  });
}

function getAwsConfig(hostname) {
  const { production, staging /* , development, test */ } = awsConfigs;
  const apiEnvName = process.env.REACT_APP_API_ENV_NAME;

  if (hostname === 'choirpal.com' || isNative()) {
    return production;
  } else if (hostname === 'staging.choirpal.com') {
    return staging;
  } else {
    return awsConfigs[apiEnvName];
  }
}

export function getStripeKey() {
  const config = getAwsConfig(window.location.hostname);
  const { billing } = config;
  return billing.STRIPE_PUBLIC_KEY;
}

export function setS3Config(bucket, level) {
  const configs = getAwsConfig(window.location.hostname);
  const { s3 } = configs;
  Storage.configure({
    bucket: bucket,
    level: level,
    region: 'eu-west-1',
    identityPoolId: s3.IDENTITY_POOL_ID,
  });
}
