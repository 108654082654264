import { connect } from 'react-redux';
import { showModal } from '../../../store/modal/modal.actions';
import { addSong } from '../../../store/myMusic/myMusic.actions';
import { newSong } from '../../../store/myMusic/myMusic.model';
import { createGroupRoute, songDetailsRoute } from '../../routes';
import AddButton from './AddButton';

const mapStateToProps = (state, ownProps) => {
  const { text } = ownProps;
  return { text };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { type } = ownProps;
  return {
    onAddClick: () => {
      if (type === 'addSong') {
        const song = newSong();
        dispatch(addSong(song));
        dispatch(songDetailsRoute(song.songCuid));
      } else if (type === 'addGroup') {
        dispatch(createGroupRoute());
      } else if (type === 'joinGroup') {
        dispatch(
          showModal('POPUP', {
            body:
              'Please contact your Conductor or admin to get an invite to the group',
          }),
        );
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddButton);
